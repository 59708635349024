import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';
// components
import LoginView from '../../../../components/LoginView/LoginView';
import LogoComponent from '../../../Login/components/LogoComponent';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {withTracker} from '../../../../utils/withTracker';
import {Api, errorAlert} from '../../../../utils/api';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
import {FormikHelpers, FormikValues} from 'formik';

function AmazonAuditRegister(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
    loadCaptchaEnginge(6);
  }, [history]);

  const initialValues = {
    email: '',
    captcha: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    captcha: Yup.string().test('test-captcha', 'Wrong captcha', validateCaptcha),
  });

  async function onSubmit(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>) {
    setSubmitting(true);
    alertService.clear();
    try {
      await Api.post(`users/amazonAudit`, data, {});
      alertService.success(<>Invitation email has been sent</>, {autoClose: false});
    } catch (e) {
      errorAlert('Unable to register', e);
    } finally {
      setSubmitting(false);
    }
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Captcha',
      name: 'captcha',
      icon: 'captcha',
      type: 'captcha',
      extra: <LoadCanvasTemplate />,
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Register"
        fields={loginFields}
        logo={<LogoComponent />}
        formDescription="Please enter account details"
        actionButton={(values) => {
          withTracker('Sign Up', values.email);
        }}
      />
      <CustomAlert id="default-alert" />
    </>
  );
}

export {AmazonAuditRegister};
