import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FnTypes = (...args: any[]) => void;

export const emitterService = {
  on: (event: string, fn: FnTypes): EventEmitter => eventEmitter.on(event, fn),
  once: (event: string, fn: FnTypes): EventEmitter => eventEmitter.once(event, fn),
  off: (event: string, fn: FnTypes): EventEmitter => eventEmitter.off(event, fn),
  emit: (event: string, payload: FnTypes): boolean => eventEmitter.emit(event, payload),
};

Object.freeze(emitterService);
