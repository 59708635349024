import React, {useEffect, useState, useCallback} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Paper,
  Tooltip,
  Typography,
  Grid,
  TextField,
} from '@mui/material';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {alertService} from '../../../../services/alert.service';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CommissionRule} from '../../components/CommissionRule';
import {CustomAlert} from '../../../Login/components/CustomAlert';

const sxStyles = {
  box: {
    padding: '10px',
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
};

export interface Rule {
  operator: string;
  fixed: string | number | null;
  minimum: string | number | null;
  tiers: {
    compare_var: string | number;
    compare_threshold: string | number;
    value: string | number;
    value_var: string | number;
    value_from: string | number;
    value_to: string | number;
  }[];
}

function CommissionDetail(): JSX.Element {
  const [brand, setBrand] = useState<string | null>(null);
  const [month, setMonth] = useState<moment.Moment | null>(null);
  const [current, setCurrent] = useState<Current | null>(null);
  const [rule, setRule] = useState<Rule>();
  const [percentage, setPercentage] = useState(5.0);
  const [openWaiveDialog, setOpenWaiveDialog] = useState(false);
  const [openWaivePercentageDialog, setOpenWaivePercentageDialog] = useState(false);
  const [override, setOverride] = useState<Rule | null>(null);

  const location = useLocation();

  type Current = {
    account_manager: string;
    brand_code: string;
    category: string;
    predicted: number;
    think_tank: string;
    value: number;
  };

  async function getRule(brandQuery: string) {
    try {
      const {data} = await Api.get(`brand-commission/rule/${brandQuery}`);
      setRule(data);
    } catch (e) {
      errorAlert('Unable to get commission rule data', e, {id: 'commission-details'});
    }
  }

  async function getCurrent(monthValue: moment.Moment, brandQuery: string) {
    try {
      const {data} = await Api.get(
        `brand-commission/detail/${brandQuery}?` +
          `from=${monthValue.startOf('month').format('YYYY-MM-DD')}` +
          `&to=${
            monthValue.endOf('month') > moment.utc()
              ? moment.utc().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
              : monthValue.endOf('month').format('YYYY-MM-DD')
          }`,
      );
      if (data && data[0]) {
        setCurrent(data[0]);
      }
    } catch (e) {
      errorAlert('Unable to get commission data', e, {id: 'commission-details'});
    }
  }

  async function getBrandCommission(monthValue: moment.Moment, brandQuery: string) {
    try {
      const {data} = await Api.get(
        `brand-commission/override?brandCode=${brandQuery}&month=${monthValue.format('YYYY-MM-DD')}`,
      );
      if (data) {
        setOverride(data);
      } else {
        setOverride(null);
      }
    } catch (e) {
      setOverride(null);
    }
  }

  const reload = useCallback(async (monthValue, brandQuery) => {
    if (!monthValue) return;
    await Promise.all([getCurrent(monthValue, brandQuery), getBrandCommission(monthValue, brandQuery)]);
  }, []);

  async function waive(percentage: number) {
    try {
      const res = await Api.post(`brand-commission/override`, {
        brandCode: brand,
        month: moment.utc().startOf('month').format('YYYY-MM-DD'),
        salesPercentage: percentage,
      });
      if (res.status === 201) {
        alertService.success('Commission waived successfully', {id: 'commission-details'});
        reload(month, brand);
      }
    } catch (e) {
      errorAlert('Error waiving commissions', e, {id: 'commission-details'});
    } finally {
      setOpenWaiveDialog(false);
      setOpenWaivePercentageDialog(false);
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const brandQuery = urlParams.get('brand');
    setBrand(brandQuery);
    const monthQuery = urlParams.get('month');
    const monthValue = monthQuery ? moment.utc(monthQuery) : moment.utc();
    setMonth(monthValue);
    getRule(brandQuery as string);
  }, [location.search]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (brand) {
      urlParams.set('brand', brand);
    }
    if (month) {
      urlParams.set('month', month.format('YYYY-MM'));
    }
    const newurl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({path: newurl}, '', newurl);
    reload(month, brand);
  }, [month, brand, reload, location.search]);

  return (
    <TableContainer container style={{paddingTop: 60}}>
      <Grid item lg={12} container spacing={2}>
        <Grid item lg={6} container spacing={2}>
          <Grid item lg={12} container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                BRAND
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brand ? brand : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Month"
                  inputFormat="YYYY/MMMM"
                  views={['month']}
                  value={month}
                  onChange={(x) => {
                    setMonth(x);
                  }}
                  renderInput={(params) => <TextField sx={{width: '100%'}} size="small" {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} style={{width: '100%'}}>
            <Paper sx={sxStyles.box} variant={'elevation'}>
              <Tooltip title="Selected month commissions" style={{cursor: 'help'}}>
                <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                  Commissions
                </Typography>
              </Tooltip>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {current ? current?.value.toFixed(2) : 'N/A'}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} style={{width: '100%'}}>
            <Paper sx={sxStyles.box} variant={'elevation'}>
              <Tooltip title="Forecast end of month commissions" style={{cursor: 'help'}}>
                <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                  Predicted
                </Typography>
              </Tooltip>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {current ? current?.predicted.toFixed(2) : 'N/A'}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sm={12} lg={12}>
            <CommissionRule rule={rule} />
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid item lg={12}>
            <Accordion>
              <AccordionSummary>
                <Typography variant={'subtitle1'}>Waive latest month commissions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>
                  <Typography variant={'body1'}>
                    Waive monthly commissions completely for this month. Commissions are going to be 0 until the rest of
                    the month.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant={'contained'} color={'primary'} onClick={() => setOpenWaiveDialog(true)}>
                    Waive
                  </Button>
                </CardActions>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                <Typography variant={'subtitle1'}>Waive latest month commissions and use Gross Sales %</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>
                  <Typography variant={'body1'}>
                    Waive monthly commissions to use percentage of Gross sales for this month. Commissions are going to
                    be selected percentage of Gross Sales until the rest of the month.
                  </Typography>
                  <Typography color={'primary'} variant={'h6'} style={{marginTop: 20}}>
                    Gross sales %
                    <Input
                      style={{marginLeft: 20}}
                      value={percentage}
                      onChange={(x) => setPercentage(x.target.value === '' ? 0 : Number(x.target.value))}
                      inputProps={{
                        step: 0.1,
                        type: 'number',
                        max: 100,
                        min: 0,
                      }}
                    />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant={'contained'} color={'primary'} onClick={() => setOpenWaivePercentageDialog(true)}>
                    Waive
                  </Button>
                </CardActions>
              </AccordionDetails>
            </Accordion>
            {override ? <CommissionRule rule={override} temp={true} /> : ''}
          </Grid>
        </Grid>
      </Grid>
      <CustomAlert id="commission-details" />
      <Dialog
        open={openWaiveDialog}
        onClose={() => setOpenWaiveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to waive commissions for this month?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Commissions are going to be 0 until the rest of the month.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWaiveDialog(false)}>Discard</Button>
          <Button color={'primary'} variant={'contained'} onClick={() => waive(0)} autoFocus>
            Waive
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWaivePercentageDialog}
        onClose={() => setOpenWaivePercentageDialog(false)}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <DialogTitle id="alert-dialog-title2">Are you sure you want to waive commissions for this month?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description2">
            <Typography>
              Commissions are going to be selected percentage of Gross Sales until the rest of the month.
            </Typography>
            <Typography variant={'h6'} color={'primary'}>
              {percentage}% Gross sales
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWaivePercentageDialog(false)}>Discard</Button>
          <Button color={'primary'} variant={'contained'} onClick={() => waive(percentage)} autoFocus>
            Waive
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

export {CommissionDetail};
