import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Tab, Tabs} from '@mui/material';
import {GridSearchIcon} from '@mui/x-data-grid';
import {AttachMoney, Edit, Equalizer, InfoOutlined, ListAlt, LocalOffer} from '@mui/icons-material';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Role} from '../../../utils/role';

export function buildMenu(currentValue: string, id: string, history: RouteComponentProps['history']): JSX.Element {
  return (
    <Tabs value={currentValue} orientation={'vertical'} centered>
      <Tab icon={<Edit />} label="Edit" value="edit" onClick={() => history.push(`/asins/update/${id}`)} />
      <Tab icon={<InfoOutlined />} label="Details" value="details" onClick={() => history.push(`/asins/view/${id}`)} />
      <Tab
        icon={<Equalizer />}
        label="Performance"
        value="performance"
        onClick={() => history.push(`/asins/performance/${id}`)}
      />
      {[Role.Admin, Role.Manager].includes(accountService.userValue.role) && (
        <Tab
          icon={<GridSearchIcon />}
          label="Keyword"
          value="keyword"
          onClick={() => history.push(`/asins/keywords/${id}`)}
        />
      )}
      <Tab
        icon={<ListAlt />}
        label="Inventory"
        value="inventory"
        onClick={() => history.push(`/asins/inventory/${id}`)}
      />
      {[Role.Admin, Role.Manager].includes(accountService.userValue.role) && (
        <Tab
          icon={<AttachMoney />}
          label="Fees Estimates"
          value="fees"
          onClick={() => history.push(`/asins/fees/${id}`)}
        />
      )}
      {[Role.Admin, Role.Manager].includes(accountService.userValue.role) && (
        <Tab
          icon={<LocalOffer />}
          label="Competing Offers"
          value="offers"
          onClick={() => history.push(`/asins/product-offers/${id}`)}
        />
      )}
    </Tabs>
  );
}
