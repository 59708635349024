import React, {useEffect, useState, useCallback} from 'react';
import {Typography, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {
  DataGrid,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridColTypeDef,
  GridFilterItem,
  GridFilterModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import moment from 'moment';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
import {buildMenu} from '../../components/menu';

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const columns: GridColDef[] = [
  {
    field: 'seller_sku',
    headerName: 'SKU',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'product_name',
    headerName: 'Name',
    flex: 0.15,
    minWidth: 500,
    sortable: false,
    filterable: false,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 0.1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'fulfillment_center_id',
    headerName: 'Fulfullment Center',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'detailed_disposition',
    headerName: 'Detailed Disposition',
    flex: 0.25,
    sortable: false,
    filterable: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 0.1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{moment(params.row.date).utc().format('M/d/y HH:mm')}</Typography>
    ),
  },
];

function AsinInventory(): JSX.Element {
  const firstMonth = '2021-04-01';
  const history = useHistory();

  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<GridSortItem>({field: 'month', sort: 'desc'});
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const rowsPerPageOptions = [20, 50, 100];

  const dateStart = moment(new Date(firstMonth));
  const dateEnd = moment(new Date());
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  function onPageChange(page: number) {
    setPage(page);
  }

  const loadData = useCallback(
    async function () {
      try {
        const {data} = await Api.get(`asin-inventory/${id}`, {
          params: {
            page: page,
            pageSize: pageSize,
            sortField: sort.field || null,
            sortOrder: sort.sort || null,
            filterField: filter?.columnField || null,
            filterValue: filter?.value || null,
          },
        });
        setTotal(data.count);
        setRows(
          data.rows.map(
            (
              item: {
                [x: string]: string;
              },
              index: number,
            ) => {
              return {
                ...item,
                id: index,
              };
            },
          ),
        );
      } catch (e) {
        errorAlert('Unable to get inventory data', e);
      }
    },
    [id, page, pageSize, sort, filter],
  );

  function handleSortModelChange(params: GridSortModel) {
    setSort(params[0]);
  }

  function handleFilterModelChange(params: GridFilterModel) {
    setFilter(params.items[0]);
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('inventory', id as string, history);
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [loadData, id, history]);

  return (
    <TableContainer>
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <Grid container spacing={1}>
          <Grid item lg={12} style={{width: '100%'}}>
            <DataGrid
              className="custom-table"
              autoHeight={true}
              pageSize={pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              page={page}
              rowCount={total}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rows={rows}
              columns={columns}
            />
            <CustomAlert id="default-alert" />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export {AsinInventory};
