import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Edit, Visibility} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridFilterItem,
  GridFilterModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
// services
import {accountService} from '../../services/account.service';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import {CustomAlert} from '../Login/components/CustomAlert';

const columns: GridColDef[] = [
  {field: 'asin', headerName: 'Asin', width: 140, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', width: 300, type: 'filterString'},
  {field: 'account_manager', headerName: 'Account manager', flex: 1, type: 'filterString'},
];

const brandUserColumns: GridColDef[] = [
  {field: 'asin', headerName: 'Asin', flex: 0.5, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', flex: 1.5, sortable: false, filterable: false},
  {field: 'account_manager', headerName: 'Account manager', flex: 1, sortable: false, filterable: false},
];

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

function Asins(): JSX.Element {
  document.title = 'ASINs';
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<GridSortItem | null>(null);
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const [loading, setLoading] = useState(false);
  const rowsPerPageOptions = [40, 80, 100];

  function onPageChange(page: number) {
    setPage(page);
  }

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await Api.get('asins', {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sort?.field || null,
          sortOrder: sort?.sort || null,
          filterField: filter?.columnField || null,
          filterValue: filter?.value || null,
        },
      });
      setCount(data.count);
      setRows(
        data.rows.map(
          (
            x: {
              [x: string]: string;
            },
            i: number,
          ) => {
            return {id: i, ...x};
          },
        ),
      );
    } catch (e) {
      errorAlert('Unable to get Asins', e);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, sort, filter]);

  function handleSortModelChange(params: GridSortModel) {
    setSort(params[0]);
  }

  function handleFilterModelChange(params: GridFilterModel) {
    setFilter(params.items[0]);
    setPage(0);
  }

  const thisRef = useRef(null);

  const customButtons =
    Role.BrandUser === accountService.userValue.role
      ? [
          {
            icon: <Visibility />,
            url: (v: {asin: string}) => `/asins/view/${v.asin}`,
            tooltip: 'View',
          },
        ]
      : [
          {
            icon: <Visibility />,
            url: (v: {asin: string}) => `/asins/view/${v.asin}`,
            tooltip: 'View',
          },
          {
            icon: <Edit />,
            url: (v: {asin: string}) => `/asins/update/${v.asin}`,
            tooltip: 'Edit',
          },
        ];

  useEffect(() => {
    makeColumnFreeze(thisRef, {}, rows);
  }, [rows]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <TableContainer style={{paddingTop: 50}}>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={rows} buttons={customButtons} />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        <DataGrid
          style={{width: '100%'}}
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowCount={count}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
          }}
          columnTypes={{filterString: stringColumnType}}
          onPageChange={onPageChange}
          loading={loading}
          pagination
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          rows={rows}
          disableSelectionOnClick={true}
          columns={
            Role.BrandUser === accountService.userValue.role
              ? ([...brandUserColumns, fixedPlaceHolder({})] as GridColDef[])
              : ([...columns, fixedPlaceHolder({})] as GridColDef[])
          }
        />
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {Asins};
