import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {ListItem, ListItemText, ListItemIcon} from '@mui/material';
import {DragHandle} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
// styles
import {sxStyles} from './Styles';

export type DraggableListItemProps = {
  item: GridColDef;
  index: number;
  disabled?: boolean;
};

const DraggableListItem = ({item, index, disabled}: DraggableListItemProps): JSX.Element => {
  const CustomComponent = (props: {draggableId: string; index: number; style: React.CSSProperties}) => {
    return (
      <Draggable {...props}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={
              snapshot.isDragging
                ? sxStyles('draggingListItem')
                : item.hide
                ? sxStyles('hideListItem')
                : sxStyles('listItem')
            }
          >
            <ListItemIcon>
              <DragHandle />
            </ListItemIcon>
            <ListItemText primary={item.headerName} />
          </ListItem>
        )}
      </Draggable>
    );
  };
  return disabled ? (
    <ListItem sx={item.hide ? sxStyles('hideListItem') : sxStyles('listItem')}>
      <ListItemIcon>
        <DragHandle />
      </ListItemIcon>
      <ListItemText primary={item.headerName} />
    </ListItem>
  ) : (
    <CustomComponent draggableId={item.field} index={index} style={{cursor: disabled ? 'default' : 'pointer'}} />
  );
};

export default DraggableListItem;
