import React, {useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {Grid, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import DataGridToolbar from '../../../../components/DataGridToolbar/DataGridToolbar';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../../../utils/tableSettings';
// styles
import {sxStyles} from '../../Styles';

interface BrandOverviewData {
  roup: string;
  displayName: string;
  count: number;
  percentage: number;
  id?: number;
  total: number;
}

export default function BrandsOverview(): JSX.Element {
  document.title = 'Brands Overview';
  const [rows, setRows] = useState<BrandOverviewData[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [tableLoading, setTableLoading] = useState(false);

  const columns: GridColDef[] = useMemo(
    () => [
      {field: 'group', headerName: 'By', minWidth: 280, flex: 1},
      {field: 'displayName', headerName: 'Tag', minWidth: 250, flex: 1},
      {
        field: 'total',
        headerName: 'Active',
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <span>{params.row.count || params.row.total || 0}</span>,
      },
      {
        field: 'percentage',
        headerName: 'Percentage %',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => <span>{params.row.percentage || 0}%</span>,
      },
    ],
    [],
  );

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: 'brands-overview',
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
  }, [loading, columns]);

  async function loadData() {
    setLoading(true);
    try {
      const {
        data,
      }: {
        data: {
          [key: string]: BrandOverviewData;
        };
      } = await Api.get('brands/overview-data');
      const newData: BrandOverviewData[] = [];
      let response = {};
      Object.entries(data).forEach(([key, value], index) => {
        response = {
          ...response,
          [key]: {key: key, id: index, ...value},
        };
      });
      _.chain(response)
        .groupBy('group')
        .forEach((value) => {
          newData.push(...value);
        })
        .value();
      setRows(newData);
    } catch (e) {
      errorAlert('Unable to get brands overview data', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const customToolbarProps = {
    loadingData: loading || tableLoading,
    ignoreItems: ['actions'],
    items: [...orderColumns],
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        'brands-overview',
        setTableLoading,
      );
    },
  };

  return (
    <TableContainer>
      <Grid item lg={12} style={{width: '100%'}}>
        <Typography variant="h5" sx={sxStyles('title')}>
          Brands Overview
        </Typography>
        <Grid container position="relative">
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            componentsProps={{
              toolbar: customToolbarProps,
              pagination: customToolbarProps,
            }}
            sx={sxStyles('overviewGrid')}
            className={`custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
            style={{width: '100%'}}
            autoHeight={true}
            loading={loading}
            disableSelectionOnClick={true}
            rows={rows}
            getRowClassName={(params) => `column-${params.row.group.replace('&', '').replaceAll(' ', '-')}`}
            columns={[...orderColumns]}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: columns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                'brands-overview',
                setTableLoading,
              );
            }}
          />
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}
