import moment from 'moment';
import {Sales} from '../pages/Brands/subpages/BrandsReports/interfaces/brand-reports.interfaces';

export const Helper = {
  arrayGroupBy: (
    array: Sales[],
    k: string | number,
  ): {
    [key: string]: Sales[];
  } => {
    return array.reduce(
      (
        result: {
          [key: string]: Sales[];
        },
        currentValue: Sales,
      ) => {
        const key = k as keyof typeof currentValue;

        (result[currentValue[key] as string] = result[currentValue[key] as string] || []).push(currentValue);

        return result;
      },
      {},
    );
  },
  getMonthsBetweenDates: (startDate: string | Date, endDate: string | Date | null = null): string[] => {
    const dateStart = moment(new Date(startDate));
    const dateEnd = endDate ? moment(new Date(endDate)) : moment(new Date());
    const timeValues: string[] = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push(dateStart.format('YYYY-MM'));
      dateStart.add(1, 'month');
    }
    return timeValues.reverse();
  },
  formatAmount(data: number): string {
    return new Intl.NumberFormat().format(data);
  },
  encodeSlash(uri: string): string {
    return uri.replace(/\//g, '%2F');
  },
  formatAmountAndUnitLabel(amount: number | undefined | null, unit: string): string {
    if (!amount || amount < 1) return `No ${unit}`;
    else if (amount === 1) return `${amount} ${unit}`;
    else return `${amount} ${unit}s`;
  },
};
