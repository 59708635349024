import {Api, errorAlert} from './api';
// interfaces
import {BrandInterface} from '../interfaces/brands/brand';

export async function getSubBrands(
  brandCodes: Array<string>,
  setLoading?: (x: boolean) => void,
): Promise<BrandInterface[] | null | undefined> {
  if (!brandCodes || brandCodes.length === 0) return;
  setLoading?.(true);
  try {
    const {data} = await Api.get(`/brands/sub-brands?brandCodes=${brandCodes}`);
    return data;
  } catch (e) {
    errorAlert('Error getting sub brands', e);
  } finally {
    setLoading?.(false);
  }
}
