import React, { useEffect, useState } from 'react';

// import {Link} from 'react-router-dom';
import {Grid} from '@mui/material';
import {Formik, FormikHelpers} from 'formik';
import {AxiosError} from 'axios';

// utils
import {Api, errorAlert} from '../../../../utils/api';
import {getArticle} from '../../../../utils/FFPBeacon';
import {BrandTypesAlias} from '../../../../utils/brand-types';

// components
import TableContainer from '../../../../components/TableContainer/TableContainer';

// import Button from '../../../../components/Button/Button';
import {AuthorizeSpApiForFFPBrand} from './components/AuthorizeSpApiForFFPBrand';
import {AuthorizeAdvertisingForFFPBrand} from './components/AuthorizeAdvertisingForFFPBrand';
import Spacer from '../../../../components/Spacer/Spacer';
import {GoogleDrive} from '../../components/GoogleDrive';
import {GoogleDriveTopFolder} from '../../components/GoogleDriveTopFolder';
import {CustomAlert} from '../../../Login/components/CustomAlert';

//services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';

// styles
import {sxStyles} from '../../Styles';

// interfaces
import {BrandInterface} from '../../../../interfaces/brands/brand';
import {LastSuccessInterface} from '../../../../interfaces/brands/last-success';

interface FfpSubBrandsProps {
  GAEvents?: (category: string, action: string, label?: string) => void;
}

interface GoogleFolderInterface extends BrandInterface {
  googleDriveFolders: BrandInterface['google_drive_folders'];
  lastSuccess: LastSuccessInterface[];
}

function FfpSubBrands({GAEvents}: FfpSubBrandsProps): JSX.Element {
  
  document.title = 'Connect to Amazon';

  const [brandInfo, setBrandInfo] = useState<BrandInterface | null>(null);
  const [googleDriveFolders, setGoogleDriveFolders] = useState<GoogleFolderInterface['googleDriveFolders']>([]);
  const [lastSuccess, setLastSuccess] = useState<LastSuccessInterface[]>([]);
  const [googleDriveLoaded, setGoogleDriveLoaded] = useState(false);

  async function getBrandInfo(): Promise<void> {
    // debug
    // console.log('accountService.userValue');
    // console.log(accountService.userValue);
    // end debug
    try {
      const {data} = await Api.get(`brands/${accountService.userValue.brand_code}`);
      setBrandInfo(data);
      setGoogleDriveFolders(data.google_drive_folders || []);
      setLastSuccess(data.last_success || []);
      setGoogleDriveLoaded(true);
    } catch (e) {
      errorAlert('Unable to get Brand Info', e);
    }
  }

  useEffect(() => {
    getBrandInfo();
  }, []);

  async function onSubmit(data: GoogleFolderInterface, {setSubmitting}: FormikHelpers<GoogleFolderInterface>) {
    alertService.clear();
    const fields = {
      google_drive_folders: data.googleDriveFolders,
    };
    try {
      await Api.patch(`brands/${accountService.userValue.brand_code}`, fields);
      alertService.success('Google Drive data updated successfully');
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to update Google Drive data', e);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)) {
      getArticle({});
    }
  }, [brandInfo]);

  // TODO revisit connect another seller central account
  return (
    <TableContainer>
      <Grid container style={{width: '100%'}}>
        {/* <Grid item xs={12} lg={12} style={{textAlign: 'right'}}>
          {brandInfo?.sp_api_authorized_marketplaces && brandInfo.sp_api_authorized_marketplaces.length > 0 && (
            <Link to="/brands/create-sub-brand">
              <Button
                onClick={() => {
                  GAEvents?.('Click', 'Create Sub Connector');
                }}
              >
                Connect Another Seller Central Account
              </Button>
            </Link>
          )}
          <Spacer height={20} />
        </Grid> */}
        <Grid container style={{marginBottom: 20}} rowSpacing={1.5} columnSpacing={{xs: 0, md: 1.5, xl: 1.5}}>
          <>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={5} sx={sxStyles('spApiCard')}>
              <AuthorizeSpApiForFFPBrand
                editable
                brandInfo={brandInfo}
                refreshBrand={getBrandInfo}
                brandCode={accountService.userValue.brand_code}
                hideButton={
                  !!brandInfo?.sp_api_authorized_marketplaces && brandInfo.sp_api_authorized_marketplaces.length > 0
                }
                GAEvents={(category, action, label) => {
                  GAEvents?.(category, action, label);
                }}
                advertisingAPI={
                  accountService.userValue.brand_type === 'ecom3k' ? (
                    <AuthorizeAdvertisingForFFPBrand
                      brandInfo={brandInfo}
                      refreshBrand={getBrandInfo}
                      brandCode={accountService.userValue.brand_code}
                      hideButton={
                        !!brandInfo?.sp_api_authorized_marketplaces &&
                        brandInfo.sp_api_authorized_marketplaces.length > 0
                      }
                      GAEvents={(category, action, label) => {
                        GAEvents?.(category, action, label);
                      }}
                    />
                  ) : null
                }
              />
            </Grid>
            {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <GoogleDriveTopFolder />
              </Grid>
            )}
          </>
        </Grid>
        <Spacer height={20} />
        {accountService.userValue.brand_type === 'ecom3k' &&
          brandInfo?.selling_partner_api_access &&
          googleDriveLoaded && (
            <Grid item xs={12} lg={12} style={{marginBottom: '25px'}}>
              <Formik
                initialValues={
                  {
                    googleDriveFolders,
                    lastSuccess,
                  } as GoogleFolderInterface
                }
                onSubmit={onSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({isSubmitting, values: formValues, submitForm, setFieldValue}) => {
                  return (
                    <Grid>
                      <GoogleDrive
                        brandReports={formValues.googleDriveFolders || []}
                        lastSuccess={formValues.lastSuccess || []}
                        brandCode={accountService.userValue.brand_code}
                        onSubmit={submitForm}
                        setValues={setFieldValue}
                        keyValue="googleDriveFolders"
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                  );
                }}
              </Formik>
            </Grid>
          )}
      </Grid>
      <CustomAlert id="default-alert" />
    </TableContainer>
  );
}

export {FfpSubBrands};
