
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import React, {useCallback, useEffect, useRef, useState} from 'react';

import { DateInterface, DateRangeInterface } from '../../interfaces/date/date';

import {Link} from 'react-router-dom';
import moment from 'moment';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Autocomplete} from '@mui/material';
import {useLocation} from 'react-router-dom';

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSortDirection,
  GridColumnVisibilityModel,
  GridSortItem,
  GridSortCellParams
} from '@mui/x-data-grid';
import {Marketplace} from '@bizon/amazon-ids'
import _ from 'lodash';
// components
import {MenuStore} from '../../App';
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import Button from '../../components/Button/Button';
import Spacer from '../../components/Spacer/Spacer';
import {PerformanceSummary} from './components/PerformanceSummary';
import {PerformanceChart} from './components/PerformanceChart';
import {CustomAlert} from '../Login/components/CustomAlert';
import DataGridToolbar from '../../components/DataGridToolbar/DataGridToolbar';
// utils
import {Role} from '../../utils/role';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {marketplaces} from './utils/marketplaces';
import {onFromToChange} from '../../utils/OnFromToChange';
import {rowsPerPageOptions} from '../../utils/constants';
import {Helper} from '../../utils/helper';
import {Api, errorAlert} from '../../utils/api';
import {getSubBrands} from '../../utils/getSubBrands';
// services
import mixPanel from '../../services/mixpanel';
import {accountService} from '../../services/account.service';
// styles
import {sxStyles} from './Styles';
// interfaces
import {BrandInterface} from '../../interfaces/brands/brand';

type DefaultQuery = {
  from?: string;
  to?: string;
  preset?: string | null;
  selected?: string[] | null;
  metric?: string[] | null;
  compareFrom?: string | null;
  compareTo?: string | null;
  filterBrands?: string[] | null;
  filterProductGroups?: string[] | null;
  filterAccountManagers?: string[] | null;
  filterMarketplaces?: string[] | null;
  groupBy?: string | null;
  sortBy?: string | null;
  sortDirection?: TSortDirection;
};

type Filter = {
  accountManagers?: string[];
  brandCodes?:string[];
  brands?: BrandInterface[] | string[];
  marketplace: Partial<Marketplace>[];
  productGroups?: string[] | null;
  subBrands?: BrandInterface[] | string[];
};

type FilterData = {
  filter?: Filter;
  data?: SkuRow[] | string[];
  from?: DateInterface['date'];
  to?: DateInterface['date'];
  compareFrom?: DateInterface['date'] | null;
  compareTo?: DateInterface['date'] | null;
  metric?: string[];
  groupBy?: string;
  sendData?: boolean;
};

type SkuRow = {
  account_manager: string;
  brand_code: string;
  brand_name: string;
};

type TMetricData = {
  interval:string;
  data:unknown
}

type TSortDirection =  'asc' | 'desc' | undefined


export default function OverallPerformance(): JSX.Element {
  document.title = 'Performance';
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);

  const [fromTo, setFromTo] = useState<DateRangeInterface>({from: new Date(), to: new Date(), compareFrom: null, compareTo: null});

  const [metric, setMetric] = useState<string[]>([]);
  const [period, setPeriod] = useState<string>('');
  const [groupBy, setGroupBy] = useState('sku');
  const [groupRows, setGroupRows] = useState([]);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');

  const [skuRows, setSkuRows] = useState<SkuRow[]>([]);
  const [filteredRows, setFilteredRows] = useState([]);

  // const [filter, setFilter] = useState<Filter>({});

  const [filter, setFilter] = useState<Filter|undefined>({
    accountManagers: [],
    brands: [],
    marketplace: [],
    productGroups: [],
    subBrands: [],
  });

  const [metricData, setMetricData] = useState<TMetricData | undefined>(undefined);
  const [summaryData, setSummaryData] = useState<unknown>(null);

  const [loadingSku, setLoadingSku] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [compare, setCompare] = useState(false);
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);

  const [filterData, setFilterData] = useState<FilterData>({
    filter: {
      accountManagers: [],
      brands: [],
      marketplace: [],
      productGroups: [],
      subBrands: [],
    },
  });

    // data?: SkuRow[] | string[];
    // from?: string | null;
    // to?: string | null;
    // compareFrom?: string | null;
    // compareTo?: string | null;
    // metric?: string[];
    // groupBy?: string;
    // sendData?: boolean;


  const [selectedRow, setSelectedRow] = useState(null);

  const thisRef = useRef(null);

  const defaultVisibilityModel = {
    sub_brand_code: false,
    account_manager: false,
  };

  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(defaultVisibilityModel);
  const [tableLoading, setTableLoading] = useState(false);
  const columns: GridColDef[] = [
    {
      field: 'product_image',
      headerName: 'Image',
      width: 100,
      filterable: false,
      renderCell: (params) => {
        if (params.row.product_image)
          return (
            <img
              src={params.row.product_image}
              alt={params.row.product_image}
              style={{maxWidth: '35px', maxHeight: '35px'}}
            />
          );
        else return <Typography>{'N/A'}</Typography>;
      },
    },
    {field: 'seller_sku', headerName: 'SKU', width: 200},
    {
      field: 'asin',
      width: 150,
      headerName: 'ASIN',
      renderCell: (x) => {
        return (
          <Link to={`/asins/performance/${x.value}?metric=revenue%2Cbuy_box%2Csession%2Cconversion`}>{x.value}</Link>
        );
      },
    },
    {
      field: 'brand_code',
      width: 150,
      headerName: 'Brand',
      renderCell: (x) => {
        return <Link to={`/brands/${x.value}/detail`}>{x.value}</Link>;
      },
    },
    {
      field: 'brand_name',
      headerName: 'Brand name',
      width: 160,
      renderCell: (x) => {
        return <Link to={`/brands/${x.row.brand_code}/detail`}>{x.value}</Link>;
      },
    },
    {
      field: 'sub_brand_code',
      headerName: 'Sub Brand',
      width: 280,
      renderCell: (x) => {
        return <Link to={`/brands/${x.value}/detail`}>{x.value}</Link>;
      },
    },
    {field: 'account_manager', headerName: 'Account manager', width: 280},
    {
      field: 'buy_box',
      headerName: 'BB%',
      width: 100,
      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'session',
      headerName: 'Sessions',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'revenue',
      headerName: 'Revenue',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'conversion',
      headerName: 'Conversions',
      width: 200,
      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'page_views_percentage',
      headerName: 'Page view %',
      width: 150,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'units',
      align: 'right',
      headerName: 'Units',
      width: 100,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'browser_session',
      headerName: 'Browser session',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'browser_page_views_percentage',
      headerName: 'Browser page view %',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'browser_conversion',
      headerName: 'Browser conversion',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'mobile_session',
      headerName: 'Mobile session',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'mobile_conversion',
      headerName: 'Mobile conversion',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      headerName: 'Mobile page view %',
      field: 'mobile_page_views_percentage',
      width: 130,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
  ];
  const brandColumns: GridColDef[] = [
    {
      field: 'logo',
      headerName: 'Logo',
      width: 100,
      filterable: false,
      renderCell: (params) => {
        if (params.row.logo)
          return (
            <img src={params.row.logo} alt={params.row.brand_code} style={{maxWidth: '35px', maxHeight: '35px'}} />
          );
        else return <Typography>{'N/A'}</Typography>;
      },
    },
    {
      field: 'brand_code',
      headerName: 'Brand',
      width: 120,
      renderCell: (x) => {
        return <Link to={`/brands/${x.value}/detail`}>{x.value}</Link>;
      },
    },
    {
      field: 'brand_name',
      headerName: 'Brand name',
      width: 160,
      renderCell: (x) => {
        return <Link to={`/brands/${x.row.brand_code}/detail`}>{x.value}</Link>;
      },
    },
    {field: 'account_manager', headerName: 'Account manager', width: 260},
    {
      field: 'buy_box',
      headerName: 'BB%',
      width: 160,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'session',
      headerName: 'Sessions',
      width: 160,

      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'revenue',
      headerName: 'Revenue',
      width: 160,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'conversion',
      headerName: 'Conversions',
      width: 160,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'page_views_percentage',
      headerName: 'Page view %',
      width: 160,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'units',
      align: 'right',
      headerName: 'Units',
      width: 100,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
  ];

  const asinColumns: GridColDef[] = [
    {
      field: 'product_image',
      headerName: 'Image',
      width: 100,
      filterable: false,
      renderCell: (params) => {
        if (params.row.product_image)
          return (
            <img
              src={params.row.product_image}
              alt={params.row.product_image}
              style={{maxWidth: '35px', maxHeight: '35px'}}
            />
          );
        else return <Typography>{'N/A'}</Typography>;
      },
    },
    {
      field: 'asin',
      headerName: 'ASIN',
      width: 160,
      renderCell: (x) => {
        return (
          <Link to={`/asins/performance/${x.value}?metric=revenue%2Cbuy_box%2Csession%2Cconversion`}>{x.value}</Link>
        );
      },
    },
    {
      field: 'brand_code',
      headerName: 'Brand',
      width: 160,
      renderCell: (x) => {
        return <Link to={`/brands/${x.value}/detail`}>{x.value}</Link>;
      },
    },
    {
      field: 'brand_name',
      headerName: 'Brand name',
      width: 160,
      renderCell: (x) => {
        return <Link to={`/brands/${x.row.brand_code}/detail`}>{x.value}</Link>;
      },
    },
    {field: 'account_manager', headerName: 'Account manager', width: 250},
    {
      field: 'buy_box',
      headerName: 'BB%',
      width: 160,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'session',
      headerName: 'Sessions',
      width: 160,

      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'revenue',
      headerName: 'Revenue',
      width: 160,

      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'conversion',
      headerName: 'Conversions',
      width: 160,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'page_views_percentage',
      headerName: 'Page view %',
      width: 180,

      filterable: false,
      renderCell: (x) => renderPercentageCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'units',
      align: 'right',
      headerName: 'Units',
      width: 100,

      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
  ];

  const asinConditionsColumns: GridColDef[] = [
    ...asinColumns.filter((x) => x.field !== 'asin').splice(0, 1),
    {
      field: 'asin',
      headerName: 'ASIN',
      width: 160,
    },
    ...asinColumns.filter((x) => x.field !== 'asin').splice(1),
  ];

  function sortCompare(v1: unknown, v2: unknown, cellParams1: GridSortCellParams<unknown>, cellParams2: GridSortCellParams<unknown>) {
    const value1 = cellParams1.value != null ? Math.round(parseFloat(cellParams1.value.toString()) * 100) / 100 : null;
    const value2 = cellParams2.value != null ? Math.round(parseFloat(cellParams2.value.toString()) * 100) / 100 : null;
    if (!compare && value1 && value2) {
      return value1 - value2;
    }

    const row1 = cellParams1.api.getRow(cellParams1.id);
    const row2 = cellParams2.api.getRow(cellParams2.id);

    const oldValue1 =
      'old_' + cellParams1.field in row1 && row1['old_' + cellParams1.field] != null
        ? Math.round(parseFloat(row1['old_' + cellParams1.field].toString()) * 100) / 100
        : null;
    const percentage1 =
      cellParams1.value !== null &&
      row1['old_' + cellParams1.field] != null &&
      row1['old_' + cellParams1.field] !== 0 &&
      value1 &&
      oldValue1
        ? ((value1 - oldValue1) * 100) / oldValue1
        : null;
    const oldValue2 =
      'old_' + cellParams2.field in row2 && row2['old_' + cellParams2.field]
        ? Math.round(parseFloat(row2['old_' + cellParams2.field].toString()) * 100) / 100
        : null;
    const percentage2 =
      cellParams2.value !== null &&
      row2['old_' + cellParams2.field] != null &&
      row2['old_' + cellParams2.field] !== 0 &&
      value2 &&
      oldValue2
        ? ((value2 - oldValue2) * 100) / oldValue2
        : null;
    if (percentage1 === null) {
      return -1;
    }
    if (percentage2 === null) {
      return 1;
    }
    return percentage1 - percentage2;
  }

  function renderPercentageCell(x: GridCellParams) {
    const value = x.value ? Math.round(parseFloat(x.value.toString()) * 100) / 100 : null;
    let oldValue = null;
    let percentage = null;
    if ('old_' + x.field in x.row) {
      oldValue =
        x.row['old_' + x.field] != null
          ? Math.round(parseFloat(x.row['old_' + x.field].toString()) * 100) / 100
          : 'N/A';
      if (value !== null && x.row['old_' + x.field] !== null && x.row['old_' + x.field] !== 0 && oldValue) {
        percentage = (((value - +oldValue) * 100) / +oldValue).toFixed(2);
      }
    }
    return (
      <div>
        <Typography color={'primary'}>{value ? `${value}%` : 'N/A'}</Typography>
        {'old_' + x.field in x.row ? (
          <Typography color={'textSecondary'}>{oldValue !== 'N/A' ? `${oldValue}%` : oldValue}</Typography>
        ) : (
          ''
        )}
        {percentage ? (
          <Typography sx={+percentage >= 0 ? sxStyles('green') : sxStyles('red')} color={'primary'}>
            {+percentage < 0 ? '' : '+'}
            {percentage}%
          </Typography>
        ) : (
          ''
        )}
      </div>
    );
  }

  function renderAmountCell(x: GridCellParams) {
    const value = x.value ? Math.round(parseFloat(x.value.toString()) * 100) / 100 : null;
    let oldValue = null;
    let percentage = null;
    if ('old_' + x.field in x.row) {
      oldValue =
        x.row['old_' + x.field] != null
          ? Math.round(parseFloat(x.row['old_' + x.field].toString()) * 100) / 100
          : 'N/A';
      if (value !== null && x.row['old_' + x.field] != null && x.row['old_' + x.field] !== 0 && oldValue) {
        percentage = (((value - +oldValue) * 100) / +oldValue).toFixed(2);
      }
    }
    return (
      <div>
        <Typography color={'primary'}>{value ? Helper.formatAmount(value) : 'N/A'}</Typography>
        {'old_' + x.field in x.row ? <Typography color={'textSecondary'}>{oldValue}</Typography> : ''}
        {percentage ? (
          <Typography sx={+percentage >= 0 ? sxStyles('green') : sxStyles('red')} color={'primary'}>
            {+percentage < 0 ? '' : '+'}
            {percentage}%
          </Typography>
        ) : (
          ''
        )}
      </div>
    );
  }

  const list =
    groupBy === 'sku'
      ? columns
      : groupBy === 'brand_code'
      ? brandColumns
      : groupBy === 'asin'
      ? asinColumns
      : asinConditionsColumns;

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: list,
        tableName: `performance-${groupBy}`,
        defaultVisibilityModel: defaultVisibilityModel,
        loading: setTableLoading,
        groupBy: groupBy,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  async function pullSubBrands(brandCodes: string[]) {
    let newSubBrands: BrandInterface[] = [];
    const data = await getSubBrands(brandCodes);
    if (data) {
      newSubBrands = data;
    }
    const userSubBrands = accountService.userValue?.sub_brand_codes;

    if (userSubBrands && userSubBrands.length > 0) {
      newSubBrands = newSubBrands.filter((subBrand: BrandInterface) => userSubBrands.includes(subBrand.brand_code));
    }

    setSubBrands(newSubBrands);
  }

  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    const fromQuery = urlParams.get('from');
    const toQuery = urlParams.get('to');
    const selectedQuery = urlParams.get('skus');
    const metricQuery = urlParams.get('metric');
    const groupByQuery = urlParams.get('groupBy');
    const compareFromQuery = urlParams.get('compareFrom');
    const compareToQuery = urlParams.get('compareTo');
    const brandsQuery = urlParams.get('filterBrands');
    const productGroupsQuery = urlParams.get('filterProductGroups');
    const accManagersQuery = urlParams.get('filterAccountManagers');
    const marketplacesQuery = urlParams.get('filterMarketplaces');
    const sortByQuery = urlParams.get('sortBy');
    const sortDirectionQuery = urlParams.get('sortDirection') as TSortDirection;
    const query: DefaultQuery = {
    };

    query.metric = metricQuery && metricQuery !== '' ? metricQuery.split(',') : ['revenue'];
    query.selected = selectedQuery && selectedQuery !== '' ? selectedQuery.split(',') : [];
    query.filterBrands = brandsQuery && brandsQuery !== '' ? brandsQuery.split(',') : [];
    const currentUser = accountService.userValue;
    if (query.filterBrands || currentUser.role === Role.BrandUser) {
      if (currentUser.role === Role.BrandUser) {
        query.filterBrands = [currentUser.brand_code];
      }
      pullSubBrands(query.filterBrands);
    }
    query.groupBy = groupByQuery;
    query.sortBy = sortByQuery;
    query.sortDirection = sortDirectionQuery;
    query.filterProductGroups = productGroupsQuery && productGroupsQuery !== '' ? productGroupsQuery.split(',') : [];
    if (!fromQuery || !toQuery) {
      query.to = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      query.from = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
      query.preset = '7d';
    } else if (!compareFromQuery || !compareToQuery) {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
    } else {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
      query.compareFrom = moment(compareFromQuery).format('YYYY-MM-DD');
      query.compareTo = moment(compareToQuery).format('YYYY-MM-DD');
    }

    query.filterAccountManagers = accManagersQuery && accManagersQuery !== '' ? accManagersQuery.split(',') : [];
    query.filterMarketplaces = marketplacesQuery && marketplacesQuery !== '' ? marketplacesQuery.split(',') : [];
    return query;
  }

  function updateQueryParams() {
    const search = new URLSearchParams(location.search);
    if (metric && metric.length !== 0 && metric.length !== skuRows.length) {
      search.set('metric', metric.toString());
    }
    if (fromTo.from && fromTo.to) {
      search.set('from', String(fromTo.from));
      search.set('to', String(fromTo.to));
    }
    if (fromTo.compareFrom && fromTo.compareTo) {
      search.set('compareFrom', String(fromTo.compareFrom));
      search.set('compareTo', String(fromTo.compareTo));
    } else {
      search.delete('compareFrom');
      search.delete('compareTo');
    }
    if (filter?.brands && filter.brands.length !== 0) {
      search.set('filterBrands', filter.brands.toString());
    } else {
      search.delete('filterBrands');
    }

    if (filter?.productGroups && filter.productGroups.length !== 0) {
      search.set('filterProductGroups', filter.productGroups.toString());
    } else {
      search.delete('filterProductGroups');
    }

    const accManagerFilter = Array.from(
      new Set(skuRows?.map((x) => x.account_manager).filter((x) => x != null)),
    ).filter((x) => filter?.accountManagers?.includes(x));
    if (accManagerFilter && accManagerFilter?.length !== 0) {
      search.set('filterAccountManagers', accManagerFilter.toString());
    } else {
      search.delete('filterAccountManagers');
    }

    if (filter?.marketplace && filter.marketplace.length !== 0) {
      search.set('filterMarketplaces', filter.marketplace.map((x) => x.id).toString());
    } else {
      search.delete('filterMarketplaces');
    }

    if (groupBy !== 'sku') search.set('groupBy', groupBy);
    else search.delete('groupBy');
    if (sortBy && sortDirection) {
      search.set('sortBy', sortBy);
      search.set('sortDirection', sortDirection);
    } else {
      search.delete('sortBy');
      search.delete('sortDirection');
    }
    const newurl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newurl}, '', newurl);
  }

  useEffect(() => {
    const defaultQuery = getDefaultQuery();
    setMetric(defaultQuery.metric ? defaultQuery.metric : ['revenue']);
    loadSkus(
      defaultQuery.selected,
      defaultQuery.preset,
      defaultQuery.from,
      defaultQuery.to,
      defaultQuery.compareFrom,
      defaultQuery.compareTo,
      true,
      defaultQuery.metric,
      {
        brands: defaultQuery.filterBrands,
        productGroups: defaultQuery.filterProductGroups,
        accountManagers: defaultQuery.filterAccountManagers,
        marketplace:
          ( defaultQuery?.filterMarketplaces && defaultQuery.filterMarketplaces?.length > 0 &&
            defaultQuery.filterMarketplaces.map((x) => marketplaces.find((y) => y.id === x))) ||
          marketplaces.filter((x) => x.id === 'ATVPDKIKX0DER'),
      },
      defaultQuery.groupBy,
      true,
    );
    setFromTo({
      from: defaultQuery.from,
      to: defaultQuery.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset);
    setCompare(defaultQuery.compareFrom != null);
    if (defaultQuery.groupBy) setGroupBy(defaultQuery.groupBy);
    if (defaultQuery.sortBy && defaultQuery.sortDirection) {
      setSortBy(defaultQuery.sortBy);
      setSortDirection(defaultQuery.sortDirection);
    }
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTo, skuRows, period, metric, filter, groupBy, sortBy, sortDirection]);

  async function loadSkus(selected:unknown, period:unknown, from:string, to:string, compareFrom:string, compareTo:string, init, metric, filter, groupBy?:string, update?:boolean) {
    setLoadingSku(true);
    try {
      const {data: brandData} = await Api.get(
        `performance/skus?from=${from}&to=${to}${compareFrom ? `&compareFrom=${compareFrom}` : ''}${
          compareTo ? `&compareTo=${compareTo}` : ''
        }${filter?.marketplace.length > 0 ? `&marketplace=${filter?.marketplace?.map((x:Marketplace) => x.id)}` : ''}`,
      );

      const data = brandData?.map((x:unknown) => {
        return {id: x.seller_sku.replace("'", '#'), ...x};
      });

      if (update) {
        setSkuRows(data);
        setFilteredRows(data);
      }

      onFilterChange({
        filter,
        data,
        from,
        to,
        compareFrom,
        compareTo,
        metric,
        groupBy,
        sendData: update,
      });
    } catch (e) {
      errorAlert('Unable to get SKUs', e, {id: 'sku-performance'});
    } finally {
      setLoadingSku(false);
    }
  }

  async function loadSummary(
    selected: string[],
    from: string | null,
    to: string | null,
    compareFrom: string | null,
    compareTo: string | null,
    filter: Filter,
  ) {
    setLoadingSummary(true);
    try {
      const {data} = await Api.post(`performance/summary`, {
        skus: selected,
        from: from,
        to: to,
        compareFrom: compareFrom,
        compareTo: compareTo,
        marketplace: filter?.marketplace?.map((x) => x.id),
      });
      setSummaryData(data);
    } catch (e) {
      errorAlert('Unable to get Summary', e, {id: 'asin-summary'});
    } finally {
      setLoadingSummary(false);
    }
  }

  function getInterval(from:Date, to:Date, compareFrom:Date, compareTo:Date) {
    const fromValue = compareFrom < from ? compareFrom : from;
    const toValue = compareTo < to ? compareTo : to;
    if (moment(toValue).diff(fromValue, 'hours') < 24) {
      return 'minute';
    }
    return moment(toValue).diff(fromValue, 'days') > 5 ? 'day' : 'hour';
  }

  async function loadMetric(selected:string[], from:Date, to:Date, compareFrom:Date, compareTo:Date, metric:string[], filter:Filter) {
    if (metric?.length === 0) return;
    setLoadingChart(true);
    const interval = getInterval(from, to, compareFrom, compareTo);
    try {
      const {data} = await Api.post(`performance/metric`, {
        metric: metric,
        skus: selected,
        from: from,
        to: to,
        compareFrom: compareFrom,
        compareTo: compareTo,
        interval: interval,
        marketplace: filter?.marketplace?.map((x) => x.id),
      });
      setMetricData({interval: interval, data: data});
    } catch (e) {
      errorAlert('Unable to get metric data', e, {id: 'performance-chart'});
    } finally {
      setLoadingChart(false);
    }
  }

  function toggleCompare(checked: boolean ) {
    setCompare(checked);
    document.getElementById(`accordion-clone`)?.remove();
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      preset: period,
      compare: checked,
      setFromTo: setFromTo,
    });
  }

  const changeMetric = useCallback(
    (x) => {
      onFilterChange({
        filter: filter,
        data: skuRows,
        from: fromTo.from,
        to: fromTo.to,
        compareFrom: fromTo.compareFrom,
        compareTo: fromTo.compareTo,
        metric: x,
        groupBy: groupBy,
        sendData: false,
      });
      setMetric(x);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredRows, fromTo, metric],
  );

  function onBackendFilterChange(
    filter: Filter,
    data: string,
    from: string,
    to: string,
    compareFrom: string,
    compareTo: string,
    metric: string[],
    groupBy: string
  ) {
    loadSkus(data, period, from, to, compareFrom, compareTo, false, metric, filter, groupBy, false);
  }

  function onFilterChange({
    filter,
    data,
    from,
    to,
    compareFrom,
    compareTo,
    metric,
    groupBy,
    sendData
  }: FilterData) {
    let result: Array<string|SkuRow>|undefined = data;
    document.getElementById(`accordion-clone`)?.remove();
    if (filter?.brands && filter.brands.length) {
      result = result?.filter((x:string|SkuRow) => typeof x !== 'string' &&  filter?.brands?.includes(x?.brand_code));

      if (filter?.subBrands && filter.subBrands.length > 0) {
        const subBrandCodes = filter?.subBrands.map((x) => x?.brand_code);

        result = result?.filter((x) => subBrandCodes.includes(x.sub_brand_code));
      }
    }

    const accManagerFilter = Array.from(new Set(data?.map((x) => x?.account_manager).filter((x) => x != null))).filter(
      (x) => filter?.accountManagers?.includes(x),
    );
    if (accManagerFilter && accManagerFilter?.length) {
      result = result?.filter((x: { account_manager: string; }) => accManagerFilter.includes(x.account_manager));
    }
    let newFilter = filter;
    if (filter?.brands && filter.brands.length === 0) {
      newFilter = {
        ...filter,
        subBrands: [],
      };
    }

    setFilter(newFilter);

    const selected = result?.map((x) => x.seller_sku);
    if (sendData) {
      setFilteredRows(result);
      if (groupBy !== 'sku') {
        calculateGroupBy(result, groupBy, compareFrom && compareTo);
      }
      if (selected.length) {
        loadSummary(selected, from, to, compareFrom, compareTo, newFilter);
        loadMetric(selected, from, to, compareFrom, compareTo, metric, newFilter);
      } else {
        setMetricData(null);
        setSummaryData(null);
      }
      if (newFilter?.marketplace && newFilter.marketplace.length > 0) {
        onBackendFilterChange(newFilter, result, from, to, compareFrom, compareTo, metric, groupBy);
      }
    }
    setFilterData({filter: newFilter, data: result, from, to, compareFrom, compareTo, metric, groupBy});
  }

  function calculateGroupBy(rows, groupBy, compare) {
    setGroupRows(
      _.map(_.groupBy(rows, groupBy === 'asin_conditions' ? 'asin' : groupBy), (o, idx) => {
        const result = compare
          ? {
              id: idx,
              revenue: _.sumBy(o, 'revenue'),
              buy_box: _.meanBy(o, 'buy_box'),
              sessions: _.sumBy(o, 'session'),
              conversion: _.meanBy(o, 'conversion'),
              page_views_percentage: _.meanBy(o, 'page_views_percentage'),
              units: _.sumBy(o, 'units'),
              old_revenue: _.sumBy(o, 'old_revenue'),
              old_buy_box: _.meanBy(o, 'old_buy_box'),
              old_session: _.sumBy(o, 'old_session'),
              old_conversion: _.meanBy(o, 'old_conversion'),
              old_page_views_percentage: _.meanBy(o, 'old_page_views_percentage'),
              old_units: _.sumBy(o, 'old_units'),
            }
          : {
              id: idx,
              revenue: _.sumBy(o, 'revenue'),
              buy_box: _.meanBy(o, 'buy_box'),
              session: _.sumBy(o, 'session'),
              conversion: _.meanBy(o, 'conversion'),
              page_views_percentage: _.meanBy(o, 'page_views_percentage'),
              units: _.sumBy(o, 'units'),
            };
        switch (groupBy) {
          case 'brand_code':
            return {
              brand_code: idx,
              brand_name: o.length ? o[0]['brand_name'] : null,
              logo: o.length ? o[0]['logo'] : null,
              // think_tank: o.length ? o[0]['think_tank'] : null,
              account_manager: o.length ? o[0]['account_manager'] : null,
              ...result,
            };
          case 'asin':
            return {
              asin: idx,
              brand_name: o.length ? o[0]['brand_name'] : null,
              product_image: o.length ? o[0]['product_image'] : null,
              brand_code: o.length ? o[0]['brand_code'] : null,
              // think_tank: o.length ? o[0]['think_tank'] : null,
              account_manager: o.length ? o[0]['account_manager'] : null,
              ...result,
            };
          case 'asin_conditions':
            return {
              asin: idx,
              brand_name: o.length ? o[0]['brand_name'] : null,
              product_image: o.length ? o[0]['product_image'] : null,
              brand_code: o.length ? o[0]['brand_code'] : null,
              // think_tank: o.length ? o[0]['think_tank'] : null,
              account_manager: o.length ? o[0]['account_manager'] : null,
              skus: o,
              ...result,
            };
          default:
            return {
              ...result,
            };
        }
      }).filter((x) => x.id !== 'null'),
    );
  }

  useEffect(() => {
    if (selectedRow !== null) {
      showAccordion(selectedRow);
    } else {
      document.getElementById(`accordion-clone`)?.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const setAccordion = (row) => {
    setSelectedRow((p) => (p === row ? null : row));
    document.getElementById(`accordion-clone`)?.remove();
  };
  const showAccordion = () => {
    const id = selectedRow.id;

    const row = document.querySelector(`div[data-id="${id}"]`);
    if (!document.getElementById(`accordion-clone`) && row) {
      const comp = thisRef.current;
      if (comp) {
        comp.id = `accordion-clone`;
      }
      row?.parentNode?.insertBefore(comp, row.nextSibling);
    }
  };

  function onGroupBy(e) {
    setGroupBy(e.target.value);
    setSortBy(null);
    setSortDirection('asc');
    if (e.target.value !== 'asin_conditions') {
      document.getElementById(`accordion-clone`)?.remove();
    }
    calculateGroupBy(filteredRows, e.target.value, compare);
  }

  const customToolbarProps = {
    loadingData: loadingSku || tableLoading,
    items: orderColumns,
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        `performance-${groupBy}`,
        setTableLoading,
      );
    },
  };

  useEffect(() => {
    if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type as string))
      mixPanel({
        eventName: 'Performance',
      });
  }, []);

  const onFilterDate = () => {
    loadSkus(
      skuRows,
      period,
      fromTo.from,
      fromTo.to,
      fromTo.compareFrom,
      fromTo.compareTo,
      false,
      metric,
      filter,
      groupBy,
      true,
    );
  };

  let sortModel: GridSortItem[] = [];
  if (sortBy !== null) {
    sortModel = [{
      field: sortBy,
      sort: sortDirection as GridSortDirection,
    }];
  }

  return (
    <React.Fragment>
      {groupBy === 'asin_conditions' ? (
        <div style={{display: 'none'}}>
          <div ref={thisRef} className="custom-accordion">
            <CustomDataGrid rows={selectedRow?.row?.skus || []} columns={columns} />
          </div>
        </div>
      ) : (
        ''
      )}
      <TableContainer>
        <DateRangeButtons
          sx={sxStyles('buttonGroup')}
          fromTo={fromTo}
          compare={compare}
          period={period}
          setPeriod={(v) => setPeriod(v)}
          setFromTo={(v) => setFromTo(v)}
          showButton
          buttonOnClick={() => onFilterDate()}
          compareOnChange={(e) => toggleCompare(e.target.checked)}
          compareComponent
          pageTitle="Overall Performance"
        />
        <Spacer height={15} />
        <PerformanceSummary data={summaryData} loading={loadingSummary || loadingChart} />
        <PerformanceChart
          data={metricData}
          onMetricChange={changeMetric}
          metric={metric}
          loading={loadingChart || loadingSku}
        />
        <Grid container spacing={1} style={{paddingTop: '30px'}}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={Array.from(
                new Set(
                  skuRows
                    .map((x) => x.brand_code)
                    .filter((x) => x != null)
                    .sort(),
                ),
              )}
              getOptionLabel={(option) => {
                const brandName = skuRows.find((x) => x.brand_code === option)?.brand_name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={(filter?.brands ? filter.brands: []) as string[]}
              onChange={(event, value) => {
                pullSubBrands(value);
                if (fromTo !== null && fromTo.from &&
                  fromTo.to && fromTo.compareFrom && fromTo.compareTo) {
                  onFilterChange({
                    filter: {...filter, brands: value},
                    data: skuRows,
                    from: fromTo.from,
                    to: fromTo.to,
                    compareFrom: fromTo.compareFrom,
                    compareTo: fromTo.compareTo,
                    metric: metric,
                    groupBy: groupBy,
                    sendData: false,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
              )}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              size="small"
              options={subBrands}
              getOptionLabel={(option) => option.brand_code}
              groupBy={(option) => option.parent_brand_code as string}
              value={filter?.subBrands ? filter.subBrands as BrandInterface[]: []}
              // getOptionSelected={(option, value) => option.brand_code === value.brand_code}
              onChange={(event, value) => {
                if (fromTo !== null && fromTo.from &&
                  fromTo.to && fromTo.compareFrom && fromTo.compareTo) {
                  onFilterChange({
                    filter: {...filter, subBrands: value},
                    data: skuRows,
                    from: fromTo.from,
                    to: fromTo.to,
                    compareFrom: fromTo.compareFrom,
                    compareTo: fromTo.compareTo,
                    metric: metric,
                    groupBy: groupBy,
                    sendData: false,
                  });
                } 
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by sub brands" />}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              size="small"
              options={marketplaces}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.name;
              }}
              value={filter?.marketplace ? filter.marketplace : []}
              onChange={(event, value) => {
                setFilter({
                  ...filter,
                  marketplace: value,
                });
                setFilterData({
                  ...filterData,
                  filter: {
                    ...filterData.filter,
                    marketplace: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by Marketplace" placeholder="Marketplace" />
              )}
            />
          </Grid>
          {accountService.userValue.role !== Role.BrandUser && (
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Autocomplete
                multiple
                size="small"
                options={Array.from(new Set(skuRows.map((x) => x.account_manager).filter((x) => x != null)))}
                getOptionLabel={(option) => option}
                value={
                  filter?.accountManagers
                    ? Array.from(new Set(skuRows.map((x) => x.account_manager).filter((x) => x != null))).filter((x) =>
                        filter.accountManagers?.includes(x),
                      )
                    : []
                }
                onChange={(event, value) => {
                  if (fromTo !== null && fromTo.from &&
                    fromTo.to && fromTo.compareFrom && fromTo.compareTo) {
                    onFilterChange({
                      filter: {...filter, accountManagers: value},
                      data: skuRows,
                      from: fromTo.from,
                      to: fromTo.to,
                      compareFrom: fromTo.compareFrom,
                      compareTo: fromTo.compareTo,
                      metric: metric,
                      groupBy: groupBy,
                      sendData: false,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by account managers"
                    placeholder="Account managers"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid container style={{paddingTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
          <Grid item lg={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              size="small"
              onClick={() => {
                onFilterChange({
                  ...filterData,
                  sendData: true,
                });
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{paddingTop: '15px'}}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <FormControl variant="outlined" size="small" style={{width: '100%'}}>
              <InputLabel id="demo-simple-select-outlined-label">Group by</InputLabel>
              <Select
                value={groupBy ? groupBy : 'sku'}
                onChange={onGroupBy}
                label={'Group by'}
                labelId="demo-simple-select-outlined-label"
                variant="outlined"
              >
                <MenuItem value={'sku'}>SKU</MenuItem>
                <MenuItem value={'brand_code'}>Brand</MenuItem>
                <MenuItem value={'asin'}>ASIN</MenuItem>
                <MenuItem value={'asin_conditions'}>Asin - All conditions</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
          <CustomAlert id="sku-performance" />
          <CustomAlert id="default-alert" />
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            componentsProps={{
              toolbar: customToolbarProps,
              pagination: customToolbarProps,
            }}
            sx={sxStyles('grid')}
            className={`custom-table ${tableLoading ? 'load-headers' : ''}`}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              let data: GridColumnVisibilityModel = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: list,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `performance-${groupBy}`,
                setTableLoading,
              );
            }}
            autoHeight={true}
            rows={groupBy === 'sku' ? filteredRows : groupRows}
            disableSelectionOnClick={true}
            columns={orderColumns}
            loading={loadingSku}
            disableColumnFilter
            rowHeight={72}
            pagination
            page={page}
            rowBuffer={pageSize}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setPage(0);
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            onSortModelChange={(x) => {
              if (x[0] !== null && x[0].field !== null && x[0].sort !== null) {
                setSortBy(x[0]?.field);
                setSortDirection(x[0]?.sort);
              }
            }}
            sortModel={sortModel}
            onCellClick={(params) => {
              if (groupBy === 'asin_conditions') setAccordion(params);
            }}
          />
        </Grid>
      </TableContainer>
    </React.Fragment>
  );
}
