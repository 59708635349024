import axios, { AxiosResponse } from 'axios';

// services
import {accountService} from '../services/account.service';
import {alertService} from '../services/alert.service';

const excludedURL = ['/auth/login'];

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Api.interceptors.request.use((config) => {
  const access = accountService.userValue?.accessToken;
  if (access) {
    config.headers['Authorization'] = `Bearer ${access}`;
  }
  return config;
});

Api.interceptors.response.use(
  (response): AxiosResponse => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && !excludedURL.includes(error.response.config.url)) {
      accountService.logout();
      alertService.warn('Session expired, please login again');
    }
    return Promise.reject(error);
  },
);

export const ApiWithCustomToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const errorAlert = (
  errorMessage: string | JSX.Element,
  // eslint-disable-next-line
  error: any,
  // eslint-disable-next-line
  id?: any,
): void => {
  let errorText = errorMessage;
  if (error?.response?.status !== 401) {
    if(error?.response?.data?.message) 
     errorText = errorText + ` ${error.response.data.message}`;
    alertService.error(errorText, id);
  }
};
