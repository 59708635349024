import React, {useEffect, useState, useRef, useCallback} from 'react';
import {AxiosError} from 'axios';
import {
  Typography,
  Link,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Grid,
} from '@mui/material';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridCellParams,
  GridColumnVisibilityModel,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import moment from 'moment';
import {LinkOff, Visibility, Send, Assignment} from '@mui/icons-material';
import io from 'socket.io-client';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
import TableContainer from '../../../components/TableContainer/TableContainer';
import Button from '../../../components/Button/Button';
import ModalButton from '../../../components/ModalButton/ModalButton';
import {brandReportTypes} from '../subpages/BrandsReports/utils/BrandReportProperties';
import TableSelectInput from '../../../components/TableSelectInput/TableSelectInput';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DataGridToolbar from '../../../components/DataGridToolbar/DataGridToolbar';
// utils
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../../utils/tableSettings';
import {rowsPerPageOptions} from '../../../utils/constants';
import getLabel from '../../../utils/getLabel';
import {Api, ApiWithCustomToken, errorAlert} from '../../../utils/api';
import {onFromToChange} from '../../../utils/OnFromToChange';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../../utils/makeColumnFreeze';
// services
import {accountService} from '../../../services/account.service';
import {alertService} from '../../../services/alert.service';
import {emitterService} from '../../../services/emitter.service';
// styles
import {sxStyles} from '../Styles';
// assets
import GoogleIcon from '../../../assets/images/google-icon.png';
// interfaces
import {DateRangeInterface} from '../../../interfaces/date/date';
import {GoogleDriveFolderInterface} from '../../../interfaces/brands/google-drive-folder';
import {LastSuccessInterface} from '../../../interfaces/brands/last-success';

declare let google: {
  accounts: {
    oauth2: {
      initTokenClient: (options: {client_id: string; callback: (authResult: authResult) => void; scope: string}) => {
        requestAccessToken: (props: {prompt: string}) => void;
      };
    };
  };
  picker: {
    PickerBuilder: new () => {
      setAppId: (appId: string) => {
        setOAuthToken: (token: string) => {
          setDeveloperKey: (developerKey: string) => {
            setCallback(callback: (data: PickerCallback) => void): {
              setLocale: (locale: string) => void;
            };
          };
        };
      };
    };
    Feature: {
      NAV_HIDDEN: string;
      MULTISELECT_ENABLED: string;
      SIMPLE_UPLOAD_ENABLED: string;
      SUPPORT_DRIVES: string;
    };
    Action: {
      PICKED: string;
      CANCEL: string;
    };
    DocsView: new (x: string) => {
      setMimeTypes: (mimeTypes: string) => void;
      setSelectFolderEnabled: (x: boolean) => void;
    };
    DocsUploadView: new () => {
      setMimeTypes: (mimeTypes: string) => void;
      setParent: (parent: string) => void;
      setIncludeFolders: (x: boolean) => void;
    };
    ViewId: ViewIdOptions;
  };
};

declare let gapi: {
  load: (api: string, options: {callback: () => void}) => void;
};

let accessToken: string;
let tokenClient;

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

type CallbackDoc = {
  downloadUrl?: string;
  uploadState?: string;
  description: string;
  driveSuccess: boolean;
  embedUrl: string;
  iconUrl: string;
  id: string;
  isShared: boolean;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  rotation: number;
  rotationDegree: number;
  serviceId: string;
  sizeBytes: number;
  type: string;
  url: string;
};

type PickerCallback = {
  action: string;
  docs: CallbackDoc[];
};

type authResult = {
  access_token: string;
  authuser: string;
  client_id: string;
  cookie_policy: string;
  expires_at: string;
  expires_in: string;
  issued_at: string;
  login_hint: string;
  response_type: string | undefined;
  scope: string;
  session_state: null;
  status: {signed_in: boolean; method: string; google_logged_in: boolean};
  token_type: string;
  error: boolean | undefined;
};

type ViewIdOptions =
  | 'DOCS'
  | 'DOCS_IMAGES'
  | 'DOCS_IMAGES_AND_VIDEOS'
  | 'DOCS_VIDEOS'
  | 'DOCUMENTS'
  | 'DRAWINGS'
  | 'FOLDERS'
  | 'FORMS'
  | 'PDFS'
  | 'SPREADSHEETS';

type PickerConfiguration = {
  clientId: string;
  developerKey: string;
  viewId?: ViewIdOptions;
  viewMimeTypes?: string;
  setIncludeFolders?: boolean;
  setSelectFolderEnabled?: boolean;
  disableDefaultView?: boolean;
  token?: string;
  multiselect?: boolean;
  disabled?: boolean;
  appId?: string;
  supportDrives?: boolean;
  showUploadView?: boolean;
  showUploadFolders?: boolean;
  setParentFolder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customViews?: any[];
  locale?: string;
  customScopes?: string[];
};

export const defaultConfiguration: PickerConfiguration = {
  clientId: '',
  developerKey: '',
  viewId: 'DOCS',
};

type HookOptions = {
  onCancel?: () => void;
};

function useDrivePicker({
  onCancel,
}: HookOptions): [
  (config: PickerConfiguration) => boolean | undefined,
  PickerCallback | undefined,
  authResult | undefined,
] {
  const defaultScopes = 'https://www.googleapis.com/auth/drive';
  const [pickerApiLoaded, setpickerApiLoaded] = useState(false);
  const [callBackInfo, setCallBackInfo] = useState<PickerCallback>();
  const [openAfterAuth, setOpenAfterAuth] = useState(false);
  const [authWindowVisible, setAuthWindowVisible] = useState(false);
  const [config, setConfig] = useState<PickerConfiguration>(defaultConfiguration);
  const [authRes, setAuthRes] = useState<authResult>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let picker: any;

  // use effect to open picker after auth
  useEffect(() => {
    if (openAfterAuth && config.token && pickerApiLoaded) {
      createPicker(config);
      setOpenAfterAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAfterAuth, config.token, pickerApiLoaded]);

  // open the picker
  const openPicker = (config: PickerConfiguration) => {
    // global scope given conf
    setConfig(config);

    // if we didnt get token generate token.
    if (!config.token) {
      setAuthWindowVisible(true);
    }

    // if we have token and everything is loaded open the picker
    if (config.token && pickerApiLoaded) {
      return createPicker(config);
    }
  };

  // load the Drive picker api
  const loadApis = useCallback(() => {
    gapi?.load('picker', {callback: onPickerApiLoad});
  }, []);

  // get the apis from googleapis
  useEffect(() => {
    if (!pickerApiLoaded) {
      loadApis();
    }
  }, [pickerApiLoaded, loadApis]);

  useEffect(() => {
    if (authRes) {
      accessToken = authRes.access_token;
    }
  }, [authRes]);

  const onPickerApiLoad = () => {
    setpickerApiLoaded(true);
  };

  // Open auth window after given config state is ready
  useEffect(() => {
    if (authWindowVisible) {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        callback: handleAuthResult,
        scope: defaultScopes,
      });
      if (!config.token) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({prompt: 'consent'});
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({prompt: ''});
      }
    }
  }, [authWindowVisible, config.clientId, config.token]);

  const handleAuthResult = (authResult: authResult) => {
    setAuthWindowVisible(false);

    if (authResult && !authResult.error) {
      setAuthRes(authResult);
      setConfig((prev) => ({...prev, token: authResult.access_token}));
      setOpenAfterAuth(true);
    }
  };

  const createPicker = ({
    token,
    appId = '',
    supportDrives = false,
    developerKey,
    viewId = 'DOCS' as ViewIdOptions,
    disabled,
    multiselect,
    showUploadView = false,
    showUploadFolders,
    setParentFolder = '',
    viewMimeTypes,
    customViews,
    locale = 'en',
    setIncludeFolders,
    setSelectFolderEnabled,
    disableDefaultView = false,
  }: PickerConfiguration) => {
    if (disabled) return false;

    const view = new google.picker.DocsView(google.picker.ViewId[+viewId]);
    if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
    if (setIncludeFolders) view.setSelectFolderEnabled(true);
    if (setSelectFolderEnabled) view.setSelectFolderEnabled(true);

    const uploadView = new google.picker.DocsUploadView();
    if (viewMimeTypes) uploadView.setMimeTypes(viewMimeTypes);
    if (showUploadFolders) uploadView.setIncludeFolders(true);
    if (setParentFolder) uploadView.setParent(setParentFolder);

    picker = new google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token as string)
      .setDeveloperKey(developerKey)
      .setCallback(pickerCallback)
      .setLocale(locale);

    if (!disableDefaultView) {
      picker.addView(view);
    }

    if (customViews) {
      customViews.map((view) => picker.addView(view));
    }

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    if (showUploadView) picker.addView(uploadView);

    if (supportDrives) {
      picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES);
    }

    picker.build().setVisible(true);
    return true;
  };

  // A simple callback implementation.
  const pickerCallback = (data: PickerCallback) => {
    if (data.action === google.picker.Action.CANCEL && onCancel) {
      onCancel();
    }

    if (data.action === google.picker.Action.PICKED) {
      setCallBackInfo(data);
    }
  };

  return [openPicker, callBackInfo, authRes];
}

function GoogleDrive(props: {
  lastSuccess: LastSuccessInterface[];
  brandReports: GoogleDriveFolderInterface[];
  keyValue: string;
  setValues: (key: string, value: GoogleDriveFolderInterface[]) => void;
  onSubmit: (values: GoogleDriveFolderInterface[], isSubmitting: boolean) => void;
  isSubmitting: boolean;
  brandCode: string;
}): JSX.Element {
  const [openPicker, pickerData] = useDrivePicker({
    onCancel: () => console.log('User closed picker with close/cancel button'),
  });
  const [rows, setRows] = useState<GoogleDriveFolderInterface[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [currentReportName, setCurrentReportName] = useState('');
  const [currentReportType, setCurrentReportType] = useState('');
  const [folders, setFolders] = useState<GoogleDriveFolderInterface[]>([]);
  const [selectedReport, setSelectedReport] = useState<LastSuccessInterface | null>(null);
  const [fromTo, setFromTo] = useState<DateRangeInterface>({
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  });
  const [reportToSend, setReportToSend] = useState<GoogleDriveFolderInterface | null>(null);
  const mobile = useMediaQuery('(max-width:767px)');

  const columns: GridColDef[] = [
    {
      field: 'reportName',
      headerName: 'Report',
      minWidth: 380,
      sortable: false,
    },
    {
      field: 'lastSuccess',
      headerName: 'Last Success',
      minWidth: 600,
      renderCell: (params: GridCellParams) => {
        if (params.row.reportType && props.lastSuccess.length) {
          const lastSuccessByReportType = props.lastSuccess?.find(
            (ls) => (ls?.reportResponse?.reportType || ls?.reportType) === params.row.reportType,
          );
          if (lastSuccessByReportType) {
            const ReportResponseElement = () => {
              return lastSuccessByReportType?.reportResponse ? (
                <>
                  <Button
                    size="small"
                    color="info"
                    style={{
                      padding: '5px 10px',
                      float: lastSuccessByReportType.fileId || lastSuccessByReportType.error ? 'right' : 'left',
                    }}
                    startIcon={<Visibility />}
                    variant="text"
                    onClick={() => {
                      setSelectedReport(lastSuccessByReportType);
                    }}
                  >
                    Report Response
                  </Button>
                </>
              ) : (
                <></>
              );
            };

            if (lastSuccessByReportType?.error) {
              return (
                <Grid container>
                  <Grid lg={12}>
                    <Typography component="span" color="error">
                      {lastSuccessByReportType.error}
                    </Typography>
                  </Grid>
                  <Grid lg={12}>
                    <ReportResponseElement />
                  </Grid>
                </Grid>
              );
            }

            const href = `https://drive.google.com/open?id=${lastSuccessByReportType.fileId}`;

            return (
              <Grid container>
                <Grid lg={12}>
                  <Typography>
                    <Link href={href} target="_blank" rel="noreferrer noopener">
                      {lastSuccessByReportType?.fileName}
                    </Link>
                  </Typography>
                </Grid>
                <Grid lg={12}>
                  <ReportResponseElement />
                </Grid>
              </Grid>
            );
          }

          return <></>;
        }

        return <></>;
      },
      sortable: false,
    },
    {
      field: 'folderName',
      headerName: 'Folder Name',
      flex: 1,
      minWidth: 300,
      editable: true,
      renderCell: (params) => {
        return (
          <TableSelectInput
            readOnly
            value={params.row?.folderId}
            loading={loading}
            renderValue={params.row?.folderName}
          />
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const ids = rows.map((o) => o.folderId);
        const optionsArray = rows.filter(
          (item, index) => !ids.includes(item.folderId, index + 1) && item.folderId && item.folderId !== '',
        );

        return (
          <TableSelectInput
            value={params.row.folderId}
            renderValue={params.row.folderName}
            onChange={(event, label) => {
              props.brandReports[params.row.id].folderId = !event || event === '' ? '' : event;
              props.brandReports[params.row.id].folderName = !label || label === 'N/A' ? '' : label;
              setFolders(props.brandReports);
              props.setValues(props.keyValue, props.brandReports);
            }}
            options={optionsArray?.map((item, index) => (
              <MenuItem key={index} value={item.folderId}>
                {item.folderName ? item.folderName : 'Unnamed'}
              </MenuItem>
            ))}
          />
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    const socketOptions = {
      extraHeaders: {
        Authorization: `${accountService.userValue.accessToken}`,
      },
    };
    const socket = io(process.env.REACT_APP_API_URL as string, socketOptions);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socket.on('googleDriveReportReady', (data: any) => {
      const lastSuccessByReportTypeIndex = props.lastSuccess?.findIndex(
        (ls) =>
          (ls?.reportResponse?.reportType || ls?.reportType) === data.responseLastSuccess.reportResponse.reportType,
      );

      if (lastSuccessByReportTypeIndex > -1) {
        props.lastSuccess[lastSuccessByReportTypeIndex] = data.responseLastSuccess;
      }
    });
  }, [props.lastSuccess]);

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (pickerData) {
      pickerData.docs.map(async (i) => {
        const index = props.brandReports.findIndex((b) => b.reportType === currentReportType);

        props.brandReports[index] = {
          reportName: currentReportName,
          reportType: currentReportType,
          folderName: i.name,
          folderId: i.id,
        };
        // Set the service account as a writer
        try {
          await ApiWithCustomToken.post(
            `https://www.googleapis.com/drive/v3/files/${i.id}/permissions`,
            {
              type: 'user',
              role: 'writer',
              emailAddress: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
        } catch (err) {
          alertService.warn('Write Permission Setting Error on service account.');
        }
      });
      loadData();
      props.onSubmit(props.brandReports, props.isSubmitting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerData]);

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: `users-${accountService.userValue.role}`,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const loadData = useCallback(() => {
    setLoading(true);

    brandReportTypes.forEach((r) => {
      const brandReportType = props.brandReports?.find((b) => b.reportType === r.reportType);

      if (!brandReportType) {
        const emptyData = {
          reportType: r.reportType,
          reportName: r.name,
          folderName: '',
          folderId: '',
        };

        props.brandReports.push(emptyData);
      }
    });

    setCount(props.brandReports.length);

    setRows(
      props.brandReports.map((r, id) => {
        return {
          ...r,
          id,
        };
      }),
    );
    setLoading(false);
  }, [props.brandReports]);

  const handleOpenPicker = (v: GoogleDriveFolderInterface) => {
    setCurrentReportName(v.reportName);
    setCurrentReportType(v.reportType);

    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID as string,
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY as string,
      viewId: 'FOLDERS',
      token: accessToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      setSelectFolderEnabled: true,
    });
  };

  const handleSendReportNow = async (v: GoogleDriveFolderInterface) => {
    if (!v) return;
    if (!v.folderId) {
      alertService.warn('No Folder ID set for this report type.');
      return;
    }
    setLoading(true);
    try {
      const body: {
        reportType: string;
        brandCode: string;
        dataStartTime?: string;
        dataEndTime?: string;
      } = {
        reportType: v.reportType,
        brandCode: props.brandCode,
      };

      if (fromTo.from && fromTo.to) {
        body.dataStartTime = fromTo.from as string;
        body.dataEndTime = fromTo.to as string;
      }
      const {data} = await Api.post(`reports/send-now`, body);
      alertService.success(data);
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error.message, e);
    } finally {
      setLoading(false);
    }
  };

  const customToolbarProps = {
    loadingData: loading || tableLoading,
    ignoreItems: ['actions'],
    items: [...orderColumns],
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        `users-${accountService.userValue.role}`,
        setTableLoading,
      );
    },
  };

  const thisRef = useRef(null);

  const customButtons = [
    {
      customButton: {
        label: <Typography sx={sxStyles('googleLabel')}>Sign In With Google and Select Folder:</Typography>,
        component: (
          <Button sxStyles={sxStyles('googleButton')}>
            <img src={GoogleIcon} width={30} height={30} alt="google" />
            <Typography>Google</Typography>
          </Button>
        ),
      },
      onClick: (v: GoogleDriveFolderInterface) => handleOpenPicker(v),
    },
    {
      icon: <LinkOff sx={sxStyles('driveIcons')} />,
      onClick: (v: GoogleDriveFolderInterface) => setDeleteFolder(v),
      tooltip: 'Unlink',
    },
    {
      icon: <Send sx={sxStyles('driveIcons')} />,
      onClick: (v: GoogleDriveFolderInterface) => setReportToSend(v),
      tooltip: 'Send Now',
    },
  ];

  function setDeleteFolder(v: GoogleDriveFolderInterface) {
    if (v.id) {
      props.brandReports[+v.id] = {
        reportType: v.reportType,
        reportName: v.reportName,
        folderName: '',
        folderId: '',
        id: v.id,
      };
      loadData();
      props.onSubmit(props.brandReports, props.isSubmitting);
    }
  }

  useEffect(() => {
    makeColumnFreeze(thisRef, visibilityModel, rows);
  }, [visibilityModel, rows]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    emitterService.on('topFolder', (brandReports: GoogleDriveFolderInterface[]) => {
      setRows(
        brandReports.map((r, id) => {
          return {
            ...r,
            id,
          };
        }),
      );

      brandReports.forEach((report, index) => {
        props.brandReports[index] = report;
      });
    });
  }, [props.brandReports]);

  useEffect(() => {
    emitterService.on('topFolderLoading', (isLoading) => {
      setTableLoading(isLoading);
    });
  }, [tableLoading]);

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn
          thisRef={thisRef}
          rows={rows}
          buttons={customButtons}
          width={mobile ? '175px' : '260px'}
          height="90px"
        />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        <Grid item lg={12} position="relative">
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            componentsProps={{
              toolbar: customToolbarProps,
              pagination: customToolbarProps,
            }}
            sx={sxStyles('grid')}
            className={`custom-table drive ${tableLoading && !loading ? 'load-headers' : ''}`}
            onCellEditCommit={() => {
              props.onSubmit(folders, props.isSubmitting);
              loadData();
            }}
            onPageChange={(page) => {
              setPage(page);
            }}
            onPageSizeChange={(size) => {
              setPageSize(size);
            }}
            style={{width: '100%'}}
            rowHeight={90}
            autoHeight={true}
            pageSize={pageSize}
            page={page}
            loading={loading}
            rowCount={count}
            columnTypes={{filterString: stringColumnType}}
            pagination
            disableSelectionOnClick={true}
            rows={rows}
            columns={[...orderColumns, fixedPlaceHolder({width: 260})]}
            columnVisibilityModel={visibilityModel}
            rowsPerPageOptions={rowsPerPageOptions}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: columns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `users-${accountService.userValue.role}`,
                setTableLoading,
              );
            }}
          />
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
      <ModalButton
        modalSxStyle={sxStyles('reportModal')}
        openModal={reportToSend !== null}
        modalTitle="Send for Selected Date Range"
        hideButton
        closable
        onCloseText="Cancel"
        onCloseAction={() => setReportToSend(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              if (reportToSend) {
                handleSendReportNow(reportToSend);
                closeModal();
              }
            }}
          >
            Send Now
          </Button>
        )}
      >
        {(): JSX.Element => {
          return (
            <Grid container style={{width: '100%', minWidth: '300px', height: '400px'}}>
              {fromTo.to && fromTo.from && (
                <DateRangePicker
                  customPicker
                  width="320px"
                  disabled={loading}
                  from={fromTo.from}
                  to={fromTo.to}
                  onChange={(x) => {
                    onFromToChange({
                      from: x.selection?.startDate,
                      to: x.selection?.endDate,
                      preset: 'custom',
                      loadData: loadData,
                      setFromTo: setFromTo,
                    });
                  }}
                />
              )}
            </Grid>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={selectedReport !== null}
        modalTitle={`Report: ${selectedReport?.fileName}`}
        hideButton
        closable
        onCloseAction={() => setSelectedReport(null)}
      >
        {(): JSX.Element => {
          return selectedReport ? (
            <List dense>
              {Object.entries(selectedReport?.reportResponse).map(([key, value], index) => {
                const validate = new Date(value).toString();
                const date = validate !== 'Invalid Date' && moment(validate).format('LLL');
                return (
                  <ListItem key={`${key}-${index}`}>
                    <ListItemIcon>
                      <Assignment />
                    </ListItemIcon>
                    <ListItemText
                      style={{textTransform: 'capitalize'}}
                      primary={getLabel(key)}
                      secondary={date ? date : value}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <></>
          );
        }}
      </ModalButton>
    </TableContainer>
  );
}

export {GoogleDrive, useDrivePicker, accessToken};
