import React, {memo} from 'react';
import {styled} from '@mui/system';
import {Clear, Search as SearchIcon} from '@mui/icons-material';

import {
  Button,
  TextField,
} from '@mui/material';

const SearchContainer = styled('div')(({theme}) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '60%',
  'fieldset': {
    border: 'none',
  },
  '&.hide-btn': {
    marginRight: '10px',
    '& > .MuiSvgIcon-root': {
      right: '10px',
    },
  },
  '& > .MuiSvgIcon-root': {
    position: 'absolute',
    top: '10px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  '& > .MuiSvgIcon-root#search-icon': {
    left: '6px',
  },
  '& > .MuiSvgIcon-root#clear-icon': {
    position: 'relative',
    right: '30px',
  },
  '& > div': {
    width: '95%',
    '& input': {
      paddingRight: '50px',
    },
  },
  '& > button': {
    marginLeft: '5px',
  },
  '& .MuiInputBase-input': {
    padding: '8.5px 14px 8.5px 32px',
    border: '1px solid',
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input:hover': {
    color: theme.palette.text.secondary,
  },
}));

interface SearchBarProps {
  onSearch?: () => void;
  applyFilters?: () => void;
  onChange: (event: string) => void;
  value: string;
  placeholder?: string;
  showButton?: boolean;
  disabled?: boolean;
}

export default memo(function SearchBar({
  onSearch,
  applyFilters,
  value,
  onChange,
  placeholder,
  showButton = true,
  disabled,
}: SearchBarProps) {
  const currentValue = value ? value : '';

  const onTextChange = (value: string) => onChange(value);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  return (
    <SearchContainer className={!showButton ? 'hide-btn' : ''}>
      <SearchIcon id="search-icon" />
      <TextField
        disabled={disabled}
        value={currentValue}
        size="small"
        placeholder={placeholder}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
        inputProps={{
          onClick: handleClick
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // console.log((e.target as HTMLInputElement).value);
            onSearch?.();
            applyFilters?.();
            e.preventDefault();
          }
        }}
      />
      {currentValue.length > 0 && (
        <Clear
          id="clear-icon"
          onClick={() => {
            onChange('');
          }}
        />
      )}
      {showButton && (
        <Button
          id="filterFieldsButton"
          onClick={() => onSearch?.()}
          type="button"
          variant="contained"
          color="primary"
        >
          Filter
        </Button>
      )}
    </SearchContainer>
  );
});
