import React from 'react';

import { Difference } from 'src/pages/Listings/interfaces/interfaces';

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTheme } from '@mui/material/styles';

import ReflectingDifference from '../ReflectingDifference';

type Props = {
  differences: Difference[];
};

const ReflectingDifferenceContainer = ({
  differences,
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <TableContainer
      className={`theme-${theme.palette.mode} differences-container`}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Submitted changes</TableCell>
            <TableCell>Displayed on Amazon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {differences.map(
            (difference, index) => {
              return (
                <ReflectingDifference
                  key={index}
                  difference={difference}
                />
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReflectingDifferenceContainer;
