import React, {useState} from 'react';
import {AxiosError} from 'axios';
import {
  CircularProgress,
  Theme,
  Typography,
  Paper,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Tooltip,
  SxProps,
  useTheme,
} from '@mui/material';
import {Summarize, Download} from '@mui/icons-material';
import moment from 'moment';
// utils
import {Api} from '../../../utils/api';
// services
import {AlertType} from '../../../services/alert.service';
// interfaces
import {DateRangeInterface} from '../../../interfaces/date/date';

const sxStyles = (
  theme: Theme,
): {
  [key: string]: SxProps<Theme>;
} => ({
  paper: {
    padding: '10px',
  },
  button: {
    width: '100%',
    margin: '3px 3px 0 0',
    '& > button': {
      textTransform: 'none',
      width: '100%',
    },
    [theme.breakpoints.down(767)]: {
      '&.MuiBox-root': {
        padding: 0,
      },
    },
  },
});

function CsvExportButton({
  apiUrl,
  reportFileName,
  fromTo = undefined,
  handleAlert = null,
}: {
  apiUrl: string;
  reportFileName: string;
  fromTo?: {from?: DateRangeInterface['from']; to?: DateRangeInterface['to']};
  handleAlert?: ((alert: {type: string; message: string}) => void) | null;
}): JSX.Element {
  const theme = useTheme();

  const [downloading, setDownloading] = useState(false);

  async function getData() {
    setDownloading(true);
    const params = fromTo
      ? {
          from: moment(fromTo.from).format('YYYY-MM-DD'),
          to: moment(fromTo.to).format('YYYY-MM-DD'),
        }
      : null;

    try {
      const res = await Api.get(apiUrl, {
        params: {...params, reportFileName},
      });
      switch (res.status) {
        case 200:
          handleAlert?.({type: AlertType.Success, message: res.data?.message});
          break;
        case 202:
          handleAlert?.({type: AlertType.Info, message: res.data});
          break;
        case 204:
          handleAlert?.({type: AlertType.Warning, message: 'No data!'});
          break;
        default:
          handleAlert?.({type: AlertType.Info, message: res.data?.message});
          break;
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error.response && error.response.status !== 401) {
        handleAlert?.({type: AlertType.Error, message: `Unable to get ${reportFileName}.`});
      }
    } finally {
      setDownloading(false);
    }
  }

  return (
    <Paper elevation={2} sx={sxStyles(theme).button}>
      <ListItem
        secondaryAction={
          <Tooltip title="Download">
            <IconButton edge="end" aria-label="delete" onClick={getData} className="default-icon download">
              <Download />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <Summarize />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              {downloading && (
                <>
                  {'Downloading ... '}
                  <CircularProgress size={12} color="inherit" />
                </>
              )}
              {!downloading && `${reportFileName}`}
            </Typography>
          }
          secondary={
            fromTo?.from
              ? `${moment(fromTo?.from).format('MMMM Do YYYY')} - ${moment(fromTo?.to).format('MMMM Do YYYY')}`
              : ''
          }
        />
      </ListItem>
    </Paper>
  );
}

export {CsvExportButton};
