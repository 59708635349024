import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  draggingListItem: {
    background: theme.palette.mode === 'dark' ? '#757575' : 'rgb(235,235,235)',
    textTransform: 'capitalize',
    padding: '3px 16px',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  hideListItem: {
    visibility: 'hidden',
    height: 0,
    padding: 0,
  },
  listItem: {
    padding: '3px 16px',
    textTransform: 'capitalize',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
