import React from 'react';
import DraggableListItem from './DraggableListItem';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {GridColDef} from '@mui/x-data-grid';

export type DraggableListProps = {
  items: GridColDef[];
  setItems: (items: DraggableListProps['items']) => void;
  ignoreItems?: string[];
};

const DraggableList = React.memo(({items, setItems, ignoreItems}: DraggableListProps) => {
  let newItems = items;

  const reorder = (
    list: DraggableListProps['items'],
    startIndex: number,
    endIndex: number,
  ): DraggableListProps['items'] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({destination, source}: {destination: DropResult['destination']; source: DropResult['source']}) => {
    if (!destination) return;
    const data = reorder(items, source.index, destination.index);
    setItems(data);
    newItems = data;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {newItems.map((item, index) => {
              if (ignoreItems?.includes(item.field)) {
                item.hide = true;
              }
              return <DraggableListItem item={item} index={index} key={item.field} />;
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

DraggableList.displayName = 'DraggableList';

export default DraggableList;
