import {Theme} from '@mui/material';
// utils
import {GetStyles} from '../../utils/getStyles';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';

const styles = (
  theme: Theme,
): {
  [key: string]: SxStyleTypes;
} => ({
  box: {
    padding: '10px',
  },
  grid: {
    display: 'flex',
    '& .edit-cell-icon': {
      display: 'none',
      position: 'absolute',
      right: '0',
      '& svg': {
        color: '#a1a1a1',
        fontSize: '16px',
        marginLeft: '5px',
        '&:last-of-type': {
          cursor: 'pointer',
        },
      },
    },
    '& .column-checkbox': {
      margin: '0 8px 0 0',
    },
    '& .custom-textfield .MuiOutlinedInput-notchedOutline': {
      outline: 'none!important',
      border: 'none!important',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },

    '& .MuiDataGrid-cell--editable ': {
      position: 'relative',
      '& span': {
        paddingRight: '30px',
      },
    },
    '& .MuiDataGrid-cell--editable:hover .edit-cell-icon': {
      display: 'block',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CloseIcon"]': {
      color: '#f44336',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CheckIcon"]': {
      color: '#4caf50',
    },

    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '65vh!important',
      overflowY: 'scroll!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      display: 'flex',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none',
    },
    // fixed columns
    '& .fixed-column-container': {
      position: 'sticky',
      marginLeft: 'auto',
      alignItems: 'center',
      height: '0',
      right: '0',
      zIndex: 1,
      '& > div': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
        '& svg': {
          fontSize: '22px',
          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#a1a1a1',
        },
      },
    },
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '20px',
  },
  overviewGrid: {
    display: 'flex',
    '& .edit-cell-icon': {
      display: 'none',
      position: 'absolute',
      right: '0',
      '& svg': {
        color: '#a1a1a1',
        fontSize: '16px',
        marginLeft: '5px',
        '&:last-of-type': {
          cursor: 'pointer',
        },
      },
    },
    '& .MuiDataGrid-cell ': {
      textTransform: 'capitalize',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
    '& .column-all': {
      backgroundColor: '#0087fe37',
    },
    '& .column-MC--MR': {
      backgroundColor: '#00c49f37',
    },
    '& .column-sell-out': {
      backgroundColor: '#d63f7e37',
    },
    '& .column-type': {
      backgroundColor: '#ffbb2837',
    },
    '& .column-brand-tier': {
      backgroundColor: '#ff804237',
    },
    '& .column-account-manager': {
      backgroundColor: '#d63f3f37',
    },
    '& .column-account-manager-associate': {
      backgroundColor: '#d354c237',
    },
    '& .column-advertising': {
      backgroundColor: '#10665637',
    },
    '& .column-inventory-supervisor': {
      backgroundColor: '#14589437',
    },
    '& .column-case-manager': {
      backgroundColor: '#f390b937',
    },
    '& .column-inventory-onboarding-supervisor': {
      backgroundColor: '#c7952b37',
    },
    '& .column-content': {
      backgroundColor: '#ce4f0f37',
    },
    '& .column-copy': {
      backgroundColor: '#f0666637',
    },
    '& .column-design': {
      backgroundColor: '#91108037',
    },
    '& .column-catalog': {
      backgroundColor: '#10629137',
    },
    '& .column-listing-QA': {
      backgroundColor: '#7e911037',
    },
    '& .column-marketing-team-member': {
      backgroundColor: '#91101037',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiDataGrid-cell--editable ': {
      position: 'relative',
      '& span': {
        paddingRight: '30px',
      },
    },
    '& .MuiDataGrid-cell--editable:hover .edit-cell-icon': {
      display: 'block',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CloseIcon"]': {
      color: '#f44336',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CheckIcon"]': {
      color: '#4caf50',
    },

    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '70vh!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      display: 'flex',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none',
    },
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  inputImage: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    '& .MuiInputLabel-root:last-of-type': {
      padding: '10px 0',
      '& span': {
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  inputField: {
    marginTop: '0!important',
    margin: '0.90em 0',
    width: '95%',
  },
  inputFieldSelect: {
    marginTop: '0!important',
    margin: '0.70em 0',
    '& > .MuiInputLabel-root': {
      display: 'none',
    },
    '& > .MuiFormControl-root .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      position: 'absolute',
      top: '-8px',
    },
  },
  inputFieldDate: {
    '& .MuiFormControl-root': {
      width: '95%',
      margin: '0.50em 0',
      '& .MuiOutlinedInput-input': {
        padding: '8px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    inputField: {
      width: '100%',
    },
    inputFieldDate: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '900px',

    '& .MuiButton-outlined:not(:focus, :active)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
    },
    '&.MuiButtonGroup-root .MuiButton-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(1240)]: {
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+4)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(4)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.new-group)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '&.MuiButtonGroup-root .MuiButton-root': {
          border: theme.palette.mode === 'dark' ? '1px solid #3887be' : '1px solid #174580',
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3n)': {
          borderLeftColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3)': {
          borderTopRightRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRightColor: 'transparent',
          '&:hover': {
            borderRightColor: 'transparent',
          },
        },
        '& .MuiButton-root:last-of-type': {
          gridArea: '4 / 1 / 4/ 4',
          borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          borderRadius: '0',
          borderBottomRightRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-outlined': {
          border: 'none',
          color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          '&:last-of-type': {
            backgroundColor: '#e9e9e9',
          },
        },
        '& .MuiButton-containedPrimary': {
          border: 'none',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
            outline: 'none',
          },
        },
      },
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',

        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+6)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(2)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(6), ': {
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(7)': {
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
  },
  notesCard: {
    margin: '15px 0',
    minWidth: 275,
    background: theme.palette.mode === 'dark' ? '#4b4b4b' : '#f8f8f8',
    '&.note-loading': {
      opacity: 0.5,
    },
    '& .card-content': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div:first-of-type': {
        width: '100%',
      },
      '& > div:last-of-type': {
        minWidth: '100px',
        padding: '0 10px',
        '& .edit-buttons button': {
          width: '100%',
          fontSize: '12px',
          marginBottom: '5px',
        },
      },
      [theme.breakpoints.down(600)]: {
        '& > div:last-of-type': {
          minWidth: 'auto',
          padding: '0 5px',
          '& .edit-buttons button': {
            width: '100%',
            fontSize: '12px',
            marginBottom: '5px',
          },
        },
      },
    },
    '& .date': {
      margin: '15px 0 0 0',
      textAlign: 'right',
      color: theme.palette.mode === 'dark' ? '#b6b6b6' : '#858585',
    },
    '& .MuiCardActions-root': {
      padding: '0 15px',
      '& svg': {
        fontSize: '22px',
      },
    },
  },
  notesTextArea: {
    '& textarea': {
      width: '100%',
      resize: 'none',
      height: '130px',
      padding: '20px',
      borderRadius: '5px',
      border: theme.palette.mode === 'dark' ? '1px solid #707070' : '1px solid #c9c9c9',
      background: theme.palette.mode === 'dark' ? '#4b4b4b' : '#f8f8f8',
      color: theme.palette.mode === 'dark' ? '#ececec' : '#272727',
      '&:focus': {
        border: '1px solid #3c8dc7',
        outline: 'none',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#999',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#777',
      },
    },
  },
  noteStreamPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiTypography-root': {
      padding: '0 20px',
    },
    '& .MuiFormControl-root': {
      minWidth: '50px',
    },
  },
  reportModal: {
    '& .MuiDialogContent-root': {
      background: '#353535',
      [theme.breakpoints.down(600)]: {
        padding: '20px 5px!important',
      },
    },
  },
  googleButton: {
    padding: '0px!important',
    background: '#ffffff!important',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px',
    '& p': {
      padding: '0 8px',
      fontFamily: 'Roboto',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.541)',
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down(767)]: {
      '& p': {
        padding: '0 5px!important',
        fontSize: '10px',
      },
      '& img': {
        width: '25px!important',
        height: '25px!important',
      },
    },
  },
  googleLabel: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    padding: '5px 0',
    [theme.breakpoints.down(767)]: {
      fontSize: '8px',
      padding: '3px 0',
    },
  },
  driveIcons: {
    [theme.breakpoints.down(767)]: {
      fontSize: '16px!important',
    },
  },
  connectorCard: {
    textAlign: 'center',
    background: theme.palette.background.default,
    boxShadow: '0 0 10px 0 rgba(143, 143, 143, 0.1)',
    '& .MuiCardHeader-content span': {
      fontSize: '1.3rem',
      color: '#fff',
    },
    '& .MuiCardContent-root, & .MuiCardContent-root div': {
      justifyContent: 'center',
    },
    '& .MuiCardContent-root h6': {
      fontSize: '1.1rem',
      lineHeight: '1.4',
    },
    '& .MuiCardHeader-root': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '& .MuiCardActions-root': {
      justifyContent: 'center',
      paddingBottom: '15px',
    },
    [theme.breakpoints.down(767)]: {
      '& .MuiCardHeader-content span': {
        fontSize: '1rem',
      },
      '& .MuiCardContent-root h6': {
        fontSize: '.9rem',
      },
    },
  },
  spApiCard: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px',
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
