import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import moment, {Moment} from 'moment';
import {Button, ButtonGroup, Checkbox, Grid, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Helper} from '../../../../utils/helper';
// components
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import {MenuStore} from '../../../../App';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {AsinPerformanceSummary} from './AsinPerformanceSummary';
import {PerformanceChart} from './PerformanceChart';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {buildMenu} from '../../components/menu';
// styles
import {sxStyles} from '../../../Brands/Styles';

type DefaultQuery = {
  from: string | null;
  to: string | null;
  preset: string | null;
  skus: string[] | null;
  metric: string[] | null;
  compareFrom: string | null;
  compareTo: string | null;
};

interface LoadDataInterface {
  selected?: string[];
  period?: string;
  from: string;
  to: string;
  compareFrom: string | null;
  compareTo: string | null;
  init?: boolean;
  metric?: string[] | null;
  model?: GridSelectionModel;
  updateSelected?: boolean;
  data?: {
    buy_box: number | null;
    conversion: number | null;
    page_views_percentage: number | null;
    revenue: number | null;
    seller_sku: string;
    session: number;
    units: number;
  }[];
}

const columns: GridColDef[] = [
  {field: 'seller_sku', headerName: 'SKU', flex: 0.6, filterable: false},
  {field: 'buy_box', headerName: 'BB%', flex: 0.2, filterable: false, renderCell: (x) => renderPercentageCell(x)},
  {
    field: 'session',
    align: 'right',
    headerName: 'Sessions',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
  {
    field: 'revenue',
    align: 'right',
    headerName: 'Revenue',
    flex: 0.3,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
  {
    field: 'conversion',
    headerName: 'Conversions',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderPercentageCell(x),
  },
  {
    field: 'page_views_percentage',
    headerName: 'Page view %',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderPercentageCell(x),
  },
  {
    field: 'units',
    align: 'right',
    headerName: 'Units',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
];

function renderAmountCell(x: GridCellParams) {
  return (
    <div>
      <Typography color={'primary'}>
        {x.value != null ? Helper.formatAmount(parseFloat(x.value.toString())) : 'N/A'}
      </Typography>
      {'old_' + x.field in x.row ? (
        <Typography color={'textSecondary'}>
          {x.row['old_' + x.field] != null ? Helper.formatAmount(x.row['old_' + x.field]) : 'N/A'}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
}

function renderPercentageCell(x: GridCellParams) {
  return (
    <div>
      <Typography color={'primary'}>{x.value != null ? `${x.value}%` : 'N/A'}</Typography>
      {'old_' + x.field in x.row ? (
        <Typography color={'textSecondary'}>
          {x.row['old_' + x.field] != null ? `${x.row['old_' + x.field]}$` : 'N/A'}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
}

function AsinPerformance(): JSX.Element {
  const location = useLocation();

  const {id} = useParams<Record<string, string | undefined>>();
  const [fromTo, setFromTo] = useState<{
    from: string;
    to: string;
    compareFrom: null | string;
    compareTo: null | string;
  }>({from: moment().toString(), to: moment().toString(), compareFrom: null, compareTo: null});
  const [metric, setMetric] = useState<string[] | null>([]);
  const [period, setPeriod] = useState<string | null>('custom');

  const [skuRows, setSkuRows] = useState<{
    rows: never[];
    selected: string[];
  }>({rows: [], selected: []});
  const [metricData, setMetricData] = useState<{
    interval: string;
    data: {
      metric: string;
      data: {
        date: string;
        value: number;
      }[];
      type: string;
    }[];
  } | null>(null);
  const [summaryData, setSummaryData] = useState(null);

  const [loadingSku, setLoadingSku] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  const history = useHistory();

  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    const fromQuery = urlParams.get('from');
    const toQuery = urlParams.get('to');
    const selectedQuery = urlParams.get('skus');
    const metricQuery = urlParams.get('metric');
    const compareFromQuery = urlParams.get('compareFrom');
    const compareToQuery = urlParams.get('compareTo');
    const query: DefaultQuery = {
      from: null,
      metric: null,
      preset: null,
      skus: null,
      to: null,
      compareFrom: null,
      compareTo: null,
    };

    query.metric = metricQuery && metricQuery !== '' ? metricQuery.split(',') : ['revenue'];
    query.skus = selectedQuery && selectedQuery !== '' ? selectedQuery.split(',') : [];

    if (!fromQuery || !toQuery) {
      query.to = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      query.from = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
      query.preset = '7d';
    } else if (!compareFromQuery || !compareToQuery) {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
    } else {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
      query.compareFrom = moment(compareFromQuery).format('YYYY-MM-DD');
      query.compareTo = moment(compareToQuery).format('YYYY-MM-DD');
    }
    return query;
  }

  function lastNDays(days: number) {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = moment(toValue).subtract(days - 1, 'days');
    onFromToChange(fromValue, toValue, null, null, days + 'd', compare);
    setPeriod(days + 'd');
  }

  function quarterRange() {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = moment().quarter(moment().quarter()).startOf('quarter');
    onFromToChange(fromValue, toValue, null, null, 'q', compare);
    setPeriod('q');
  }

  function yesterdayRange() {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = toValue;
    onFromToChange(fromValue, toValue, null, null, 'y', compare);
    setPeriod('y');
  }

  function onFromToChange(
    from: Moment | string,
    to: Moment | string,
    cFrom: string | null | Moment,
    cTo: string | null | Moment,
    preset: string | null,
    compare: boolean,
  ) {
    const fromValue = moment(from).format('YYYY-MM-DD');
    const toValue = moment(to).format('YYYY-MM-DD');
    let compareFrom = cFrom;
    let compareTo = cTo;
    if (!compareFrom && !compareTo && compare) {
      if (preset === 'q') {
        compareFrom = moment(fromValue).subtract(1, 'quarter');
        compareTo = moment().quarter(moment(compareFrom).quarter()).endOf('quarter');
      } else {
        const diff = moment(toValue).diff(fromValue, 'days');
        compareFrom = moment(fromValue).subtract(diff + 1, 'days');
        compareTo = moment(toValue).subtract(diff + 1, 'days');
      }
    }
    const compareFromValue = compareFrom ? moment(compareFrom).format('YYYY-MM-DD') : null;
    const compareToValue = compareTo ? moment(compareTo).format('YYYY-MM-DD') : null;
    loadSkus({
      selected: skuRows.selected,
      from: fromValue,
      to: toValue,
      compareFrom: compareFromValue,
      compareTo: compareToValue,
      init: false,
    });
    setFromTo({from: fromValue, to: toValue, compareFrom: compareFromValue, compareTo: compareToValue});
    if (!preset) {
      setPeriod(null);
    }
  }

  const updateQueryParams = useCallback(() => {
    const search = new URLSearchParams(location.search);
    if (metric && metric.length !== 0 && metric.length !== skuRows.rows.length) {
      search.set('metric', metric.toString());
    }
    search.set('from', fromTo.from as string);
    search.set('to', fromTo.to as string);
    if (fromTo.compareFrom && fromTo.compareTo) {
      search.set('compareFrom', fromTo.compareFrom as string);
      search.set('compareTo', fromTo.compareTo as string);
    } else {
      search.delete('compareFrom');
      search.delete('compareTo');
    }
    if (skuRows.selected.length !== 0 && skuRows.selected.length !== skuRows.rows.length) {
      search.set('skus', skuRows.selected.toString());
    } else search.delete('skus');
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newUrl}, '', newUrl);
  }, [
    fromTo.to,
    fromTo.from,
    fromTo.compareTo,
    fromTo.compareFrom,
    location.search,
    metric,
    skuRows.selected,
    skuRows.rows,
  ]);

  const menuItems = buildMenu('performance', id as string, history);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    const defaultQuery = getDefaultQuery();
    loadSkus({
      selected: defaultQuery.skus || [],
      from: defaultQuery.from as string,
      to: defaultQuery.to as string,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
      init: true,
    });

    setFromTo({
      from: defaultQuery.from as string,
      to: defaultQuery.to as string,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset);
    setMetric(defaultQuery.metric);
    setCompare(defaultQuery.compareFrom != null);
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [fromTo, skuRows, period, metric, updateQueryParams]);

  async function loadSkus({selected, from, to, compareFrom, compareTo, init}: LoadDataInterface) {
    setLoadingSku(true);
    try {
      const {data: skuData} = await Api.get(
        `asins/performance/skus/${id}?from=${from}&to=${to}${compareFrom ? `&compareFrom=${compareFrom}` : ''}${
          compareTo ? `&compareTo=${compareTo}` : ''
        }`,
      );
      const data = skuData.map((x: {seller_sku: string}) => {
        return {id: x.seller_sku, ...x};
      });
      let model;
      if (selected?.length === 0) {
        model = skuData.map((x: {seller_sku: string}) => x.seller_sku);
      } else {
        model = selected?.filter((x) => data.some((y: {id: string}) => y.id === x));
      }
      setSkuRows({rows: data, selected: model});
      if (
        !init &&
        JSON.stringify(model) === JSON.stringify(selected?.filter((x) => data.some((y: {id: string}) => y.id === x)))
      ) {
        onSelect({
          data,
          model,
          from,
          to,
          compareFrom,
          compareTo,
          updateSelected: false,
        });
      }
    } catch (e) {
      errorAlert('Unable to get SKUs', e, {id: 'sku-performance'});
    } finally {
      setLoadingSku(false);
    }
  }

  async function loadSummary({selected, from, to, compareFrom, compareTo}: LoadDataInterface) {
    try {
      const {data} = await Api.get(
        `asins/performance/summary/${id}?skus=${selected}&from=${from}&to=${to}${
          compareFrom ? `&compareFrom=${compareFrom}` : ''
        }${compareTo ? `&compareTo=${compareTo}` : ''}`,
      );
      setSummaryData(data);
    } catch (e) {
      errorAlert('Unable to get Asins', e, {id: 'asin-summary'});
    }
  }

  function getInterval(from: string, to: string, compareFrom: string, compareTo: string) {
    const newDate = (date: string) => new Date(date).getTime();
    const fromValue = newDate(compareFrom) < newDate(from) ? compareFrom : from;
    const toValue = newDate(compareTo) < newDate(to) ? compareTo : to;
    if (moment(toValue).diff(fromValue, 'hours') < 24) {
      return 'minute';
    }
    return moment(toValue).diff(fromValue, 'days') > 5 ? 'day' : 'hour';
  }

  async function loadMetric({selected, from, to, compareFrom, compareTo, metric}: LoadDataInterface) {
    if (metric?.length === 0) return;
    setLoadingChart(true);
    const interval = getInterval(from, to, compareFrom as string, compareTo as string);
    try {
      const {data} = await Api.get(
        `asins/performance/metric/${id}` +
          `?metric=${metric}` +
          `&skus=${selected}` +
          `&from=${from}` +
          `&to=${to}` +
          `${compareFrom ? `&compareFrom=${compareFrom}` : ''}` +
          `${compareTo ? `&compareTo=${compareTo}` : ''}` +
          `&interval=${interval}`,
      );
      setMetricData({interval: interval, data: data});
    } catch (e) {
      errorAlert('Unable to get metric data', e, {id: 'performance-chart'});
    } finally {
      setLoadingChart(false);
    }
  }

  function onSelect({data, model, from, to, compareFrom, compareTo, updateSelected}: LoadDataInterface) {
    let newModel: string[] = [];
    if (model && model.length === 0) {
      newModel = data?.map((x) => x.seller_sku) || [];
    } else {
      loadSummary({selected: newModel, from, to, compareFrom, compareTo});
      loadMetric({selected: newModel, from, to, compareFrom, compareTo, metric});
    }
    if (updateSelected) setSkuRows({rows: skuRows.rows, selected: newModel});
    if (newModel.length === 0) {
      setSummaryData(null);
      setMetricData(null);
    }
  }

  function toggleCompare(checked: boolean) {
    setCompare(checked);
    onFromToChange(fromTo.from, fromTo.to, null, null, period, checked);
  }

  const changeMetric = useCallback(
    (x) => {
      loadMetric({
        selected: skuRows.selected,
        from: fromTo.from,
        to: fromTo.to,
        compareFrom: fromTo.compareFrom,
        compareTo: fromTo.compareTo,
        metric: x,
      });
      setMetric(x);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skuRows, fromTo, metric],
  );

  return (
    <>
      <TableContainer className="sub-page">
        <Grid container>
          <Grid item lg={8} xs={12}>
            <ButtonGroup
              sx={sxStyles('buttonGroup')}
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              disabled={loadingSku || loadingChart}
              fullWidth
              style={{marginBottom: '10px'}}
            >
              <Button
                variant={period === 'y' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => yesterdayRange()}
              >
                Yesterday
              </Button>
              <Button
                variant={period === '7d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(7)}
              >
                7 days
              </Button>
              <Button
                variant={period === '30d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(30)}
              >
                30 days
              </Button>
              <Button
                variant={period === '60d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(60)}
              >
                60 days
              </Button>
              <Button
                variant={period === '90d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(90)}
              >
                90 days
              </Button>
              <Button
                variant={period === 'q' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => quarterRange()}
              >
                Quarter
              </Button>
              <Button variant={!period ? 'contained' : 'outlined'} color={'primary'} onClick={() => setPeriod(null)}>
                Custom
              </Button>
            </ButtonGroup>
            {fromTo.to && fromTo.from && (
              <DateRangePicker
                width="450px"
                disabled={loadingSku || loadingChart}
                from={fromTo.from}
                to={fromTo.to}
                onChange={(x) => {
                  onFromToChange(
                    x.selection.startDate as string,
                    x.selection.endDate as string,
                    fromTo.compareFrom,
                    fromTo.compareTo,
                    period,
                    compare,
                  );
                }}
              />
            )}
          </Grid>
          <Grid item lg={4} xs={12} style={{margin: 'auto', textAlign: 'right', paddingBottom: '15px'}}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              Individual ASIN Performance
            </Typography>
            <Typography component="h2" variant="h5" gutterBottom>
              {id}
            </Typography>
            <Typography>
              <Checkbox checked={compare} onChange={(e) => toggleCompare(e.target.checked)} /> Compare to previous
            </Typography>
          </Grid>
        </Grid>
        <AsinPerformanceSummary data={summaryData} />
        <PerformanceChart
          data={metricData}
          onMetricChange={changeMetric}
          metric={metric ? metric : []}
          loading={loadingChart}
        />
        <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
          <CustomAlert id="sku-performance" />
          <DataGrid
            autoHeight={true}
            rows={skuRows.rows}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingSku}
            checkboxSelection
            onSelectionModelChange={(x) => {
              onSelect({
                data: skuRows.rows,
                model: x,
                from: fromTo.from,
                to: fromTo.to,
                compareFrom: fromTo.compareFrom,
                compareTo: fromTo.compareTo,
                updateSelected: true,
              });
            }}
            selectionModel={skuRows.selected}
            rowHeight={72}
            hideFooterPagination={true}
          />
        </Grid>
      </TableContainer>
    </>
  );
}

export {AsinPerformance};
