import React, {useEffect, useState, useCallback} from 'react';
import {Button, Typography, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {Visibility, VisibilityOutlined} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {buildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
// interfaces
import {ViewAsinsData} from '../../interfaces/asins.interfaces';

function ViewAsin(): JSX.Element {
  const [values, setValues] = useState({} as ViewAsinsData);
  const {id} = useParams<Record<string, string | undefined>>();
  const history = useHistory();

  const getAsin = useCallback(async () => {
    try {
      const {data} = await Api.get(`asins/${id}`);
      setValues(data);
    } catch (e) {
      errorAlert('Unable to get Asin', e);
    }
  }, [id]);

  async function watchAsin() {
    try {
      await Api.post(`asins/watchlist/watch/${id}`, null);
      setValues((prev) => ({...prev, watched: true}));
    } catch (e) {
      errorAlert('Unable to add Asin to watchlist', e);
    }
  }

  async function unWatchAsin() {
    try {
      await Api.post(`asins/watchlist/unwatch/${id}`, null);
      setValues((prev) => ({...prev, watched: false}));
    } catch (e) {
      errorAlert('Unable to remove Asin from watchlist', e);
    }
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('details', id as string, history);
    });
    getAsin();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [getAsin, history, id]);

  return (
    <TableContainer flexDirection="row" className="sub-page-details">
      <Grid container spacing={2}>
        <Grid item lg={6}>
          {values ? (
            values?.watched ? (
              <>
                <Button
                  color="primary"
                  onClick={unWatchAsin}
                  startIcon={<Visibility />}
                  style={{textTransform: 'none'}}
                >
                  <Typography component="h6" variant="subtitle1" color="inherit">
                    Unwatch
                  </Typography>
                </Button>
                <br />
              </>
            ) : (
              <>
                <Button onClick={watchAsin} startIcon={<VisibilityOutlined />} style={{textTransform: 'none'}}>
                  <Typography component="h6" variant="subtitle1" color="inherit">
                    Watch
                  </Typography>
                </Button>
                <br />
              </>
            )
          ) : (
            ''
          )}
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            ASIN
          </Typography>
          <Typography component="h2" variant="h5" color="primary" gutterBottom>
            {values?.asin ? values.asin : 'N/A'}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            Parent ASIN
          </Typography>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {values?.parent_asin ? values.parent_asin : 'N/A'}
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            Brand code
          </Typography>
          <Typography component="h2" variant="h5" color="primary" gutterBottom>
            {values?.brand_code ? values.brand_code : 'N/A'}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            Brand name
          </Typography>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {values?.brand?.name ? values.brand.name : 'N/A'}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            Think tank
          </Typography>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {values?.brand?.think_tank?.name ? values.brand.think_tank.name : 'N/A'}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
            Account manager
          </Typography>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {values?.brand?.account_manager?.email ? values.brand.account_manager.email : 'N/A'}
          </Typography>
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {ViewAsin};
