// services
import {accountService} from '../services/account.service';
// utils
import {Role} from './role';
// interface
import {AccountManagersInterface} from '../context/AppContext/interfaces/interfaces';
import {QueryValuesInterface} from './urlParams';

export function getAccountManagers(
  query: QueryValuesInterface,
  key: string,
  currentAM: AccountManagersInterface[],
  AMs: AccountManagersInterface[],
): AccountManagersInterface[] | undefined {
  if (AMs.length > 0 && [Role.Admin].includes(accountService.userValue.role)) {
    let currentUser = currentAM;
    if (query[key]) {
      currentUser = AMs.filter((manager) => manager.email === query[key]);
    }
    return currentUser;
  }
}
