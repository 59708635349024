import React, {useEffect, useState, useContext} from 'react';
import * as Yup from 'yup';
import Fade from '@mui/material/Fade';
import {useHistory} from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial,
  Typography,
  AutocompleteRenderInputParams,
  Button,
  Grid,
} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {CheckboxWithLabel, Select, TextField, Autocomplete} from 'formik-mui';
// services
import {alertService} from '../../../../services/alert.service';
import {accountService} from '../../../../services/account.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Role} from '../../../../utils/role';
import {getSubBrands} from '../../../../utils/getSubBrands';
// styles
import {sxStyles} from '../../../Brands/Styles';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CreateBrandUser} from '../CreateBrandUser/CreateBrandUser';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';
import {BrandInterface} from '../../../../interfaces/brands/brand';

const initialValues = {
  email: '',
  name: '',
  role: Role.User,
  brand_code: null,
  department_id: '',
  think_tank_id: '',
  brand_admin: false,
  create_users: false,
  inactive: false,
  sub_brand_code: [] as BrandInterface[],
};

type CreateUser = {
  email: string;
  name: string;
  role: string;
  brand_code: {brand_code: string; name: string} | null;
  department_id: string;
  think_tank_id: string;
  brand_admin: boolean;
  create_users: boolean;
  inactive: boolean;
  sub_brand_code: BrandInterface[];
};

function CreateUser(): JSX.Element {
  const history = useHistory();
  const [departments, setDepartments] = useState<{id: number; name: string}[]>([]);
  const [thinkTanks, setThinkTanks] = useState<{id: number; name: string}[]>([]);
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [validation, setValidation] = useState(getValidationSchema(Role.User));
  const [role, setRole] = useState(Role.User);
  const [subBrandsLoading, setSubBrandsLoading] = useState(false);

  const {brands} = useContext(AppContext);

  async function getDepartments() {
    try {
      const {data} = await Api.get('departments');
      setDepartments(data);
    } catch (e) {
      errorAlert('Unable to get departments!', e);
    }
  }

  async function getThinkTanks() {
    try {
      const {data} = await Api.get('think-tanks');
      setThinkTanks(data);
    } catch (e) {
      errorAlert('Unable to get think tanks!', e);
    }
  }

  async function onSubmit(data: CreateUser, {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}) {
    alertService.clear();
    const body = {
      ...data,
      brand_code: data.brand_code ? data.brand_code?.brand_code : null,
      sub_brand_code: data.sub_brand_code ? data.sub_brand_code.map((subBrand) => subBrand.brand_code) : [],
      department_id: data.department_id ? +data.department_id : null,
      think_tank_id: data.think_tank_id ? +data.think_tank_id : null,
      role: data.role,
    };

    try {
      await Api.post('users', body);
      history.push('/users');
      alertService.success('User created successfully');
    } catch (e) {
      errorAlert('Unable to create user!', e);
    } finally {
      setSubmitting(false);
    }
  }

  const onRoleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setRole(event.target.value as string);
    setValidation(getValidationSchema(event.target.value as string));
  };

  function getValidationSchema(roleSelected: string) {
    switch (roleSelected) {
      case Role.Admin:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          role: Yup.string().required('Role is required'),
        });
      case Role.User:
      case Role.Manager:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          role: Yup.string().required('Role is required'),
          department_id: Yup.number().typeError('Department is required').required('Department is required'),
          think_tank_id: Yup.number().typeError('Think tank is required').required('Think tank is required'),
        });
      case Role.BrandUser:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          role: Yup.string().required('Role is required'),
          brand_code: Yup.object({
            name: Yup.string().typeError('Brand is required').required('Brand is required'),
            brand_code: Yup.string().typeError('Brand is required').required('Brand is required'),
          })
            .typeError('Brand is required')
            .required('Brand is required'),
        });
    }
  }

  async function pullSubBrands(brandCode: string) {
    const data = await getSubBrands([brandCode], setSubBrandsLoading);
    if (data) {
      setSubBrands(data);
    }
  }

  useEffect(() => {
    if (accountService.userValue.role === Role.BrandUser) return;
    getDepartments();
    getThinkTanks();
  }, []);

  return accountService.userValue.role === Role.BrandUser ? (
    <CreateBrandUser />
  ) : (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Create user
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit} validateOnChange={true}>
          {({errors, touched, isSubmitting, setFieldValue, values: formValues}) => (
            <Form>
              <div>
                <div>
                  <Field
                    sx={sxStyles('inputField')}
                    component={TextField}
                    variant="outlined"
                    name="email"
                    label="Email"
                    type="text"
                    size="small"
                  />
                </div>
                <div>
                  <Field
                    sx={sxStyles('inputField')}
                    component={TextField}
                    variant="outlined"
                    name="name"
                    label="Name"
                    type="text"
                    size="small"
                  />
                </div>
                <div>
                  <FormControl sx={[sxStyles('inputField'), sxStyles('inputFieldSelect')]} variant={'outlined'}>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Field
                      size="small"
                      value={role}
                      onChange={onRoleChange}
                      component={Select}
                      name="role"
                      label="Role"
                      labelId="role-label"
                      defaultValue={Role.User}
                    >
                      <MenuItem key={Role.User} value={Role.User}>
                        User
                      </MenuItem>
                      <MenuItem key={Role.Admin} value={Role.Admin}>
                        Admin
                      </MenuItem>
                      <MenuItem key={Role.BrandUser} value={Role.BrandUser}>
                        Brand manager
                      </MenuItem>
                      <MenuItem key={Role.Manager} value={Role.Manager}>
                        Manager
                      </MenuItem>
                      <MenuItem key={Role.StandardAgencyUser} value={Role.StandardAgencyUser}>
                        Standard Agency User
                      </MenuItem>
                    </Field>
                  </FormControl>
                </div>
                <Fade in={[Role.User, Role.Manager].includes(role)} timeout={400} unmountOnExit={true}>
                  <div>
                    <FormControl
                      error={touched['think_tank_id'] && !!errors['think_tank_id']}
                      sx={[sxStyles('inputField'), sxStyles('inputFieldSelect')]}
                      variant={'outlined'}
                    >
                      <InputLabel id="department-label">Department</InputLabel>
                      <Field
                        size="small"
                        component={Select}
                        name="department_id"
                        label="Department"
                        labelId="department-label"
                      >
                        {departments.map((department) => (
                          <MenuItem key={department.id} value={department.id}>
                            {department.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <FormControl
                      error={touched['think_tank_id'] && !!errors['think_tank_id']}
                      sx={[sxStyles('inputField'), sxStyles('inputFieldSelect')]}
                      variant={'outlined'}
                    >
                      <InputLabel id="think-tank-label">Think tank</InputLabel>
                      <Field
                        size="small"
                        component={Select}
                        name="think_tank_id"
                        label="Think tank"
                        labelId="think-tank-label"
                      >
                        {thinkTanks.map((thinkTank) => (
                          <MenuItem key={thinkTank.id} value={thinkTank.id}>
                            {thinkTank.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </div>
                </Fade>
                <Fade in={[Role.BrandUser].includes(role)} timeout={400} unmountOnExit={true}>
                  <div>
                    <Field
                      sx={sxStyles('inputField')}
                      name="brand_code"
                      size="small"
                      component={Autocomplete}
                      options={brands}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, value: {brand_code: string}) => {
                        setFieldValue('brand_code', value);
                        if (value) {
                          pullSubBrands(value.brand_code);
                        } else {
                          setSubBrands([]);
                          setFieldValue('sub_brand_code', []);
                        }
                      }}
                      getOptionLabel={(option: {name: string; brand_code: string}) =>
                        `${option.name} - ${option.brand_code}`
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={touched['brand_code'] && !!errors['brand_code']}
                          helperText={touched['brand_code'] && errors['brand_code']}
                          label="Brands"
                          variant={'outlined'}
                        />
                      )}
                    />
                    {formValues.brand_code !== null && (
                      <Field
                        multiple
                        sx={sxStyles('inputField')}
                        disabled={subBrandsLoading}
                        size="small"
                        name="sub_brand_code"
                        component={Autocomplete}
                        options={subBrands}
                        getOptionLabel={(option: {name: string}) => option.name}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextFieldMaterial
                            {...params}
                            error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                            helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                            label="Sub brands"
                            variant={'outlined'}
                          />
                        )}
                      />
                    )}
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="brand_admin"
                      Label={{label: 'Brand admin'}}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="create_users"
                      Label={{label: 'Can create users'}}
                    />
                  </div>
                </Fade>
                <div>
                  {![Role.BrandUser].includes(role) && (
                    <Field component={CheckboxWithLabel} type="checkbox" name="inactive" Label={{label: 'InActive'}} />
                  )}
                </div>
                <div>
                  <Button size="large" type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                  <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Grid>
    </TableContainer>
  );
}

export {CreateUser};
