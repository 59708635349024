import React, {useEffect, useState, useCallback} from 'react';
import {IconButton, Grid} from '@mui/material';
import {Link, useHistory, useParams} from 'react-router-dom';
import {DataGrid, getGridStringOperators, GridCellParams, GridColDef, GridColTypeDef} from '@mui/x-data-grid';
import {Edit, Visibility} from '@mui/icons-material';
import moment from 'moment';
import {htmlToText} from 'html-to-text';
// utils
import {Helper} from '../../../../utils/helper';
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {BuildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {ReportDataInterface} from './interfaces/brand-reports.interfaces';

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const columns: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Month',
    width: 120,
    type: 'filterString',
  },
  {
    field: 'sales_note',
    headerName: 'Sales Note',
    width: 200,
    type: 'filterString',
  },
  {
    field: 'ad_note',
    headerName: 'Advertising Note',
    flex: 1,
    width: 200,
    type: 'filterString',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 130,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Link to={`/brandreports/view/${params.row.brand_code}/${params.row.month}`}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
        <Link to={`/brandreports/update/${params.row.brand_code}/${params.row.month}`}>
          <IconButton>
            <Edit />
          </IconButton>
        </Link>
      </>
    ),
  },
];

function BrandReports(): JSX.Element {
  const firstMonth = '2021-04-01';
  const history = useHistory();

  const {id} = useParams<Record<string, string>>();
  const [rows, setRows] = useState<ReportDataInterface[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [count, setCount] = useState(0);
  const rowsPerPageOptions = [20, 50, 100];

  const dateStart = moment(new Date(firstMonth));
  const dateEnd = moment(new Date());
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  function onPageChange(page: number) {
    setPage(page);
  }

  const loadData = useCallback(async () => {
    const months = Helper.getMonthsBetweenDates(firstMonth);
    try {
      const {data} = await Api.get(`brandreports/${id}`);
      setCount(months.length);
      setRows(
        months.map((month) => {
          const item = data.rows.find((item: ReportDataInterface) => item.month === month);
          const ret = item
            ? {...item, id: month}
            : {
                id: month,
                month: month,
                brand_code: id,
                sales_note: 'N/A',
                ad_note: 'N/A',
              };

          ret.sales_note = htmlToText(ret.sales_note);
          ret.ad_note = htmlToText(ret.ad_note);
          return ret;
        }),
      );
    } catch (e) {
      errorAlert('Unable to get brands', e);
    }
  }, [id]);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = BuildMenu('monthly_reports', id, history);
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [history, id, loadData]);

  return (
    <TableContainer className="sub-page">
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <DataGrid
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowCount={count}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          columnTypes={{filterString: stringColumnType}}
          onPageChange={onPageChange}
          pagination
          disableSelectionOnClick={true}
          rows={rows}
          columns={columns}
        />
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {BrandReports};
