import React from 'react';
import { useParams} from 'react-router-dom';
import {LockOutlined} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import LockResetIcon from '@mui/icons-material/LockReset';
import {Typography, InputAdornment} from '@mui/material';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-mui';
import {changePasswordFormFields, updatePasswordValidationSchema} from './constant';
import {TFormFieldItem, TValues} from './types';
import { Api, errorAlert } from '../../../../utils/api';
import { GetStyles } from '../../../../utils/getStyles';
import { styles } from './styles';
import { SxStyleTypes } from '../../../../interfaces/sxStyles/sxStyles.interface';
import Spacer from '../../../../components/Spacer/Spacer';
import { alertService } from '../../../../services/alert.service';

const ChangePassword: React.FC = () => {
  const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];
  const {id} = useParams<Record<string, string | undefined>>();
  const values: TValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };

  const onSubmit = async (data: TValues, {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}) => {
    try{
      setSubmitting(true);
      const result = await Api.put(`users/${id}/change-password`, data);
      if(result.data === true)        alertService.success(
        <>{`Password changed Successfully.`}</>,
      );
    } catch (e) {
      errorAlert('Unable to update user!', e);
    } finally {
    setSubmitting(false);
  }
  };

  return (
    <>
      <Typography component="h2" variant="h4" color="primary" gutterBottom>
        Change Password
      </Typography>
      {values && (
        <Formik
          initialValues={values}
          validationSchema={updatePasswordValidationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
        >
          {({errors, touched, isSubmitting}) => {
            return (
              <Form>
                {changePasswordFormFields.map((item: TFormFieldItem) => (
                    <Field
                    helperText={touched[item.name as keyof typeof touched] && errors[item.name as keyof typeof errors]}
                    key={item.name}
                      sx={sxStyles('inputField')}
                      component={TextField}
                      variant="outlined"
                      name={item.name}
                      label={item.label}
                      type={item.type}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{outline: 'none'}}>
                            {<LockOutlined />}
                          </InputAdornment>
                        ),
                      }}
                      
                    />
                ))}
                     <Spacer height={16} />
                <LoadingButton
                  loading={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  loadingPosition="start"
                  disabled={isSubmitting || Object.keys(errors)?.length >0 || Object.keys(touched)?.length === 0}
                  startIcon={<LockResetIcon />}
                >

                  Change Password
                </LoadingButton>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default ChangePassword;
