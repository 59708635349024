import React from 'react';

import {
  Grid,
} from '@mui/material';

import PageTitle from 'src/components/PageTitle/PageTitle';

export default function PaymentCompletePage(): JSX.Element {
  return (
    <Grid
      className='page-container'
      container
    >
      <PageTitle>Subscription Plan Changed</PageTitle>
      <p>Your subscription plan was changed, thank you.</p>
    </Grid>
  )
}
