import io from 'socket.io-client';

// TODO see GoogleDrive.tsx
const socketOptions = {
  // extraHeaders: {
  //   Authorization: `${accountService.userValue.accessToken}`,
  // },
  transports: ['websocket', 'polling'],
  path: '/channels/socket.io',
};

export const socket = io(
  `${process.env.REACT_APP_WEBSOCKETS_URL}/channels`,
  socketOptions,
);
