import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Api, errorAlert } from 'src/utils/api';

import Button from 'src/components/Button/Button';
import { PlanWithPrices } from 'src/interfaces/stripe/plan-with-prices';
import { PlanWithDetails } from 'src/interfaces/stripe/plan-with-details';
import { BasicSubscriptionItem } from 'src/interfaces/stripe/basic-subscription-item';

import mixPanel from 'src/services/mixpanel';

interface Props {
  availablePlans: PlanWithPrices[];
  currentPriceId: string;
  setClientSecret: (clientSecret: string) => void;
  setCurrentForm: (formName: string) => void;
  subscriptionItems: BasicSubscriptionItem[];
}

export default function ChangeSubscriptionForm(
  { 
    availablePlans,
    currentPriceId,
    setClientSecret,
    setCurrentForm,
    subscriptionItems,
  }: Props
): JSX.Element {

  const [isLoading, setIsLoading] = useState(false);
  const [plansWithDetails, setPlansWithDetails] = useState<PlanWithDetails[]>([]);
  const [selectedPriceId, setSelectedPriceId] = useState<string>(currentPriceId);

  const history = useHistory();

  useEffect(() => {
    const plansWithDetails: PlanWithDetails[] = [];
    availablePlans.forEach(plan => {
      plan.prices.forEach(price => {
        plansWithDetails.push({
          id: plan.id,
          name: plan.name,
          price_id: price.id,
          unit_amount: price.unit_amount,
          interval: price.recurring.interval
        });
      });
    });
    setPlansWithDetails(plansWithDetails);
  }, [availablePlans]);

  useEffect(() => {
    setSelectedPriceId(currentPriceId);
  }, [currentPriceId]);

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (currentPriceId) {
      // Active user changing plan.
      try {
        const { data } = await Api.post(
          '/stripe/change-subscription-plan',
          {
            selectedPriceId,
            subscriptionItems,
            activePlan: true,
          }
        );
        setIsLoading(false);
        if (data.newSubscriptionItems.length > 0) {
          mixPanel({
            eventName: 'Change subscription plan',
            eventProperties: {
              newPlan: selectedPriceId,
              oldPlan: currentPriceId,
            },
          });
          history.push('/subscription-plan-changed');
        } else {
          if (data.success === false) {
            errorAlert(data.message, {});
          }
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    } else {
      // Inactive user reactivating plan, which means creating
      // a new Stripe subscription.
      try {
        const { data } = await Api.post(
          '/stripe/change-subscription-plan',
          {
            selectedPriceId,
            subscriptionItems,
            activePlan: false,
          }
        );
        setIsLoading(false);
        if (data.clientSecret) {
          mixPanel({
            eventName: 'Reactivate subscription plan',
            eventProperties: {
              newPlan: selectedPriceId,
            },
          });
          setCurrentForm('createPaymentMethod');
          setClientSecret(data.clientSecret);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPriceId(e.target.value);
  };

  return (
    <form
      className="subscription-form"
      id="change-subscription-form"
    >
      <h5>Select your subscription plan</h5>

      {plansWithDetails.map((plan, index) => {
        return (
          <div className="plan-with-details" key={index}>
            <input
              id={plan.price_id}
              checked={selectedPriceId === plan.price_id}
              name="plan"
              onChange={handleOptionChange}
              type="radio"
              value={plan.price_id}
            />
            <label
              htmlFor={plan.price_id}
            >
              {capitalize(plan.name)} at ${(plan.unit_amount/100).toFixed(2)} per {plan.interval}
            </label>
          </div>
        );
      })}

      <div className="buttons">
        <Button
          disabled={isLoading}
          onClick={handleSubmit}
          size="small"
        >
          Update subscription
        </Button>
        <Button
          size="small"
          onClick={() => setCurrentForm('')}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
      <div className="terms">
        By confirming your subscription, you allow FlatFilePro to charge you for future payments in accordance with our <a href="https://flatfile.pro/terms-of-service/" target="_blank" rel="noreferrer">terms of service</a>.
      </div>
    </form>
  );
}