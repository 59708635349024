import React from 'react';
import {GridCellParams, GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid';
import {IconButton, Tooltip} from '@mui/material';
// interfaces
import {ListingsInterface} from '../pages/Listings/interfaces/interfaces';
import {BrandInterface} from '../interfaces/brands/brand';
import {GoogleDriveFolderInterface} from '../interfaces/brands/google-drive-folder';

export interface ColumnFreezeButtonProps {
  url?: (row: GridCellParams['row']) => string;
  icon?: ((row: GridCellParams['row']) => JSX.Element | null) | JSX.Element | null;
  tooltip?: string | ((row: GridCellParams['row']) => string | null);
  onClick?: (row: GridCellParams['row']) => void;
  customButton?: {
    label?: string | JSX.Element;
    component: JSX.Element | string;
  };
}

type User = {
  brand: string;
  brand_admin: boolean;
  department: string;
  email: string;
  id: string;
  inactive: boolean;
  name: string;
  role: string;
  sub_brand_codes: string[];
  think_tank: string;
};

export const fixedPlaceHolder = ({
  width = 120,
  buttons,
}: {
  width?: number;
  buttons?: ColumnFreezeButtonProps[];
}): GridColDef => {
  return {
    field: 'Actions',
    headerName: '',
    flex: 1,
    minWidth: width,
    align: 'right',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (buttons && buttons.length > 0) {
        return (
          <div style={{minWidth: '120px', maxWidth: '120px'}} className="customButtons">
            {buttons.map((button: ColumnFreezeButtonProps, j: string | number) => {
              const {url, icon, onClick} = button;
              const newIcon = typeof icon === 'function' ? icon(params.row) : icon;
              if (onClick) {
                return (
                  <IconButton key={j} onClick={() => onClick(params.row)}>
                    {newIcon}
                  </IconButton>
                );
              } else {
                return (
                  <a key={j} target="_blank" rel="noreferrer" href={url && url(params.row)}>
                    <IconButton>{newIcon}</IconButton>
                  </a>
                );
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    },
  };
};

type Row =
  | ListingsInterface
  | BrandInterface
  | User
  | GoogleDriveFolderInterface
  | {
      [key: string]: string | number | null | undefined;
    };

export const CustomColumn = ({
  thisRef,
  rows,
  buttons,
  height = '52px',
  width = '120px',
}: {
  thisRef: React.ClassAttributes<HTMLDivElement>['ref'];
  rows:
    | {
        [key: string]: string | number | null | undefined;
      }[]
    | ListingsInterface['rows']
    | BrandInterface[]
    | User[]
    | GoogleDriveFolderInterface[];
  buttons: ColumnFreezeButtonProps[];
  height?: string;
  width?: string;
}): JSX.Element => {
  return (
    <div ref={thisRef} id={`fixed-column`} className="fixed-column-container" style={{width: width}}>
      {(
        rows as {
          [key: string]: string | number | null | undefined;
        }[]
      )?.map((row: Row, i: number) => {
        return (
          <div
            key={i}
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-cell--editable"
            style={{minWidth: width, maxWidth: width, minHeight: height, maxHeight: height}}
          >
            {buttons.map((button: ColumnFreezeButtonProps, j: string | number) => {
              const {url, icon, tooltip, onClick, customButton} = button;
              const newIcon = typeof icon === 'function' ? icon(row) : icon;
              const newTooltip = typeof tooltip === 'function' ? tooltip(row) : tooltip;
              const newButton = url ? (
                <a key={j} rel="noreferrer" href={url(row)}>
                  <IconButton>{newIcon}</IconButton>
                </a>
              ) : newIcon ? (
                <IconButton key={j} onClick={() => onClick && onClick(row)}>
                  {newIcon}
                </IconButton>
              ) : null;

              const component =
                newTooltip && newButton ? (
                  <Tooltip key={j} title={newTooltip}>
                    {newButton}
                  </Tooltip>
                ) : customButton ? (
                  <div key={j} style={{padding: '0 10px'}}>
                    {customButton.label && customButton.label}
                    <div onClick={() => onClick && onClick(row)}>{customButton.component}</div>
                  </div>
                ) : (
                  newButton
                );

              return component;
            })}
          </div>
        );
      })}
    </div>
  )
};

export const makeColumnFreeze = (
  thisRef: React.RefObject<HTMLInputElement>,
  visibilityModel: GridColumnVisibilityModel,
  rows:
    | {
        [key: string]: string | number | null | undefined;
      }[]
    | ListingsInterface['rows']
    | BrandInterface[]
    | User[]
    | GoogleDriveFolderInterface[],
): void => {
  if (document.getElementById('fixed-column')) {
    document.getElementById('fixed-column')?.remove();
  }
  const row = document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0];
  if (!visibilityModel.hasOwnProperty('Actions')) {
    if (!document.getElementById(`fixed-column`) && row && rows.length > 0 && thisRef && thisRef.current) {
      row.appendChild(thisRef.current);
    }
  }
};
