import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {Select} from 'formik-mui';
import * as Yup from 'yup';

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
  // Link,
  // useTheme,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  // Stack,
  Box,
} from '@mui/material';

import mixPanel from 'src/services/mixpanel';

import {
  Edit,
  Check,
  // Close
} from '@mui/icons-material';

import {Alert} from '@mui/lab';
// utils
import {Api, errorAlert} from '../../../../../utils/api';
// services
import {accountService} from '../../../../../services/account.service';
import {alertService} from '../../../../../services/alert.service';
// components
import ModalButton from '../../../../../components/ModalButton/ModalButton';
import Button from '../../../../../components/Button/Button';
// styles
import {sxStyles} from '../../../../Account/Styles';
// interface
import {BrandInterface} from '../../../../../interfaces/brands/brand';
// import { Brand } from 'src/pages/Brands/utils/interface';

import { Marketplaces } from '../../../../Listings/utils/constant';

const marketplaceValidationSchema = Yup.object().shape({
  sp_auth_marketplace: Yup.string().required('The marketplace is required'),
});

interface GroupedAuthorizedMarketplaces {
  [sellerId: string]: {
    sellerName: string;
    marketplaces: {
      marketplace_id: string;
      marketplace_name: string;
    }[];
  };
}

interface AuthorizeSpApiForFFPBrandProps {
  brandCode: string;
  buttonOnly?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  hideButton?: boolean;
  brandInfo: BrandInterface | null;
  refreshBrand?: () => void;
  GAEvents?: (category: string, action: string, label?: string) => void;
  subBrand?: boolean;
  advertisingAPI?: JSX.Element | null;
  editable?: boolean;
}

function AuthorizeSpApiForFFPBrand({
  brandCode,
  buttonOnly,
  buttonSize,
  brandInfo,
  refreshBrand,
  GAEvents,
  subBrand,
  advertisingAPI,
  editable, // eslint-disable-line
}: AuthorizeSpApiForFFPBrandProps): JSX.Element {
  // const theme = useTheme();

  const [displayConfirmDisconnectSpApi, setDisplayConfirmDisconnectSpApi] = useState(false);
  const [amazonMarketplaces, setAmazonMarketplaces] = useState<
    {
      marketplaceId: string;
      name: string;
      countryCode: string;
    }[]
  >([]);
  const [marketplaceToDelete, setMarketplaceToDelete] = useState('');
  const [marketplaceIdToAuthorize, setMarketplaceIdToAuthorize] = useState('');
  const [marketplaceNameToDelete, setMarketplaceNameToDelete] = useState('');
  const [sellerIdToDelete, setSellerIdToDelete] = useState('');
  const [maxMarketplaces, setMaxMarketplaces] = useState<number>(1);

  const [groupedAuthorizedMarketplaces, setGroupedAuthorizedMarketplaces] = useState<GroupedAuthorizedMarketplaces>({});

  const [editingSellerName, setEditingSellerName] = useState<{[sellerId: string]: boolean}>({});
  const [originalSellerName, setOriginalSellerName] = useState<string>('');

  // const [editBrand, setEditBrand] = useState(false);
  // const [newBrandName, setNewBrandName] = useState('');

  useEffect(() => {
    getMarketplaces();
  }, []);

  useEffect(() => {
    pullSubscriptionItems();
  }, []);

  useEffect(() => {
    const groupedAuthorizedMarketplaces = getGroupedAuthorizedMarketplaces(brandInfo?.sp_api_authorized_marketplaces);
    setGroupedAuthorizedMarketplaces(groupedAuthorizedMarketplaces);
    const editingSellerName: {[sellerId: string]: boolean} = {};
    for (const sellerId in groupedAuthorizedMarketplaces) {
      editingSellerName[sellerId] = false;
    }
    setEditingSellerName(editingSellerName);
  }, [brandInfo]);

  async function pullSubscriptionItems() {
    try {
      const { data } = await Api.get(`/brands/${accountService.userValue.brand_code}/subscription-items`);
      setMaxMarketplaces(data.maxMarketplaces);
    } catch (e) {
      errorAlert('Something went wrong', e);
    }
  }

  async function getMarketplaces() {
    try {
      const {data} = await Api.get('sp-api/marketplaces');
      setAmazonMarketplaces(data);
    } catch (e) {
      errorAlert('Unable to get Amazon Marketplaces', e);
    }
  }

  async function grantSellingPartnerAccess(
    data: {
      sp_auth_marketplace: string;
    },
    closeModal?: () => void,
  ) {
    try {
      const response = await Api.get(`sp-api/authorization-link/${brandCode}/${data.sp_auth_marketplace}`);
      const marketplaceName = Marketplaces.find((m) => m.id === data.sp_auth_marketplace);
      mixPanel({
        eventName: 'Connect to Amazon marketplace',
        eventProperties: {
          marketplace: marketplaceName?.name,
          numberOfAuthorizedMarketplaces: numberOfAuthorizedMarketplaces + 1,
          maxMarketplaces,
        },
      });
      window.location.href = response.data;
      closeModal?.();
    } catch (e) {
      errorAlert('Unable to get authorization link', e);
    }
  }

  async function confirmDisconnectSellingPartnerAccess(closeModal: () => void) {
    setDisplayConfirmDisconnectSpApi(false);
    const endpoint = subBrand
      ? `/sp-api/deauthorize/${brandCode}/${marketplaceToDelete}/${sellerIdToDelete}`
      : `/sp-api/deauthorize/brand-user/${marketplaceToDelete}/${sellerIdToDelete}`;
    try {
      const response = await Api.post(endpoint, {});
      if (response.data.ok === true) {
        const marketplaceName = Marketplaces.find((m) => m.id === marketplaceToDelete);
        mixPanel({
          eventName: 'Disconnect from Amazon marketplace',
          eventProperties: {
            marketplace: marketplaceName?.name,
            numberOfAuthorizedMarketplaces: numberOfAuthorizedMarketplaces - 1,
            maxMarketplaces,
          },
        });
        alertService.success('The Selling Partner API access was revoked.');
      }
      accountService.refreshToken();
      refreshBrand?.();

      // Clear stored selected marketplace ID and seller ID to allow listings
      // page to get refresh them.
      localStorage.removeItem('selectedSellerId');
      localStorage.removeItem('selectedMarketplaceId');

      closeModal();
    } catch (e) {
      errorAlert('Unable to revoke Selling Partner API access', e);
    }
  }

  // async function updateParentBrand(id: string, field: string, value: string) {
  //   if (value === '') {
  //     return;
  //   }
  //   try {
  //     await Api.patch(`brands/${id}`, {[field]: value});
  //     alertService.success('Brand updated successfully');
  //     // setEditBrand(false);
  //     // setNewBrandName('');
  //     refreshBrand?.();
  //   } catch (e) {
  //     errorAlert('Unable to update brand', e);
  //   }
  // }

  const authorizedMarketplacesModal = (
    <ModalButton
      modalTitle="Please select the marketplace that you want to connect"
      buttonText="Grant New Marketplace for Selling Partner API access"
      buttonSize={buttonSize}
      onClick={() => GAEvents && GAEvents('Click', 'Selling Partner API')}
    >
      {(closeModal) => {
        return (
          <Formik
            initialValues={{
              sp_auth_marketplace: 'ATVPDKIKX0DER',
            }}
            validationSchema={marketplaceValidationSchema}
            onSubmit={(values) => grantSellingPartnerAccess(values, closeModal)}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {({errors, touched, isSubmitting, values: formValues}) => (
              <Box sx={sxStyles('form')}>
                <Form>
                  {brandInfo?.sp_api_authorized_marketplaces?.find(
                    (marketplace) => marketplace.marketplace_id === formValues.sp_auth_marketplace,
                  ) && (
                    <Alert color="warning">
                      <Typography>
                        This brand has already granted us Selling Partner API authorization for this marketplace.
                        Authorizing this marketplace again would overwrite the information from the previous
                        authorization.
                      </Typography>
                    </Alert>
                  )}
                  <FormControl
                    size="small"
                    error={touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']}
                    sx={sxStyles('inputField')}
                    variant={'outlined'}
                  >
                    <Field
                      size="small"
                      component={Select}
                      name="sp_auth_marketplace"
                      label="Marketplace"
                      labelId="sp-marketplace-label"
                    >
                      {amazonMarketplaces.map((marketplace) => (
                        <MenuItem key={`marketplace_${marketplace.marketplaceId}`} value={marketplace.marketplaceId}>
                          {marketplace.name} ({marketplace.countryCode})
                        </MenuItem>
                      ))}
                    </Field>
                    <FormHelperText>
                      {touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']
                        ? errors['sp_auth_marketplace']
                        : ''}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      GAEvents?.('Selling Partner API', 'Connect', 'Connect');
                    }}
                  >
                    Authorize
                  </Button>
                </Form>
              </Box>
            )}
          </Formik>
        );
      }}
    </ModalButton>
  );

  const getGroupedAuthorizedMarketplaces = (
    authorizedMarketplaces: BrandInterface['sp_api_authorized_marketplaces']
  ) => {
    const groupedAuthorizedMarketplaces: GroupedAuthorizedMarketplaces = {};
    if (authorizedMarketplaces) {
      // TODO group by sellerID, include a key for sellerName and an array of marketplaces
      authorizedMarketplaces.forEach((item) => {
        if (!groupedAuthorizedMarketplaces[item.selling_partner_id]) {
          groupedAuthorizedMarketplaces[item.selling_partner_id] = {
            sellerName: item.seller_name,
            marketplaces: [],
          };
        }
        groupedAuthorizedMarketplaces[item.selling_partner_id].marketplaces.push({
          marketplace_id: item.marketplace_id,
          marketplace_name: item.marketplace_name,
        });
      });
    }
    return groupedAuthorizedMarketplaces;
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    sellerId: string
  ) => {
    if (e.key === 'Enter') {
      handleSellerNameSet(sellerId);
    } else if (e.key === 'Escape' || e.key === 'Esc') {
      setGroupedAuthorizedMarketplaces(prevState => ({
        ...prevState,
        [sellerId]: {
          ...prevState[sellerId],
          sellerName: originalSellerName,
        }
      }));
      setEditingSellerName((prev) => ({...prev, [sellerId]: false}));
    }
  };

  const handleSellerNameSet = async (
    sellerId: string
  ) => {
    const sellerName = groupedAuthorizedMarketplaces[sellerId].sellerName;
    groupedAuthorizedMarketplaces[sellerId].sellerName = sellerName;
    setGroupedAuthorizedMarketplaces(groupedAuthorizedMarketplaces);
    try {
      const res = await Api.post(`brands/${brandCode}/update-seller-name`, {
        sellerId,
        sellerName,
      });
      if (res.data.ok) {
        mixPanel({
          eventName: 'Update seller name',
          eventProperties: {
            sellerId,
            sellerName,
          },
        });
        alertService.success('Seller name updated');
      }
    } catch (e) {
      errorAlert('Unable to update seller name', e);
    }
    setEditingSellerName((prev) => ({...prev, [sellerId]: false}));
  };

  const handleSellerNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sellerId: string
  ) => {
    const sellerName = e.target.value;
    // Clone the state
    const updatedGroupedAuthorizedMarketplaces = {
      ...groupedAuthorizedMarketplaces,
      [sellerId]: {
        ...groupedAuthorizedMarketplaces[sellerId],
        sellerName: sellerName // Update the specific value
      }
    };

    // Update the state with the new object
    setGroupedAuthorizedMarketplaces(updatedGroupedAuthorizedMarketplaces);
  };

  const handleEditSellerName = (sellerId: string) => {
    setEditingSellerName((prev) => ({...prev, [sellerId]: true}));
    setOriginalSellerName(groupedAuthorizedMarketplaces[sellerId].sellerName);
  };

  const numberOfAuthorizedMarketplaces = brandInfo?.sp_api_authorized_marketplaces?.length || 0;

  return (
    <>
      {!buttonOnly && (
        <Card variant="outlined" sx={sxStyles('connectorCard')}>
          <CardHeader title="Connect to an Amazon Seller Central account" />
          <CardContent>
            {/* {editable ? (
              <Box sx={sxStyles('brandInput')}>
                {!editBrand ? (
                  <div>
                    <Typography variant="subtitle1">
                      <span>Parent Account Name: </span> {brandInfo?.name}
                    </Typography>
                    <Edit onClick={() => setEditBrand((p) => !p)} />
                  </div>
                ) : (
                  <div>
                    <TextField
                      variant="outlined"
                      name="name"
                      label="Account name"
                      type="text"
                      size="small"
                      defaultValue={brandInfo?.name}
                      onChange={(e) => setNewBrandName(e.target.value)}
                    />
                    <Button onClick={() => updateParentBrand(brandInfo?.brand_code as string, 'name', newBrandName)}>
                      Save
                    </Button>
                    <Close onClick={() => setEditBrand((p) => !p)} />
                  </div>
                )}
              </Box>
            ) : (
              <Typography variant="subtitle1">
                <span>Parent Account Name: </span> {brandInfo?.name}
              </Typography>
            )} */}
            <div className="overview">
              <p>
                Click the <strong>connect</strong> button next to an Amazon marketplace and follow the instructions to connect with your Amazon Seller Central account for that marketplace and manage your listings.
              </p>
            </div>

            <form id="available-marketplaces">
              <Autocomplete
                size="small"
                options={amazonMarketplaces.map((marketplace) => ({label: marketplace.name, id: marketplace.marketplaceId}))}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select a country" />}
                onChange={(event, value) => {
                  if (value) {
                    setMarketplaceIdToAuthorize(value.id);
                  }
                }}
              />
              <Button
                disabled={!marketplaceIdToAuthorize || (numberOfAuthorizedMarketplaces >= maxMarketplaces)}
                size="small"
                onClick={() => {
                  grantSellingPartnerAccess({sp_auth_marketplace: marketplaceIdToAuthorize});
                }}
              >
                CONNECT
              </Button>
            </form>

            <div className="authorized-marketplaces">

              <h4>Authorized sellers and marketplaces</h4>
              <p>
                You are connected to <strong>{numberOfAuthorizedMarketplaces}</strong> marketplace{numberOfAuthorizedMarketplaces === 1 ? '' : 's'} of a maximum of <strong>{maxMarketplaces}</strong>.
              </p>
              {numberOfAuthorizedMarketplaces >= maxMarketplaces && (
                <Alert color="warning">
                  <Typography>
                    Maximum of marketplaces reached. Please disconnect from a marketplace before you can connect to a new one or <a href="/account/billing">add marketplaces to your subscription</a>.
                  </Typography>
                </Alert>
              )}
              {Object.keys(groupedAuthorizedMarketplaces).map((sellerId, i) => (
                <div
                  className="authorized-seller"
                  key={i}
                >
                  <div>
                  <h5>
                    Seller:
                    {' '}
                    {editingSellerName[sellerId] ? (
                      <input
                        onChange={(e) => handleSellerNameChange(e, sellerId)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => handleKeyDown(e, sellerId)}
                        type="text"
                        value={groupedAuthorizedMarketplaces[sellerId].sellerName}
                      />
                    ): (
                      <span title={`Seller ID: ${sellerId}`}>{groupedAuthorizedMarketplaces[sellerId].sellerName}</span>
                    )}
                    <Edit
                      className={`edit-seller ${editingSellerName[sellerId] ? 'hidden' : ''}`}
                      onClick={() => handleEditSellerName(sellerId)}
                     />
                     <Check
                      className={`save-seller ${editingSellerName[sellerId] ? '' : 'hidden'}`}
                      onClick={() => handleSellerNameSet(sellerId)}
                     />
                  </h5>
                  {groupedAuthorizedMarketplaces[sellerId].marketplaces.map((marketplace, j) => (
                    <div
                      className="authorized-marketplace"
                      key={j}
                    >
                      <div>
                        {marketplace.marketplace_name}
                      </div>
                      <div>
                        <Button
                          size="small"
                          onClick={() => {
                            setMarketplaceToDelete(marketplace.marketplace_id);
                            setMarketplaceNameToDelete(marketplace.marketplace_name);
                            setSellerIdToDelete(sellerId);
                            setDisplayConfirmDisconnectSpApi(true);
                          }}
                        >
                          DISCONNECT
                        </Button>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              ))}

            </div>

            {advertisingAPI && (
              <>
                <Divider />
                {advertisingAPI}
              </>
            )}

          </CardContent>
        </Card>
      )}
      {buttonOnly && authorizedMarketplacesModal}
      <ModalButton
        openModal={displayConfirmDisconnectSpApi}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplayConfirmDisconnectSpApi(false)}
        modalTitle={`Are you sure you want to disconnect from the ${marketplaceNameToDelete} marketplace for seller ${sellerIdToDelete}?`}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              confirmDisconnectSellingPartnerAccess(closeModal);
              GAEvents?.('Selling Partner API', 'Revoke Marketplace', marketplaceToDelete);
            }}
          >
            Agree
          </Button>
        )}
        buttonText="Disconnect"
      >
        {() => {
          return (
            <>
              This cannot be undone. After the Selling Partner API access is disconnected, we will no longer
              pull the brand&apos;s information from this marketplace until the access is granted again.
            </>
          );
        }}
      </ModalButton>
    </>
  );
}

export {AuthorizeSpApiForFFPBrand};
