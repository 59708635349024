import {Theme, createTheme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const ecom3kStyles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  inputField: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputLabel-shrink': {
      fontWeight: '600',
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 100px #fff inset!important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      color: '#adadad',
    },
    [theme.breakpoints.down(900)]: {
      '& .MuiOutlinedInput-root': {
        background: '#fff',
        borderRadius: '5px',
      },
    },
  },
  loginButton: {
    margin: '20px 0 30px 0',
    width: '100%',
    padding: '13px 0',
    background: '#5A38FD',
    '&:hover': {
      background: '#4c2de7',
    },
  },
  customView: {
    height: '100vh',
    '& > div:last-of-type': {
      background: '#5A38FD',
    },
  },
  grid: {
    background: '#fff',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
    },
  },
  quote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .quote-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '60px!important',
      opacity: 0.8,
      transition: '.3s ease-out',
      '& .card': {
        flexWrap: 'nowrap!important',
        alignItems: 'center',
      },
      '& p': {
        fontStyle: 'italic',
        fontSize: '18px',
        lineHeight: '24px',
      },
      '& span': {
        lineHeight: '20px',
      },
      '& .MuiAvatar-root': {
        width: '45px',
        height: '45px',
      },
      '&:hover': {
        opacity: 1,
        transition: '.3s ease-in',
      },
      [theme.breakpoints.down(1024)]: {
        padding: '40px 35px!important',
        '& p': {
          fontSize: '16px',
          lineHeight: '20px',
        },
        '& span': {
          fontSize: '15px',
          lineHeight: '20px',
        },
      },
    },
    '& .relative': {
      position: 'relative',
      width: '100%',
      '& .MuiAvatar-root': {
        marginRight: '20px',
      },
      '& svg': {
        position: 'absolute',
        top: '-10px',
        left: '-40px',
        transform: 'scaleX(-1)',
        fontSize: '80px',
        color: '#7c61ff',
        [theme.breakpoints.down(1024)]: {
          fontSize: '70px',
        },
      },
      '& .author-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    '& .absolute': {
      position: 'absolute',
    },
  },
});

const ecom3kNewTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#fff',
      main: '#174582',
      dark: '#052d61',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#174582',
      dark: '#1b2132',
      contrastText: '#000',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#24242488',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Karla',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const ecom3kSxStyles = (key: string): SxStyleTypes => GetStyles(ecom3kStyles)[key];

export {ecom3kSxStyles, ecom3kNewTheme};
