import React from 'react';
import {Theme, Grid} from '@mui/material';
import {useParams} from 'react-router-dom';
// components
import SummaryItem from './SummaryItem';
import {CustomAlert} from '../../../../Login/components/CustomAlert';
import {metricOptionsData} from './Metrics';

const sxStyles = (theme: Theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridGap: '15px',
    '& .MuiTypography-subtitle1 ': {
      fontSize: '.8rem',
      whiteSpace: 'nowrap',
    },
    '& .MuiTypography-h5': {
      fontSize: '1rem',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.7rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '.8rem',
      },
    },
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    },
  },
});

interface BrandPerformanceSummaryProps {
  data: {
    [key: string]: {
      [key: string]: number;
    };
  } | null;
  loading?: boolean;
  hiddenMetrics?: string[];
}

function BrandPerformanceSummary({data, loading, hiddenMetrics}: BrandPerformanceSummaryProps): JSX.Element {
  const {adsType} = useParams<{
    adsType: string;
  }>();
  const metricsOptions = metricOptionsData[adsType as keyof typeof metricOptionsData] || {};

  return (
    <Grid container sx={(theme) => sxStyles(theme).grid}>
      {Object.keys(metricsOptions).map((metricKey: string, idx) =>
        hiddenMetrics?.includes(metricKey) ? null : (
          <SummaryItem
            loading={loading}
            key={idx}
            data={data?.[metricKey]}
            metricOption={metricsOptions[metricKey as keyof typeof metricsOptions]}
          />
        ),
      )}
      <CustomAlert id="brand-summary" />
    </Grid>
  );
}

export {BrandPerformanceSummary};
