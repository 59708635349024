import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  box: {
    padding: '10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  formGroup: {
    '&.MuiGrid-root': {
      width: '100%',
      paddingTop: '30px',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > div:first-of-type': {
        padding: '20px 0',
        display: 'flex',
        width: '60%',
        '& > div': {
          width: '50%',
          maxWidth: '300px',
          '&:first-of-type': {
            paddingRight: '10px',
          },
        },
      },
      '& > div:last-of-type': {
        width: '100%',
        maxWidth: '300px',
      },
      [theme.breakpoints.down(1024)]: {
        '& > div:first-of-type': {
          width: '70%',
          paddingRight: '10px',
        },
        '& > div:last-of-type': {
          maxWidth: '200px',
        },
      },
      [theme.breakpoints.down(767)]: {
        paddingTop: 0,
        '& > div:first-of-type': {
          justifyContent: 'space-between',
          width: '100%',
          paddingRight: '0',
          '& > .MuiAutocomplete-root': {
            maxWidth: '100%',
            paddingRight: '0',
          },
        },
        '& > div:last-of-type': {
          maxWidth: '100%',
        },
      },
      [theme.breakpoints.down(520)]: {
        '& > div:first-of-type': {
          padding: '10px 0',
          width: '100%',
          flexDirection: 'column',
          '& > .MuiAutocomplete-root': {
            width: '100%',
            '&:first-of-type': {
              padding: '10px 0',
            },
          },
        },
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
        borderRadius: '0',
        borderTopLeftRadius: '3px',
      },
      '&.MuiButtonGroup-root .MuiButton-root': {
        border: '1px solid #174580',
        borderBottomColor: 'transparent',
      },
      '& .MuiButton-root:nth-of-type(3n)': {
        borderLeftColor: 'transparent',
      },
      '& .MuiButton-root:nth-of-type(3)': {
        borderTopRightRadius: '3px',
      },
      '& .MuiButton-root:nth-of-type(4n)': {
        borderRightColor: 'transparent',
        '&:hover': {
          borderRightColor: 'transparent',
        },
      },
      '& .MuiButton-root:last-of-type': {
        gridArea: '4 / 1 / 4/ 4',
        borderBottomColor: theme.palette.primary.main,
        borderRadius: '0',
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
      },
      '& .MuiButton-outlined': {
        border: 'none',
        '&:last-of-type': {
          backgroundColor: '#e9e9e9',
        },
      },
      '& .MuiButton-containedPrimary': {
        border: 'none',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          outline: 'none',
        },
      },
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
