import React, {useState} from 'react';
import moment from 'moment';
import {Checkbox, FormControlLabel, LinearProgress, Paper, Typography, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
  BarChart,
  Bar,
  Cell,
} from 'recharts';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
import Button from '../../../components/Button/Button';
import Spacer from '../../../components/Spacer/Spacer';

const metricsOptions = {
  buy_box: {
    color: '#4dc2c2',
    label: 'Buy Box %',
  },
  session: {
    color: '#cf2727',
    label: 'Sessions',
  },
  conversion: {
    color: '#a337cb',
    label: 'Conversion Rate',
  },
  revenue: {
    color: '#dab642',
    label: 'Revenue',
  },
  units: {
    color: '#003ea9',
    label: 'Units Sold',
  },
};

interface MetricDataInterface {
  date: string;
  value: number;
  metric: string;
  type: string;
}

export interface ChartDataTypes {
  interval?: string;
  data: {
    data: {
      date: string;
      value: number;
      predicted?: string;
    }[];
    metric: string;
    type: string;
  }[];
}

function PerformanceChart({
  data,
  metric,
  onMetricChange,
  loading,
}: {
  data: ChartDataTypes | null;
  metric: string[];
  onMetricChange: (metric: string[]) => void;
  loading: boolean;
}): JSX.Element {
  const theme = useTheme();
  const [mode, setMode] = useState('line');

  function formatXAxis(tickItem: string) {
    switch (data?.interval) {
      case 'day':
        return moment.utc(tickItem).format('YYYY-MM-DD');
      default:
        return moment.utc(tickItem).format('YYYY-MM-DD HH:mm');
    }
  }

  const handleMetricChange = (metricName: string, metricValue: boolean) => {
    if (metricValue) onMetricChange([...metric, metricName]);
    else {
      onMetricChange(metric.filter((x) => x !== metricName));
    }
  };

  const transformData = () => {
    const newData: MetricDataInterface[][] = [];
    let newArray: MetricDataInterface[] = [];
    data?.data?.map((x) => {
      const newD = x.data.map((y) => {
        return {
          value: y.value,
          date: y.date,
          metric: x.metric,
          type: x.type,
        };
      });
      return newData.push(newD);
    });
    newArray = newData.flat(1);
    return newArray;
  };

  const barChartData = Object.values(
    transformData().reduce(
      (
        acc: {
          [key: string]: {
            date: string;
            [key: string]: number | string;
            type: string;
          };
        },
        d,
      ) => {
        const {date, metric, type, value} = d;
        acc[date] = acc[date] || {date};
        acc[date][metric] = value;
        acc[date]['type'] = type;
        return acc;
      },
      {},
    ),
  );

  const metrics = Array.from(new Set(transformData().map((i) => i.metric)));

  return (
    <Grid container spacing={1} style={{paddingTop: 30}}>
      <Grid container spacing={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button size="small" onClick={() => setMode('line')}>
          Line
        </Button>
        <Spacer width={5} />
        <Button size="small" onClick={() => setMode('bar')}>
          Bar
        </Button>
      </Grid>

      <Grid item width="100%">
        {metric?.length && data?.data.some((x) => x.data?.length) ? (
          <ResponsiveContainer height={450} width={'100%'}>
            {mode === 'line' ? (
              <LineChart>
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis
                  type={'number'}
                  allowDuplicatedCategory={false}
                  allowDataOverflow={true}
                  domain={['dataMin', 'dataMax']}
                  dataKey={(x) => moment.utc(x.date).valueOf()}
                  tickFormatter={formatXAxis}
                  minTickGap={20}
                  interval="preserveStartEnd"
                />

                <Tooltip
                  labelStyle={{color: theme.palette.primary.main}}
                  labelFormatter={(label) =>
                    data.interval === 'day'
                      ? moment.utc(label).format('YYYY-MM-DD')
                      : moment.utc(label).format('YYYY-MM-DD HH:mm')
                  }
                />
                {data.data?.map((x, i) => {
                  const metric = x.metric as keyof typeof metricsOptions;
                  return (
                    <Line
                      key={i}
                      data={x.data}
                      type="monotone"
                      dataKey="value"
                      name={metricsOptions[metric].label}
                      stroke={metricsOptions[metric].color}
                      strokeWidth={4}
                      dot={false}
                      yAxisId={x.metric}
                      strokeDasharray={x.type === 'previous' ? '5 5' : ''}
                      connectNulls
                    />
                  );
                })}
                {data.data?.map((x, i) => {
                  return (
                    <YAxis
                      key={`axis_${i}`}
                      domain={['auto', 'auto']}
                      hide={true}
                      axisLine={false}
                      yAxisId={x.metric}
                      type={'number'}
                    />
                  );
                })}
              </LineChart>
            ) : (
              <BarChart data={barChartData}>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis
                  allowDataOverflow={true}
                  domain={['dataMin', 'dataMax']}
                  dataKey={'date'}
                  tickFormatter={formatXAxis}
                  interval="preserveStartEnd"
                />

                <Tooltip
                  labelStyle={{color: theme.palette.primary.main}}
                  labelFormatter={(label) =>
                    data.interval === 'day'
                      ? moment.utc(label).format('YYYY-MM-DD')
                      : moment.utc(label).format('YYYY-MM-DD HH:mm')
                  }
                />
                {metrics.map((x, i) => {
                  const metric = x as keyof typeof metricsOptions;
                  return (
                    <Bar
                      key={i}
                      dataKey={x}
                      name={metricsOptions[metric]?.label}
                      fill={metricsOptions[metric]?.color}
                      label={x}
                      yAxisId={x}
                      maxBarSize={25}
                    >
                      {barChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fillOpacity={entry.type === 'previous' ? 0.4 : 1} />
                      ))}
                    </Bar>
                  );
                })}
              </BarChart>
            )}
          </ResponsiveContainer>
        ) : (
          <Paper
            variant={'outlined'}
            style={{
              height: '450px',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '0px 0px 6px 0px #94949433',
            }}
          >
            <Typography color={'textSecondary'} variant={'h4'}>
              No data
            </Typography>
          </Paper>
        )}
        {loading ? <LinearProgress color={'primary'} /> : <></>}
        <div style={{textAlign: 'center'}}>
          {Object.keys(metricsOptions)?.map((x, i) => (
            <FormControlLabel
              key={i}
              style={{color: theme.palette.text.primary}}
              control={
                <Checkbox
                  style={{color: metricsOptions[x as keyof typeof metricsOptions].color}}
                  checked={metric.includes(x)}
                  onChange={(e) => handleMetricChange(x, e.target.checked)}
                  disabled={loading}
                />
              }
              label={metricsOptions[x as keyof typeof metricsOptions].label}
            ></FormControlLabel>
          ))}
        </div>
        <CustomAlert id="performance-chart" />
      </Grid>
    </Grid>
  );
}

export {PerformanceChart};
