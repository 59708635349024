import React, {useEffect, useState} from 'react';
import {ButtonGroup, Button, Grid, Checkbox, Typography, Theme} from '@mui/material';
import {Moment} from 'moment';
import {SystemStyleObject, Theme as SystemTheme} from '@mui/system';
// components
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import PageTitle from '../PageTitle/PageTitle';
// utils
import {onFromToChange} from '../../utils/OnFromToChange';
import {rangeData} from './rangeData';
// styles
import {sxStyles} from './Styles';
// interfaces
import {DateInterface, DateRangeInterface} from '../../interfaces/date/date';
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';

interface DateRangeButtonsProps {
  className?: string;
  sx?: ((theme: Theme) => SxStyleTypes) | SxStyleTypes;
  loading?: boolean;
  compare?: boolean;
  loadData?: (
    preset: string,
    fromValue: DateInterface['date'],
    toValue: DateInterface['date'],
    compareFromValue: DateInterface['date'] | null,
    compareToValue: DateInterface['date'] | null,
  ) => void;
  fromTo: DateRangeInterface;
  setFromTo: (v: DateRangeInterface) => void;
  period?: string;
  setPeriod?: (period: string) => void;
  showButton?: boolean;
  buttonOnClick?: () => void;
  compareComponent?: boolean;
  compareOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pageTitle?: string;
  pageTitleComponent?: JSX.Element;
}

export default function DateRangeButtons({
  className,
  sx,
  loading,
  compare,
  loadData,
  fromTo,
  setFromTo,
  period,
  setPeriod,
  showButton,
  buttonOnClick,
  compareComponent,
  compareOnChange,
  pageTitle,
  pageTitleComponent,
}: DateRangeButtonsProps): JSX.Element {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const currentDate = Object.keys(rangeData).find((key) => {
    const from = (rangeData[key]?.from as Moment)?.format('YYYY-MM-DD');
    const to = (rangeData[key]?.to as Moment)?.format('YYYY-MM-DD');
    return from === fromTo?.from && to === fromTo?.to;
  });

  useEffect(() => {
    if (currentDate) {
      setPeriod?.(rangeData[currentDate].value);
    } else {
      setPeriod?.('custom');
    }
  }, [currentDate, setPeriod, fromTo]);

  return (
    <Grid container spacing={2} sx={sxStyles('pageHeader')}>
      <Grid item lg={pageTitle || pageTitleComponent ? 9 : 12} xs={12}>
        <ButtonGroup
          size={'small'}
          variant={'outlined'}
          color={'primary'}
          disabled={loading}
          fullWidth
          style={{marginBottom: '20px'}}
          className={`${className} new-group`}
          sx={[sxStyles('buttonGroup'), sx] as SystemStyleObject<SystemTheme>[]}
        >
          {Object.entries(rangeData).map(([index, item]): JSX.Element => {
            return (
              <Button
                key={index}
                variant={period === item.value ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => {
                  if (item.value !== 'custom') {
                    onFromToChange({
                      from: item.from as string,
                      to: item.to as string,
                      compareFrom: null,
                      compareTo: null,
                      preset: item.value,
                      compare: compare,
                      loadData: loadData && loadData,
                      setFromTo: setFromTo,
                    });
                  } else {
                    setShowDateRangePicker((prev) => !prev);
                  }
                  setPeriod?.(item.value);
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </ButtonGroup>

        {fromTo.to && fromTo.from && (
          <Grid container style={{display: 'flex', alignItems: 'center'}}>
            {compareComponent && (
              <Grid item xs={12} lg={12} style={{fontSize: '13px', marginBottom: '10px'}}>
                <Typography variant="overline" display="block" color="secondary">
                  <Checkbox size="small" checked={compare} onChange={(e) => compareOnChange?.(e)} /> Compare to previous
                </Typography>
              </Grid>
            )}
            <DateRangePicker
              width="450px"
              disabled={loading}
              from={fromTo.from}
              open={showDateRangePicker}
              setOpen={setShowDateRangePicker}
              to={fromTo.to}
              onChange={(x) => {
                setPeriod?.('custom');
                if (x.selection?.startDate && x.selection?.endDate) {
                  onFromToChange({
                    from: x.selection.startDate,
                    to: x.selection.endDate,
                    compareFrom: fromTo.compareFrom,
                    compareTo: fromTo.compareTo,
                    preset: 'custom',
                    compare: compare,
                    loadData: loadData && loadData,
                    setFromTo: setFromTo,
                  });
                }
              }}
            />
            {showButton && (
              <Grid item xs={12} lg={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{margin: '0 10px'}}
                  onClick={buttonOnClick}
                >
                  Apply Filters
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {pageTitle && (
        <Grid item lg={3} xs={12} className="page-title">
          <PageTitle variant="h2" component="h5" gutterBottom fontSize="24px">
            {pageTitle}
          </PageTitle>
        </Grid>
      )}
      {pageTitleComponent && (
        <Grid item lg={3} xs={12} className="page-title">
          {pageTitleComponent}
        </Grid>
      )}
    </Grid>
  );
}
