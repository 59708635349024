import React, {useEffect, useState, useContext} from 'react';
import {TextField as TextFieldMaterial, Typography, AutocompleteRenderInputParams, Grid} from '@mui/material';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {TextField, Autocomplete} from 'formik-mui';
import * as Yup from 'yup';
import {useHistory, useParams} from 'react-router-dom';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
import {buildMenu} from '../../components/menu';
// styles
import {sxStyles} from '../../../Brands/Styles';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';
// interfaces
import {BrandInterface} from '../../../../interfaces/brands/brand';
import {alertService} from '../../../../services/alert.service';

const validationSchema = Yup.object().shape({
  asin: Yup.string().required('ASIN is required'),
  parent_asin: Yup.string(),
  brand_code: Yup.object({
    name: Yup.string().typeError('Brand is required').required('Brand is required'),
    brand_code: Yup.string().typeError('Brand is required').required('Brand is required'),
  })
    .typeError('Brand is required')
    .required('Brand is required'),
});

interface AsinDataTypes {
  asin: string;
  parent: string;
  brand_code?: BrandInterface | null | string;
}

function UpdateAsin(): JSX.Element {
  const history = useHistory();

  const [values, setValues] = useState<AsinDataTypes | null>(null);
  const {id} = useParams<Record<string, string | undefined>>();

  const {brands} = useContext(AppContext);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('edit', id as string, history);
    });
    async function getAsin() {
      try {
        const {data} = await Api.get(`/asins/${id}`);
        const tempBrands = brands;
        setValues({
          asin: data.asin,
          parent: data.parent,
          brand_code: data.brand_code ? tempBrands.find((x) => x.brand_code === data.brand_code) : null,
        });
      } catch (e) {
        errorAlert('Unable to get Asins', e);
      }
    }
    if (brands.length > 0) {
      getAsin();
    }
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [brands, id, history]);

  function onSubmit(data: AsinDataTypes, {setSubmitting}: FormikHelpers<AsinDataTypes>) {
    alertService.clear();
    if (data.brand_code) {
      data.brand_code = (data.brand_code as BrandInterface).brand_code;
    }
    try {
      Api.put(`asins/${id}`, data);
      alertService.success('Brand updated successfully');
    } catch (e) {
      errorAlert('Unable to update brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <TableContainer className="sub-page" container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Update ASIN
        </Typography>
        {values ? (
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({errors, touched, isSubmitting}) => (
              <Form>
                <div>
                  <div>
                    <Field
                      sx={sxStyles('inputField')}
                      component={TextField}
                      variant="outlined"
                      name="asin"
                      label="ASIN number"
                      type="text"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <Field
                      sx={sxStyles('inputField')}
                      component={TextField}
                      variant="outlined"
                      name="parent_asin"
                      label="Parent"
                      type="text"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <Field
                    sx={sxStyles('inputField')}
                    name="brand_code"
                    component={Autocomplete}
                    options={brands}
                    size="small"
                    disabled={true}
                    getOptionLabel={(option: BrandInterface) => option.name}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextFieldMaterial
                        {...params}
                        error={touched['brand_code'] && !!errors['brand_code']}
                        helperText={touched['brand_code'] && errors['brand_code']}
                        label="Brands"
                        variant={'outlined'}
                      />
                    )}
                  />
                  <div>
                    <Button size="large" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                    <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          ''
        )}
      </Grid>
    </TableContainer>
  );
}

export {UpdateAsin};
