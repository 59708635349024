import {Paper, Grid} from '@mui/material';
import React, {useEffect} from 'react';
// components
import TableContainer from '../../../components/TableContainer/TableContainer';
import PageTitle from '../../../components/PageTitle/PageTitle';
// services
import {accountService} from '../../../services/account.service';
// styles
import {sxStyles} from '../Styles';

function SubscriptionCancelledPage(): JSX.Element {
  useEffect(() => {
    accountService.refreshToken();
  }, []);

  return (
    <TableContainer sx={sxStyles('grid')} container justifyContent="space-between" alignItems="center">
      <Grid item lg={6} md={6} xs={12} sx={sxStyles('image')}>
        <img src={process.env.PUBLIC_URL + '/Market.svg'} alt="login-img" />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Paper sx={sxStyles('loginPaper')} elevation={5}>
          <PageTitle variant="h4" component="h1" display="block" fontSize="28px" fontWeight={600}>
            Welcome back!
          </PageTitle>
        </Paper>
      </Grid>
    </TableContainer>
  );
}

export {SubscriptionCancelledPage};
