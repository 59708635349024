import React, {useEffect, useRef, useMemo} from 'react';
import {AxiosError} from 'axios';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
// components
import LoginView from '../../components/LoginView/LoginView';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {Api} from '../../utils/api';
import {withTracker} from '../../utils/withTracker';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
import mixPanel from '../../services/mixpanel';
import {FormikHelpers, FormikValues} from 'formik';
// styles
import {sxStyles} from '../ECOM3KRegistration/Styles';
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: {action: string}) => Promise<string>;
    };
  }
}

function FFPRegistration(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  document.title = 'Create Account';

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/account/connect-to-amazon');
    }
  }, [history]);

  // TODO review couponCode and distinct_id usage
  // useEffect(() => {
  //   if (urlParams.get('couponCode')) {
  //     window.localStorage.setItem('couponCode', urlParams.get('couponCode') as string);
  //   }
  //   if (urlParams.get('distinct_id')) {
  //     mixPanel({
  //       eventName: 'Account Creation',
  //       registrationId: urlParams.get('distinct_id') as string,
  //     });
  //   }
  // }, [urlParams]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  const initialValues = {
    email: '',
    brandName: '',
    password: '',
    passwordConfirmation: '',
    captcha: '',
  };
  const formRef = useRef(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        'Email not valid',
      ),
    brandName: Yup.string().required('Account name is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  async function onSubmit(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>) {
    setSubmitting(true);
    alertService.clear();
    alertService.info('Creating account ...');
    const plan = urlParams.get('plan') || 'starter';
    const price = urlParams.get('price') || 'monthly';
    try {
      await window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string, {action: 'submit'})
          .then(async (token: string) => {
            if (token) {
              const response = await Api.post('ffp/sign-up', {
                email: data.email.toLowerCase(),
                password: data.password,
                brandName: data.brandName,
                recatpchaToken: token,
                plan,
                price,
              });
              if (response.status === 201 && response.data.id) {
                alertService.success('Account created. Now you need to connect to your Amazon account.', {autoClose: false});
                mixPanel({
                  eventName: 'Create account',
                  registrationId: data.email.toLowerCase(),
                });
                withTracker('Submit', 'Account Creation Complete', data.brandName);
                setTimeout(
                  () =>
                    accountService
                      .login(data.email, data.password)
                      .then(() => {
                        setSubmitting(false);
                        if (urlParams.get('couponCode')) {
                          history.push('/account/billing?couponCode=' + urlParams.get('couponCode')?.toString());
                        } else {
                          // Redirect to connect to Amazon for the first time
                          history.push('/account/connect-to-amazon');
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        alertService.clear();
                        if (error.response && error.response.status === 401) {
                          alertService.error('Email or password is incorrect.');
                        } else {
                          alertService.error(error.message);
                        }
                      }),
                  3000,
                );
              } else {
                setSubmitting(false);
                alertService.error('Error creating account, that email may already be taken.');
              }
            }
          });
      });
    } catch (e) {
      setSubmitting(false);
      const error = e as AxiosError;
      if (error.response?.data && error.response.data.message) {
        alertService.error(error.response.data.message);
      } else {
        alertService.error(error.message);
      }
    }
  }

  const loginFields = [
    {
      label: 'Business name',
      name: 'brandName',
      icon: 'user',
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Create account"
        fields={loginFields}
        formDescription="Complete your registration details below"
        action="register"
      />
      <CustomAlert id="default-alert" sxStyles={sxStyles('inputField')} />
    </>
  );
}

export {FFPRegistration};
