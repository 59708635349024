import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Grid,
  Typography,
  InputLabel,
  LinearProgress,
  Select,
  Box,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../../../components/DateRangeButtons/DateRangeButtons';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import {BrandPerformanceSummary} from './components/BrandPerformanceSummary';
import {PerformanceChart} from './components/PerformanceChart';
import {metricOptionsData} from './components/Metrics';
import {BuildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
// styles
import {sxStyles} from '../../Styles';
// utils
import {onFromToChange} from '../../../../utils/OnFromToChange';
import {updateQueryParams, getDefaultQuery} from '../../../../utils/urlParams';
import {AdvertisingProfile, Brand} from '../../utils/interface';
import moment from 'moment';
import {DateRangeInterface} from '../../../../interfaces/date/date';

interface MetricTypes {
  type: string;
  metric: string;
  data: {
    date: string;
    value: number;
  }[];
}
[];

function BrandAdvertising(props: {adsType: string}): JSX.Element {
  const location = useLocation();
  const {adsType} = props;
  const {id} = useParams<Record<string, string | undefined>>();
  const [brand, setBrand] = useState<Brand | null>(null);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [adsProfile, setAdsProfile] = useState<AdvertisingProfile | null | undefined>(null);
  const [fromTo, setFromTo] = useState<DateRangeInterface>({
    from: moment(),
    to: moment(),
    compareFrom: null,
    compareTo: null,
  });
  const [metric, setMetric] = useState<string[]>([]);
  const [period, setPeriod] = useState('custom');
  const [metricData, setMetricData] = useState<MetricTypes[] | null>(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  const history = useHistory();

  const params = useMemo(
    () => ({
      from: fromTo.from,
      to: fromTo.to,
      compareFrom: fromTo.compareFrom,
      compareTo: fromTo.compareTo,
      metric: metric.toString(),
      marketplace_id: adsProfile?.accountInfo?.marketplaceStringId || 'ATVPDKIKX0DER',
    }),
    [fromTo, metric, adsProfile],
  );

  const menuItems = BuildMenu('ads_performance', id as string, history);

  const getQueryValues = useCallback(
    (location, params) => {
      const query = getDefaultQuery(location, params);
      const newQuery: {
        [key: string]: string | string[] | null;
      } = query;
      newQuery.metric =
        query.metric && query.metric !== ''
          ? query.metric.split(',')
          : metricOptionsData.hasOwnProperty(adsType)
          ? [Object.keys(metricOptionsData[adsType as keyof typeof metricOptionsData])[0]]
          : [];
      newQuery.marketplace_id = query.marketplace_id || adsProfile?.accountInfo.marketplaceStringId || 'ATVPDKIKX0DER';
      return newQuery;
    },
    [adsProfile?.accountInfo.marketplaceStringId, adsType],
  );

  const loadData = useCallback(
    async (query) => {
      if (!query.marketplace_id) return;
      setLoadingChart(true);
      const qs = Object.keys(query)
        .map((key) => (query[key] ? `${key}=${query[key]}` : null))
        .filter((item) => item !== null)
        .join('&');
      try {
        const {data} = await Api.get(`advertising/brand-performance/${id}/${adsType}?` + qs);
        if (data) {
          const _summaryData = Object.assign({});
          const _metricData: MetricTypes[] = [];
          for (const property in data) {
            const dataType = property.replace('AdsData', '');
            for (const record of data[property] || []) {
              for (const metricKey in record) {
                if (metricKey === 'reportDate') continue;
                if (!_summaryData.hasOwnProperty(metricKey)) {
                  _summaryData[metricKey] = Object.assign({});
                } else {
                  if (_summaryData[metricKey]?.hasOwnProperty(dataType)) {
                    _summaryData[metricKey][dataType] =
                      Math.round((_summaryData[metricKey][dataType] + Number(record[metricKey])) * 100) / 100;
                  } else {
                    _summaryData[metricKey][dataType] = 0;
                  }
                }
                const metricDataIdx = _metricData.findIndex(
                  (item) => item.type === dataType && item.metric === metricKey,
                );
                if (metric.includes(metricKey)) {
                  if (metricDataIdx < 0) {
                    _metricData.push({
                      type: dataType,
                      metric: metricKey,
                      data: [{date: record.reportDate, value: Number(record[metricKey])}],
                    });
                  } else {
                    _metricData[metricDataIdx].data?.push({date: record.reportDate, value: Number(record[metricKey])});
                  }
                }
              }
            }
          }
          setSummaryData(_summaryData);
          setMetricData(_metricData);
        }
      } catch (e) {
        errorAlert('Unable to get data', e, {id: 'brand-summary'});
      } finally {
        setLoadingChart(false);
      }
    },
    [adsType, id, metric],
  );

  useEffect(() => {
    loadData(getQueryValues(location, params));
  }, [getQueryValues, location, params, loadData]);

  function toggleCompare(checked: boolean) {
    setCompare(checked);
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      compareFrom: null,
      compareTo: null,
      preset: period,
      compare: checked,
      setFromTo,
    });
  }

  function updateMetric(_metric: string[]) {
    setMetric(_metric);
    const newParams = {...params, metric: _metric};
    loadData(newParams);
  }

  async function loadBrand() {
    setLoadingBrand(true);
    try {
      const {data} = await Api.get(`brands/${id}`);
      setBrand(data);
      const adsProfiles: AdvertisingProfile[] = data?.advertising_profiles || [];
      let defaultAdsProfile = adsProfiles.find((adsProfile) => adsProfile.countryCode === 'US');
      if (!defaultAdsProfile) defaultAdsProfile = adsProfiles[0];
      setAdsProfile(defaultAdsProfile);
    } catch (e) {
      errorAlert('Unable to get brands', e, {id: 'ads-performance-tab-alert'});
    } finally {
      setLoadingBrand(false);
    }
  }

  function handleChangeMarketplace(e: SelectChangeEvent<string>) {
    const selectedAdsProfile = brand?.advertising_profiles?.find(
      (profile) => profile.accountInfo.marketplaceStringId === e.target.value,
    );
    setAdsProfile(selectedAdsProfile);
    const currentParams = {
      ...params,
      marketplace_id: selectedAdsProfile?.accountInfo?.marketplaceStringId,
    };
    loadData(currentParams);
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadBrand();
    const defaultQuery = getQueryValues(location, params);

    setFromTo({
      from: (defaultQuery.from as string) || fromTo.from,
      to: (defaultQuery.to as string) || fromTo.from,
      compareFrom: defaultQuery.compareFrom as string,
      compareTo: defaultQuery.compareTo as string,
    });
    setPeriod(defaultQuery.preset as string);
    setMetric(defaultQuery.metric as string[]);
    setCompare(defaultQuery.compareFrom !== null);
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateQueryParams(location, params);
  }, [location, params]);

  return loadingBrand ? (
    <TableContainer className="sub-page">
      <Typography>Loading Brand Information ...</Typography>
      <LinearProgress />
    </TableContainer>
  ) : (
    <TableContainer className="sub-page">
      <DateRangeButtons
        className="new-group"
        sx={sxStyles('buttonGroup')}
        fromTo={fromTo}
        compare={compare}
        period={period}
        setPeriod={(v) => setPeriod(v)}
        setFromTo={(v) => setFromTo(v)}
        compareOnChange={(e) => toggleCompare(e.target.checked)}
        compareComponent
        showButton
        buttonOnClick={() => {
          loadData(params);
        }}
        pageTitleComponent={
          <Grid item lg={12} xs={12} style={{textAlign: 'right'}}>
            <PageTitle variant="h2" component="h5" gutterBottom fontSize="24px">
              Individual Brand Performance
            </PageTitle>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <Typography component="h2" variant="h5" gutterBottom>
                {id}
              </Typography>
              {adsProfile && (
                <FormControl
                  variant="outlined"
                  style={{width: '150px', textAlign: 'left', marginRight: '16px'}}
                  size="small"
                >
                  <InputLabel id="marketplace">Marketplace</InputLabel>
                  <Select
                    value={adsProfile.accountInfo.marketplaceStringId}
                    onChange={handleChangeMarketplace}
                    label={'marketplace'}
                    labelId="marketplace"
                    variant="outlined"
                  >
                    {brand?.advertising_profiles.map((profile) => (
                      <MenuItem
                        key={profile.accountInfo.marketplaceStringId}
                        value={profile.accountInfo.marketplaceStringId}
                      >
                        {profile.countryCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Grid>
        }
      />
      <Grid item lg={12} xs={12}>
        <BrandPerformanceSummary data={summaryData} />
        <PerformanceChart data={metricData} onMetricChange={updateMetric} metric={metric} loading={loadingChart} />
      </Grid>
    </TableContainer>
  );
}

export {BrandAdvertising};
