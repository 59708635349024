import React, {useState} from 'react';
import {Menu, IconButton} from '@mui/material';
import {Reorder} from '@mui/icons-material';
// components
import Button from '../Button/Button';
interface DropDownButtonProps {
  buttonText?: string;
  iconButton?: React.ReactNode;
  children: React.ReactNode;
  buttonType?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  loading?: boolean;
  closable?: true;
}

export default function DropDownButton({
  buttonText,
  iconButton,
  children,
  buttonType,
  size,
  bold,
  loading,
  closable,
}: DropDownButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {buttonText && (
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant={buttonType}
          size={size}
          style={{fontWeight: bold ? 'bold' : 'normal'}}
          startIcon={<Reorder />}
          disabled={loading}
        >
          {buttonText}
        </Button>
      )}
      {iconButton && (
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={loading}
        >
          {iconButton}
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClick={() => closable && handleClose()}
      >
        {children}
      </Menu>
    </div>
  );
}
