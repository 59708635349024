import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/system';
import {useTheme} from '@mui/material/styles';
import {Box, AppBar, Tab, Tabs} from '@mui/material';
// components
import TableContainer from '../../../components/TableContainer/TableContainer';
import AlertsTable from '../Alerts';
import LateFBMShipments from '../subpages/LateFBMShipments/LateFBMShipments';
import DeletedSkus from '../subpages/DeletedSkus/DeletedSkus';
import ParentChanges from '../subpages/ParentChanges/ParentChanges';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Role} from '../../../utils/role';
// styles
import {sxStyles} from '../Styles';

const CustomAppBar = styled(AppBar)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  '& .MuiTabs-root, & .MuiTabs-scroller,  & .MuiTabs-flexContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer > button': {
    padding: '0 1.5em',
  },
  '& .Mui-selected ': {
    fontWeight: 'bold',
  },
  [theme.breakpoints.down(767)]: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      borderBottom: '2px solid',
      borderColor: theme.palette.mode === 'light' ? 'primary' : 'secondary',
    },
  },
  [theme.breakpoints.down(620)]: {
    '& .MuiTabs-flexContainer > button': {
      minHeight: 'auto',
      fontSize: '12px',
      padding: '1em',
    },
  },
}));

function TabPanel(props: {children?: React.ReactNode; index: number; value: number}): JSX.Element {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{flexGrow: 1}}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Alerts(): JSX.Element {
  const theme = useTheme();
  document.title = 'Alerts';
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  let extraTabs: {
    label: string;
    value: number;
    props: {id: string; 'aria-controls': string};
  }[] = [];
  switch (accountService.userValue.role) {
    case Role.BrandUser:
      break;
    case Role.Admin:
    case Role.Manager:
    default:
      extraTabs = [
        {label: 'Late FBM Shipments', value: 1, props: {...a11yProps(1)}},
        {label: 'Deleted SKUs', value: 2, props: {...a11yProps(2)}},
        {label: 'Parent Changes', value: 3, props: {...a11yProps(3)}},
      ];

      break;
  }

  return (
    <>
      <CustomAppBar position={'static'} color="default">
        <Tabs
          value={value}
          onChange={(e, v) => handleChange(v)}
          indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
          textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="All Alerts" value={0} {...a11yProps(0)} />
          {extraTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value} {...tab.props} />
          ))}
        </Tabs>
      </CustomAppBar>
      <TableContainer>
        <TabPanel value={value} index={0}>
          <AlertsTable alertType="All" sxStyles={sxStyles} key={`tabPanel-0`} />
        </TabPanel>
        <TabPanel value={value} index={1} key={`tabPanel-1}`}>
          <LateFBMShipments sxStyles={sxStyles} alertType={'late-fbm-shipments'} />
        </TabPanel>
        <TabPanel value={value} index={2} key={`tabPanel-2`}>
          <DeletedSkus sxStyles={sxStyles} alertType={'deleted-skus'} />
        </TabPanel>
        <TabPanel value={value} index={3} key={`tabPanel-3`}>
          <ParentChanges sxStyles={sxStyles} alertType={'parent-changes'} />
        </TabPanel>
      </TableContainer>
    </>
  );
}

export {Alerts};
