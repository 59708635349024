import React from 'react';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {Link} from 'react-router-dom';
import {Grid, Paper, InputAdornment, Button, Typography, CircularProgress, Avatar} from '@mui/material';
import {AlternateEmail, LockOutlined, PersonOutlineOutlined, FormatQuote} from '@mui/icons-material';
import {ThemeProvider} from '@mui/material';
// styles
import {ecom3kSxStyles, ecom3kNewTheme} from './Styles';
// components
import Spacer from '../Spacer/Spacer';
import TableContainer from '../TableContainer/TableContainer';
// interfaces
import {ECOM3KRegistrationViewProps} from './interfaces/login-view';

export default function ECOM3KRegistrationView({
  validationSchema,
  onSubmit,
  initialValues,
  fields,
  submitText,
  forgotPassword,
  formDescription,
  logo,
  itemRef,
  actionButton,
  isInvalid,
  message,
}: ECOM3KRegistrationViewProps): JSX.Element {
  const icons = {
    email: <AlternateEmail />,
    password: <LockOutlined />,
    user: <PersonOutlineOutlined />,
  };

  const testimonials = [
    {
      author: 'Matthew Turner',
      job: 'Founder and 7-figure seller',
      content: "Wow. That's Amazing",
      avatar: 'https://ecom3k.com/wp-content/uploads/2022/06/unnamed.jpeg',
    },
    {
      author: 'Ian Sells',
      job: ' Founder, Million Dollar Sellers',
      content: 'This is a homerun. We use Ecom3k data to improve conversion rates on our ASINs',
      avatar: 'https://ecom3k.com/wp-content/uploads/2022/08/1516341140773.jpeg',
    },
  ];

  return (
    <ThemeProvider theme={ecom3kNewTheme}>
      <TableContainer sx={ecom3kSxStyles('grid')} container justifyContent="space-between" alignItems="center">
        <Grid container sx={ecom3kSxStyles('customView')}>
          <Grid item lg={7} md={7} sm={12} xs={12} container alignItems="center" justifyContent="center">
            <Paper sx={ecom3kSxStyles('loginPaper')} elevation={0}>
              {logo}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={itemRef}
              >
                {({values, isSubmitting}) => {
                  return (
                    <Form>
                      {isInvalid ? (
                        <Typography component="h2" variant="h4" color={'error'} gutterBottom>
                          {message}
                        </Typography>
                      ) : (
                        <>
                          {formDescription && (
                            <div>
                              <Typography component="h3" variant="h6" className="header" gutterBottom>
                                {formDescription}
                              </Typography>
                              <br />
                            </div>
                          )}
                          <div>
                            {fields.map((item, key) => {
                              return (
                                <div key={key}>
                                  <div>{item.extra}</div>
                                  <Field
                                    autoComplete="none"
                                    sx={ecom3kSxStyles('inputField')}
                                    component={TextField}
                                    variant="outlined"
                                    name={item.name}
                                    placeholder={item.label}
                                    type={item.type}
                                    size="large"
                                    InputLabelProps={{shrink: false}}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{outline: 'none'}}>
                                          {icons[item.icon as keyof typeof icons]}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <div>
                              <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isSubmitting}
                                sx={ecom3kSxStyles('loginButton')}
                                onClick={() => actionButton && actionButton(values)}
                              >
                                {submitText}
                                {isSubmitting && <CircularProgress size={12} style={{margin: '0 10px'}} />}
                              </Button>
                            </div>
                            {forgotPassword && (
                              <div>
                                <Link to={forgotPassword}>
                                  <Button variant="outlined" color="secondary" size="large">
                                    Forgot password
                                  </Button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} sx={ecom3kSxStyles('quote')}>
            <div className="quote-container">
              {testimonials.map((item, index) => (
                <blockquote className="relative" key={index}>
                  <FormatQuote />
                  <p className="relative">{item.content}</p>
                  <Grid container className="card">
                    <Avatar src={item.avatar} />
                    <div className="author-container">
                      <span style={{fontWeight: 600, textTransform: 'uppercase'}}>{item.author}</span>
                      <span>{item.job}</span>
                    </div>
                  </Grid>
                  <Spacer height={15} />
                </blockquote>
              ))}
            </div>
          </Grid>
        </Grid>
      </TableContainer>
    </ThemeProvider>
  );
}
