import * as Yup from 'yup';
import { TFormFieldItem } from './types';


export const updatePasswordValidationSchema = Yup.object({
  current_password: Yup.string().required('Current Passowrd is required'),
  new_password: Yup.string()
    .required('Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirm_password: Yup.string().required('Please confirm password.').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export const changePasswordFormFields : TFormFieldItem[] = [
  {
    label: 'Current Password',
    name: 'current_password',
    icon: 'password',
    type: 'password',
  },
  {
    label: 'New Password',
    name: 'new_password',
    icon: 'password',
    type: 'password',
  },
  {
    label: 'Confirm Password',
    name: 'confirm_password',
    icon: 'password',
    type: 'password',
  },
];