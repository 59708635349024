import React from 'react';
import {
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridPagination,
  GridColDef,
} from '@mui/x-data-grid';
import DraggableList from '../DraggableList/DraggableList';
import DropDownButton from '../DropdownButton/DropDownButton';
import {Theme} from '@mui/material';

const sxStyles = (theme: Theme) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  padding: '.5rem 1rem .4rem 1rem',
  [theme.breakpoints.down(820)]: {
    fontSize: '12px',
  },
});

interface DataGridToolbarProps {
  pagination?: GridSlotsComponentsProps['pagination'];
  loadingData?: boolean;
  items: GridColDef[];
  ignoreItems?: string[];
  setItems: (v: GridColDef[]) => void;
}

export default function DataGridToolbar({
  pagination = {},
  loadingData,
  items,
  ignoreItems,
  setItems,
}: DataGridToolbarProps): JSX.Element {
  return (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton sx={(theme) => sxStyles(theme)} disabled={loadingData} {...pagination} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loadingData}>
        <DraggableList
          items={items}
          ignoreItems={ignoreItems}
          setItems={(v) => {
            setItems(v);
          }}
        />
      </DropDownButton>
      <GridPagination {...pagination} />
    </GridToolbarContainer>
  );
}
