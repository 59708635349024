import React, {useState, useEffect, useCallback} from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  Divider,
  CircularProgress,
} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import moment from 'moment';
import {useParams} from 'react-router-dom';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';
import ModalButton from '../../../../components/ModalButton/ModalButton';
import Button from '../../../../components/Button/Button';
// styles
import {sxStyles} from '../../Styles';

interface NotesInterface {
  id: string;
  brand_code: string;
  note: string;
  created_at: string;
  updated_at: string;
  user_name: string;
  user_id: string;
  content: {
    description: string;
  };
}

export default function NoteStream(): JSX.Element {
  const {id} = useParams<Record<string, string | undefined>>();
  const [notes, setNotes] = useState<NotesInterface[]>([]);
  const [currentNote, setCurrentNote] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const notesPerPage = [10, 20, 30, 40, 50];
  const [count, setCount] = useState(0);
  const [editNote, setEditNote] = useState<string | null>(null);
  const [currentEditNote, setCurrentEditNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState('');

  const getNotes = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await Api.get(`/brand-note/${id}?page=${page - 1}&pageSize=${pageSize}`);
      setNotes(data.rows);
      setCount(data.count < pageSize ? 1 : Math.ceil(data.count / pageSize));
    } catch (e) {
      errorAlert('Unable to get notes', e);
    } finally {
      setLoading(false);
    }
  }, [id, page, pageSize]);

  async function sendNote(description: string | null) {
    setLoading(true);
    if (!description && description === '') {
      alertService.warn('The note cannot be empty');
      return;
    }
    const body = {
      user_id: accountService.userValue.userId,
      content: {description: description},
      brand_code: id,
    };
    try {
      await Api.post('/brand-note', body);
      getNotes();
      setCurrentNote('');
      alertService.success('Note created');
    } catch (e) {
      errorAlert('Unable to save note', e);
    } finally {
      setLoading(false);
    }
  }

  async function updateNote(noteId: string, description: string | null) {
    setNoteLoading(noteId);
    if (!description && description === '') {
      alertService.warn('The note cannot be empty');
      return;
    }
    const body = {
      content: {description: description},
      brand_code: id,
    };
    try {
      await Api.put(`/brand-note/${noteId}`, body);
      getNotes();
      setCurrentEditNote('');
      setEditNote(null);
      alertService.success('Note updated');
    } catch (e) {
      errorAlert('Unable to update note', e);
    } finally {
      setNoteLoading('');
    }
  }

  async function deleteNote(noteId: string) {
    setNoteLoading(noteId);
    try {
      await Api.delete(`/brand-note/${noteId}/${id}`);
      getNotes();
      alertService.success('Note deleted');
    } catch (e) {
      errorAlert('Unable to delete note', e);
    } finally {
      setNoteLoading('');
    }
  }

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return (
    <Grid container style={{padding: '30px 0', width: '100%'}}>
      <CustomAlert id="default-alert" />
      <Typography gutterBottom variant="h5" color="primary">
        Note Stream
      </Typography>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={sxStyles('notesTextArea')}>
        <textarea
          value={currentNote}
          onChange={(e) => setCurrentNote(e.target.value)}
          placeholder="Write a note..."
          onFocus={() => setEditNote(null)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'right'}}>
        <Button onClick={() => sendNote(currentNote)}>Send</Button>
      </Grid>
      <Divider style={{width: '100%', margin: '20px 0'}} />
      {loading ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '30px'}}
        >
          <Typography color="secondary" variant="h5" gutterBottom>
            Loading...
          </Typography>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {notes.length > 0 ? (
            <>
              {notes.map((note) => {
                return (
                  <Card
                    key={note.id}
                    className={`${noteLoading === note.id ? 'note-loading' : ''}`}
                    sx={sxStyles('notesCard')}
                  >
                    <CardHeader
                      title={
                        <Typography sx={{fontSize: 14}} color="secondary" gutterBottom>
                          Created by {note.user_name} at {moment(note.created_at).format('lll')}
                        </Typography>
                      }
                      subheader={
                        note.created_at !== note.updated_at && (
                          <>
                            <Typography variant="caption">
                              (Updated: {moment(note.updated_at).format('lll')})
                            </Typography>
                          </>
                        )
                      }
                    />
                    <Divider />
                    <div className="card-content">
                      <CardContent>
                        {editNote === note.id ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={sxStyles('notesTextArea')}>
                            <textarea
                              defaultValue={note.content.description}
                              onChange={(e) => {
                                setCurrentEditNote(e.target.value);
                              }}
                              placeholder="Write a note..."
                            />
                          </Grid>
                        ) : (
                          <Typography style={{whiteSpace: 'pre-line'}} variant="body2">
                            {note.content.description}
                          </Typography>
                        )}
                      </CardContent>
                      <Divider orientation="vertical" style={{height: 'auto'}} />
                      <CardActions disableSpacing>
                        {editNote === note.id ? (
                          <div className="edit-buttons">
                            <Button onClick={() => updateNote(note.id, currentEditNote)}>Save</Button>{' '}
                            <Button
                              onClick={() => {
                                setEditNote(null);
                                setCurrentEditNote('');
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <>
                            {note.user_id === accountService.userValue.userId && (
                              <IconButton
                                onClick={() => {
                                  setEditNote(note.id);
                                  setCurrentEditNote('');
                                }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                            <ModalButton
                              modalTitle="Are you sure you want delete this note?"
                              startIcon={<Delete />}
                              buttonType="icon"
                              actions={(closeModal): JSX.Element => (
                                <Button
                                  onClick={() => {
                                    deleteNote(note.id);
                                    closeModal();
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            >
                              {(): JSX.Element => {
                                return (
                                  <Grid>
                                    <Typography style={{fontSize: '18px'}} color="secondary">
                                      Created by {note.user_name} at {moment(note.created_at).format('lll')}
                                    </Typography>
                                    <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                      &quot;{note.content.description}&quot;
                                    </div>
                                  </Grid>
                                );
                              }}
                            </ModalButton>
                          </>
                        )}
                      </CardActions>
                    </div>
                  </Card>
                );
              })}
              <Grid sx={sxStyles('noteStreamPagination')}>
                <Typography>Notes per page:</Typography>
                <FormControl variant="standard">
                  <Select
                    size="small"
                    value={pageSize}
                    onChange={(e) => {
                      const page = typeof e.target.value === 'string' ? parseInt(e.target.value) : e.target.value;
                      setPageSize(page);
                    }}
                  >
                    {notesPerPage.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Pagination page={page} count={count} onChange={(e, v) => setPage(v)} />
              </Grid>
            </>
          ) : (
            <Typography gutterBottom variant="h6" color="primary" style={{textAlign: 'center', padding: '80px 25px'}}>
              No notes yet!
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}
