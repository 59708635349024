import React, { memo, useEffect, useState, useCallback } from 'react';

import {
  Button,
  Grid,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { GridSelectionModel } from '@mui/x-data-grid';

import { Link } from 'react-router-dom';

import {
  ListingsInterface,
} from '../../interfaces/interfaces';

import {
  Api,
} from 'src/utils/api';

import { alertService } from 'src/services/alert.service';

import {
  VariationFamilyWithSku,
} from '../../interfaces/interfaces';

type Props = {
  selectionModel: GridSelectionModel;
  listingRows: ListingsInterface['rows'];
  handleCloseManageVariations: () => void;
  manageVariationsSuccess: (message: string) => void;
  marketplaceId: string | null;
  sellerId: string | null;
  selectedProductType: string;
};

const ManageVariationsContainer = ({
  selectionModel,
  listingRows,
  handleCloseManageVariations,
  marketplaceId,
  sellerId,
  manageVariationsSuccess,
  selectedProductType,
}: Props): JSX.Element => {

  // Do not render form until done loading.
  const [loading, setLoading] = useState(false);

  const [checkedNonChildren, setCheckedNonChildren] = useState<string[]>([]);
  const [checkedChildren, setCheckedChildren] = useState<string[]>([]);

  const [currentParentNonChildrenSkus, setCurrentParentNonChildrenSkus] = useState<string[]>([]);
  const [currentParentChildrenSkus, setCurrentParentChildrenSkus] = useState<string[]>([]);

  const [variationFamiliesWithSkus, setVariationFamiliesWithSkus] = useState<VariationFamilyWithSku[]>([]);
  const [parentSkus, setParentSkus] = useState<string[]>([]);
  const [storedParentSkus, setStoredParentSkus] = useState<string[]>([]);
  const [selectedParentSku, setSelectedParentSku] = useState<string | null>(null);

  // Comma-separated list of selected SKUs.
  const [selectedSkus, setSelectedSkus] = useState<string>('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateListboxes = useCallback((
    selectedParentSku: string | null,
    variationFamiliesWithSkus: VariationFamilyWithSku[],
  ) => {
      updateSelectedFamily(
        selectedParentSku,
        variationFamiliesWithSkus,
      );
      setCheckedChildren([]);
      setCheckedNonChildren([]);
    },
    []
  );

  useEffect(() => {
    setLoading(true);

    const initializeVariationFamilies = async () => {
      const selectedSkus: string[] = [];
      let parentSkus: string[] = [];

      listingRows.filter(listingRows => selectionModel.includes(listingRows.id as number)).forEach((listing) => {
        selectedSkus.push(listing.sku);
      });
      const skus = selectedSkus.toString(); // equivalent to selectedSkus.join(',')

      setSelectedSkus(skus);
      // Persist the comma-separated list of selected SKUs to use with handleChangeParentSku.

      // Begin by getting the parents and family details from the selected listings.
      const uri = 'catalog/load-items-by-sku';
      let variationFamiliesWithSkus: VariationFamilyWithSku[] = [];
      try {
        const { data } = await Api.get(
          uri,
          {
            params: {
              skus,
            },
          });
        parentSkus = data.parentSkus;
        variationFamiliesWithSkus = data.variationFamiliesWithSkus;
      } catch (e) {
        alertService.error('Unable to load catalog items by SKU.');
        console.log('catalog/load-items-by-sku', e)
      }

      if (parentSkus.length > 0) {
        setParentSkus(parentSkus);
        setStoredParentSkus(parentSkus);
        setSelectedParentSku(parentSkus[0]);
        updateListboxes(
          parentSkus[0], // selectedParentSku
          variationFamiliesWithSkus,
        );
      }

      setVariationFamiliesWithSkus(variationFamiliesWithSkus);

    };

    initializeVariationFamilies();

    setLoading(false);
  }, [
    selectionModel,
    listingRows,
    selectedProductType,
    updateListboxes,
  ]);

  const updateSelectedFamily = (
    selectedParentSku: string | null,
    variationFamiliesWithSkus: VariationFamilyWithSku[],
  ) => {
    const selectedFamily = variationFamiliesWithSkus.find(family => family.parentSku === selectedParentSku);
    if (selectedFamily) {
      if (selectedFamily.nonChildren) {
        setCurrentParentNonChildrenSkus(selectedFamily.nonChildren.map(item => item.sku));
      }
      if (selectedFamily.children) {
        setCurrentParentChildrenSkus(selectedFamily.children.map(item => item.sku));
      }
    } else {
      // TODO fill all non children with potential children
      // setCurrentParentNonChildrenSkus([]);
      // As there's no family for this parent, there's no children.
      setCurrentParentChildrenSkus([]);
      // TODO for moving listings between boxes; think I have to create empty
      // structure for all potential parents
    }
  };

  const handleUpdateManageVariations = async (e: React.SyntheticEvent) => {
    setIsSubmitting(true);
    e.preventDefault();

    const selectedFamilyIndex = variationFamiliesWithSkus.findIndex(family => family.parentSku === selectedParentSku);
    const selectedFamily = variationFamiliesWithSkus[selectedFamilyIndex];
    const modifiedChildren = selectedFamily.children.filter(child => child.updated);
    const modifiedNonChildren = selectedFamily.nonChildren.filter(nonChild => nonChild.updated);
    if (modifiedChildren.length === 0 && modifiedNonChildren.length === 0) {
      alertService.error('No changes detected.');
      setIsSubmitting(false);
      return;
    }

    try {
      const res = await Api.post(
        'amazon-listings/update-variation-family',
        {
          selectedFamily,
          selectedParentSku,
          marketplaceId,
          sellerId,
          selectedProductType,
        }
      );
      if (res.data?.status === 'ACCEPTED') {
        handleCloseManageVariations();
        manageVariationsSuccess('Variation family updated successfully.');
      } else if (res.data?.status === 'INVALID') {
        handleCloseManageVariations();
        if (res.data.skusToReview && res.data.skusToReview.length > 0) {
          const issues = () => (
            <div>
              Some listings did not pass validation. Please review and update the following SKUs and try again:
              <br />
              {res.data.skusToReview.map((sku: string, index: number) => (
                <p
                  key={index}
                >
                  <Link
                    target='_blank'
                    to={`/amazon-listings-item/${marketplaceId}/${sellerId}/${sku}/edit`}
                  >
                    {sku}
                  </Link>
                </p>
              ))}
            </div>
          );
          alertService.error(
            issues(),
            {
              autoClose: false,
            },
          )
        } else {
          alertService.error(
            res.data.message,
            {
              autoClose: false,
            },
          );
        }
      }
    } catch (e) {
      alertService.error('Sorry, Amazon is taking longer than usual to process this variation family update, please try later.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
      // Search for parent SKUs and add to list.
      const search = e.target.value;
      let foundSkus: string[] = [];
      try {
        const { data } = await Api.get(`amazon-listings/search-by-sku`, {
          params: {
            marketplaceId,
            search,
            selectedProductType,
          },
        });
        foundSkus = data.foundSkus;
      } catch (e) {
        alertService.error('Unable to load search listings by SKU.');
        console.log('amazon-listings/search-by-sku', e)
      }

      // Keeping a copy of parent SKUs of the selected listings in storedParentSkus
      // so we can add the found parent SKUs to the list of parent SKUs.
      // Note how we use Set to remove duplicates.
      const parentSkusCombinedSet = new Set([...storedParentSkus, ...foundSkus]);
      const parentSkus = [
        ...Array.from(parentSkusCombinedSet),
      ];
      setParentSkus(parentSkus);
  };

  const handleChangeParentSku = async (
    e: React.SyntheticEvent,
    selectedParentSku: string | null,
  ) => {
    if (selectedParentSku) {
      setSelectedParentSku(selectedParentSku);
      const variationFamiliesParentSkus = variationFamiliesWithSkus.map(family => family.parentSku);
      let updatedVariationFamiliesWithSkus = variationFamiliesWithSkus;

      if (!variationFamiliesParentSkus.includes(selectedParentSku)) {
        // Add family data of selected parent SKU if not already in variationFamiliesWithSkus
        const uri = 'catalog/load-family-by-parent-sku';
        try {
          const { data } = await Api.get(
            uri,
            {
              params: {
                selectedParentSku,
                selectedSkus,
              },
            });
          updatedVariationFamiliesWithSkus = [
            ...variationFamiliesWithSkus,
            ...data.variationFamiliesWithSkus,
          ];
          setVariationFamiliesWithSkus(updatedVariationFamiliesWithSkus);
        } catch (e) {
          alertService.error('Unable to load family data for parent SKU.');
          console.log('catalog/load-family-by-parent-sku', e)
        }
      }

      updateListboxes(
        selectedParentSku, // selectedParentSku
        updatedVariationFamiliesWithSkus,
      );

    }
  };

  const handleToggle = (
    value: string,
    checked: string[],
    setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  ) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const addToChildren = () => {
    if (checkedNonChildren.length) {
      const selectedFamilyIndex = variationFamiliesWithSkus.findIndex(family => family.parentSku === selectedParentSku);
      const selectedFamily = variationFamiliesWithSkus[selectedFamilyIndex];
      const newChildren = checkedNonChildren.map(sku => {
        const child = selectedFamily.nonChildren.find(child => child.sku === sku);
        if (child) {
          return {
            asin: child.asin,
            sku,
            updated: !child.updated,
          }
        } else {
          return {
            asin: '',
            sku,
            updated: false,
          }
        }
      }).filter(child => child.asin !== '');
      const updatedChildren = [
        ...selectedFamily.children,
        ...newChildren,
      ];
      const updatedNonChildren = selectedFamily.nonChildren.filter(child => !checkedNonChildren.includes(child.sku));
      variationFamiliesWithSkus[selectedFamilyIndex].children = updatedChildren;
      variationFamiliesWithSkus[selectedFamilyIndex].nonChildren = updatedNonChildren;
      setVariationFamiliesWithSkus(variationFamiliesWithSkus);
      updateListboxes(
        selectedParentSku,
        variationFamiliesWithSkus,
      );
    } else {
      alertService.error('No free listings selected.');
    }
  };

  const removeFromChildren = () => {
    if (checkedChildren.length) {
      const selectedFamilyIndex = variationFamiliesWithSkus.findIndex(family => family.parentSku === selectedParentSku);
      const selectedFamily = variationFamiliesWithSkus[selectedFamilyIndex];
      const newNonChildren = checkedChildren.map(sku => {
        const child = selectedFamily.children.find(child => child.sku === sku);
        if (child) {
          return {
            asin: child.asin,
            sku,
            updated: !child.updated,
          }
        } else {
          return {
            asin: '',
            sku,
            updated: false,
          }
        }
      }).filter(child => child.asin !== '');
      const updatedNonChildren = [
        ...selectedFamily.nonChildren,
        ...newNonChildren,
      ];
      const updatedChildren = selectedFamily.children.filter(child => !checkedChildren.includes(child.sku));
      variationFamiliesWithSkus[selectedFamilyIndex].children = updatedChildren;
      variationFamiliesWithSkus[selectedFamilyIndex].nonChildren = updatedNonChildren;
      setVariationFamiliesWithSkus(variationFamiliesWithSkus);
      updateListboxes(
        selectedParentSku,
        variationFamiliesWithSkus,
      );
    } else {
      alertService.error('No children selected.');
    }
  };

  const theme = useTheme();

  return (
    <Grid
      className={`theme-${theme.palette.mode} manage-variations-container`}
      container
    >
      {!loading && (
        <form aria-disabled={isSubmitting}>
          <Grid
            container
            item xs={12}
          >
            <Autocomplete
              data-cy="parentSku"
              disablePortal
              id="parentSku"
              freeSolo
              onChange={(e: React.SyntheticEvent, newValue: string | null) => {
                handleChangeParentSku(e, newValue);
              }}
              options={parentSkus}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent SKU"
                  onChange={handleInputChange}
                />
              )}
              value={selectedParentSku}
              selectOnFocus
              clearOnBlur
            />

          </Grid>

          <Grid
            container
            className="selection-container"
          >
            <Grid
              item xs={5}
            >
              <h3>Free Listings</h3>
            </Grid>
            <Grid
              item xs={2}
            >
            </Grid>
            <Grid
              item xs={5}
            >
              <h3>Children</h3>
            </Grid>
          </Grid>

          <Grid
            container
            className="selection-container"
          >
            <Grid
              className="nonChildren"
              container
              item xs={5}
            >
              <List>
                {currentParentNonChildrenSkus.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={handleToggle(
                          value,
                          checkedNonChildren,
                          setCheckedNonChildren
                        )}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedNonChildren.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid
              container
              className="button-container"
              item xs={2}
            >
              <Button onClick={addToChildren}>&gt;</Button>
              <Button onClick={removeFromChildren}>&lt;</Button>
            </Grid>
            <Grid
              className="children"
              container
              item xs={5}
            >
              <List>
                {currentParentChildrenSkus.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={handleToggle(
                          value,
                          checkedChildren,
                          setCheckedChildren
                        )}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedChildren.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>

          <Grid
            container
            className="update-button"
            item xs={12}
          >
            <Button
              id="updateVariationFamilyButton"
              data-cy="updateVariationFamilyButton"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleUpdateManageVariations}
            >
              Update Variation Family
            </Button>
          </Grid>

        </form>
      )}
    </Grid>
  );
};

export default memo(ManageVariationsContainer);
