import React, {createContext, useState, useMemo} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
interface ProviderProps {
  children?: JSX.Element;
}

import colors from '../../styles/partials/_colors.module.scss';

interface ColorModeState {
  toggleColorMode: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
  colorMode: boolean;
  setColorMode: ((x: boolean) => void) | null;
}

const initialState: ColorModeState = {
  toggleColorMode: undefined,
  // temporarily disable theme switcher
  // colorMode: window.localStorage.getItem('colorMode') === 'true' ? true : false,
  colorMode: false,
  setColorMode: null,
};

export const ColorModeContext = createContext(initialState);

function ColorModeProvider(props: ProviderProps): JSX.Element {
  const [colorMode, setColorMode] = useState(initialState.colorMode);

  const toggleColorMode = () => {
    setColorMode((prevMode) => (prevMode === true ? false : true));
    window.localStorage.setItem('colorMode', colorMode ? 'false' : 'true');
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode ? 'dark' : 'light',
          primary: {
            light: colors.light_primary,
            main: colorMode ? colors.dark_primary_main : colors.light_primary_main,
            dark: colorMode ? colors.dark_primary_dark : colors.light_primary_dark,
            contrastText: colorMode ? colors.dark_primary_contrastText : colors.light_primary_contrastText,
          },
          secondary: {
            light: colors.light_secondary,
            main: colorMode ? colors.dark_secondary_main : colors.light_secondary_main,
            dark: colorMode ? colors.dark_secondary_dark : colors.light_secondary_dark,
            contrastText: colorMode ? colors.dark_secondary_contrastText : colors.light_secondary_contrastText,
          },
          success: {
            light: colorMode ? colors.dark_success_light : colors.light_success_light,
            main: colorMode ? colors.dark_success_main : colors.light_success_main,
            dark: colorMode ? colors.dark_success_dark : colors.light_success_dark,
            contrastText: colorMode ? colors.dark_success_contrastText : colors.light_success_contrastText,
          },
          text: {
            primary: colorMode ? colors.dark_text_primary : colors.light_text_primary,
            secondary: colorMode ? colors.dark_text_secondary : colors.light_text_secondary,
          },
          background: {
            default: colorMode ? colors.dark_background : colors.light_background,
            paper: colorMode ? colors.dark_background_paper : colors.light_background_paper,
          },
        },
        typography: {
          fontFamily: [
            'Karla',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        components: {
          MuiTab: {
            styleOverrides: {
              root: {
                maxWidth: 'none',
              },
            },
          },
        },
      }),
    [colorMode],
  );

  return (
    <ColorModeContext.Provider value={{colorMode, setColorMode, toggleColorMode}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default ColorModeProvider;
