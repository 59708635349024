import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Box, Grid, Tab, Tabs, useTheme} from '@mui/material';
// components
import CustomTabs from '../../../../components/CustomTabs/CustomTabs';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {MenuStore} from '../../../../App';
import {AsinKeywords} from './AsinKeywords';
import {AsinKeywordsMetrics} from './AsinKeywordsMetrics';
import {buildMenu} from '../../components/menu';

function TabPanel(props: {children?: React.ReactNode; index: number; value: number}): JSX.Element {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{flexGrow: 1}}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function AsinKeywordsTop(): JSX.Element {
  const theme = useTheme();
  const {id} = useParams<Record<string, string>>();
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const history = useHistory();

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('keyword', id, history);
    });
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [history, id]);

  return (
    <Grid container alignItems="center" justifyContent="center" style={{position: 'relative'}}>
      <CustomTabs position={'static'} color="default" className="asin-tab">
        <Tabs
          value={value}
          onChange={(e, v) => handleChange(v)}
          indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
          textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
        >
          <Tab label="Keywords" value={0} />
          <Tab label="Search Result Rank" value={1} />
        </Tabs>
      </CustomTabs>
      <TableContainer className="sub-page">
        <TabPanel value={value} index={0}>
          <AsinKeywords />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AsinKeywordsMetrics />
        </TabPanel>
      </TableContainer>
    </Grid>
  );
}

export {AsinKeywordsTop};
