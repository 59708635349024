import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';

const sxStyles = (
  theme: Theme,
): {
  [key: string]: SxStyleTypes;
} => ({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  buttons: {
    margin: '0.75em 0',
    '& > button:first-of-type': {
      marginRight: '0.5em',
    },
  },
  customGrid: {
    minWidth: '350px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px 60px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  customField: {
    position: 'relative',
    '& > .MuiOutlinedInput-root:last-of-type': {
      backgroundColor: theme.palette.primary.main,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
    '& > .MuiFormControl-root > .MuiOutlinedInput-root:first-of-type ': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    '& > .MuiOutlinedInput-root:last-of-type > fieldset': {
      borderColor: 'transparent',
    },
  },
});

export {sxStyles};
