import {AlertColor} from '@mui/material';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

const alertSubject = new Subject<AlertsOptionsProps>();
const defaultId = 'default-alert';

export const alertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear,
};

export const AlertType = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
};

export interface AlertsOptionsProps {
  autoClose?: boolean;
  id?: string;
  type?: AlertColor | undefined | string;
  message?: string | JSX.Element;
  fade?: boolean;
  keepAfterRouteChange?: boolean;
}

// enable subscribing to alerts observable
function onAlert(id = defaultId): Observable<AlertsOptionsProps> {
  return alertSubject.asObservable().pipe(
    filter((x: AlertsOptionsProps) => {
      return x && x.id === id;
    }),
  );
}

// convenience methods
function success(message: string | JSX.Element, options?: AlertsOptionsProps): void {
  alert({...options, type: AlertType.Success, message});
}

function error(message: string | JSX.Element, options?: AlertsOptionsProps): void {
  alert({...options, type: AlertType.Error, message});
}

function info(message: string | JSX.Element, options?: AlertsOptionsProps): void {
  alert({...options, type: AlertType.Info, message});
}

function warn(message: string | JSX.Element, options?: AlertsOptionsProps): void {
  alert({...options, type: AlertType.Warning, message});
}

// core alert method
function alert(alert: AlertsOptionsProps): void {
  alert.id = alert.id || defaultId;
  alert.autoClose = alert.autoClose === undefined ? true : alert.autoClose;
  alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId): void {
  alertSubject.next({id});
}
