import React from 'react';
import moment from 'moment';
import {SxProps, Theme, Typography} from '@mui/material';
import {getMarketplaceById} from '@bizon/amazon-ids';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

import Icons from '../../../components/Icons/Icons';

import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import { ListingsItemIssue } from '../interfaces/interfaces';

import {accountService} from '../../../services/account.service';

const selectedColumns: GridColDef[] = [
  {
    field: 'parent_asin',
    headerName: 'Parent Asin',
    minWidth: 220,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'child_asins',
    headerName: 'Image',
    filterable: false,
    minWidth: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      const image = params.row?.child_asins?.[0]?.mainImage;
      if (image) return <img src={image} alt="" style={{maxWidth: '35px', maxHeight: '35px'}} />;
      else return <Typography>{'N/A'}</Typography>;
    },
  },
  {
    field: 'brand_code',
    headerName: 'Brand Code',
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'brand_name',
    headerName: 'Brand',
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 220,
    disableColumnMenu: true,
  },
];

const defaultColumns: (
  groupBy: string | null,
  newColumns: GridColDef[],
  classes: (key: string) => SxProps<Theme>,
) => GridColDef[] = (groupBy, newColumns, classes) => [
  ...(!groupBy && accountService.userValue.brand_type !== 'ffp' ? newColumns : []),
  {
    field: 'mainImage',
    headerName: 'Image',
    minWidth: 100,
    filterable: false,
    sortable: false,
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      if (params.row.summaries && params.row.summaries[0].mainImage) {
        return <img src={params.row.summaries[0].mainImage.link} alt="" style={{maxWidth: '35px', maxHeight: '35px'}} />;
      } else {
        return <Typography></Typography>;
      }
    },
  },
  {
    field: 'itemName',
    headerName: 'Title',
    flex: 1,
    minWidth: 500,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
    renderCell: (params: GridCellParams): JSX.Element => {
      return <Typography className='item-name'>{params.row.itemName}</Typography>
    },
  },
  {
    field: 'productType',
    headerName: 'Product Type',
    minWidth: 200,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      return <Typography>{params.row.productType}</Typography>
    },
  },
  {
    field: 'sku',
    headerName: 'SKU',
    minWidth: 280,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      return accountService.userValue.brand_type !== 'ffp' ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/products/${encodeURIComponent(params.row.sku)}/detail`}
        >
          <Typography color="secondary">{params.row.sku}</Typography>
        </a>
      ) : (
        <Typography color="secondary">{params.row.sku}</Typography>
      );
    },
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    flex: 1,
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
  },
  {
    field: 'issues',
    headerName: 'Issues',
    minWidth: 100,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      if (params.row.issues && params.row.issues.length > 0) {
        return (
          <div
            className='item-issues'
          >
            <Tooltip
              title={
                <>
                  {params.row.issues.map((issue: ListingsItemIssue, index: number) => (
                    <p
                      key={index}
                    >
                      {issue.message} (code: ${issue.code})
                    </p>
                  ))}
                </>
              }
            >
              <WarningIcon
                color="error"
              />
            </Tooltip>
          </div>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    field: 'marketplace_id',
    headerName: 'Marketplace',
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): JSX.Element => {
      const data = params.row.marketplace_id ? getMarketplaceById(params.row.marketplace_id) : null;
      return (
        <Typography sx={data?.code ? classes('iconFlag') : undefined}>
          {data?.code ? <Icons name={data?.code.toUpperCase()} tooltip /> : 'N/A'}
        </Typography>
      );
    },
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Updated',
    flex: 0.2,
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
    renderCell: (params): JSX.Element => {
      return <Typography>{moment(params.row.lastUpdatedDate).utc().format('YYYY-MM-DD')}</Typography>;
    },
  },
  {
    field: 'brand_name',
    headerName: 'Brand',
    minWidth: 200,
    flex: 1,
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
  },
];

export {selectedColumns, defaultColumns};
