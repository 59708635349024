// services
import {accountService} from '../services/account.service';
// utils
import {FFPArticles, ecom3kArticles} from './FFPArticles';
import {Api} from '../utils/api';
declare global {
  interface Window {
    Beacon: BeaconInterface;
  }
}

interface BeaconInterface {
  (
    arg0: string,
    arg1?:
      | {
          docsEnabled?: boolean;
          mode?: string;
          display?: {
            style: string;
            position: string;
          };
          name?: string;
          email?: string;
        }
      | string
      | null,
    arg2?: {
      docsEnabled?: boolean;
      mode?: string;
      display?: {
        style: string;
        position: string;
      };
      type?: string;
    },
  ): void;
}

let mounted = false;
let selectedArticle: string;

async function getArticleViews() {
  try {
    const {data} = await Api.get(`users/me`);
    return data.viewed_articles;
  } catch (error) {}
}

export function closeBeacon(): void {
  const Beacon = window.Beacon;
  if (Beacon) {
    Beacon('close');
  }
}

async function saveArticleViews(): Promise<void> {
  try {
    await Api.put(`users/${accountService.userValue.userId}/articles`, {
      viewed_article: selectedArticle,
    });
    getArticleViews();
    closeBeacon();
  } catch (error) {}
}

export function initBeacon(Beacon: BeaconInterface, left?: boolean): void {
  const user = accountService.userValue;
  if (!mounted && user) {
    Beacon('init', process.env.REACT_APP_BEACON_KEY);
    Beacon('config', {
      docsEnabled: true,
      mode: 'askFirst',
      display: {
        style: 'icon',
        position: left ? 'left' : 'right',
      },
    });
    Beacon('identify', {
      name: user.name,
      email: user.email,
    });

    mounted = true;
  }
}

export function onClose(): void {
  const iframe = document.getElementById('BeaconInlineArticlesFrame') as HTMLIFrameElement;
  const component = iframe?.contentWindow?.document.getElementsByClassName('c-CloseButton')[0];
  const saveArticle = (e: Event) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    saveArticleViews();
  };
  component?.addEventListener('click', saveArticle);
}

export function openArticle(
  article: string,
  Beacon: BeaconInterface,
  type: string | undefined,
  viewed: string[],
): void {
  if (Beacon && article) {
    if (!viewed?.includes(article)) {
      Beacon('article', article, {type: type});
      selectedArticle = article;
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  }
}

export function destroyBeacon(): void {
  const Beacon = window.Beacon;
  if (Beacon) {
    Beacon('destroy');
    mounted = false;
  }
}

interface Article {
  id: string;
  name: string;
  type?: string | undefined;
}

export function verifyArticle(
  user: {
    brand_type: string;
  },
  Beacon: BeaconInterface,
  viewedArticles: string[],
  key: string,
): void {
  if (['ffp', 'ecom3k'].includes(user.brand_type) && Beacon) {
    const userArticles: {
      [key: string]: Article | Article[];
    } = user.brand_type === 'ffp' ? FFPArticles : ecom3kArticles;
    initBeacon(Beacon);

    if (key === '/account/connect-to-amazon') {
      const viewed = viewedArticles?.includes((userArticles[key] as Article[])[0].id);
      const article = !viewed ? (userArticles[key] as Article[])[0] : (userArticles[key] as Article[])[1];
      openArticle(article.id, Beacon, article.type, viewedArticles);
    } else {
      const article = userArticles[key];
      if (article) {
        const currentArticle = article as Article;
        openArticle(currentArticle.id, Beacon, currentArticle.type ? currentArticle.type : 'sidebar', viewedArticles);
      }
    }
  }
}

export function getArticle({
  filterPath,
  withSearch,
  beacon,
}: {
  filterPath?: boolean;
  withSearch?: boolean;
  beacon?: BeaconInterface;
}): void {
  const user = accountService.userValue;
  const Beacon = beacon ? beacon : window.Beacon;
  const path = window.location.pathname.split('/');
  const key = filterPath
    ? `/${path[path.length - 1]}`
    : `${window.location.pathname}${withSearch ? window.location.search : ''}`;
  const data = async (): Promise<void> => {
    await getArticleViews().then((res) => {
      verifyArticle(user, Beacon, res, key);
    });
  };

  data();
}
