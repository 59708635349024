import React, {useEffect, useState} from 'react';
import {Typography, useTheme, Card, CardHeader, CardContent, CardActions} from '@mui/material';
// services
import {alertService} from '../../../services/alert.service';
import {accountService} from '../../../services/account.service';
import {emitterService} from '../../../services/emitter.service';
// utils
import {Api, ApiWithCustomToken} from '../../../utils/api';
import {brandReportTypes} from '../subpages/BrandsReports/utils/BrandReportProperties';
// components
import {useDrivePicker, accessToken} from './GoogleDrive';
import Button from '../../../components/Button/Button';
// styles
import {sxStyles} from '../Styles';
// assets
import GoogleIcon from '../../../assets/images/google-icon.png';

function GoogleDriveTopFolder(): JSX.Element {
  const theme = useTheme();
  const [openPicker, pickerData] = useDrivePicker({
    onCancel: () => console.log('User closed picker with close/cancel button'),
  });
  const [loading, setLoading] = useState(false);

  const handleOpenPicker = () => {
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID as string,
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY as string,
      viewId: 'FOLDERS',
      token: accessToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      setSelectFolderEnabled: true,
      // customViews: customViewsArray, // custom view
    });
  };

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (pickerData) {
      pickerData.docs.map(async (i) => {
        setLoading(true);
        emitterService.emit('topFolderLoading', () => true);

        // Create ecom3k subfolder and report subfolders
        // Grant permission for each report type subfolder
        const ecom3kFolderResponse = await ApiWithCustomToken.post(
          `https://www.googleapis.com/drive/v3/files`,
          {
            name: 'ecom3k reports',
            mimeType: 'application/vnd.google-apps.folder',
            parents: [i.id],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        await Promise.all(
          [i.id, ecom3kFolderResponse.data.id].map((id) => {
            ApiWithCustomToken.post(
              `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
              {
                type: 'user',
                role: 'writer',
                emailAddress: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );
          }),
        );

        const brandReports = await Promise.all(
          brandReportTypes.map(async (report) => {
            const folderResponse = await ApiWithCustomToken.post(
              `https://www.googleapis.com/drive/v3/files`,
              {
                name: report.name,
                mimeType: 'application/vnd.google-apps.folder',
                parents: [ecom3kFolderResponse.data.id],
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );

            await ApiWithCustomToken.post(
              `https://www.googleapis.com/drive/v3/files/${folderResponse.data.id}/permissions`,
              {
                type: 'user',
                role: 'writer',
                emailAddress: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );

            return {
              reportType: report.reportType,
              reportName: report.name,
              folderName: report.name,
              folderId: folderResponse.data.id,
            };
          }),
        );

        const fields = {
          google_drive_folders: brandReports,
        };

        try {
          await Api.patch(`brands/${accountService.userValue.brand_code}`, fields);

          alertService.success('Google Drive data updated successfully');
        } catch (error) {
          alertService.error('Error updating Google Drive data');
        } finally {
          setLoading(false);
          emitterService.emit('topFolderLoading', () => false);
        }
      });
    }
  }, [pickerData]);

  return (
    <Card variant="outlined" sx={sxStyles('connectorCard')}>
      <CardHeader title="Google Drive Connector" />
      <CardContent>
        <Typography component="h6" color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} gutterBottom>
          Sign In With Google and Create Default Report Folder Structure:
        </Typography>
      </CardContent>
      <CardActions>
        <Button sxStyles={sxStyles('googleButton')} onClick={() => handleOpenPicker()} disabled={loading}>
          <img src={GoogleIcon} width={30} height={30} alt="google" />
          <Typography>Google</Typography>
        </Button>
      </CardActions>
    </Card>
  );
}

export {GoogleDriveTopFolder};
