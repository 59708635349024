import React, {useEffect, useCallback, useState} from 'react';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import {
  TextField as TextFieldMaterial,
  Typography,
  Grid,
  AutocompleteRenderInputParams,
  Box,
  useTheme,
} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {Autocomplete} from 'formik-mui';
// utils
import {Api, errorAlert} from '../../utils/api';
// services
import {alertService} from '../../services/alert.service';
// components
import Button from '../../components/Button/Button';
import {CustomAlert} from '../Login/components/CustomAlert';
import {getSubBrands} from '../../utils/getSubBrands';
// interfaces
import {ProductInterface} from '../../interfaces/product/product';
import {BrandInterface} from '../../interfaces/brands/brand';
// styles
import {sxStyles} from './Styles';

export default function ProductDetails(): JSX.Element {
  const {sku} = useParams<Record<string, string | undefined>>();
  const theme = useTheme();
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [product, setProduct] = useState<ProductInterface>();
  const [editSubBrand, setEditSubBrand] = useState(false);
  const [currentSubBrand, setCurrentSubBrand] = useState<BrandInterface>();
  const [editIgnore, setEditIgnore] = useState(false);

  const fetchProduct = useCallback(async () => {
    try {
      const {
        data: skuData,
      }: {
        data: ProductInterface;
      } = await Api.get(`/products/product/detail?sku=${sku}`);
      setProduct(skuData);
      if (skuData.brand_code) {
        const data = await getSubBrands([skuData.brand_code]);

        if (data) {
          setSubBrands(data);
          const newData = data.find((subBrand: BrandInterface) => subBrand.brand_code === skuData.sub_brand_code);
          if (skuData.sub_brand_code) {
            if (newData) {
              setCurrentSubBrand(newData);
            } else {
              setCurrentSubBrand(data[0]);
            }
          }
        }
      }
    } catch (e) {
      errorAlert('Unable to get product', e);
    }
  }, [sku]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  async function onSubmit(
    data: {
      sub_brand_code?: {brand_code: string};
      ignore?: boolean;
    },
    setSubmitting: (isSubmitting: boolean) => void,
    field: string,
  ) {
    if (!sku) return;
    setSubmitting(true);
    const values: {
      [key: string]: string | boolean;
    } = {
      'sub-brand-code': data?.sub_brand_code?.brand_code || '',
      ignore: data.ignore || false,
    };
    const endpoint =
      field === 'ignore'
        ? `products/change-ignore-status/${encodeURIComponent(sku)}`
        : `/products/${encodeURIComponent(sku)}/${field}`;
    try {
      await Api.patch(endpoint, {[field]: values[field]});
      alertService.success('Product updated successfully');
      setEditSubBrand(false);
      fetchProduct();
    } catch (e) {
      errorAlert('Unable to update product', e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Grid item xs={12} sm={10} md={6} lg={6} container style={{paddingTop: '50px', margin: 'auto', maxWidth: '500px'}}>
      <Typography component="h2" variant="h4" color="primary" gutterBottom>
        Details for SKU {sku}
      </Typography>
      {product ? (
        <Grid container spacing={1} style={{paddingTop: 30}}>
          {product?.title && (
            <Grid item xs={12}>
              <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                Title{' '}
                <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                  {product.title}
                </Typography>
              </Typography>
            </Grid>
          )}
          <Grid item sx={sxStyles(theme).customGrid}>
            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              SKU{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {sku && decodeURIComponent(sku)}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Brand{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product.brand_name}
                {product?.brand_code ? ` (${product.brand_code})` : ''}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              ASIN{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product.asin}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              FNSKU{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product.fnsku}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Average Daily Sales{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {Number(product.average_daily_sales).toFixed(2)}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Hazmat{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product.hazmat ? 'Yes' : 'No'}
              </Typography>
            </Typography>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Consumable{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product.consumable ? 'Yes' : 'No'}
              </Typography>
            </Typography>

            <div>
              {product && (
                <>
                  <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                    Ignore
                  </Typography>
                  {editIgnore ? (
                    <Formik
                      initialValues={{ignore: product?.ignore}}
                      validationSchema={Yup.object().shape({
                        ignore: Yup.boolean().typeError('This field is required').required('This field is required'),
                      })}
                      onSubmit={(data, {setSubmitting}) => {
                        onSubmit(data, setSubmitting, 'ignore');
                      }}
                      validateOnChange={true}
                    >
                      {({errors, touched, isSubmitting}) => (
                        <Form>
                          <div style={{width: '100%'}}>
                            <Field
                              size="small"
                              name="ignore"
                              component={Autocomplete}
                              options={[true, false]}
                              getOptionLabel={(option: boolean) => (option ? 'Yes' : 'No')}
                              renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextFieldMaterial
                                  {...params}
                                  error={touched['ignore'] && !!errors['ignore']}
                                  helperText={touched['ignore'] && errors['ignore']}
                                  label="Ignore"
                                  variant={'outlined'}
                                />
                              )}
                            />
                          </div>
                          <Box sx={sxStyles(theme).buttons}>
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                              Save
                            </Button>
                            <Button onClick={() => setEditIgnore(false)}>Cancel</Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                        {product.ignore ? 'Yes' : 'No'}
                      </Typography>
                      {editIgnore === false && (
                        <Button style={{margin: 'auto'}} onClick={() => setEditIgnore(true)}>
                          Edit
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Master Carton{' '}
              <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                {product && product.master_carton ? (
                  <div>
                    <p>Case quantity: {product.master_carton.case_quantity || 'None'}</p>
                    <p>
                      Dimensions (X-Y-Z): {product.master_carton.dimensions.x}-{product.master_carton.dimensions.y}-
                      {product.master_carton.dimensions.z}
                    </p>
                  </div>
                ) : (
                  'None'
                )}
              </Typography>
            </Typography>
            <div>
              {product && (
                <>
                  <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                    Sub Brand
                  </Typography>
                  {editSubBrand ? (
                    <Formik
                      initialValues={{sub_brand_code: currentSubBrand}}
                      onSubmit={(data, {setSubmitting}) => {
                        onSubmit(data, setSubmitting, 'sub-brand-code');
                      }}
                      validateOnChange={true}
                    >
                      {({errors, touched, isSubmitting}) => (
                        <Form>
                          <div style={{width: '100%'}}>
                            <Field
                              size="small"
                              name="sub_brand_code"
                              component={Autocomplete}
                              options={subBrands}
                              getOptionLabel={(option: {name: string}) => option.name}
                              renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextFieldMaterial
                                  {...params}
                                  error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                                  helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                                  label="Sub brands"
                                  variant={'outlined'}
                                />
                              )}
                            />
                          </div>
                          <Box sx={sxStyles(theme).buttons}>
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                              Save
                            </Button>
                            <Button onClick={() => setEditSubBrand(false)}>Cancel</Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <Typography component="h2" variant="h6" color="primary" gutterBottom>
                        {product.sub_brand_code || 'N/A'}
                      </Typography>
                      {editSubBrand === false && (
                        <Button style={{margin: 'auto'}} onClick={() => setEditSubBrand(true)}>
                          Edit
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
          <CustomAlert sxStyles={sxStyles(theme).inputField} id="default-alert" />
        </Grid>
      ) : (
        <Typography component="h2" variant="h6" gutterBottom>
          {' '}
          No product information{' '}
        </Typography>
      )}
    </Grid>
  );
}
