import React from 'react';
import {Typography} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';
// styles
import {useStyles} from '../Styles';

export function renderRevenue(x: GridRenderCellParams): React.ReactNode {
  const classes = useStyles;
  return x?.value ? (
    <Typography sx={x.value >= 0 ? classes.green : classes.red} color={'primary'}>
      {x.value < 0 ? '' : '+'}
      {x.value}$
    </Typography>
  ) : (
    <></>
  );
}
