import React, {useCallback, useEffect, useState, useContext} from 'react';
import moment from 'moment';
import {
  Grid,
  InputLabel,
  Select,
  Box,
  FormControl,
  MenuItem,
  Autocomplete,
  TextField,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import {
  DataGrid,
  GridSortDirection,
  GridColumnVisibilityModel,
  GridSortModel,
  GridSortItem,
  GridColDef,
} from '@mui/x-data-grid';
import {useLocation} from 'react-router-dom';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import Button from '../../components/Button/Button';
import {BrandPerformanceSummary} from '../Brands/subpages/Advertising/components/BrandPerformanceSummary';
import {PerformanceChart} from '../Brands/subpages/Advertising/components/PerformanceChart';
import {CustomAlert} from '../Login/components/CustomAlert';
import SearchBar from '../../components/SearchBar/SearchBar';
import {metricOptionsData} from '../Brands/subpages/Advertising/components/Metrics';
import DataGridToolbar from '../../components/DataGridToolbar/DataGridToolbar';
// styles
import {sxStyles} from './Styles';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// utils
import {createQueryString} from '../../utils/createQueryString';
import {onFromToChange} from '../../utils/OnFromToChange';
import {updateQueryParams, getDefaultQuery} from '../../utils/urlParams';
import {advertisingGroups} from './utils/advertisingGroups';
import {rowsPerPageOptions} from '../../utils/constants';
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {marketplaces} from '../Performance/utils/marketplaces';
import {getColumns} from './utils/getColumns';
// context
import {AppContext} from '../../context/AppContext/AppContext';
// interfaces
import {BrandsInterface} from '../../context/AppContext/interfaces/interfaces';

type DefaultQuery = {
  from: string;
  to: string;
  compareFrom?: string;
  compareTo?: string;
  preset: string;
  metric: string[];
  marketplace?: string;
  groupBy: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  pageSize?: number;
  brandCodes?: string[];
  searchStr?: string;
  brands?: string[];
};

const hiddenMetrics = ['revenue', 'tacos'];

function AdvertisingPerformanceMain(props: {adsType: string}): JSX.Element {
  const location = useLocation();
  const {adsType} = props;
  const [loadingChart, setLoadingChart] = useState(false);
  const [chartData, setChartData] = useState<
    {type: string; metric: string; data: {date: string | number; value: number}[]}[] | null
  >(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summaryData, setSummaryData] = useState<{[key: string]: {[key: string]: number}} | null>(null);
  const [loadingGroupRows, setLoadingGroupRows] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [compare, setCompare] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [groupBy, setGroupBy] = useState(adsType === 'dsp' ? 'advertiser' : 'campaign');
  const [metric, setMetric] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string | null>('name');
  const [sortDirection, setSortDirection] = useState<string | null | undefined>('desc');
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState<{
    brandCodes: string[];
    from: string | number | Date | moment.Moment | undefined;
    to: string | number | Date | moment.Moment | undefined;
    compareFrom?: string | number | Date | moment.Moment | null;
    compareTo?: string | number | Date | moment.Moment | null;
    preset?: string;
    marketplace_id?: string | null | undefined;
    searchStr?: string | null;
    accountManagers?: string[];
  }>({
    brandCodes: [],
    from: moment(),
    to: moment(),
    compareFrom: null,
    compareTo: null,
    preset: 'custom',
    marketplace_id: marketplaces.find((x) => x.id === 'ATVPDKIKX0DER')?.id,
    searchStr: '',
    accountManagers: [],
  });
  const [filterData, setFilterData] = useState<{
    metric?: string[];
    groupBy?: string;
    sortBy?: string | null;
    sortDirection?: 'asc' | 'desc' | null | string;
    page?: number;
    pageSize?: number;
  }>(
    {} as {
      metric?: string[];
      groupBy?: string;
      sortBy?: string | null;
      sortDirection?: 'asc' | 'desc' | null | string;
      page?: number;
      pageSize?: number;
    },
  );
  const [columns, setColumns] = useState(getColumns(adsType, groupBy));

  const {accountManagers, brands, appLoading} = useContext(AppContext);

  useEffect(() => {
    async function getTableData() {
      setTableLoading(true);
      const _columns = getColumns(adsType, groupBy);
      setColumns(_columns);
      try {
        const data = await getUserPreferences({
          list: _columns,
          tableName: `advertising-${groupBy}`,
          defaultVisibilityModel: {},
          loading: setTableLoading,
        });
        if (data) {
          setOrderColumns(data.columns);
          setVisibilityModel(data.visibility);
        }
      } catch (e) {
      } finally {
        setTableLoading(false);
      }
    }
    getTableData();
  }, [adsType, groupBy]);

  const getCustomUrlParams = useCallback(() => {
    const query = getDefaultQuery(location, {...filter, groupBy, metric, sortBy, sortDirection, page, pageSize});

    const currentMetrics = Object.keys(metricOptionsData[adsType as keyof typeof metricOptionsData]).filter((x) =>
      filterData?.metric?.includes(x),
    );

    const newParams: {
      page: number;
      groupBy: string;
      metric: string[];
      sortBy: string | null;
      sortDirection: string | null;
      pageSize: number;
      brandCodes?: string[];
      accountManagers?: string[];
      marketplace_id?: string | null | undefined;
      preset?: string;
      from?: string;
      to?: string;
      compareFrom?: string | null;
      compareTo?: string | null;
      searchStr?: string | null;
    } =
      Object.keys(filterData).length > 0
        ? {
            ...filterData,
            page: 0,
            pageSize: 40,
            groupBy: adsType === 'dsp' ? 'advertiser' : 'campaign',
            metric:
              currentMetrics.length > 0
                ? currentMetrics
                : [Object.keys(metricOptionsData[adsType as keyof typeof metricOptionsData])[0]],
            sortBy: '',
            sortDirection: 'asc',
          }
        : {
            ...query,
            brandCodes: query.brandCodes?.split(',') || [],
            accountManagers: query.accountManagers?.split(',') || [],
            marketplace_id: marketplaces.find((x) => x.id === query.marketplace_id)?.id || filter.marketplace_id,
            groupBy: query.groupBy || groupBy,
            sortBy: query.sortBy,
            sortDirection: query.sortDirection,
            page: query.page ? +query.page : +page,
            pageSize: query.pageSize ? +query.pageSize : +pageSize,
            metric: query.metric
              ? query.metric.split(',')
              : metricOptionsData.hasOwnProperty(adsType)
              ? [Object.keys(metricOptionsData[adsType as keyof typeof metricOptionsData])[0]]
              : metric,
          };

    setMetric(newParams.metric);
    setGroupBy(newParams.groupBy);
    setSortBy(newParams.sortBy);
    setSortDirection(newParams.sortDirection);
    setPage(newParams.page);
    setPageSize(newParams.pageSize);
    setFilter({
      brandCodes: newParams.brandCodes || [],
      from: newParams.from || moment(),
      to: newParams.to || moment(),
      compareFrom: newParams.compareFrom,
      compareTo: newParams.compareTo,
      preset: newParams.preset,
      marketplace_id: newParams.marketplace_id,
      searchStr: newParams.searchStr,
      accountManagers: newParams.accountManagers || [],
    });
    return newParams;
  }, [adsType, filter, filterData, groupBy, location, metric, page, pageSize, sortDirection, sortBy]);

  const loadGroupRowsData = useCallback(
    async (params) => {
      setRows([]);
      setLoadingGroupRows(true);
      const query = createQueryString(
        {
          ...params,
          displayType: 'group',
        },
        ['accountManagers'],
      );
      try {
        const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
        if (data) {
          setCount(data.count);
          setRows(data.rows);
        }
      } catch (e) {
        errorAlert('Unable to get data', e, {id: 'groups-alert'});
      } finally {
        setLoadingGroupRows(false);
      }
    },
    [adsType],
  );

  const loadSummaryData = useCallback(
    async (params: DefaultQuery) => {
      setLoadingSummary(true);
      const query = createQueryString({...params, displayType: 'summary'}, ['accountManagers']);
      try {
        const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
        const _summaryData: {[key: string]: {[key: string]: number}} = {};
        for (const periodType of Object.keys(data)) {
          const timeRangeType = periodType.replace('AdsData', '');
          for (const metricKey in data[periodType]) {
            _summaryData[metricKey] = _summaryData.hasOwnProperty(metricKey)
              ? {..._summaryData[metricKey]}
              : Object.assign({});
            _summaryData[metricKey][timeRangeType] = Number(data[periodType][metricKey]);
          }
        }
        setSummaryData(_summaryData);
      } catch (e) {
        errorAlert('Unable to get summary data', e, {id: 'summary-alert'});
      } finally {
        setLoadingSummary(false);
      }
    },
    [adsType],
  );

  const loadChartData = useCallback(
    async (params: DefaultQuery) => {
      setChartData(null);
      setLoadingChart(true);
      const query = createQueryString({...params, displayType: 'chart'}, ['accountManagers']);
      try {
        const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
        if (!data) return;
        const _chartData: {
          type: string;
          metric: string;
          data: {date: string; value: number}[];
        }[] = [];
        for (const periodType of Object.keys(data)) {
          const timeRangeType = periodType.replace('AdsData', '');
          for (const record of data?.[periodType] || []) {
            for (const metricKey in record) {
              if (metricKey === 'reportDate') continue;
              const chartDataIdx = _chartData.findIndex(
                (item) => item.type === timeRangeType && item.metric === metricKey,
              );
              if (metric?.includes(metricKey) || true) {
                if (chartDataIdx < 0) {
                  _chartData.push({
                    type: timeRangeType,
                    metric: metricKey,
                    data: [
                      {
                        date: record.reportDate,
                        value: Number(record[metricKey]),
                      },
                    ],
                  });
                } else {
                  _chartData[chartDataIdx].data?.push({
                    date: record.reportDate,
                    value: Number(record[metricKey]),
                  });
                }
              }
            }
          }
        }
        setChartData(_chartData);
      } catch (e) {
        errorAlert('Unable to get chart data', e, {id: 'chart-alert'});
      } finally {
        setLoadingChart(false);
      }
    },
    [metric, adsType],
  );

  async function onDownload() {
    setDownloading(true);
    const data = {
      ...filter,
      ...filterData,
      adsType,
      displayType: 'group',
      marketplaceName: marketplaces.find((x) => x.id === filter.marketplace_id)?.name,
      metric: metric.join(','),
      sortBy,
      sortDirection,
      brandCodes: filter.brandCodes || [],
    };
    try {
      const {data: performanceData} = await Api.post(`advertising/performance-download`, data);
      if (performanceData.status === true) {
        const fileName = 'Advertising Performance Data.csv';
        const link = document.createElement('a');
        link.href = performanceData.message;
        link.setAttribute('download', fileName);
        link.setAttribute('rel', `noreferrer noopener`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        alertService.success('Advertising Performance Data was downloaded.');
      } else {
        alertService.error(performanceData?.message);
      }
    } catch (e) {
      errorAlert('Unable to download Advertising Performance Data', e);
    } finally {
      setDownloading(false);
    }
  }

  const getAdvertisingData = useCallback(
    (params) => {
      if (!params.marketplace_id) return;
      updateQueryParams(location, params);
      loadGroupRowsData(params);
      loadSummaryData(params);
      loadChartData(params);
    },
    [loadChartData, loadGroupRowsData, loadSummaryData, location],
  );

  const toggleCompare = (checked: boolean) => {
    setCompare(checked);
    onFromToChange({
      from: filter.from,
      to: filter.to,
      compareFrom: null,
      compareTo: null,
      preset: filter.preset || 'custom',
      compare: checked,
      loadData: (preset, fromValue, toValue, compareFromValue, compareToValue) => {
        const queryParams = {
          ...filter,
          from: fromValue,
          to: toValue,
          compareFrom: compareFromValue,
          compareTo: compareToValue,
        };
        setFilter(queryParams);
      },
      setFromTo: (x) => setFilter((prev) => ({...prev, ...x})),
    });
  };

  const changeMetric = useCallback(
    (x) => {
      setMetric(x);
      setFilterData((prev) => ({...prev, metric: x}));
      getAdvertisingData({...filterData, metric: x});
    },
    [filterData, getAdvertisingData],
  );

  const handleChangeMarketplace = (e: SelectChangeEvent<string | null>) => {
    const _marketplace = marketplaces.find((x) => x.id === e.target.value)?.id;
    setFilter((prev) => ({...prev, marketplace_id: _marketplace}));
  };

  const handleChangeBrand = (brands: string[]) => {
    setFilter((prev) => ({
      ...prev,
      brandCodes: brands,
      accountManagers: brands.length === 0 ? [] : prev.accountManagers,
    }));
  };

  const handleChangeAccountManager = (accountManager: string[]) => {
    const newBrandsArray: BrandsInterface[] = [];
    const currentAccountManagers = accountManagers.filter((x) => accountManager.includes(x.email));

    currentAccountManagers.forEach((x) => {
      x.brands.forEach((brand) => {
        if (brands.find((b) => b.brand_code === brand.brand_code)) {
          newBrandsArray.push(brand);
        }
      });
    });

    const newBrands = newBrandsArray.map((brand) => brand.brand_code);
    const newAccountManagers = currentAccountManagers.map((x) => x.email);
    setFilter((prev) => ({...prev, brandCodes: newBrands, accountManagers: newAccountManagers}));
    handleChangeBrand(newBrands);
  };

  const onGroupBy = (e: SelectChangeEvent<string>) => {
    setSortBy(null);
    setSortDirection('asc');
    const _params = {...filterData, groupBy: e.target.value, sortDirection: 'asc', page: 0};
    setCount(0);
    setPage(0);
    setGroupBy(e.target.value);
    setFilterData(_params);
    getAdvertisingData(_params);
    updateQueryParams(location, _params);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    const _params = {...filterData, page};
    setFilterData(_params);
    loadGroupRowsData(_params);
    updateQueryParams(location, _params);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    const _params = {...filterData, pageSize, page: 0};
    setFilterData(_params);
    loadGroupRowsData(_params);
    updateQueryParams(location, _params);
  };

  const onSearchBy = (search: string) => {
    const _params = {...filter, searchStr: search};
    setFilter(_params);
  };

  const onSortModelChange = (x: GridSortItem[]) => {
    setSortBy(x[0]?.field);
    setSortDirection(x[0]?.sort);
    const _params = {...filterData, sortBy: x[0]?.field, sortDirection: x[0]?.sort};
    setFilterData(_params);
    loadGroupRowsData(_params);
  };

  const customToolbarProps = {
    loadingData: loadingSummary || loadingChart || loadingSummary || tableLoading,
    items: [...orderColumns],
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        `advertising-${groupBy}`,
        setTableLoading,
      );
    },
  };

  useEffect(() => {
    const defaultQuery = getCustomUrlParams();
    setFilterData(defaultQuery);
    setCompare(defaultQuery.compareFrom !== null);
    getAdvertisingData(defaultQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adsType]);

  return (
    <TableContainer className="sub-page">
      <Grid container>
        <Grid container>
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <DateRangeButtons
              sx={sxStyles('buttonGroup')}
              fromTo={{
                from: filter.from,
                to: filter.to,
                compareFrom: filter.compareFrom,
                compareTo: filter.compareTo,
              }}
              compare={compare}
              setFromTo={(v) => setFilter((prev) => ({...prev, ...v}))}
              period={filter.preset}
              loadData={(preset, fromValue, toValue, compareFromValue, compareToValue) => {
                const queryParams = {
                  ...filter,
                  from: fromValue,
                  to: toValue,
                  compareFrom: compareFromValue,
                  compareTo: compareToValue,
                  preset: preset,
                };
                setFilter(queryParams);
              }}
              compareOnChange={(e) => toggleCompare(e.target.checked)}
              compareComponent
            />
          </Grid>
          <Grid item lg={3} md={12} sm={12} xs={12} style={{textAlign: 'right', marginBottom: '15px'}}>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <FormControl
                variant="outlined"
                style={{width: '100%', maxWidth: '220px', textAlign: 'left'}}
                size="small"
              >
                <InputLabel id="marketplace">Marketplace</InputLabel>
                <Select
                  value={filter.marketplace_id}
                  onChange={handleChangeMarketplace}
                  label={'marketplace'}
                  labelId="marketplace"
                  variant="outlined"
                >
                  {marketplaces.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{margin: '10px 0 30px 0'}}>
          <Grid item lg={2.5} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={brands?.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = brands?.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={filter?.brandCodes}
              disabled={loadingGroupRows || loadingChart || loadingSummary || appLoading}
              onChange={(event, newValue) => {
                handleChangeBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
              )}
            />
          </Grid>
          {!(
            accountService?.userValue.role === Role.StandardAgencyUser ||
            accountService.userValue.role === Role.BrandUser
          ) && (
            <Grid item lg={2.5} md={3} sm={6} xs={12}>
              <Autocomplete
                size="small"
                multiple
                options={accountManagers?.map((x) => x.email)}
                getOptionLabel={(option) => option}
                value={filter?.accountManagers}
                disabled={loadingGroupRows || loadingChart || loadingSummary || appLoading}
                onChange={(event, newValue) => {
                  handleChangeAccountManager(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by Account Manager"
                    placeholder="Account Manager"
                  />
                )}
              />
            </Grid>
          )}

          <Grid item lg={2.5} md={3} sm={6} xs={12}>
            <SearchBar
              value={filter.searchStr || ''}
              placeholder="Search by"
              onChange={(value) => onSearchBy(value)}
              showButton={false}
            />
          </Grid>

          <Grid item lg={2}>
            <Button
              size="small"
              disabled={loadingSummary || appLoading}
              onClick={() => {
                getAdvertisingData({...filterData, ...filter, page: 0});
                setFilterData((prev) => ({...prev, ...filter, page: 0}));
                setPage(0);
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container>
            <CustomAlert id="summary-alert" />
            <BrandPerformanceSummary data={summaryData} loading={loadingSummary} hiddenMetrics={hiddenMetrics} />
            <CustomAlert id="chart-alert" />
            <PerformanceChart
              data={chartData}
              onMetricChange={changeMetric}
              metric={metric}
              loading={loadingChart}
              hiddenMetrics={hiddenMetrics}
            />
          </Grid>
          <CustomAlert id="groups-alert" />
          <Grid container marginTop={3} spacing={1}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid item lg={5} md={3} sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={loadingSummary || loadingChart || loadingSummary}
                >
                  <InputLabel id="demo-simple-select-outlined-label">Group by</InputLabel>
                  <Select
                    value={groupBy}
                    onChange={onGroupBy}
                    label={'Group by'}
                    labelId="demo-simple-select-outlined-label"
                    variant="outlined"
                  >
                    {Object.entries(advertisingGroups?.[adsType as keyof typeof advertisingGroups]).map(
                      ([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12} textAlign={'right'}>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                disabled={isDownloading}
                onClick={onDownload}
              >
                Export {isDownloading && <CircularProgress size={12} style={{color: 'blue'}} />}
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
            <DataGrid
              className={`custom-table ${loadingGroupRows ? 'load-headers' : ''}`}
              sx={sxStyles('grid')}
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              componentsProps={{
                toolbar: customToolbarProps,
                pagination: customToolbarProps,
              }}
              rowHeight={70}
              autoHeight={true}
              rows={rows || []}
              disableSelectionOnClick={true}
              disableVirtualization
              loading={loadingGroupRows}
              disableColumnFilter
              pagination
              rowCount={count}
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              onPageChange={(params) => handleChangePage(params)}
              onPageSizeChange={(params) => handleChangePageSize(params)}
              rowsPerPageOptions={rowsPerPageOptions}
              onSortModelChange={(x: GridSortModel) => {
                onSortModelChange(x);
              }}
              sortModel={
                sortBy && sortDirection
                  ? [
                      {
                        field: sortBy,
                        sort: sortDirection as GridSortDirection,
                      },
                    ]
                  : []
              }
              columns={orderColumns}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `advertising-${groupBy}`,
                  setTableLoading,
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}
export {AdvertisingPerformanceMain};
