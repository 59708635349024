import React, {useState, useEffect, memo} from 'react';
import {Grid, List, ListItem, ListItemIcon, Typography, Paper, ListItemText} from '@mui/material';
import {Square, ArrowRightAlt} from '@mui/icons-material';
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';
// utils
import getLabel from '../../../utils/getLabel';
// styles
import {sxStyles} from '../../Performance/Styles';
// components
import {ChartContainer} from './components';
// interfaces
import {
  FBACountInterface,
  FBADataInterface,
  FixedAndFormatInterface,
  PurchaserBrandInterface,
  PurchaserInterface,
  ReturnRateData,
  SubsAndSaveDataInterface,
  TransformSubsAndSaveBrandDataInterface,
} from '../interfaces/insights';
import {SxStyleTypes} from '../../../interfaces/sxStyles/sxStyles.interface';

export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#d63f7e',
  '#FFBB28',
  '#FF8042',
  '#d63f3f',
  '#d354c2',
  '#106656',
  '#145894',
  '#f390b9',
  '#c7952b',
  '#ce4f0f',
  '#f06666',
  '#911080',
];

export function getAmountDiff({
  current,
  old,
  styles,
  format,
  invertedColor,
}: {
  current: number;
  old: number;
  styles: (key: string) => SxStyleTypes;
  format?: string;
  invertedColor?: boolean;
}): JSX.Element {
  if (current === 0 && old === 0) {
    return (
      <Typography
        component="span"
        sx={invertedColor ? styles('red') : styles('green')}
        style={{display: 'block', lineHeight: 1}}
      >
        0{format ? format : '%'}
      </Typography>
    );
  } else if (current > 0 && old === 0) {
    return (
      <Typography
        component="span"
        sx={invertedColor ? styles('green') : styles('red')}
        style={{display: 'block', lineHeight: 1}}
      >
        +100{format ? format : '%'}
      </Typography>
    );
  } else if (current === old) {
    return (
      <Typography
        component="span"
        sx={invertedColor ? styles('green') : styles('red')}
        style={{display: 'block', lineHeight: 1}}
      >
        0{format ? format : '%'}
      </Typography>
    );
  } else {
    const s = current - old;
    const c = s / old;
    const diff = c * 100;
    let diffColor;
    if (diff >= 0) {
      diffColor = invertedColor ? styles('green') : styles('red');
    } else {
      diffColor = invertedColor ? styles('red') : styles('green');
    }
    return (
      <Typography component="span" sx={diffColor} style={{padding: '0 5px'}}>
        {diff >= 0 && '+'}
        {`${diff.toFixed(1)}%`}
      </Typography>
    );
  }
}

type PieChartData =
  | FBADataInterface[]
  | FBACountInterface[]
  | FBACountInterface[]
  | PurchaserInterface[]
  | ReturnRateData[]
  | ReturnRateData[][]
  | FixedAndFormatInterface[]
  | SubsAndSaveDataInterface[]
  | ChartData[];

type ChartDataTypes =
  | ChartData[]
  | PurchaserBrandInterface[]
  | ReturnRateData[]
  | TransformSubsAndSaveBrandDataInterface[];
interface ChartData {
  brand_code?: string;
  brand_name?: string;
  color?: string;
  metric: string;
  old_percent?: number;
  old_value?: number;
  percentage?: number;
  sub_and_save?: number;
  total?: number;
  value: number;
  value_percent: number;
  format?: string;
  fixed?: number;
  invertedColor?: boolean;
}

interface RadianPropsTypes {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  value: number;
}

interface PieChartProps {
  style?: SxStyleTypes;
  listStyle?: SxStyleTypes;
  className?: string;
  data?: PieChartData;
  loading: boolean;
  filters?: {[key: string]: string | number | null | string[]};
  chartTitle?: string | JSX.Element;
  compare?: boolean;
  getItem?: (x: string) => void;
  subChart?: boolean;
  selectedItem?: string | null;
  actionButton?: {
    label: string;
    action: () => void;
  }[];
  doubleChart?: boolean;
  dataTitle?: string | JSX.Element;
  secondaryDataTitle?: string | JSX.Element;
  customList?: JSX.Element;
  clickable?: boolean;
  showTotal?: boolean;
  pieType?: string;
  chartData?: ChartDataTypes | null;
  showCount?: boolean;
  chartDescription?: string | JSX.Element;
  format?: string;
  sortData?: boolean;
  customValue?: boolean;
  labelIcon?: boolean;
  minHeight?: number;
}

export default memo(function PieCharts({
  style,
  listStyle,
  className,
  loading,
  filters,
  chartTitle,
  compare,
  data,
  getItem,
  subChart,
  selectedItem,
  actionButton,
  dataTitle,
  secondaryDataTitle,
  customList,
  clickable = true,
  showTotal = true,
  chartData,
  showCount = true,
  chartDescription,
  format = '%',
  sortData = true,
  customValue = true,
  labelIcon = true,
  minHeight,
}: PieChartProps): JSX.Element {
  const getChartData = (data as ChartData[])
    ?.filter((entry) => {
      const percent = +entry.value_percent;
      return percent >= 1;
    })
    .slice(0, 20);
  const pieData = chartData ? chartData : getChartData;
  const [total, setTotal] = useState((pieData as ChartData[])?.reduce((acc, curr) => acc + curr.value, 0));
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, value}: RadianPropsTypes) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return customValue ? (
      <text
        style={{
          fontSize: '12px',
        }}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {value}%
      </text>
    ) : (
      <text
        style={{
          fontSize: '12px',
        }}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {(+percent * 100).toFixed(2)}%
      </text>
    );
  };

  useEffect(() => {
    const percent = (pieData as ChartData[])?.reduce((acc, curr) => acc + curr.value, 0);
    setTotal(percent);
  }, [pieData]);

  const chartList = (currentData: ChartData[]) => {
    return (
      <List sx={listStyle}>
        <>
          {currentData.map((entry, index) => {
            return (
              <ListItem
                key={index}
                style={{
                  padding: '4px 0',
                }}
                className={`${selectedItem === entry.metric ? 'active' : ''}${clickable ? 'clickable' : ''}`}
                onClick={() => {
                  if (clickable) getItem?.(entry.metric);
                }}
              >
                {entry.color && labelIcon && (
                  <ListItemIcon>
                    <Square style={{borderRadius: '50px', color: entry.color}} />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={
                    <>
                      <Typography component="span" variant="overline" style={{textTransform: 'capitalize'}}>
                        {getLabel(entry.metric)}
                      </Typography>
                      <Typography component="span" variant="overline">
                        {showCount
                          ? `(${+entry.value.toFixed(0)}) - ${
                              !isNaN(entry.value_percent) ? entry.value_percent : +entry.value_percent.toFixed(2)
                            }${entry.format !== null && entry.format !== undefined ? entry.format : format}`
                          : `${!isNaN(entry.value_percent) ? entry.value_percent : +entry.value_percent.toFixed(2)}${
                              entry.format !== null && entry.format !== undefined ? entry.format : format
                            }`}
                      </Typography>
                    </>
                  }
                  secondary={
                    compare && (
                      <>
                        <Typography component="span" variant="overline">
                          {!Number.isInteger(entry.old_value)
                            ? entry.old_value && +entry.old_value?.toFixed(entry.fixed ? entry.fixed : subChart ? 0 : 2)
                            : entry.old_value}{' '}
                          {entry.format}
                          <ArrowRightAlt />
                          {!Number.isInteger(entry.value)
                            ? +entry.value?.toFixed(entry.fixed ? entry.fixed : subChart ? 0 : 2)
                            : entry.value}
                          {entry.format} ={' '}
                          {getAmountDiff({
                            current: +entry.value,
                            old: entry?.old_value ? +entry?.old_value : 0,
                            styles: sxStyles,
                            format: entry.format,
                            invertedColor: entry.invertedColor,
                          })}
                        </Typography>
                      </>
                    )
                  }
                />
              </ListItem>
            );
          })}
        </>
      </List>
    );
  };

  return (
    <ChartContainer
      minHeight={minHeight}
      chartTitle={chartTitle}
      loading={loading}
      filters={filters}
      actionButton={actionButton}
      data={data as ChartData[]}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{minHeight: '400px'}}
        position="relative"
      >
        {data && data.length > 0 ? (
          <Grid container alignItems="center" style={{padding: '20px 0 50px 0'}}>
            <Grid
              item
              lg={6}
              md={5}
              sm={12}
              xs={12}
              sx={style}
              className={className}
              alignItems="center"
              display="flex"
              justifyContent={'center'}
              flexDirection="column"
            >
              {total > 0 && pieData && pieData.length > 0 ? (
                <>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                      <Pie
                        data={pieData}
                        isAnimationActive={false}
                        cy={'50%'}
                        cx={'50%'}
                        labelLine={false}
                        label={(v) => renderCustomizedLabel({...v, value: v.value_percent})}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {(pieData as ReturnRateData[]).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color as string} />
                        ))}
                      </Pie>

                      <Tooltip
                        wrapperStyle={{outline: 'none'}}
                        content={({active, payload}) => {
                          return active ? (
                            <Paper style={{padding: '10px'}}>
                              <Typography
                                component="span"
                                variant="overline"
                                gutterBottom
                                style={{textTransform: 'capitalize'}}
                              >
                                {customValue
                                  ? `${getLabel(payload?.[0].payload.metric)}: (${payload?.[0].payload.value}) ${
                                      payload?.[0].payload.value_percent
                                    }${
                                      payload?.[0].payload.format !== null && payload?.[0].payload.format !== undefined
                                        ? payload?.[0].payload.format
                                        : format
                                    }`
                                  : `${getLabel(payload?.[0].payload.metric)}: (${payload?.[0].payload.value}) ${(
                                      (parseFloat(payload?.[0].payload.value_percent) / total) *
                                      100
                                    ).toFixed(2)}${
                                      payload?.[0].payload.format !== null && payload?.[0].payload.format !== undefined
                                        ? payload?.[0].payload.format
                                        : format
                                    }
                                        `}
                              </Typography>
                            </Paper>
                          ) : null;
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  <Typography variant="body1" style={{textAlign: 'center'}} color="secondary">
                    {chartDescription}
                  </Typography>
                </>
              ) : (
                <Typography component="h6" variant="h6" color="primary" gutterBottom>
                  No data
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={7} sm={12} xs={12} sx={sxStyles('dataContainer')}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                display="flex"
                style={{maxWidth: '500px'}}
              >
                {dataTitle && (
                  <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                    {dataTitle}
                  </Typography>
                )}
                {showTotal && (
                  <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                    Total: {total.toFixed(0)}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} sx={sxStyles('listContainer')}>
                {chartList(sortData ? (data as ChartData[]).sort((a, b) => b.value - a.value) : (data as ChartData[]))}
              </Grid>
              {customList && (
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                    display="flex"
                    style={{maxWidth: '500px'}}
                  >
                    {secondaryDataTitle && (
                      <Typography component="span" variant="overline" color="secondary">
                        {secondaryDataTitle}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={sxStyles('listContainer')}>
                    {customList}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography component="h2" variant="h5" color="primary" gutterBottom>
            No data available
          </Typography>
        )}
      </Grid>
    </ChartContainer>
  );
});
