import React, {useEffect, useState} from 'react';
import {AutocompleteRenderInputParams, Typography, TextField as TextFieldMaterial, Button, Grid} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import {CheckboxWithLabel, TextField, Autocomplete} from 'formik-mui';
import * as Yup from 'yup';
// services
import {alertService} from '../../../../services/alert.service';
import {accountService} from '../../../../services/account.service';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {withTracker} from '../../../../utils/withTracker';
import {Api, errorAlert} from '../../../../utils/api';
import {BrandTypesAlias} from '../../../../utils/brand-types';
import {getSubBrands} from '../../../../utils/getSubBrands';
// interfaces
import {BrandInterface} from '../../../../interfaces/brands/brand';

const sxStyles = {
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
};

const initialValues = {
  email: '',
  name: '',
  brand_admin: false,
  create_users: false,
  sub_brand_manager: false,
  sub_brand_code: [] as BrandInterface[],
  sub_brand_codes: [] as string[],
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  name: Yup.string().required('Name is required'),
  sub_brand_code:
    !accountService.userValue ||
    !accountService.userValue.sub_brand_codes ||
    accountService.userValue.sub_brand_codes.length === 0
      ? Yup.array().when('sub_brand_manager', {
          is: true,
          then: Yup.array().min(1, 'A sub brand is required'),
        })
      : Yup.array().min(1, 'A sub brand is required'),
});

function CreateBrandUser(): JSX.Element {
  const history = useHistory();
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);

  async function pullSubBrands(brandCode: string) {
    let newSubBrands = [] as BrandInterface[];
    const data = await getSubBrands([brandCode]);
    if (data) {
      newSubBrands = data;
    }
    if (accountService.userValue.sub_brand_codes && accountService.userValue.sub_brand_codes.length > 0) {
      newSubBrands = newSubBrands.filter((sb) => accountService.userValue.sub_brand_codes.includes(sb.brand_code));
    }
    setSubBrands(newSubBrands);
  }

  useEffect(() => {
    pullSubBrands(accountService.userValue.brand_code);
  }, []);

  async function onSubmit(
    data: {sub_brand_code: BrandInterface[]; sub_brand_manager: boolean; sub_brand_codes: string[]},
    {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void},
  ) {
    if (data.sub_brand_manager && data.sub_brand_code.length > 0) {
      data.sub_brand_codes = data.sub_brand_code.map((subBrand) => subBrand.brand_code);
    }

    if (accountService.userValue.sub_brand_codes && accountService.userValue.sub_brand_codes.length > 0) {
      data.sub_brand_codes = data.sub_brand_code.map((subBrand) => subBrand.brand_code);
    }
    alertService.clear();
    try {
      await Api.post('users/brand', data);
      if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)) {
        history.push('/account/users');
      } else {
        history.push('/users');
      }
      alertService.success('User created successfully');
    } catch (e) {
      errorAlert('Failed to create user.', e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item lg={4} sm={6} xs={12}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Create user
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
        >
          {({errors, touched, isSubmitting, values: formValues}) => (
            <Form>
              <div>
                <div>
                  <Field
                    sx={sxStyles.inputField}
                    component={TextField}
                    variant="outlined"
                    name="email"
                    label="Email"
                    type="text"
                    size="small"
                  />
                </div>
                <div>
                  <Field
                    sx={sxStyles.inputField}
                    component={TextField}
                    variant="outlined"
                    name="name"
                    label="Name"
                    type="text"
                    size="small"
                  />
                </div>
                {(!accountService.userValue.sub_brand_codes ||
                  accountService.userValue.sub_brand_codes.length === 0) && (
                  <div>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="sub_brand_manager"
                      Label={{label: 'Create as a sub account manager'}}
                    />
                  </div>
                )}
                {formValues.sub_brand_manager && (
                  <Field
                    multiple
                    sx={sxStyles.inputField}
                    name="sub_brand_code"
                    size="small"
                    component={Autocomplete}
                    options={subBrands}
                    getOptionLabel={(option: {name: string}) => option.name}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextFieldMaterial
                        {...params}
                        error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                        helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                        label="Sub brands"
                        variant={'outlined'}
                      />
                    )}
                  />
                )}

                {accountService.userValue.sub_brand_codes && accountService.userValue.sub_brand_codes.length > 0 && (
                  <Field
                    multiple
                    sx={sxStyles.inputField}
                    name="sub_brand_code"
                    component={Autocomplete}
                    size="small"
                    options={subBrands}
                    getOptionLabel={(option: {name: string}) => option.name}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextFieldMaterial
                        {...params}
                        error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                        helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                        label="Sub brands"
                        variant={'outlined'}
                      />
                    )}
                  />
                )}
                <div>
                  {accountService.userValue.brand_type !== BrandTypesAlias.ffp && (
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="create_users"
                      Label={{label: 'Can create users'}}
                    />
                  )}
                </div>
                <div>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => withTracker('User', 'Create User')}
                  >
                    Save
                  </Button>
                  <CustomAlert sxStyles={sxStyles.inputField} id="default-alert" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Grid>
    </TableContainer>
  );
}

export {CreateBrandUser};
