const useStyles = {
  wrapperDiv: {
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0px 0px 6px 0px #94949433',
    background: 'background.default',
    '& h2': {
      margin: '10px 0',
    },
    '& .MuiDataGrid-autoHeight': {
      margin: '0 3px',
      boxShadow: 'none',
    },
    '& .brand-cards': {
      '& > .MuiAvatar-root': {
        backgroundColor: 'primary.main',
        margin: '10px auto',
        width: 80,
        height: 80,
        fontWeight: 400,
        color: '#fff',
      },
      '& > .MuiTypography-root': {
        fontWeight: 400,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  box: {
    padding: '10px',
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  pagination: {
    '& ul': {
      justifyContent: 'center',
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  loginPaper: {
    textAlign: 'center',
    padding: '1em 2em 3em 2em',
    margin: '2em 0em',
  },
  loginButton: {
    margin: '0.3em',
  },
  logoImg: {
    width: '10em',
    margin: '3em',
  },
  gridWithImage: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/Market.svg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30em',
    backgroundPositionY: '5em',
  },
} as const;

export {useStyles};
