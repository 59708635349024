import React, {useEffect, useState, useContext, useCallback} from 'react';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
  Box,
  Grid,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  Autocomplete,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import {Visibility, VisibilityOff} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Role} from '../../../../utils/role';
import {rowsPerPageOptions} from '../../../../utils/constants';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// components
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';
// interfaces
import {AlertTableProps, LateFBMShipmentsItem, LateFBMShipmentsTypes} from '../../interfaces/alerts.interfaces';
import {AccountManagersInterface} from '../../../../context/AppContext/interfaces/interfaces';

export default function LateFBMShipments({alertType, sxStyles}: AlertTableProps): JSX.Element {
  const [loadingData, setLoadingData] = useState(false);
  const [rows, setRows] = useState<LateFBMShipmentsTypes['rows']>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [selectedIdxes, setSelectedIdxes] = useState<GridSelectionModel>([]);
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const [sort, setSort] = useState<GridSortModel | null>(null);
  const [accountManager, setAccountManager] = useState<AccountManagersInterface | null>(null);

  const {accountManagers, currentAM} = useContext(AppContext);

  useEffect(() => {
    if (currentAM) {
      setAccountManager(currentAM);
    }
  }, [currentAM]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 110,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        if (params.row.finished) {
          return (
            <Tooltip title={'Show Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <Visibility style={{color: 'green'}} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={'Hide Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <VisibilityOff />
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: 0.15,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.orderId}>
          <span className="table-cell-trucate">{params.row.orderId}</span>
        </Tooltip>
      ),
    },
    {field: 'sku', headerName: 'SKU', flex: 0.4},
    {
      field: 'promiseDate',
      headerName: 'Promised Date',
      flex: 0.15,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.promiseDate}>
          <span className="table-cell-trucate">{params.row.promiseDate}</span>
        </Tooltip>
      ),
    },
    {field: 'daysPastPromise', headerName: 'Days past promise', flex: 0.15},
    {field: 'status', headerName: 'Status', flex: 0.15},
  ];

  const csvHeaders = columns
    .filter((column) => column.field !== 'actions')
    .map((column) => {
      return {key: column.field, label: column.headerName || ''};
    });

  const loadData = useCallback(
    async (page, pageSize, blShowAll = showAll, sortModel, filterModel, amId) => {
      setLoadingData(true);
      try {
        const {
          data,
        }: {
          data: LateFBMShipmentsTypes;
        } = await Api.get(`alerts/late-fbm-shipments`, {
          params: {
            page: page,
            pageSize: pageSize,
            showAll: blShowAll,
            sortBy: sortModel ? sortModel.field : null,
            sortOrder: sortModel ? sortModel.sort : null,
            filterField: filterModel ? filterModel.columnField : null,
            filterValue: filterModel ? filterModel.value : null,
            filterOperator: filterModel ? filterModel.operatorValue : null,
            accountManagerId: amId,
          },
        });
        setCount(data.count);
        const rowsGrid = data.rows.map((x, i) => {
          x.promiseDate = moment(new Date(x.promiseDate)).format('YYYY-MM-DD hh:mm A');
          return {...x, id: i};
        });
        setRows(rowsGrid);
        setSelectedIdxes([]);
      } catch (e) {
        errorAlert('Unable to get alerts', e);
      } finally {
        setLoadingData(false);
      }
    },
    [showAll],
  );

  function handleChangePage(page: number) {
    setPage(page);
  }

  function handleChangeShowAll() {
    const tmpShowAll = !showAll;
    setShowAll(tmpShowAll);
  }

  function handleChangeAM(newValue: AccountManagersInterface | null) {
    setAccountManager(newValue);
  }

  function handleSortModelChange(sortModel: GridSortModel) {
    setSort(sortModel);
  }

  function handleFilterModelChange(params: {items: GridFilterItem[]}) {
    const filterModel = params.items[0];
    delete filterModel.id;
    setFilter(filterModel);
  }

  function handleChangePageSize(pageSize: number) {
    setPage(0);
    setPageSize(pageSize);
  }

  async function handleHideAlerts() {
    const selectedIds = rows.filter((row) => selectedIdxes.indexOf(row.id.toString()) > -1).map((row) => row._id);
    try {
      await Api.post('alerts/toggle-alert-finish-status/late-fbm-shipments', {alertIds: selectedIds});
      loadData(page, pageSize, showAll, sort, filter, accountManager?.id || null);
      alertService.success(`Alert status changed successfully!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alerts. Please try again later.', e);
    }
  }

  async function hideAlert(record: LateFBMShipmentsItem) {
    try {
      await Api.post('alerts/toggle-alert-finish-status/late-fbm-shipments', {alertIds: [record._id]});
      loadData(page, pageSize, showAll, sort, filter, accountManager?.id || null);
      alertService.success(`Alert status changed successfully!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e);
    }
  }

  useEffect(() => {
    loadData(page, pageSize, showAll, sort, filter, accountManager?.id);
  }, [accountManager, page, pageSize, showAll, sort, filter, loadData]);

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} style={{margin: 'auto'}}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" sx={sxStyles('toolbar')}>
          {[Role.Admin].includes(accountService.userValue.role) && (
            <Autocomplete
              id="account-manager"
              size="small"
              options={accountManagers}
              getOptionLabel={(option) => option.name}
              value={accountManager}
              onChange={(event, newValue) => {
                handleChangeAM(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Account Manager" variant="outlined" />}
            />
          )}
          {alertType !== 'All' && (
            <>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={showAll} onChange={handleChangeShowAll} color="primary" />}
                  label="Show Finished Orders"
                />
                <Button size="small" onClick={handleHideAlerts} disabled={showAll || selectedIdxes?.length < 1}>
                  Hide all selected
                </Button>
              </FormGroup>
            </>
          )}
          <CSVLink filename={`${alertType} Alerts.csv`} data={rows} headers={csvHeaders.map((header) => header.label)}>
            <Button size="small">Export to CSV</Button>
          </CSVLink>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={sxStyles('grid')}
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          onPageChange={(params) => handleChangePage(params)}
          rowCount={count}
          loading={loadingData}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={sort ? sort : []}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          disableSelectionOnClick={true}
          checkboxSelection={alertType !== 'All'}
          selectionModel={selectedIdxes}
          onSelectionModelChange={(x) => {
            setSelectedIdxes(x);
          }}
          rowHeight={64}
          rows={rows}
          columns={columns}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="default-alert-3" />
      </Grid>
    </Grid>
  );
}
