import React, { useState, useCallback } from 'react';
import {Grid, Tabs, Tab} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import {useLocation, useParams, useHistory} from 'react-router-dom';
// sections
import {Users} from '../Users/Users';
import PaymentAndBilling from './subpages/PaymentAndBilling/PaymentAndBilling';
import {FfpSubBrands} from '../Brands/subpages/FFPSubBrands/FfpSubBrands';
// import {FfpSubAccounts} from '../Brands/subpages/FFPSubBrands/subpages/FfpSubAccounts/FfpSubAccounts';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
// utils
import {withTracker} from '../../utils/withTracker';

export default function AccountPageView(): JSX.Element {
  const {tabValue} = useParams<Record<string, string | undefined>>();
  const [value, setValue] = useState(tabValue ? tabValue : 'billing');
  const history = useHistory();
  const location = useLocation();

  document.title = `Listings`;
  const handleChange = (newValue: string) => {
    setValue(newValue);
    const search = new URLSearchParams(location.search);
    search.delete('couponCode');
  };

  const navigateTo = useCallback(
    (tabName: string) => {
      history.push(`/account/${tabName}`);
    },
    [history],
  );

  const tabNames = [
    {tabKey: 'billing', tabLabel: 'Payment and Billing'},
    {tabKey: 'users', tabLabel: 'Users and Permissions'},
    {tabKey: 'connect-to-amazon', tabLabel: 'Connect to Amazon'},
    // {tabKey: 'sub-accounts', tabLabel: 'Sub Accounts'},
  ];

  function a11yProps(index: number) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <TabContext value={value}>
        <CustomTabs position={'static'} color="default">
          <Tabs
            value={value}
            onChange={(e, v) => handleChange(v)}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            style={{justifyContent: 'center'}}
          >
            {tabNames.map(({tabKey, tabLabel}, idx) => (
              <Tab
                label={tabLabel}
                value={tabKey}
                key={tabKey}
                {...a11yProps(idx)}
                onClick={() => navigateTo(tabKey)}
              />
            ))}
          </Tabs>
        </CustomTabs>

        <TabPanel value={value} style={{padding: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
          <TableContainer>
            {value === 'billing' && (
              <PaymentAndBilling GAEvents={(category, action, label) => withTracker(category, action, label)} />
            )}
            {value === 'users' && (
              <Users GAEvents={(category, action, label) => withTracker(category, action, label)} />
            )}
            {value === 'connect-to-amazon' && (
              <FfpSubBrands GAEvents={(category, action, label) => withTracker(category, action, label)} />
            )}
            {/* {value === 'sub-accounts' && (
              <FfpSubAccounts GAEvents={(category, action, label) => withTracker(category, action, label)} />
            )} */}
          </TableContainer>
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
