import React, {useEffect, useState, useCallback} from 'react';
import {Chip, Paper, Tooltip, Typography, Theme, Grid, SxProps, useTheme} from '@mui/material';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';

const sxStyles = (
  theme: Theme,
): {
  [key: string]: SxProps<Theme>;
} => ({
  box: {
    padding: '10px',
  },
  green: {
    margin: '0px 10px',
    backgroundColor: '#4caf50',
  },
  red: {
    margin: '0px 10px',
    backgroundColor: '#f44336',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridTemplateRows: '1fr',
    gridGap: '15px',
    '& .MuiTypography-subtitle1 ': {
      fontSize: '.8rem',
      whiteSpace: 'nowrap',
    },
    '& .MuiTypography-h5': {
      fontSize: '1rem',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.7rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '.8rem',
      },
    },
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    },
  },
});

function AsinPerformanceSummary({
  data,
}: {
  data: {
    buy_box: number;
    old_buy_box: number;
    session: number;
    old_session: number;
    revenue: number;
    old_revenue: number;
    units: number;
    old_units: number;
  } | null;
}): JSX.Element {
  const theme = useTheme();
  const styles = (key: string) => sxStyles(theme)[key];

  const [percentageData, setPercentageData] = useState<{
    [key: string]: number | null;
  }>({});

  const calculatePercentage = useCallback(() => {
    let buy_box_percentage: number | null = null;
    let session_percentage: number | null = null;
    let revenue_percentage: number | null = null;
    let units_percentage: number | null = null;

    if (data?.buy_box && data?.old_buy_box) {
      buy_box_percentage = +(((data.buy_box - data.old_buy_box) * 100) / data.old_buy_box).toFixed(2);
    }

    if (data?.session && data?.old_session) {
      session_percentage = +(((data.session - data.old_session) * 100) / data.old_session).toFixed(2);
    }

    if (data?.revenue && data?.old_revenue) {
      revenue_percentage = +(((data.revenue - data.old_revenue) * 100) / data.old_revenue).toFixed(2);
    }

    if (data?.units && data?.old_units) {
      units_percentage = +(((data.units - data.old_units) * 100) / data.old_units).toFixed(2);
    }

    setPercentageData((p) => ({
      ...p,
      buy_box_percentage,
      session_percentage,
      revenue_percentage,
      units_percentage,
    }));
  }, [data]);

  useEffect(() => {
    calculatePercentage();
  }, [calculatePercentage]);

  return (
    <Grid container sx={styles('grid')}>
      <Grid item style={{width: '100%'}}>
        <Paper sx={styles('box')} variant={'elevation'}>
          <Tooltip
            title="The selected SKU's average Buy Box percentage for the selected period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Average Buy Box %
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {data?.buy_box != null ? data.buy_box : 'N/A'}%
            {percentageData?.buy_box_percentage ? (
              <Chip
                sx={percentageData.buy_box_percentage >= 0 ? styles('green') : styles('red')}
                size="medium"
                label={`${percentageData.buy_box_percentage < 0 ? '' : '+'}${percentageData.buy_box_percentage}%`}
                color={'primary'}
              />
            ) : (
              ''
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_buy_box' in data ? (data?.old_buy_box != null ? data.old_buy_box + '%' : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Paper sx={styles('box')} variant={'elevation'}>
          <Tooltip
            title="The sum of all selected SKU's sessions. Sessions in this context are most analogous to unique visitors."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Sessions
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {data?.session != null ? data.session : 'N/A'}
            {percentageData?.session_percentage ? (
              <Chip
                sx={percentageData.session_percentage >= 0 ? styles('green') : styles('red')}
                size="medium"
                label={`${percentageData.session_percentage < 0 ? '' : '+'}${percentageData.session_percentage}%`}
                color={'primary'}
              />
            ) : (
              ''
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_session' in data ? (data?.old_session != null ? data.old_session : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Paper sx={styles('box')} variant={'elevation'}>
          <Tooltip
            title="The sum of all revenue recorded for the selected SKUs in the selected time period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Revenue
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {data?.revenue != null ? Math.floor(data.revenue) : 'N/A'}
            {percentageData?.revenue_percentage ? (
              <Chip
                sx={percentageData.revenue_percentage >= 0 ? styles('green') : styles('red')}
                size="medium"
                label={`${percentageData.revenue_percentage < 0 ? '' : '+'}${percentageData.revenue_percentage}%`}
                color={'primary'}
              />
            ) : (
              ''
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_revenue' in data ? (data?.old_revenue != null ? Math.floor(data.old_revenue) : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Paper sx={styles('box')} variant={'elevation'}>
          <Tooltip
            title="The sum of units sold for the selected SKUs in the selected time period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Units
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {data?.units != null ? data.units : 'N/A'}
            {percentageData?.units_percentage ? (
              <Chip
                sx={percentageData.units_percentage >= 0 ? styles('green') : styles('red')}
                size="medium"
                label={`${percentageData.units_percentage < 0 ? '' : '+'}${percentageData.units_percentage}%`}
                color={'primary'}
              />
            ) : (
              ''
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_units' in data ? (data?.old_units != null ? data.old_units : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <CustomAlert id="asin-summary" />
    </Grid>
  );
}

export {AsinPerformanceSummary};
