import React, {useState} from 'react';
import {Typography, useTheme, Link} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {green} from '@mui/material/colors';
// components
import ModalButton from '../../../../../components/ModalButton/ModalButton';
import Button from '../../../../../components/Button/Button';
import Spacer from '../../../../../components/Spacer/Spacer';
// services
import {accountService} from '../../../../../services/account.service';
import {alertService} from '../../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../../utils/api';
// interfaces
import {BrandInterface} from '../../../../../interfaces/brands/brand';

interface AuthorizeAdvertisingForFFPBrandProps {
  brandCode: string;
  buttonOnly?: boolean;
  buttonSize?: string;
  hideButton?: boolean;
  brandInfo: BrandInterface | null;
  refreshBrand?: () => void;
  GAEvents?: (category: string, action: string, label?: string) => void;
}

function AuthorizeAdvertisingForFFPBrand({
  brandCode,
  buttonOnly,
  brandInfo,
  refreshBrand,
  GAEvents,
}: AuthorizeAdvertisingForFFPBrandProps): JSX.Element {
  const theme = useTheme();
  const [displayConfirmDisconnectAdvertising, setDisplayConfirmDisconnectAdvertising] = useState(false);

  async function grantAdvertisingAccess(closeModal?: () => void) {
    try {
      const response = await Api.get(`advertising/authorization-link/${brandCode}`);
      window.location.href = response.data;
      closeModal?.();
    } catch (e) {
      errorAlert('Unable to get Advertising Authorization Link', e);
    }
  }

  async function confirmDisconnectAdvertisingAccess() {
    setDisplayConfirmDisconnectAdvertising(true);
  }

  async function disconnectAdvertising(closeModal: () => void) {
    setDisplayConfirmDisconnectAdvertising(false);

    try {
      const response = await Api.post(`/advertising/deauthorize/${brandCode}`, {});
      if (response.data.ok === true) {
        alertService.success('The advertising API access was revoked successfully');
      }
      accountService.refreshToken();
      refreshBrand?.();
      closeModal();
    } catch (e) {
      errorAlert('There was an error revoking the advertising API access', e);
    }
  }

  return (
    <>
      {!buttonOnly && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography component="h6" color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} gutterBottom>
            Advertising API access:
          </Typography>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {brandInfo?.advertising_access ? (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', padding: '10px 0'}}>
                  <CheckCircle style={{color: green[500]}} />
                  <Spacer width={7} />
                  <Typography style={{color: green[500]}}>Connected</Typography>
                </div>
              </div>
            ) : (
              <>
                <Typography color="error" gutterBottom>
                  Disconnected
                </Typography>
              </>
            )}
            {brandInfo?.advertising_access && (
              <Link
                color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                style={{cursor: 'pointer', marginLeft: '16px'}}
                onClick={confirmDisconnectAdvertisingAccess}
              >
                Revoke advertising access
              </Link>
            )}
          </div>
          <Button color="primary" variant="contained" onClick={() => grantAdvertisingAccess()}>
            Grant advertising access
          </Button>
        </div>
      )}

      {buttonOnly && (
        <Button size="small" color="primary" variant="contained" onClick={() => grantAdvertisingAccess()}>
          Grant advertising access
        </Button>
      )}

      <ModalButton
        openModal={displayConfirmDisconnectAdvertising}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplayConfirmDisconnectAdvertising(false)}
        modalTitle="Are you sure you want to disconnect the Advertising API access?"
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              disconnectAdvertising(closeModal);
              GAEvents?.('Advertising API', 'Disconnect', brandCode);
            }}
          >
            Agree
          </Button>
        )}
        buttonText="Disconnect"
      >
        {() => {
          return (
            <>
              This cannot be undone. After the API access is disconnected, Stonehenge will no longer pull the
              advertising information from this brand until the access is granted again.
            </>
          );
        }}
      </ModalButton>
    </>
  );
}

export {AuthorizeAdvertisingForFFPBrand};
