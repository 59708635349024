import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {useHistory, useLocation} from 'react-router-dom';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import LoginView from '../../components/LoginView/LoginView';
import LogoComponent from './components/LogoComponent';
import {CustomAlert} from './components/CustomAlert';
import {FormikHelpers, FormikValues} from 'formik';

function Login(): JSX.Element {
  document.title = 'Log in';
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
  }, [history]);

  const initialValues = {
    email: '',
    password: '',
  };

  function isSpecialEmail(this: Yup.StringSchema, message: string) {
    return this.test('testIsSpecialEmail', message, function (value: any, context: any) { // eslint-disable-line
      // Also review validateUser in stonehenge-api/src/auth/auth.service.ts to
      // see how a special email is used.
      const normalEmailRegex = /^[a-zA-Z0-9\+\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const specialEmailRegex = /^[a-zA-Z0-9\+\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\[[a-z0-9\+\.]+@[a-z]+\.[a-z]+\])/;
      return normalEmailRegex.test(value) || specialEmailRegex.test(value);
    });
  }
  Yup.addMethod(Yup.string, "isSpecialEmail", isSpecialEmail);

  const validationSchema = Yup.object().shape({
    email: Yup.string().isSpecialEmail('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  function onSubmit(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>) {

    localStorage.removeItem('selectedSellerId');
    localStorage.removeItem('selectedMarketplaceId');

    setSubmitting(true);
    alertService.clear();
    accountService
      .login(data.email, data.password)
      .then((res) => {
        const {from} = location.state || {from: {pathname: '/'}};
        setSubmitting(false);
        if (res.brand_type === 'ffp') {
          history.push('/amazon-listings-items');
        } else {
          history.push(from);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response && error.response.status === 401) {
          alertService.error('Email or password is incorrect');
        } else {
          alertService.error(error.message);
        }
      });
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Log in"
        forgotPassword="/forgot-password"
        fields={loginFields}
        formDescription="Log in to your account"
        logo={<LogoComponent />}
        action="login"
      />
      <CustomAlert />
    </>
  );
}

export {Login};
