import React, {memo} from 'react';
import {Grid, List, ListItem, ListItemIcon, Typography, Paper, ListItemText, useTheme} from '@mui/material';
import {Square, ArrowRightAlt} from '@mui/icons-material';
import {ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList} from 'recharts';
// utils
import getLabel from '../../../utils/getLabel';
// styles
import {sxStyles} from '../../Performance/Styles';
// components
import {ChartContainer} from './components';
import {getAmountDiff} from './pieChart';
// interfaces
import {
  DefaultInsightsDataInterface,
  PromotionCodesInterface,
  PromotionCodesItemInterface,
} from '../interfaces/insights';
import {SxStyleTypes} from '../../../interfaces/sxStyles/sxStyles.interface';

export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#d63f7e',
  '#FFBB28',
  '#FF8042',
  '#d63f3f',
  '#d354c2',
  '#106656',
  '#145894',
  '#f390b9',
  '#c7952b',
  '#ce4f0f',
  '#f06666',
  '#911080',
];

type ChartData = PromotionCodesItemInterface[] | DefaultInsightsDataInterface[];
interface PieChartProps {
  style?: SxStyleTypes;
  listStyle?: SxStyleTypes;
  className?: string;
  data?: DefaultInsightsDataInterface[] | PromotionCodesInterface[];
  loading: boolean;
  filters?: {
    [key: string]: string | number | string[] | null | undefined;
  };
  chartTitle?: string | JSX.Element;
  compare?: boolean;
  getItem?: (item: string) => void;
  subChart?: boolean;
  selectedItem?: string;
  actionButton?: {
    label: string;
    action: () => void;
  }[];
  doubleChart?: boolean;
  dataTitle?: string | JSX.Element;
  secondaryDataTitle?: string | JSX.Element;
  customList?: JSX.Element;
  clickable?: boolean;
  showTotal?: boolean;
  pieType?: string;
  chartData?: ChartData;
  showCount?: boolean;
  chartDescription?: string | JSX.Element;
  customListHeight?: number;
  format?: string;
}

export default memo(function PieCharts({
  style,
  listStyle,
  className,
  loading,
  filters,
  chartTitle,
  compare,
  data,
  getItem,
  subChart,
  selectedItem,
  actionButton,
  dataTitle,
  secondaryDataTitle,
  customList,
  clickable = true,
  chartData,
  chartDescription,
  customListHeight,
  format = '%',
}: PieChartProps): JSX.Element {
  const theme = useTheme();
  const pieData = chartData ? chartData : data;

  const chartList = (currentData: DefaultInsightsDataInterface[] | PromotionCodesInterface[]) => {
    return (
      <List sx={listStyle}>
        <>
          {(currentData as DefaultInsightsDataInterface[]).map((entry, index) => {
            return (
              <ListItem
                key={index}
                style={{
                  padding: '4px 0',
                }}
                className={`${selectedItem === entry.metric ? 'active' : ''}${clickable ? 'clickable' : ''}`}
                onClick={() => {
                  if (clickable) getItem?.(entry.metric);
                }}
              >
                {entry.color && (
                  <ListItemIcon>
                    <Square style={{borderRadius: '50px', color: entry.color}} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <>
                      <Typography component="span" variant="overline" style={{textTransform: 'capitalize'}}>
                        {getLabel(entry.metric)}
                      </Typography>
                      <Typography component="span" variant="overline">
                        {entry.value}
                        {format}
                      </Typography>
                    </>
                  }
                  secondary={
                    compare &&
                    entry.old_value && (
                      <>
                        <Typography component="span" variant="overline">
                          {entry.old_value?.toFixed(subChart ? 0 : 1)}
                          {format} <ArrowRightAlt />
                          {(+entry.value)?.toFixed(subChart ? 0 : 1)}
                          {format} ={''}
                          {getAmountDiff({
                            current: +entry.value,
                            old: entry?.old_value,
                            styles: sxStyles,
                            invertedColor: entry.invertedColor,
                          })}
                        </Typography>
                      </>
                    )
                  }
                />
              </ListItem>
            );
          })}
        </>
      </List>
    );
  };

  return (
    <ChartContainer chartTitle={chartTitle} loading={loading} filters={filters} actionButton={actionButton} data={data}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{minHeight: '400px'}}
        position="relative"
      >
        <>
          {data && data.length > 0 ? (
            <Grid container alignItems="center" style={{padding: '20px 0 50px 0'}}>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={style}
                className={className}
                alignItems="center"
                display="flex"
                justifyContent={'center'}
                flexDirection="column"
                style={{padding: '0 15px 0 0'}}
              >
                {pieData && pieData.length > 0 ? (
                  <>
                    <ResponsiveContainer
                      height={pieData.length > 12 ? pieData.length * 32 + 10 : 400}
                      className="customContainer"
                    >
                      <ComposedChart layout="vertical" data={pieData} stackOffset="expand">
                        <CartesianGrid stroke={theme.palette.mode === 'dark' ? '#a1a1a1' : '#8888'} />
                        <XAxis unit={format} orientation="top" type="number" />
                        <YAxis
                          width={5}
                          dataKey="metric"
                          type="category"
                          scale="band"
                          interval={0}
                          tick={() => {
                            return <></>;
                          }}
                        />
                        <Tooltip
                          wrapperStyle={{outline: 'none'}}
                          content={({active, payload}) => {
                            return active ? (
                              <Paper style={{padding: '10px'}}>
                                <Typography
                                  component="span"
                                  variant="overline"
                                  gutterBottom
                                  style={{textTransform: 'capitalize'}}
                                >
                                  {compare ? (
                                    <Typography component="span" variant="overline" sx={sxStyles('composedTooltip')}>
                                      <Typography component="span" variant="overline">
                                        {payload?.[0].payload.label || getLabel(payload?.[0].payload.metric)}
                                      </Typography>
                                      <Typography component="span" variant="overline">
                                        {payload?.[0].payload.old_value?.toFixed(subChart ? 0 : 1)}
                                        {format} <ArrowRightAlt />
                                        {payload?.[0].payload.value?.toFixed(subChart ? 0 : 1)}
                                        {format} ={' '}
                                        {getAmountDiff({
                                          current: payload?.[0].payload.value,
                                          old: payload?.[0].payload.old_value,
                                          styles: sxStyles,
                                          invertedColor: payload?.[0].payload.invertedColor,
                                        })}
                                      </Typography>
                                    </Typography>
                                  ) : (
                                    `${payload?.[0].payload.label || getLabel(payload?.[0].payload.metric)}: ${
                                      payload?.[0].payload.value
                                    }${format}`
                                  )}
                                </Typography>
                              </Paper>
                            ) : null;
                          }}
                        />
                        <Bar dataKey="value" barSize={30} fill="#3c8dc7">
                          <LabelList
                            position="insideRight"
                            valueAccessor={(item: {value: number; metric: string; label: string}) => {
                              return {value: item.value, metric: item.metric, label: item.label};
                            }}
                            content={({x, y, value}) => {
                              const newValue = value as unknown as {
                                value: number;
                                metric: string;
                                label: string;
                              };
                              return (
                                <text className="custom-bar" x={x && +x + 10} y={y && +y + 18}>{`${
                                  newValue?.label || newValue?.metric
                                } - ${newValue?.value}${format}`}</text>
                              );
                            }}
                          />
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                    <Typography variant="body1" style={{textAlign: 'center', paddingTop: '15px'}} color="secondary">
                      {chartDescription}
                    </Typography>
                  </>
                ) : (
                  <Typography component="h2" variant="h5" color="primary" gutterBottom>
                    No data available
                  </Typography>
                )}
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12} sx={sxStyles('dataContainer')}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                  display="flex"
                  style={{maxWidth: '500px'}}
                >
                  {dataTitle && (
                    <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                      {dataTitle}
                    </Typography>
                  )}
                </Grid>

                {data && (
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={sxStyles('listContainer')}>
                    {chartList((data as DefaultInsightsDataInterface[]).sort((a, b) => +b.value - +a.value))}
                  </Grid>
                )}
                {customList && (
                  <>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                      display="flex"
                      style={{maxWidth: '500px'}}
                    >
                      {secondaryDataTitle && (
                        <Typography component="span" variant="overline" color="secondary">
                          {secondaryDataTitle}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={sxStyles('listContainer')}
                      style={{maxHeight: customListHeight ? customListHeight : '400px'}}
                    >
                      {customList}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              No data available
            </Typography>
          )}
        </>
      </Grid>
    </ChartContainer>
  );
});
