import React, { useEffect, useState } from 'react';

import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from '../../components/CheckoutForm';
import MaxMarketplacesSelect from '../../components/MaxMarketplacesSelect';
import ChangeSubscriptionForm from '../../components/ChangeSubscriptionForm';
import CancelSubscriptionForm from '../../components/CancelSubscriptionForm';

import {
  Grid,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  // ListItemIcon,
  // Skeleton,
  // useTheme,
  Box,
} from '@mui/material';

// import {Equalizer, Star, Edit, Add} from '@mui/icons-material';

// component
import Button from '../../../../components/Button/Button';
// import ModalButton from '../../../../components/ModalButton/ModalButton';
import PageTitle from '../../../../components/PageTitle/PageTitle';
// import Images from '../../../../components/Images/Images';
// import UpdateExtraProductForm from '../../components/UpdateExtraProductForm';

import {CustomAlert} from '../../../Login/components/CustomAlert';
// styles
// import {sxStyles} from '../../Styles';
// utils
// import {BrandTypesAlias} from '../../../../utils/brand-types';
import {getArticle} from '../../../../utils/FFPBeacon';
// import {Helper} from '../../../../utils/helper';
import {Api, errorAlert} from '../../../../utils/api';

import {accountService} from '../../../../services/account.service';

import { BasicSubscriptionItem } from '../../../../interfaces/stripe/basic-subscription-item';
import { PlanWithPrices } from '../../../../interfaces/stripe/plan-with-prices';
import { alertService } from 'src/services/alert.service';

interface PaymentAndBillingProps {
  GAEvents?: (category: string, action: string, label?: string) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function PaymentAndBilling({GAEvents}: PaymentAndBillingProps): JSX.Element { // eslint-disable-line

  // const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState<string>('');

  const [subscriptionItems, setSubscriptionItems] = useState<BasicSubscriptionItem[]>([]);
  const [availablePlans, setAvailablePlans] = useState<PlanWithPrices[]>([]);
  const [currentPeriodEnd, setCurrentPeriod] = useState<number | null>(null);
  const [maxMarketplaces, setMaxMarketplaces] = useState<number>(1);
  const [currentForm, setCurrentForm] = useState<string>('');
  const [numberOfAuthorizedMarketplaces, setNumberOfAuthorizedMarketplaces] = useState<number>(0);
  const [planMaxMarketplaces, setPlanMaxMarketplaces] = useState<number>(0);
  const [currentPlanProductId, setCurrentPlanProductId] = useState<string>('');

  // const [openCouponForm, setOpenCouponForm] = useState(false);
  // const [couponCode, setCouponCode] = useState('');
  const [productId, setProductId] = useState<{  // eslint-disable-line
    user?: string | null;
    extra_account?: string | null;
  }>({user: null, extra_account: null});

  async function pullSubscriptionItems() {
    setLoading(true);
    try {
      const { data } = await Api.get(`/brands/${accountService.userValue.brand_code}/subscription-items`);
      const subscriptionItems: BasicSubscriptionItem[] = data.subscriptionItems;
      const availablePlans = data.availablePlans;
      setSubscriptionItems(subscriptionItems);
      setAvailablePlans(availablePlans);
      setCurrentPeriod(data.currentPeriodEnd);
      setMaxMarketplaces(data.maxMarketplaces);
      setNumberOfAuthorizedMarketplaces(data.authorizedMarketplaces.length);
      setPlanMaxMarketplaces(data.planMaxMarketplaces);
      setCurrentPlanProductId(data.currentPlanProductId);
    } catch (e) {
      errorAlert('Something went wrong', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    pullSubscriptionItems();
    accountService.refreshToken(); // Refresh the user's subscription status
  }, []);

  useEffect(() => {
    if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)) getArticle({});
  }, [loading]);

  const getFormatedDate = (timestamp: number) => {
    // Create a new Date object from the Unix timestamp (converted to milliseconds)
    const date = new Date(timestamp * 1000);

    // Extract components of the date
    const weekday = date.toLocaleString('en-US', { weekday: 'long' }); // e.g., "Friday"
    const month = date.toLocaleString('en-US', { month: 'long' }); // e.g., "October"
    const day = date.getDate(); // Day as a number (1-31)
    const year = date.getFullYear(); // Full year

    // Assemble the date string with a comma between the day and the year
    // Remove any leading zero from the day by not padding it in the first place
    const formattedDate = `${weekday}, ${month} ${day}, ${year}`;
    return formattedDate;
  };

  // const showCancelSubscriptionForm = async (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault();
  //   setCurrentForm('cancelSubscription');
  // }

  const showUpdatePaymentMethodForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentForm('updatePaymentMethod');
    try {
      const { data } = await Api.post('/stripe/create-setup-intent');
      setClientSecret(data.clientSecret);
    } catch (e) {
      alertService.error('Problem getting payment processing details for this customer. Please contact  us for help.');
    }
  };

  const showChangeSubscriptionForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentForm('changeSubscription');
  };

  const appearance: Appearance = {
    theme: 'stripe',
  };

  const options: StripeElementsOptions = {
    clientSecret,
    loader: 'always',
    appearance,
  };

  // console.log('accountService.userValue');
  // console.log(accountService.userValue);

  return (
    <Grid
      className='page-container'
      container
    >
      <PageTitle>Payment and Billing</PageTitle>
      <Grid
        className="subscription-plan-container"
        container
      >
        {accountService.userValue.active_brand_subscription ? (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card>
              {!loading && (
                <Box>
                  <Typography gutterBottom component="h4">
                    Your current subscription plan and add-ons.
                  </Typography>
                </Box>
              )}
              <List sx={{width: '100%', maxWidth: 360}} aria-label="contacts">
                {subscriptionItems.filter(item => item.quantity > 0).map((item, index) => {
                  const text = `${item.isExtraMarketplace ? item.quantity : ''} ${item.name}${item.quantity > 1 ? 's' : ''} ($${(item.unitAmount/100*item.quantity).toFixed(2)} per ${item.interval})`;
                  return (
                    <ListItem key={item.id || index} disablePadding>
                      <ListItemText primary={text} />
                    </ListItem>
                  );
                })}
              </List>
              <MaxMarketplacesSelect
                currentPlanProductId={currentPlanProductId}
                numberOfAuthorizedMarketplaces={numberOfAuthorizedMarketplaces}
                maxMarketplaces={maxMarketplaces}
                planMaxMarketplaces={planMaxMarketplaces}
                subscriptionItems={subscriptionItems}
                setSubscriptionItems={setSubscriptionItems}
              />

              {currentPeriodEnd !== null && (
                <p>Your next billing date is {getFormatedDate(currentPeriodEnd)}. </p>
              )}
            </Card>

            {currentForm === '' && (
              <div className="buttons">
                <Button
                  size="small"
                  onClick={showUpdatePaymentMethodForm}
                >
                  Update payment method
                </Button>
                <Button
                  size="small"
                  onClick={showChangeSubscriptionForm}
                >
                  Change subscription plan
                </Button>
              </div>
            )}
            {/* {currentForm === '' && (
              <p>
                We&apos;d love to hear from you if you have any questions or need help with your subscription but if for any reason you decide to leave us you can <a href="#" onClick={showCancelSubscriptionForm}>cancel your subscription</a> at any time.
              </p>
            )} */}
            {currentForm === 'updatePaymentMethod' && clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  currentForm={currentForm}
                  setCurrentForm={setCurrentForm}
                />
              </Elements>
            )}
            {currentForm === 'changeSubscription' && (
              <ChangeSubscriptionForm
                availablePlans={availablePlans}
                currentPriceId={subscriptionItems[0]?.price}
                setCurrentForm={setCurrentForm}
                setClientSecret={setClientSecret}
                subscriptionItems={subscriptionItems}
              />
            )}
            {currentForm === 'cancelSubscription' && (
              <CancelSubscriptionForm
                setCurrentForm={setCurrentForm}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <p>Your subscription is not active. Please choose a plan and set your payment method to reactivate it.</p>
            {currentForm === '' && (
              <ChangeSubscriptionForm
                availablePlans={availablePlans}
                currentPriceId={''}
                setClientSecret={setClientSecret}
                setCurrentForm={setCurrentForm}
                subscriptionItems={[]}
              />
            )}
            {currentForm === 'createPaymentMethod' && clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  currentForm={currentForm}
                  setCurrentForm={setCurrentForm}
                />
              </Elements>
            )}
          </Grid>
        )}

      </Grid>

      <Grid item lg={12}>
        <CustomAlert id="default-alert" />
      </Grid>

    </Grid>

  );
}
