import React, {useEffect, useState, useContext, useCallback} from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial,
  Typography,
  Input,
  AutocompleteRenderInputParams,
  LinearProgress,
  Grid,
  Box,
} from '@mui/material';
import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import {Autocomplete, CheckboxWithLabel, Select, TextField} from 'formik-mui';
import * as Yup from 'yup';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import Spacer from '../../../../components/Spacer/Spacer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {brandFields} from '../../utils/BrandProperties';
import {Api, errorAlert} from '../../../../utils/api';
import {getFields, BrandFieldsInterface} from '../utils/getFields';
// services
import {alertService} from '../../../../services/alert.service';
// styles
import {sxStyles} from '../../Styles';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';
import {AxiosError} from 'axios';
import {BrandInterface} from '../../../../interfaces/brands/brand';
import {
  AccountManagersInterface,
  BrandsInterface,
  SelectedBrandTypes,
} from '../../../../context/AppContext/interfaces/interfaces';

const CustomFileUpload = (props: {
  label: string;
  disabled: boolean;
  form: {
    isSubmitting: boolean;
    setFieldValue: (name: string, value: File) => void;
  };
  field: {
    name: string;
  };
  onChange: (value: string) => void;
  uploadedFile: string;
}) => (
  <FormControl>
    {props.label && <InputLabel shrink>{props.label}</InputLabel>}
    <InputLabel
      shrink
      htmlFor={props.label}
      style={{marginTop: '15px', display: 'flex', alignItems: 'center', fontSize: '20px', color: 'black'}}
    >
      <img
        src={`${process.env.PUBLIC_URL + '/uploadFile.svg'}`}
        alt=""
        style={{
          padding: '5px 20px',
          height: 40,
          background: 'white',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      />
      {props.uploadedFile && <span style={{paddingLeft: 10}}>{props.uploadedFile}</span>}
    </InputLabel>
    <Input
      style={{display: 'none'}}
      inputProps={{
        id: props.label,
        type: 'file',
        disabled: props.disabled || props.form.isSubmitting,
        name: props.field.name,
        onChange: (event) => {
          const file = (event.currentTarget as HTMLInputElement)?.files?.[0];
          if (file) {
            props.form.setFieldValue(props.field.name, file);
            props.onChange(file.name);
          }
        },
      }}
    />
  </FormControl>
);

interface InputOptionsType {
  id: string;
  name: string;
  email: string;
  brand_code: string;
}

const validationSchema = Yup.object().shape({
  brand_code: Yup.string().required('Brand code is required'),
  name: Yup.string().required('Name is required'),
  status_id: Yup.number().typeError('Status is required').required('Status is required'),
  seller_central_account_location: Yup.object()
    .nullable()
    .when('type_id', {
      is: 2,
      then: Yup.object()
        .typeError('The Seller Central account location is required for Max Results brands')
        .required('Seller central account location is required for Max Results brands'),
    }),
});

function UpdateBrand(): JSX.Element {
  const [values, setValues] = useState<BrandInterface | null>(null);
  const {id} = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const [logoImg, setLogoImg] = useState('');
  const [productImg, setProductImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldTypeCheckbox, setFieldTypeCheckbox] = useState<BrandFieldsInterface[]>([]);
  const [fieldTypeSelect, setFieldTypeSelect] = useState<BrandFieldsInterface[]>([]);
  const [fieldTypeText, setFieldTypeText] = useState<BrandFieldsInterface[]>([]);
  const [fieldTypeDate, setFieldTypeDate] = useState<BrandFieldsInterface[]>([]);
  const [googleDriveFolders, setGoogleDriveFolders] = useState([]);
  const [lastSuccess, setLastSuccess] = useState([]);

  const {accountManagers, getBrandUtils, brandUtils, brands} = useContext(AppContext);

  const pullAllData = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await Api.get(`brands/${id}`);
      const brandInfo = data;
      setValues(brandInfo);
      setGoogleDriveFolders(brandInfo.google_drive_folders || []);
      setLastSuccess(brandInfo.last_success || []);
    } catch (e) {
      errorAlert('Error while getting brand info', e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  async function uploadLogo(brandCode: string, file: File) {
    const formData = new FormData();
    formData.append('logo', file);
    return await Api.put(`brands/${brandCode}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async function uploadProductImage(brandCode: string, file: File) {
    const formData = new FormData();
    formData.append('product_image', file);
    return await Api.put(`brands/${brandCode}/product-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async function onSubmit(
    data: BrandInterface,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) {
    alertService.clear();
    const fields = {...data, status_id: data.status_id};

    delete fields.logo;
    delete fields.product_image;
    delete fields.advertising_profiles;

    fields.google_drive_folders = googleDriveFolders;
    fields.last_success = lastSuccess;

    try {
      await Api.put(`brands/${id}`, fields);

      if (data.logo && typeof data.logo === 'object') {
        try {
          await uploadLogo(data.brand_code, data.logo);
        } catch (e) {
          const error = e as AxiosError;
          errorAlert(error?.response?.data?.message || error?.message, e);
        }
      }

      if (data.product_image && typeof data.product_image === 'object') {
        try {
          await uploadProductImage(data.brand_code, data.product_image);
        } catch (e) {
          const error = e as AxiosError;
          errorAlert(error?.response?.data?.message || error?.message, e);
        }
      }

      alertService.success('Brand updated successfully');
      history.push(`/brands/${data.brand_code}/detail`);
    } catch (e) {
      errorAlert('Error while updating brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  const onChangeLogo = (logo: string) => {
    setLogoImg(logo);
  };

  const onChangeProduct = (img: string) => {
    setProductImg(img);
  };

  const getFieldOptions = (field: string): InputOptionsType[] => {
    let optionsArray: SelectedBrandTypes = [];

    switch (field) {
      case 'role':
        optionsArray = brandUtils.brandRoles;
        break;
      case 'manager':
        optionsArray = accountManagers;
        break;
      case 'type':
        optionsArray = brandUtils.brandTypes;
        break;
      case 'status':
        optionsArray = brandUtils.brandStatuses;
        break;
      case 'brands':
        optionsArray = brands;
        break;
      case 'agreement':
        optionsArray = brandUtils.agreementTypes;
        break;
      case 'ordering':
        optionsArray = brandUtils.orderMethods;
        break;
      default:
        optionsArray = [];
        break;
    }
    return optionsArray as InputOptionsType[];
  };

  useEffect(() => {
    if (brandFields) {
      setFieldTypeCheckbox(getFields('boolean'));
      setFieldTypeText(getFields('text'));
      setFieldTypeSelect(getFields('select'));
      setFieldTypeDate(getFields('date'));
    }
  }, []);

  useEffect(() => {
    getBrandUtils?.();
  }, [getBrandUtils]);

  useEffect(() => {
    pullAllData();
  }, [pullAllData]);

  return (
    <TableContainer className="sub-page">
      {loading || !values ? (
        <Grid item lg={12} style={{paddingBottom: 30}}>
          <Typography>Loading Brand Information ...</Typography>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent="center" style={{paddingTop: 30}}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" color="primary" gutterBottom>
              Update brand
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {values ? (
              <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({errors, touched, isSubmitting, values: formValues, setFieldValue}) => {
                  return (
                    <Form>
                      <Grid container>
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                          <Grid item lg={12}>
                            <Field
                              sx={sxStyles('inputField')}
                              name="parent_brand_code"
                              size="small"
                              component={Autocomplete}
                              options={brands}
                              value={brands?.find((brand) => brand?.brand_code === formValues?.parent_brand_code)}
                              onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                                value: {
                                  brand_code: string;
                                },
                              ) => {
                                setFieldValue('parent_brand_code', value?.brand_code ?? '');
                              }}
                              getOptionLabel={(option: BrandsInterface) => `${option.brand_code} - ${option.name}`}
                              renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextFieldMaterial
                                  {...params}
                                  error={touched['parent_brand_code'] && !!errors['parent_brand_code']}
                                  helperText={touched['parent_brand_code'] && errors['parent_brand_code']}
                                  label="Parent Brand"
                                  variant={'outlined'}
                                />
                              )}
                            />
                            <Field
                              sx={sxStyles('inputField')}
                              component={TextField}
                              variant="outlined"
                              name="brand_code"
                              label="Brand code"
                              type="text"
                              size="small"
                              disabled={true}
                            />
                            <div>
                              <Field
                                sx={sxStyles('inputField')}
                                component={TextField}
                                variant="outlined"
                                name="name"
                                label="Brand name"
                                type="text"
                                size="small"
                              />
                            </div>
                            <FormControl
                              size="small"
                              error={touched['status_id'] && !!errors['status_id']}
                              sx={[sxStyles('inputField'), sxStyles('inputFieldSelect')]}
                              variant={'outlined'}
                              placeholder="Status"
                            >
                              <InputLabel id="status-label">Status</InputLabel>
                              <Field
                                size="small"
                                component={Select}
                                name="status_id"
                                label="Status"
                                labelId="status-label"
                              >
                                {brandUtils.brandStatuses?.map((brandStatus) => (
                                  <MenuItem key={`status_${brandStatus.id}`} value={brandStatus.id}>
                                    {brandStatus.name}
                                  </MenuItem>
                                ))}
                              </Field>
                              <FormHelperText>
                                {touched['status_id'] && !!errors['status_id'] ? errors['status_id'] : ''}
                              </FormHelperText>
                            </FormControl>
                            <FormControl
                              size="small"
                              error={touched['type_id'] && !!errors['type_id']}
                              sx={[sxStyles('inputField'), sxStyles('inputFieldSelect')]}
                              variant={'outlined'}
                            >
                              <InputLabel id="type-label">Brand Type</InputLabel>
                              <Field
                                size="small"
                                component={Select}
                                name="type_id"
                                label="Max Control/Max Result"
                                labelId="type-label"
                              >
                                {brandUtils.brandTypes?.map((brandType) => (
                                  <MenuItem key={`type_${brandType.id}`} value={brandType.id}>
                                    {brandType.name}
                                  </MenuItem>
                                ))}
                              </Field>
                              <FormHelperText>
                                {touched['type_id'] && !!errors['type_id'] ? errors['type_id'] : ''}
                              </FormHelperText>
                            </FormControl>
                            {formValues.type_id === 2 && (
                              <Field
                                sx={sxStyles('inputField')}
                                size="small"
                                name="seller_central_account_location"
                                component={Autocomplete}
                                value={formValues.seller_central_account_location}
                                options={brandUtils.brandMaxControl.map((item) => item?.brand_code)}
                                getOptionLabel={(option: string) => {
                                  const label = brandUtils.brandMaxControl.find((item) => item?.brand_code === option);
                                  return `${option} ${label ? `- ${label.name}` : ''}`;
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                  <TextFieldMaterial
                                    {...params}
                                    error={
                                      touched['seller_central_account_location'] &&
                                      !!errors['seller_central_account_location']
                                    }
                                    helperText={
                                      touched['seller_central_account_location'] &&
                                      errors['seller_central_account_location']
                                    }
                                    label="Seller Central Account Location"
                                    variant={'outlined'}
                                  />
                                )}
                              />
                            )}
                            <Field
                              size="small"
                              sx={sxStyles('inputField')}
                              name="account_manager_id"
                              component={Autocomplete}
                              options={accountManagers}
                              value={accountManagers.find((x) => x.id === formValues.account_manager_id)}
                              onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                                value: {
                                  id: number;
                                },
                              ) => {
                                setFieldValue('account_manager_id', value.id);
                              }}
                              getOptionLabel={(option: AccountManagersInterface) => option.email}
                              renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextFieldMaterial
                                  {...params}
                                  error={touched['account_manager_id'] && !!errors['account_manager_id']}
                                  helperText={touched['account_manager_id'] && errors['account_manager_id']}
                                  label="Account Manager"
                                  variant={'outlined'}
                                />
                              )}
                            />
                          </Grid>
                          <Grid container style={{padding: '20px 0'}}>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                              <Box sx={sxStyles('inputImage')}>
                                <Field
                                  sx={sxStyles('inputField')}
                                  uploadedFile={logoImg}
                                  onChange={onChangeLogo}
                                  component={CustomFileUpload}
                                  variant="outlined"
                                  name="logo"
                                  label="Logo"
                                  size="small"
                                />
                                <div style={{height: values.logo ? 55 : 10}} />
                                <div style={{height: 150, alignItems: 'center', display: 'flex'}}>
                                  {values.logo ? (
                                    <img src={values.logo} alt="logo" width={200} />
                                  ) : (
                                    <p>No Logo Image</p>
                                  )}
                                </div>
                              </Box>
                              <Box sx={sxStyles('inputImage')}>
                                <Field
                                  sx={sxStyles('inputField')}
                                  uploadedFile={productImg}
                                  onChange={onChangeProduct}
                                  component={CustomFileUpload}
                                  variant="outlined"
                                  name="product_image"
                                  label="Product Image"
                                  size="small"
                                />
                                <div style={{height: values.product_image ? 55 : 10}} />
                                <div style={{height: 150, alignItems: 'center', display: 'flex'}}>
                                  {values.product_image ? (
                                    <img src={values.product_image} alt="product" width={200} />
                                  ) : (
                                    <p>No Product Image</p>
                                  )}
                                </div>
                              </Box>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                              {fieldTypeCheckbox?.map((field) => {
                                const fieldName = field.name as keyof typeof formValues;
                                return (
                                  <div key={field.name}>
                                    <Field
                                      value={formValues[fieldName]}
                                      component={CheckboxWithLabel}
                                      name={field.name}
                                      Label={{label: field.label}}
                                      checked={formValues[fieldName]}
                                      label={field.label}
                                    />
                                  </div>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                        <div style={{height: 10}} />

                        <Grid item lg={5} md={5} sm={12} xs={12}>
                          {formValues.type_id === 1 && (
                            <>
                              <div>
                                <Field
                                  sx={sxStyles('inputField')}
                                  component={TextField}
                                  variant="outlined"
                                  name="seller_id"
                                  label="Seller ID"
                                  type="text"
                                  size="small"
                                />
                              </div>
                              <div>
                                <Field
                                  sx={sxStyles('inputField')}
                                  component={TextField}
                                  variant="outlined"
                                  name="mws_auth_token"
                                  label="MWS Auth Token"
                                  type="text"
                                  size="small"
                                />
                              </div>
                            </>
                          )}
                        </Grid>
                        <Spacer height={10} />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          {fieldTypeDate?.map((field) => {
                            return (
                              !field.mode && (
                                <Grid
                                  key={field.name}
                                  item
                                  lg={4}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  sx={sxStyles('inputFieldDate')}
                                >
                                  <DatePicker
                                    inputFormat="YYYY/MM/DD"
                                    label={field.label}
                                    value={formValues[field.name as keyof typeof formValues]}
                                    onChange={(newValue) => {
                                      setFieldValue(field.name, moment(newValue as Date).format('YYYY-MM-DD'));
                                    }}
                                    renderInput={(params) => (
                                      <TextFieldMaterial size="small" name={field.name} {...params} />
                                    )}
                                  />
                                </Grid>
                              )
                            );
                          })}
                        </LocalizationProvider>
                        {fieldTypeSelect?.map((field) => {
                          const fieldName = field.name as keyof typeof formValues;
                          return (
                            !field.priority &&
                            field.options && (
                              <Grid key={field.name} item lg={4} md={4} sm={6} xs={12}>
                                <Field
                                  size="small"
                                  sx={sxStyles('inputField')}
                                  name={field.name}
                                  component={Autocomplete}
                                  options={getFieldOptions(field.options)}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                                    value: InputOptionsType,
                                  ) => {
                                    setFieldValue(field.name, value?.id ? value.id : null);
                                  }}
                                  value={getFieldOptions(field.options)?.find(
                                    (x) => x.id?.toString() === formValues[fieldName]?.toString(),
                                  )}
                                  getOptionLabel={(option: {email?: string; name?: string}) =>
                                    option.email || option.name
                                  }
                                  renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextFieldMaterial
                                      {...params}
                                      error={touched[fieldName] && !!errors[fieldName]}
                                      helperText={touched[fieldName] && errors[fieldName]}
                                      label={field.label}
                                      variant={'outlined'}
                                    />
                                  )}
                                />
                              </Grid>
                            )
                          );
                        })}
                        {fieldTypeText?.map((field) => {
                          return (
                            !field.priority &&
                            !field.mode && (
                              <Grid key={field.name} item lg={4} md={4} sm={6} xs={12}>
                                <Field
                                  sx={sxStyles('inputField')}
                                  component={TextField}
                                  variant="outlined"
                                  name={field.name}
                                  label={field.label}
                                  type={field.variant ? field.variant : 'text'}
                                  size="small"
                                />
                              </Grid>
                            )
                          );
                        })}
                        <Grid
                          item
                          lg={12}
                          md={4}
                          sm={6}
                          xs={12}
                          style={{display: 'flex', justifyContent: 'center', padding: '20px 0'}}
                        >
                          <Button width="100px" disabled={isSubmitting} type="submit">
                            Save
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      )}
    </TableContainer>
  );
}

export {UpdateBrand};
