import React, {useEffect, useState, useContext, useCallback} from 'react';

import {
  FormControl,
  MenuItem,
  TextField as TextFieldMaterial,
  Typography,
  AutocompleteRenderInputParams,
  Grid,
  Fade,
  Stack,
} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {TextField, Select, CheckboxWithLabel, Autocomplete} from 'formik-mui';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
// services
import {alertService} from '../../../../services/alert.service';
import {accountService} from '../../../../services/account.service';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Spacer from '../../../../components/Spacer/Spacer';
// utils
import {Role} from '../../../../utils/role';
import {withTracker} from '../../../../utils/withTracker';
import {BrandTypesAlias} from '../../../../utils/brand-types';
import {Api, errorAlert} from '../../../../utils/api';
import {GetStyles} from '../../../../utils/getStyles';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';
import {getSubBrands} from '../../../../utils/getSubBrands';
// interfaces
import {SxStyleTypes} from '../../../../interfaces/sxStyles/sxStyles.interface';
import {BrandInterface} from '../../../../interfaces/brands/brand';
import {LoadingButton} from '@mui/lab';
import {SaveOutlined} from '@mui/icons-material';
import ChangePassword from './ChangePassword';

const styles = (): {[key: string]: SxStyleTypes} => ({
  inputField: {
    margin: '0.5em 0',
    width: '100%',
  },
  select: {
    margin: '0.5em 0',
    width: '100%',
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      top: '-8px',
    },
  },
  selected: {
    '& > .MuiInputLabel-formControl': {
      opacity: 0,
    },
  },
});

type Values = {
  id?: string;
  email?: string;
  name?: string;
  department_id?: number;
  think_tank_id?: number;
  brand_code?: string | BrandInterface | null | undefined | {brand_code: string};
  role?: string;
  brand_admin?: boolean;
  create_users?: boolean;
  brand_name?: string;
  think_tank_name?: string | null;
  department_name?: string | null;
  inactive?: boolean;
  viewed_articles?: string[];
  delete_listings?: boolean;
  sub_brand_code?: BrandInterface[];
};

type Entity = {
  id: number;
  name: string;
};
type Option = {brand_code: string; name: string};

type ThinkTank = Entity;
type Department = Entity;

function UpdateUser(): JSX.Element {
  const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];
  const history = useHistory();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [thinkTanks, setThinkTanks] = useState<ThinkTank[]>([]);
  const [validation, setValidation] = useState(getValidationSchema(Role.User));
  const [role, setRole] = useState(Role.User);
  const {id} = useParams<Record<string, string | undefined>>();
  const [values, setValues] = useState<Values | null>(null);
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [subBrandsLoading, setSubBrandsLoading] = useState(false);
  

  const {brands, appLoading} = useContext(AppContext);

  async function pullSubBrands(brandCode: string, verify?: boolean) {
    setSubBrandsLoading(true);
    let newSubBrands = [] as BrandInterface[];
    const data = await getSubBrands([brandCode], setSubBrandsLoading);
    if (data) {
      newSubBrands = data;
    }
    if (verify && accountService.userValue.sub_brand_codes?.length > 0) {
      setSubBrands(
        newSubBrands.filter((subBrand) => accountService.userValue.sub_brand_codes.includes(subBrand.brand_code)),
      );
    } else {
      setSubBrands(newSubBrands);
    }
  }

  async function getDepartments() {
    try {
      const {data} = await Api.get('departments');
      setDepartments(data);
    } catch (e) {
      errorAlert('Unable to get departments!', e);
    }
  }

  async function getThinkTanks() {
    try {
      const {data} = await Api.get('think-tanks');
      setThinkTanks(data);
    } catch (e) {
      errorAlert('Unable to get think tanks!', e);
    }
  }

  const getUser = useCallback(async () => {
    try {
      const {data} = await Api.get(`users/user/${id}`);

      setValues({
        email: data.email,
        name: data.name,
        role: data.role,
        brand_code: data.brand_code || null,
        department_id: data.department_id,
        think_tank_id: data.think_tank_id,
        brand_admin: data.brand_admin ? data.brand_admin : false,
        create_users: data.create_users ? data.create_users : false,
        inactive: data.inactive,
        delete_listings: data.delete_listings ? data.delete_listings : false,
        sub_brand_code: data.sub_brands && data.sub_brands.length > 0 ? data.sub_brands : [],
      });

      setRole(data.role as string);
      setValidation(getValidationSchema(data.role as string));

      if (data.brand_code) {
        pullSubBrands(data.brand_code);
      }
    } catch (e) {
      errorAlert('Unable to get user!', e);
    }
  }, [id]);



  useEffect(() => {
    getUser();
    if ([Role.Admin, Role.Manager].includes(accountService.userValue.role)) {
      getDepartments();
      getThinkTanks();
    }
  }, [getUser]);

  useEffect(() => {
    if (brands.length > 0) {
      setValues((prevState) => ({
        ...prevState,
        brand_code: prevState?.brand_code ? brands.find((x) => x.brand_code === prevState.brand_code) : null,
      }));
    }
  }, [brands]);

  async function onSubmit(data: Values, {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}) {
    alertService.clear();

    const body = {
      ...data,
      brand_code:
        (data.brand_code as BrandInterface)?.brand_code || (data.brand_code as {brand_code: string}).brand_code || null,
      sub_brand_code: data.sub_brand_code ? data.sub_brand_code.map((subBrand) => subBrand.brand_code) : [],
      department_id: data.department_id ? +data.department_id : null,
      think_tank_id: data.think_tank_id ? +data.think_tank_id : null,
      role: data.role,
    };

    if (accountService.userValue.brand_type === BrandTypesAlias.ffp) {
      body.brand_code = accountService.userValue.brand_code;
    }

    try {
      await Api.put(`users/${id}`, body);
      history.push(['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) ? '/account/users' : '/users');
      alertService.success('User updated successfully');
    } catch (e) {
      errorAlert('Unable to update user!', e);
    } finally {
      setSubmitting(false);
    }
  }

  const onRoleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setRole(event.target.value as string);
    setValidation(getValidationSchema(event.target.value as string));
  };

  function getValidationSchema(roleSelected: string) {
    switch (roleSelected) {
      case Role.Admin:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          role: Yup.string().required('Role is required'),
        });
      case Role.User:
      case Role.Manager:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          role: Yup.string().required('Role is required'),
          department_id: Yup.number().typeError('Department is required').required('Brand is required'),
          think_tank_id: Yup.number().typeError('Think tank is required').required('Brand is required'),
        });
      case Role.BrandUser:
        return Yup.object().shape({
          email: Yup.string().email('Email is invalid').required('Email is required'),
          name: Yup.string().required('Name is required'),
          brand_code:
            accountService.userValue.brand_type === BrandTypesAlias.ffp
              ? Yup.object({}).nullable()
              : Yup.object({
                  name: Yup.string().typeError('Brand is required').required('Brand is required'),
                  brand_code: Yup.string().typeError('Brand is required').required('Brand is required'),
                })
                  .typeError('Brand is required')
                  .required('Brand is required'),
        });
    }
  }



  return (
    <>
      <TableContainer container spacing={1} justifyContent="space-evenly" style={{paddingTop: 60}}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Typography component="h2" variant="h4" color="primary" gutterBottom>
            Update user
          </Typography>
          {values && !appLoading ? (
            <Formik initialValues={values} validationSchema={validation} onSubmit={onSubmit} validateOnChange={true}>
              {({errors, touched, isSubmitting, setFieldValue, values}) => {
                return (
                  <Form>
                    <div>
                      <div>
                        <Field
                          sx={sxStyles('inputField')}
                          component={TextField}
                          variant="outlined"
                          name="email"
                          label="Email"
                          type="text"
                          size="small"
                          disabled={true}
                        />
                      </div>
                      <div>
                        <Field
                          sx={sxStyles('inputField')}
                          component={TextField}
                          variant="outlined"
                          name="name"
                          label="Name"
                          type="text"
                          size="small"
                        />
                      </div>
                      <Fade
                        in={[Role.User, Role.Manager, Role.Admin].includes(accountService.userValue.role)}
                        timeout={400}
                        unmountOnExit={true}
                      >
                        <div>
                          <FormControl sx={sxStyles('select')} variant={'outlined'}>
                            <Field
                              value={role}
                              onChange={onRoleChange}
                              component={Select}
                              name="role"
                              label="Role"
                              labelId="role-label"
                              defaultValue={Role.User}
                              size="small"
                            >
                              <MenuItem value={Role.User}>User</MenuItem>
                              <MenuItem value={Role.Admin}>Admin</MenuItem>
                              <MenuItem value={Role.BrandUser}>Brand manager</MenuItem>
                              <MenuItem value={Role.Manager}>Manager</MenuItem>
                              <MenuItem value={Role.StandardAgencyUser}>Standard Agency User</MenuItem>
                            </Field>
                          </FormControl>
                        </div>
                      </Fade>
                      <Fade in={[Role.User, Role.Manager].includes(role)} timeout={400} unmountOnExit={true}>
                        <div>
                          <FormControl
                            error={touched['department_id'] && !!errors['department_id']}
                            sx={sxStyles('select')}
                            variant={'outlined'}
                          >
                            <Field
                              component={Select}
                              name="department_id"
                              label="Department"
                              labelId="department-label"
                              size="small"
                            >
                              {departments.map((department) => (
                                <MenuItem key={department.id} value={department.id}>
                                  {department.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                          <FormControl
                            error={touched['think_tank_id'] && !!errors['think_tank_id']}
                            sx={sxStyles('select')}
                            variant={'outlined'}
                          >
                            <Field
                              size="small"
                              component={Select}
                              name="think_tank_id"
                              label="Think tank"
                              labelId="think-tank-label"
                            >
                              {thinkTanks.map((thinkTank) => (
                                <MenuItem key={thinkTank.id} value={thinkTank.id}>
                                  {thinkTank.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </div>
                      </Fade>
                      <Fade
                        in={
                          [Role.BrandUser].includes(role) &&
                          !['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)
                        }
                        timeout={400}
                        unmountOnExit={true}
                      >
                        <div>
                          <Field
                            sx={sxStyles('select')}
                            name="brand_code"
                            component={Autocomplete}
                            options={brands}
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: BrandInterface) => {
                              setFieldValue('brand_code', value);
                              if (value) {
                                pullSubBrands(value.brand_code, true);
                              } else {
                                setFieldValue('sub_brand_code', []);
                                setSubBrands([]);
                              }
                            }}
                            getOptionLabel={(option: Option) => `${option?.brand_code} - ${option?.name}`}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                              <TextFieldMaterial
                                {...params}
                                error={touched['brand_code'] && !!errors['brand_code']}
                                helperText={touched['brand_code'] && errors['brand_code']}
                                label="Brands"
                                variant={'outlined'}
                              />
                            )}
                          />
                          <Field
                            multiple
                            disabled={subBrandsLoading}
                            sx={sxStyles('select')}
                            name="sub_brand_code"
                            size="small"
                            component={Autocomplete}
                            options={subBrands}
                            getOptionLabel={(option: Option) => option.name}
                            isOptionEqualToValue={(option: Option, value: BrandInterface) =>
                              option.brand_code === value.brand_code
                            }
                            renderInput={(params: AutocompleteRenderInputParams) => (
                              <TextFieldMaterial
                                {...params}
                                error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                                helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                                label="Sub brands"
                                variant={'outlined'}
                              />
                            )}
                          />
                          <Fade
                            in={accountService.userValue.brand_type === BrandTypesAlias.ffp}
                            unmountOnExit={true}
                            timeout={400}
                          >
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="brand_admin"
                              Label={{label: 'Brand admin'}}
                            />
                          </Fade>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="create_users"
                            Label={{label: 'Can create users'}}
                          />
                        </div>
                      </Fade>

                      <Fade
                        in={
                          [Role.BrandUser].includes(role) &&
                          ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
                          !values.brand_admin
                        }
                        timeout={400}
                        unmountOnExit={true}
                      >
                        <div>
                          <Field
                            multiple
                            sx={sxStyles('select')}
                            name="sub_brand_code"
                            size="small"
                            component={Autocomplete}
                            options={subBrands}
                            getOptionLabel={(option: Option) => option.name}
                            isOptionEqualToValue={(option: Option, value: BrandInterface) =>
                              option.brand_code === value.brand_code
                            }
                            renderInput={(params: AutocompleteRenderInputParams) => (
                              <TextFieldMaterial
                                {...params}
                                error={touched['sub_brand_code'] && !!errors['sub_brand_code']}
                                helperText={touched['sub_brand_code'] && errors['sub_brand_code']}
                                label="Sub brands"
                                variant={'outlined'}
                                value={values.sub_brand_code}
                              />
                            )}
                          />
                        </div>
                      </Fade>

                      <div>
                        {![Role.BrandUser].includes(role) && (
                          <>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="inactive"
                              Label={{label: 'InActive'}}
                            />
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="delete_listings"
                              Label={{label: 'Delete Listings'}}
                            />
                          </>
                        )}
                      </div>
                      <Spacer height={15} />
                      <Stack display={'flex'} justifyContent={'space-between'} direction={'row'}>
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          loadingPosition="start"
                          startIcon={<SaveOutlined />}
                          onClick={() => {
                            withTracker('User', 'Update user', values.name);
                          }}
                        >
                          Save
                        </LoadingButton>
                      </Stack>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            ''
          )}
        </Grid>
        {id === accountService.userValue.userId && <Grid item lg={4} md={6} sm={6} xs={12}>
          <ChangePassword/>
        </Grid>}
      </TableContainer>
      <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
    </>
  );
}

export {UpdateUser};
