import {AxiosResponse} from 'axios';
import {Api} from '../../../utils/api';
import {FunctionProps} from '../interfaces/insights';

const baseUrl = '/performance';

async function InsightsApiCall({
  url,
  data,
  sku,
}: {
  url: string;
  data: FunctionProps;
  sku?: boolean;
}): Promise<AxiosResponse> {
  Object.entries(data).forEach(([key, value]) => {
    if (!value || value === null || (Array.isArray(value) && value.length === 0)) {
      delete data[key as keyof typeof data];
    }
    if (sku) {
      delete data['brands'];
    }
  });

  const newQuery = Object.keys(data)
    .map((key) => `${key}=${data[key as keyof typeof data]}`)
    .join('&');

  const queryString = baseUrl + url + '?' + newQuery;

  const response = await Api.get(queryString);
  return response;
}

export {InsightsApiCall};
