import React, {useEffect, useState, useCallback} from 'react';
import {IconButton, Tooltip, Typography, Theme, Button, Grid, Box} from '@mui/material';
import {AxiosError} from 'axios';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {useHistory, useParams} from 'react-router-dom';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {Delete} from '@mui/icons-material';
import moment from 'moment';
// components
import {CustomAlert} from '../../../../../Login/components/CustomAlert';
import TableContainer from '../../../../../../components/TableContainer/TableContainer';
// services
import {alertService} from '../../../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../../../utils/api';
import {GetStyles} from '../../../../../../utils/getStyles';
// interfaces
import {BrandInterface} from '../../../../../../interfaces/brands/brand';
import {ReportDataInterface, SuppressionsInterface} from '../../interfaces/brand-reports.interfaces';

const sxStyles = (theme: Theme) => ({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  updateBrandForm: {
    fontWeight: 'normal',
  },
  noteLabel: {
    fontSize: 14,
    padding: '1rem .75rem',
    paddingBottom: '0.2rem',
  },
  panel: {
    padding: theme.spacing(1),
    '& .ck-editor__editable': {
      minHeight: 180,
    },
  },
});

function UpdateBrandReport(): JSX.Element {
  const columns: GridColDef[] = [
    {
      field: 'asin',
      headerName: 'ASIN',
      width: 120,
      type: 'filterString',
    },
    {
      field: 'productName',
      headerName: 'Product Name',
      flex: 0.5,
      type: 'filterString',
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.productName}>
          <Typography>{params.row.productName}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'marketplace',
      headerName: 'MarketPlace',
      width: 100,
      type: 'filterString',
    },
    {
      field: 'createdAt',
      headerName: 'Suppressed',
      width: 200,
      type: 'filterString',
      renderCell: (params: GridCellParams) => {
        return <Typography>{moment(new Date(params.row.createdAt)).format('YYYY-MM-DD HH:mm')}</Typography>;
      },
    },
    {
      field: 'diffHours',
      headerName: 'Lifted in..(H)',
      width: 100,
      type: 'filterString',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={'Ignore this suppression'}>
            <IconButton onClick={() => removeSuppresion(params.row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const styles = (key: string) => GetStyles(sxStyles)[key];
  const [values, setValues] = useState<ReportDataInterface | null>(null);
  const {id} = useParams<Record<string, string>>();
  const {month} = useParams<Record<string, string>>();
  const history = useHistory();
  const [salesNote, setSalesNote] = useState('');
  const [adsNote, setAdsNote] = useState('');
  const [alertsOnMonth, setAlertsOnMonth] = useState<SuppressionsInterface[]>([]);

  const getReports = useCallback(async () => {
    try {
      const {data} = await Api.get(`brandreports/${id}/${month}`);
      const brandInfo: BrandInterface = data.brandInfo || null;
      const notes = data.notes || null;

      setAlertsOnMonth(data.suppressions);
      setValues({
        brand_code: brandInfo?.brand_code || '',
        sales_note: notes?.sales_note || '',
        ad_note: notes?.ad_note || '',
        month: month,
        removedSuppressionIds: brandInfo?.removed_suppression_ids || [],
      });
    } catch (e) {
      errorAlert('Unable to get brands', e);
    }
  }, [id, month]);

  async function onSubmit(
    data: ReportDataInterface,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) {
    alertService.clear();
    const fields = {
      ...data,
    };
    fields.sales_note = salesNote;
    fields.ad_note = adsNote;
    fields.removed_suppression_ids = data.removedSuppressionIds;
    delete fields.removedSuppressionIds;

    try {
      await Api.put(`brandreports/${id}/${month}`, fields);
      alertService.success('Brand updated successfully');
      history.push(`/brandreports/${id}`);
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to update brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  function removeSuppresion(row: {id: string; alertLiftedId: string}) {
    const alertId = row.id;
    const alertLiftedId = row.alertLiftedId;
    const formValues = values ? values : ({} as ReportDataInterface);
    const removedSuppressionIds = formValues.hasOwnProperty('removedSuppressionIds')
      ? formValues.removedSuppressionIds
      : [];
    if (removedSuppressionIds?.indexOf(alertId) === -1) removedSuppressionIds?.push(alertId);
    if (alertLiftedId !== '' && removedSuppressionIds?.indexOf(alertLiftedId) === -1)
      removedSuppressionIds?.push(alertLiftedId);
    formValues['removedSuppressionIds'] = removedSuppressionIds;
    setValues(formValues);
    const suppressions = alertsOnMonth;
    setAlertsOnMonth(suppressions.filter((item) => item.id !== alertId));
  }

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 30}}>
      <Grid item xs={12} sx={styles('updateBrandForm')}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Update brand Report
        </Typography>
        {values ? (
          <Formik initialValues={values as ReportDataInterface} onSubmit={onSubmit} validateOnChange={true}>
            {({isSubmitting}) => (
              <Form>
                <Grid container>
                  <Grid item lg={4} xs={12}>
                    <Box sx={styles('panel')}>
                      <Box>
                        <Field
                          sx={styles('inputField')}
                          component={TextField}
                          variant="outlined"
                          name="brand_code"
                          label="Brand code"
                          type="text"
                          size="small"
                          disabled={true}
                        />
                      </Box>
                      <div style={{height: 10}} />

                      <div>
                        <Field
                          sx={styles('inputField')}
                          component={TextField}
                          variant="outlined"
                          name="month"
                          label="Month"
                          type="text"
                          size="small"
                          disabled={true}
                        />
                      </div>

                      <div>
                        <Box sx={styles('noteLabel')}>
                          <label>Sales Note</label>
                        </Box>
                        <CKEditor
                          editor={ClassicEditor}
                          data={salesNote || ''}
                          onReady={() => {
                            setSalesNote(values.sales_note);
                          }}
                          onChange={(event, editor) => {
                            setSalesNote(editor.getData());
                          }}
                        />
                      </div>
                      <div>
                        <Box sx={styles('noteLabel')}>
                          <label>Advertising Note</label>
                        </Box>
                        <CKEditor
                          editor={ClassicEditor}
                          data={adsNote || ''}
                          onReady={() => {
                            setAdsNote(values.ad_note);
                          }}
                          config={{height: 400}}
                          onChange={(event, editor) => {
                            setAdsNote(editor.getData());
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={8} xs={12}>
                    <Box sx={styles('panel')}>
                      <Box sx={styles('noteLabel')}>
                        <label>Suppressions</label>
                      </Box>
                      <DataGrid
                        autoHeight={true}
                        pageSize={10}
                        pagination
                        disableSelectionOnClick={true}
                        rows={alertsOnMonth}
                        columns={columns}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button
                      style={{marginTop: '1rem'}}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                    <CustomAlert sxStyles={styles('inputField')} id="default-alert" />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          ''
        )}
      </Grid>
    </TableContainer>
  );
}

export {UpdateBrandReport};
