import React, {useEffect, useState, useCallback} from 'react';
import moment from 'moment';
import {Typography, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {
  DataGrid,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridColTypeDef,
  GridFilterItem,
  GridFilterModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Helper} from '../../../../utils/helper';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
import {buildMenu} from '../../components/menu';
import TableContainer from '../../../../components/TableContainer/TableContainer';

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

function renderBooleanColumn(params: GridCellParams) {
  const text = params.row[params.field] ? 'Yes' : 'No';

  return <Typography variant="subtitle1">{text}</Typography>;
}

const columns: GridColDef[] = [
  {
    field: 'listing_amount',
    headerName: 'Listing Amount',
    flex: 0.25,
    sortable: false,
    filterable: false,
    align: 'right',
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{Helper.formatAmount(params.row.listing_amount)}</Typography>
    ),
  },
  {
    field: 'listing_currency',
    headerName: 'Listing Currency',
    flex: 0.25,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'marketplace_id',
    headerName: 'Marketplace',
    flex: 0.25,
    sortable: false,
    filterable: false,
  },
  {
    field: 'is_buy_box_winner',
    headerName: 'Buy Box Winner?',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: renderBooleanColumn,
  },
  {
    field: 'is_featured_merchant',
    headerName: 'Featured Merchant?',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: renderBooleanColumn,
  },
  {
    field: 'is_fulfilled_by_amazon',
    headerName: 'Fulfilled by Amazon?',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: renderBooleanColumn,
    hide: true,
  },
  {
    field: 'availability_type',
    headerName: 'Availability',
    flex: 0.25,
    sortable: false,
    filterable: false,
  },
  {
    field: 'condition',
    headerName: 'Condition',
    flex: 0.25,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'seller_feedback_count',
    headerName: 'Feedback Count',
    flex: 0.25,
    sortable: false,
    filterable: false,
    align: 'right',
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{Helper.formatAmount(params.row.seller_feedback_count)}</Typography>
    ),
    hide: true,
  },
  {
    field: 'seller_positive_feedback_rating',
    headerName: 'Positive Feedback Rating',
    flex: 0.25,
    sortable: false,
    filterable: false,
    align: 'right',
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{Helper.formatAmount(params.row.seller_positive_feedback_rating)}</Typography>
    ),
    hide: true,
  },
  {
    field: 'shipping_amount',
    headerName: 'Shipping Amount',
    flex: 0.25,
    sortable: false,
    filterable: false,
    align: 'right',
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{Helper.formatAmount(params.row.shipping_amount)}</Typography>
    ),
    hide: true,
  },
  {
    field: 'shipping_currency',
    headerName: 'Shipping Currency',
    flex: 0.25,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'shipping_time_minimum_hours',
    headerName: 'Min Shipping Time (Hours)',
    flex: 0.25,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'shipping_time_maximum_hours',
    headerName: 'Max Shipping Time (Hours)',
    flex: 0.25,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'ships_from',
    headerName: 'Ships From',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{params.row.ships_from.Country}</Typography>
    ),
    hide: true,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{moment(params.row.date).utc().format('M/d/y HH:mm')}</Typography>
    ),
  },
];

function AsinProductOffers(): JSX.Element {
  const firstMonth = '2021-04-01';
  const history = useHistory();

  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<GridSortItem>({field: 'month', sort: 'desc'});
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const rowsPerPageOptions = [20, 50, 100];

  const dateStart = moment(new Date(firstMonth));
  const dateEnd = moment(new Date());
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  function onPageChange(page: number) {
    setPage(page);
  }

  const loadData = useCallback(async () => {
    try {
      const {data} = await Api.get(`amazon-product-offers/asins/${id}`, {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sort.field || null,
          sortOrder: sort.sort || null,
          filterField: filter?.columnField || null,
          filterValue: filter?.value || null,
        },
      });
      setTotal(data.count);
      setRows(
        data.rows.map(
          (
            item: {
              [x: string]: unknown;
            },
            index: number,
          ) => {
            return {
              ...item,
              id: index,
            };
          },
        ),
      );
    } catch (e) {
      errorAlert('Unable to get inventory', e);
    }
  }, [page, pageSize, sort, filter, id]);

  function handleSortModelChange(params: GridSortModel) {
    setSort(params[0]);
  }

  function handleFilterModelChange(params: GridFilterModel) {
    setFilter(params.items[0]);
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('offers', id as string, history);
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [loadData, id, history]);

  return (
    <TableContainer>
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <Grid container spacing={1}>
          <Grid item lg={12} style={{width: '100%'}}>
            <DataGrid
              className="custom-table"
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={total}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rows={rows}
              columns={columns}
            />
            <CustomAlert id="default-alert" />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export {AsinProductOffers};
