import React, { useEffect } from 'react';

import {
  Grid,
} from '@mui/material';

import mixPanel from 'src/services/mixpanel';

import PageTitle from 'src/components/PageTitle/PageTitle';

export default function PaymentCompletePage(): JSX.Element {

  useEffect(() => {
    mixPanel({
      eventName: 'Update payment method',
    });
  }, []);

  return (
    <Grid
      className='page-container'
      container
    >
      <PageTitle>Payment Complete</PageTitle>
      <p>Your payment method was updated successfully, thank you.</p>
    </Grid>
  )
}
