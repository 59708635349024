import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
    '& .textButton': {
      fontSize: '12px',
      textDecoration: 'underline',
      padding: '0!important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '75vh!important',
      overflowY: 'auto!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      display: 'flex',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none',
    },
  },
  inputField: {
    margin: '0.75em 0',
    width: '100%',
    '& .MuiInputLabel-shrink': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
  },
  button: {
    textTransform: 'none',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      color: theme.palette.mode === 'light' ? '#000' : '#f0f0f0',
    },
    '&.MuiFormGroup-root > .MuiFormControlLabel-root': {
      padding: '10px 10px',
    },
    '& .MuiTypography-root': {
      padding: '0 2px',
    },
  },
  alertsToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiGrid-root:last-of-type': {
      display: 'flex',
    },

    [theme.breakpoints.down(1130)]: {
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    [theme.breakpoints.down(767)]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div, & a, button:not(.MuiOutlinedInput-root button), & > .MuiAutocomplete-root': {
        width: '100%',
      },
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '14px',
      },
      '& .MuiGrid-root:last-of-type': {
        flexDirection: 'column',
        '& a div': {
          margin: 0,
        },
      },
    },
    [theme.breakpoints.down(420)]: {
      '& .MuiTypography-root, & .MuiButton-root': {
        width: '100%',
      },
    },
  },
  toolbar: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > .MuiAutocomplete-root': {
      width: '200px',
    },
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      color: theme.palette.mode === 'light' ? '#000' : '#f0f0f0',
    },
    [theme.breakpoints.down(1130)]: {
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    [theme.breakpoints.down(720)]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div, & a, button:not(.MuiOutlinedInput-root button), & > .MuiAutocomplete-root': {
        width: '100%',
      },
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    [theme.breakpoints.down(420)]: {
      '& .MuiTypography-root, & .MuiButton-root': {
        width: '100%',
      },
    },
  },
  iconFlag: {
    padding: '8px 8px 2px 8px',
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f3f3f3',
    borderRadius: '50%',
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
