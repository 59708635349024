import React, {useState} from 'react';
import {Breadcrumbs, Typography, Paper, Grid, LinearProgress, IconButton, Menu, MenuItem} from '@mui/material';
import moment from 'moment';
import {NavigateNext, MoreVert} from '@mui/icons-material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
// styles
import {sxStyles} from '../../Performance/Styles';
// utils
import getLabel from '../../../utils/getLabel';
// interfaces
import {DefaultInsightsDataInterface, PromotionCodesInterface} from '../interfaces/insights';
import {HeatmapData} from './heatmap';

const BreadcrumbsComponent = ({
  parent,
  child,
  onChange,
  fontSize,
  textTransform,
  containerId,
}: {
  parent: string | JSX.Element;
  child?: string | JSX.Element | null;
  onChange?: () => void;
  fontSize?: number | string;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width' | 'full-size-kana';
  containerId?: string;
}): JSX.Element => {
  return (
    <Breadcrumbs
      sx={sxStyles('breadcrumbs')}
      separator={child ? <NavigateNext fontSize="small" /> : null}
      aria-label="breadcrumb"
    >
      <Typography
        variant="h6"
        component="h6"
        style={{fontSize: fontSize}}
        onClick={() => {
          if (child) {
            onChange?.();
            if (containerId) {
              const container = document.getElementById(containerId);
              container?.scrollTo(0, 0);
            }
          }
        }}
        className={`${child ? 'selected' : ''}`}
      >
        {parent}
      </Typography>
      {child && (
        <Typography variant="h6" component="h5" style={{fontSize: fontSize, textTransform: textTransform}}>
          {typeof child === 'string' ? getLabel(child) : child}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

const ChartContainer = ({
  children,
  loading,
  chartTitle,
  data,
  actionButton,
  filters,
  minHeight = 500,
}: {
  children: JSX.Element;
  loading: boolean;
  chartTitle?: string | JSX.Element;
  hideUtils?: boolean;
  data?: DefaultInsightsDataInterface[] | PromotionCodesInterface[] | HeatmapData['data'];
  actionButton?: {
    label: string;
    action: () => void;
  }[];
  filters?: {
    [key: string]: string | number | string[] | null | undefined;
  };
  minHeight?: number;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  return (
    <Paper variant={'outlined'} style={{minHeight: minHeight}} sx={sxStyles('chartContainer')}>
      <Grid item lg={12} className="chart-header">
        {chartTitle}
        {data?.length && data.length > 0 && actionButton?.length && actionButton.length > 0 ? (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
              }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {actionButton.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => {
                    option.action();
                    setAnchorEl(null);
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : null}
      </Grid>

      {loading ? (
        <>
          <Typography component="h2" variant="h5" color="primary" gutterBottom>
            Loading...
          </Typography>
          <LinearProgress style={{position: 'absolute', width: '100%', bottom: 0}} />
        </>
      ) : (
        children
      )}

      <Grid container justifyContent="center">
        {filters &&
          Object.keys(filters).length > 0 &&
          Object.entries(filters).map(([key, value]) => {
            const newValue = Array.isArray(value) ? value.join(', ') : value;
            return (
              <Typography key={key} component="span" variant="overline" style={{padding: '0 10px', lineHeight: '1.80'}}>
                {`${getLabel(key)}: ${getLabel(newValue as string)}`}
              </Typography>
            );
          })}
      </Grid>
    </Paper>
  );
};

const columns: GridColDef[] = [
  {
    field: 'amazon_order_id',
    headerName: 'Amazon Order ID',
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'detailed_disposition',
    headerName: 'Detailed Disposition',
    minWidth: 220,
    disableColumnMenu: true,
  },

  {
    field: 'listing_title',
    headerName: 'Listing Title',
    minWidth: 450,
    disableColumnMenu: true,
  },

  {
    field: 'quantity',
    headerName: 'Quantity',
    minWidth: 220,
    disableColumnMenu: true,
    align: 'center',
  },

  {
    field: 'reason',
    headerName: 'Reason',
    minWidth: 220,
    disableColumnMenu: true,
  },

  {
    field: 'return_date',
    headerName: 'Return Date',
    minWidth: 220,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams): string => {
      return moment(params.value).format('YYYY/MM/DD');
    },
  },
  {
    field: 'seller_sku',
    headerName: 'Seller SKU',
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'fulfillment_center_id',
    headerName: 'Fulfillment Center ID',
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'license_plate_number',
    headerName: 'License Plate Number',
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'customer_comments',
    headerName: 'Customer Comments',
    minWidth: 450,
    disableColumnMenu: true,
  },
];

export {BreadcrumbsComponent, columns, ChartContainer};
