import React from 'react';

import {
  Severity,
  CountIssues,
  CountStatuses,
} from '../../interfaces/interfaces';

interface Props {
  allIssuesTotal: number;
  countIssues?: CountIssues | null;
  countStatuses?: CountStatuses | null;
  status?: string;
}

const IssuesSummary = ({
  allIssuesTotal,
  countIssues,
  countStatuses,
  status,
}: Props): JSX.Element => {
  return (
    <div
      id="listings-summary"
    >
      {allIssuesTotal > 0 && (
      <div
        id="issues"
        >
        <div>
          <h4>Issues</h4>
          {countIssues && (
            <div>
              {Object.entries(countIssues)
                .filter(([key]) => key !== Severity.NONE)
                .map(([key, value]) => {
                  if (value) {
                    return (
                      <p key={key}>{key}: {value}</p>
                    );
                  }
                })
              }
            </div>
          )}
        </div>
      </div>
      )}
      {(status || countStatuses) && (
        <div
          id="statuses"
        >
          {status ? (
            <>
              <h4>Status</h4>
              <p>{status}</p>
            </>
          ): ''}
          {countStatuses && (
            <div>
              <h4>Statuses</h4>
              {Object.entries(countStatuses)
                .map(([key, value]) => {
                  if (value) {
                    return (
                      <p key={key}>{key}: {value}</p>
                    );
                  }
                })
              }
          </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IssuesSummary;
