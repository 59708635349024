import React, { FC, memo, useEffect, useState } from 'react';

import {
  Grid,
} from '@mui/material';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
  ErrorMessage,
  FieldProps,
  FastField,
} from 'formik';

import { ListingField } from '../../interfaces/interfaces';

type CustomInputProps = {
  fieldDefinition: ListingField;
  fieldName: string;
  labelIndex?: number;
};

// JSX.Element is used to render JSX elements at runtime.
// React.FC is used to render a React component function.
const ListingFieldComponent: FC<CustomInputProps & FieldProps> = ({
  field,
  form,
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  fieldDefinition,
  fieldName,
  labelIndex,
  ...props
}) => {

  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (fieldDefinition.meta.selectOptions) {
      // If this field has selectOptions then it's an autocomplete field and 
      // we need to set the value if there's one.
      const options = fieldDefinition.meta.selectOptions.map(option => option[0]);
      setOptions(options);
      if (
        field.value === '' &&
        fieldDefinition.meta.type === 'boolean'
      ) {
        // Make sure that fields that are boolean set the correct value
        // to show 'Yes' or 'No' in the form.
        // console.log(`field.name ${field.name} has selectOptions and an empty string value.`);
        field.value = false;
      }
      if (field.value) {
        form.setFieldValue(field.name, field.value);
      }
    }
  }, [field, fieldDefinition.meta, form]);

  // IF using Field as select box.
  //<Field
  //  as="select"
  //  {...field}
  //  {...props}
  //>
  //  {fieldDefinition.meta.selectOptions.map((option: string[], index: number) => (
  //    <option key={index} value={option[0]}>{option[1]}</option>
  //  ))}
  //</Field>

  // <select> can use onChange={form.handleChange} and onBlur={form.handleBlur}

  //<select
  //  id={field.name}
  //  {...field}
  //  {...props}
  //>
  //  {fieldDefinition.meta.selectOptions.map((option: string[], index: number) => (
  //    <option key={index} value={option[0]}>{option[1]}</option>
  //  ))}
  //</select>

  const getOptionLabel = (option: string) => {
    let optionLabel = option.toString(); // will convert empty array to empty string
    const selectedOption = fieldDefinition.meta.selectOptions?.filter(optionValue => optionValue[0] === option);
    if (selectedOption?.length === 1) {
      optionLabel = selectedOption[0][1];
    }
    return optionLabel;
  };

  const fieldsToRenderAsTextarea = [
    'bullet_point',
    'product_description',
  ];

  const attributeLabel = fieldDefinition.meta.title?.toLowerCase() === 'item name' ? 'Title' : fieldDefinition.meta.title;
  // Using workaround from https://github.com/mui/material-ui/issues/26492 for Autocomplete.
  return (
    <Grid
      container
      rowSpacing={4}
      className={`field-container`}
    >
      <Grid
        className={`label-wrapper label-wrapper-${field.name}`}
        item xs={4}
      >
        <label
          htmlFor={field.name}
          title={fieldDefinition.meta.description}
        >
          {attributeLabel} {labelIndex ? `#${labelIndex}` : ''}
        </label>
      </Grid>
      <Grid
        className={`wrapper wrapper-${field.name}`}
        item
        xs={8}
      >
        {fieldDefinition.meta.selectOptions ? (
          <Autocomplete
            className='autocomplete-field'
            disablePortal
            id={field.name}
            data-cy={field.name}
            freeSolo={true}
            value={field.value}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={(e: React.SyntheticEvent, newValue: string | null) => {
              form.setFieldValue(field.name, newValue);
            }}
            onInputChange={(e: React.SyntheticEvent, newInputValue) => {
              form.setFieldValue(field.name, newInputValue);
            }}
            sx={{
              width: '100%',
              '& .input': {
                color: 'palette.text.primary',
              },
              '& fieldset': {
                display: 'none',
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {getOptionLabel(option)}
              </li>
            )}
          />
        ) : (
          <FastField
            id={field.name}
            data-cy={field.name}
            as={fieldsToRenderAsTextarea.includes(fieldName) ? 'textarea': 'input'}
            rows={5}
            {...field}
            {...props}
          />
        )}
      </Grid>
      <Grid item xs={12} className="alert">
        {/* <p>error for fieldName: {fieldName} | field.name: {field.name}</p> */}
        <ErrorMessage name={field.name} />
      </Grid>
    </Grid>
  );
};

export default memo(ListingFieldComponent);
