import {Theme} from '@mui/material';
// icons - images
import DateIcon from '../../assets/images/calendar-alt-regular.svg';
import DateIconDark from '../../assets/images/calendar-alt-regular-dark.svg';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';

const sxStyles = (
  theme: Theme,
): {
  [key: string]: SxStyleTypes;
} => ({
  container: {
    position: 'relative',
    padding: '10px 0',
    '&.disabled .rdrDateDisplayWrapper': {
      pointerEvents: 'none',
      opacity: theme.palette.mode === 'dark' ? 1 : 0.5,
    },
    '& > .customDateRangePicker': {
      width: '100%',
      '& .date-range-button-container': {
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '& .date-range-button': {
        textAlign: 'right',
        padding: '0 10px 10px 0',
        '& button': {
          cursor: 'pointer',
          fontSize: '12px',
          color: '#fff!important',
          border: 'none',
          padding: '8px 12px',
          borderRadius: '3px',
          background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        '& button:hover': {
          background: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.dark,
        },
      },
      '& input': {
        borderBottom: '1px solid #bdbdbd!important',
        boxShadow: 'none',
        outline: 'none',
        border: 'none',
        textAlign: 'left',
      },
      '& input:hover, & input:focus': {
        borderBottom: theme.palette.mode === 'dark' ? '1px solid #ffffff!important' : '1px solid #174582!important',
      },
      '& .rdrDateInput': {
        boxShadow: 'none',
        border: 'none',
        margin: '0',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
      },
      '& .rdrDateInput::before': {
        position: 'absolute',
        top: '-18px',
        color: '#bbbbbb',
        zIndex: '1',
        fontWeight: '400',
      },
      '& .rdrDateInput:first-of-type::before': {
        content: '"From"',
      },
      '& .rdrDateInput::after': {
        content: '"-"',
        position: 'absolute',
        top: '-22px',
        right: '18px',
        fontSize: '50px',
        color: 'transparent',
        backgroundImage: theme.palette.mode === 'dark' ? `url(${DateIconDark})` : `url(${DateIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: '0.5',
        cursor: 'pointer',
      },
      '& .rdrDateInput:last-child::before': {
        content: '"To"',
      },
      '& .rdrMonthAndYearWrapper, & .rdrMonthsVertical': {
        display: 'none',
      },
      '& .rdrDateDisplayWrapper': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
      },
      '&.show .rdrMonthAndYearWrapper, &.show .rdrMonthsVertical': {
        display: 'flex',
        position: 'absolute',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
        boxShadow: '0px 11px 15px -1px rgba(0,0,0,0.32)',
        zIndex: 5,
        width: '100%',
      },
      '&.show .rdrMonthAndYearWrapper': {
        top: '58px',
      },
      '&.show .rdrMonthsVertical': {
        top: '118px',
      },
      '& .rdrMonth': {
        width: '95%',
      },
      '& .rdrDayNumber span': {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#1d2429',
      },

      '& .rdrDayPassive span': {
        color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#a3a3a3',
      },
      '& .rdrDayDisabled': {
        backgroundColor: theme.palette.mode === 'dark' ? '#5e5e5e9d' : '#f5f5f5',
      },
      '& .rdrMonthPicker, & .rdrYearPicker': {
        backgroundColor: theme.palette.mode === 'dark' ? '#e5e5e5' : '#fff',
        borderRadius: '5px',
      },
    },
  },
});

export {sxStyles};
