import React from 'react';

import { appVersion } from 'src/utils/constants';

function Footer(): JSX.Element {
  return (
    <div>
      <p>FlatFilePro {appVersion} - &copy; {new Date().getFullYear()}</p>
    </div>
  )
}

export {Footer};
