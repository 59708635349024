import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (
  theme: Theme,
): {
  [key: string]: SxStyleTypes;
} => ({
  inputField: {
    margin: '0.5em',
    width: '95%',
    '& .MuiInputLabel-shrink': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
