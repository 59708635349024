// utils
import {Api} from '../utils/api';
// services
import {accountService} from '../services/account.service';
import {GridColDef} from '@mui/x-data-grid';

interface userPreferencesProps {
  list: GridColDef[];
  tableName: string;
  defaultVisibilityModel: {
    [key: string]: boolean;
  };
  loading: (x: boolean) => void;
  currentOrder?: GridColDef[];
  groupBy?: string;
}

interface getColumnsItemsProps {
  list: GridColDef[];
  columnsVisibility: {
    [key: string]: boolean;
  };
  columnsOrder: string[] | GridColDef[];
  currentOrder?: GridColDef[];
}

const getColumnsItems = ({list, columnsVisibility, columnsOrder, currentOrder}: getColumnsItemsProps): GridColDef[] => {
  const state = columnsOrder;

  const newList = state && state.length > 0 && !currentOrder ? [] : currentOrder ? currentOrder : list;

  if (state?.length > 0 && !currentOrder) {
    state?.forEach(function (value: string | GridColDef, index: number) {
      newList[index] = list.find((x) => x.field === value) as GridColDef;
    });
  }

  return newList.map((x) => {
    return {
      ...x,
      id: x?.field,
      hide: columnsVisibility?.[x?.field] === false,
    };
  });
};

async function getUserPreferences({
  list,
  tableName,
  defaultVisibilityModel,
  loading,
  currentOrder,
}: userPreferencesProps): Promise<
  | {
      columns: GridColDef[];
      visibility: {
        [key: string]: boolean;
      };
    }
  | undefined
> {
  loading(true);
  try {
    const {data} = await Api.get(`users/${accountService.userValue.userId}/table-sort/${tableName}`);
    let visibility: userPreferencesProps['defaultVisibilityModel'];

    if (data.sort_data?.columnVisibilityModel) {
      visibility = data.sort_data.columnVisibilityModel;
    } else {
      visibility = defaultVisibilityModel;
    }

    const added: string[] = [];
    const deleted: string[] = [];

    let columns: string[] = data?.sort_data?.columnsOrder;

    const listKeys = list.map((x) => x.field);

    if (columns) {
      listKeys?.filter((v) => !columns.includes(v) && added.push(v));
      columns?.filter((v) => !listKeys.includes(v) && deleted.push(v));
    }

    if (added.length > 0) {
      columns = added.concat(columns);
    }

    if (deleted.length > 0) {
      columns = columns.filter((v) => {
        return !deleted.includes(v);
      });
      Object.keys(visibility).forEach((key) => {
        if (deleted.includes(key)) {
          return delete visibility[key];
        }
      });
    }

    const newColumnsArray = getColumnsItems({
      list: list,
      columnsVisibility: visibility,
      columnsOrder: columns,
      currentOrder,
    });

    return {
      columns: newColumnsArray,
      visibility,
    };
  } catch (error) {
  } finally {
    loading(false);
  }
}

async function setUserPreferences(
  values: {
    columnsOrder: string[];
    columnVisibilityModel: {
      [key: string]: boolean;
    };
  },
  tableName: string,
  setTableLoading: (x: boolean) => void,
): Promise<void> {
  setTableLoading(true);
  try {
    await Api.patch(`users/${accountService.userValue.userId}/table-sort/${tableName}`, {sortData: values});
  } catch (error) {
  } finally {
    setTableLoading(false);
  }
}

export {setUserPreferences, getUserPreferences, getColumnsItems};
