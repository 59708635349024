import React, {
  createContext,
  useState,
  useEffect,
  useCallback
} from 'react';

// services
import {accountService} from '../../services/account.service';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
// interfaces
import {AccountManagersInterface, BrandUtilsInterface, BrandsInterface} from './interfaces/interfaces';

import { useLocation } from 'react-router-dom';

interface ProviderProps {
  children?: JSX.Element;
}

interface AppContextState {
  brands: BrandsInterface[];
  setBrands: ((brands: BrandsInterface[]) => void) | null;
  accountManagers: AccountManagersInterface[];
  setAccountManagers: ((accountManagers: AccountManagersInterface[]) => void) | null;
  appLoading: boolean;
  currentAM: AccountManagersInterface | null;
  getBrandUtils: (() => void) | null;
  brandUtilsLoading: boolean;
  setBrandUtilsLoading: ((brandUtilsLoading: boolean) => void) | null;
  brandUtils: BrandUtilsInterface;
  setBrandUtils: ((brandUtils: BrandUtilsInterface) => void) | null;
  getBrandRoles: (() => void) | null;
}

const initialState: AppContextState = {
  accountManagers: [],
  setAccountManagers: null,
  brands: [],
  setBrands: null,
  appLoading: true,
  currentAM: null,
  getBrandUtils: null,
  brandUtilsLoading: true,
  setBrandUtilsLoading: null,
  brandUtils: {
    brandStatuses: [],
    brandRoles: [],
    brandTypes: [],
    orderMethods: [],
    agreementTypes: [],
    brandMaxControl: [],
  },
  setBrandUtils: null,
  getBrandRoles: null,
};

export const AppContext = createContext(initialState);

function AppContextProvider(props: ProviderProps): JSX.Element {
  const location = useLocation();
  const [accountManagers, setAccountManagers] = useState<AccountManagersInterface[]>(initialState.accountManagers);
  const [brands, setBrands] = useState<BrandsInterface[]>(initialState.brands);
  const [currentAM, setCurrentAM] = useState<AccountManagersInterface | null>(initialState.currentAM);
  const [appLoading, setAppLoading] = useState(false);
  const [brandUtilsLoading, setBrandUtilsLoading] = useState(initialState.brandUtilsLoading);
  const [brandUtils, setBrandUtils] = useState<BrandUtilsInterface>(initialState.brandUtils);

  async function getAccountManagers() {
    const {data} = await Api.get('users/account-managers');
    setAccountManagers(data);
    const currentUser = data.find((manager: {id: string}) => manager.id === accountService.userValue.userId);
    setCurrentAM(currentUser);
  }

  async function getBrands() {
    const splitLocation = location.pathname.split('/');
    if (splitLocation[1] !== 'amazon-listings-item' && splitLocation[4] !== 'edit') {
      // Don't get brands if for the edit listing form.
      const {data} = await Api.get('brands/ids');
      setBrands(data);
    }
  }

  const getBrandsStatuses = useCallback(async () => {
    if (brandUtils.brandStatuses.length) return;
    const {data} = await Api.get('brand-statuses');
    setBrandUtils((prev) => ({...prev, brandStatuses: data}));
  }, [brandUtils.brandStatuses.length]);

  const getBrandRoles = useCallback(async () => {
    if (brandUtils.brandRoles.length) return;
    const {data} = await Api.get('users/brand-role-assignees');
    setBrandUtils((prev) => ({...prev, brandRoles: data}));
  }, [brandUtils.brandRoles.length]);

  const getBrandTypes = useCallback(async () => {
    if (brandUtils.brandTypes.length) return;
    const {data} = await Api.get('brands/types');
    setBrandUtils((prev) => ({...prev, brandTypes: data}));
  }, [brandUtils.brandTypes.length]);

  const getOrderMethods = useCallback(async () => {
    if (brandUtils.orderMethods.length) return;
    const {data} = await Api.get('orders/methods');
    setBrandUtils((prev) => ({...prev, orderMethods: data}));
  }, [brandUtils.orderMethods.length]);

  const getAgreementTypes = useCallback(async () => {
    if (brandUtils.agreementTypes.length) return;
    const {data} = await Api.get('agreement-types');
    setBrandUtils((prev) => ({...prev, agreementTypes: data}));
  }, [brandUtils.agreementTypes.length]);

  const getBrandMaxControl = useCallback(async () => {
    if (brandUtils.brandMaxControl.length) return;
    const {data} = await Api.get('brands/max-control');
    setBrandUtils((prev) => ({...prev, brandMaxControl: data}));
  }, [brandUtils.brandMaxControl.length]);

  const getBrandUtils = useCallback(async () => {
    setBrandUtilsLoading(true);
    try {
      Promise.all([
        getBrandsStatuses(),
        getBrandRoles(),
        getBrandTypes(),
        getOrderMethods(),
        getAgreementTypes(),
        getBrandMaxControl(),
      ]);
    } catch (e) {
      errorAlert('Unable to get brand utils data!', e);
    } finally {
      setBrandUtilsLoading(false);
    }
  }, [getBrandsStatuses, getBrandRoles, getBrandTypes, getOrderMethods, getAgreementTypes, getBrandMaxControl]);

  const getInitialData = useCallback(async () => {
    setAppLoading(true);
    try {
      await getBrands();
      if (
        accountService?.userValue?.role !== Role.BrandUser &&
        !['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)
      ) {
        await getAccountManagers();
      }
    } catch (e) {
      errorAlert('Unable to get data!', e);
    } finally {
      setAppLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountService?.userValue?.accessToken) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInitialData, accountService?.userValue?.accessToken]);

  return (
    <AppContext.Provider
      value={{
        brands,
        setBrands,
        accountManagers,
        setAccountManagers,
        appLoading,
        currentAM,
        getBrandUtils,
        brandUtilsLoading,
        setBrandUtilsLoading,
        brandUtils,
        setBrandUtils,
        getBrandRoles,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
