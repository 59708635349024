import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {Paper, Typography, Grid} from '@mui/material';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
// utils
import {Api, errorAlert} from '../../../utils/api';
import {renderRevenue} from '../utils/utils';
// interfaces
import {DashboardWidgetInterface} from '../interfaces/dashboard.interfaces';

function WatchlistWidget({classes}: DashboardWidgetInterface): JSX.Element {
  const [brandRows, setBrandRows] = useState([]);
  const [asinRows, setAsinRows] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [loadingAsins, setLoadingAsins] = useState(false);

  const brandColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Brands',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <Link to={`/brands/${params.row.id}/detail`}>
            <Typography>{params.value}</Typography>
          </Link>
        </>
      ),
    },
    {field: 'revenue_1d', headerName: '1d', flex: 1, sortable: false, filterable: false, renderCell: renderRevenue},
    {field: 'revenue_7d', headerName: '7d', flex: 1, sortable: false, filterable: false, renderCell: renderRevenue},
    {
      field: 'revenue_30d',
      headerName: '30d',
      flex: 1.2,
      sortable: false,
      filterable: false,
      renderCell: renderRevenue,
    },
  ];

  const asinColumns: GridColDef[] = [
    {
      field: 'asin',
      headerName: 'ASINs',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <Link to={`/asins/view/${params.value}`}>
            <Typography>{params.value}</Typography>
          </Link>
        </>
      ),
    },
    {field: 'revenue_1d', headerName: '1d', flex: 1, sortable: false, filterable: false, renderCell: renderRevenue},
    {field: 'revenue_7d', headerName: '7d', flex: 1, sortable: false, filterable: false, renderCell: renderRevenue},
    {
      field: 'revenue_30d',
      headerName: '30d',
      flex: 1.2,
      sortable: false,
      filterable: false,
      renderCell: renderRevenue,
    },
  ];

  async function getAsinWatchlist() {
    setLoadingAsins(true);
    try {
      const {data} = await Api.get(`asins/watchlist/get`);
      setAsinRows(
        data.map((x: {asin: string}) => {
          return {id: x.asin, ...x};
        }),
      );
    } catch (e) {
      errorAlert('Unable to get ASIN watchlist', e, {id: 'asin-watchlist-alert'});
    } finally {
      setLoadingAsins(false);
    }
  }

  async function getBrandWatchlist() {
    setLoadingBrands(true);
    try {
      const {data} = await Api.get(`brands/watchlist/get`);
      setBrandRows(
        data.map((x: {brand_code: string}) => {
          return {id: x.brand_code, ...x};
        }),
      );
    } catch (e) {
      errorAlert('Unable to get brand watchlist', e, {id: 'brand-watchlist-alert'});
    } finally {
      setLoadingBrands(false);
    }
  }

  useEffect(() => {
    getAsinWatchlist();
    getBrandWatchlist();
  }, []);

  return (
    <Paper sx={classes.wrapperDiv} variant={'outlined'}>
      <Typography component="h2" variant="h5" color="secondary">
        Watchlist
      </Typography>
      <Grid container spacing={1} justifyContent="center" style={{paddingTop: 20}}>
        <Grid item lg={12}>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={brandRows}
            disableSelectionOnClick={true}
            columns={brandColumns}
            loading={loadingBrands}
          />
          <CustomAlert id={'brand-watchlist-alert'} />
        </Grid>
        <Grid item lg={12}>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={asinRows}
            disableSelectionOnClick={true}
            columns={asinColumns}
            loading={loadingAsins}
          />
          <CustomAlert id={'asin-watchlist-alert'} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export {WatchlistWidget};
