import {BehaviorSubject} from 'rxjs';
import Cookies from 'js-cookie';
import {FormikValues} from 'formik';
// utils
import {Api, ApiWithCustomToken} from '../utils/api';
import {destroyBeacon} from '../utils/FFPBeacon';

const userSubject = new BehaviorSubject(
  Cookies.get('stonehenge_current_user') && JSON.parse(Cookies.get('stonehenge_current_user') as string),
);

const baseUrl = `/auth`;

interface UserDtoTypes {
  accessToken: string;
  refreshToken?: string;
  brand_type: string;
  sub_brand_codes: string[];
  brand_code: string;
  brand_name: string;
  role: string;
  userId: string;
  name: string;
  email: string;
  delete_listings?: boolean;
  active_brand_subscription: boolean;
  brand_admin: boolean;
  create_users: boolean;
}

export const accountService = {
  login,
  logout,
  refreshToken,
  register,
  isRegisterTokenValid,
  isAmazonAudit,
  resetPassword,
  user: userSubject.asObservable(),
  get userValue(): UserDtoTypes {
    return userSubject.value;
  },
  setUserValue(userDto: {
    accessToken?: string;
    refreshToken?: string;
    brand_type?: string;
    sub_brand_codes?: string[];
    brand_code?: string;
  }): void {
    userSubject.next({...userSubject.value, ...userDto});
  },
};

function login(email: string, password: string): Promise<UserDtoTypes> {
  return Api.post(`${baseUrl}/login`, {email, password}).then((user) => {
    // publish user to subscribers and start timer to refresh token
    userSubject.next(user.data);
    // startRefreshTokenTimer();
    return user.data;
  });
}

function isRegisterTokenValid(token: string): Promise<UserDtoTypes> {
  return ApiWithCustomToken.get(`${baseUrl}/register`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function register(
  data: {
    password: string;
    passwordConfirmation: string;
  },
  token: string,
): Promise<UserDtoTypes> {
  return ApiWithCustomToken.post(`${baseUrl}/register`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function resetPassword(data: FormikValues): Promise<UserDtoTypes> {
  return ApiWithCustomToken.post(`${baseUrl}/password-reset`, data);
}

function logout(): void {
  if (!userSubject.value?.accessToken) return;
  // revoke token, stop refresh timer, publish null to user subscribers
  ApiWithCustomToken.post(
    `${baseUrl}/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userSubject.value.accessToken}`,
      },
    },
  );
  stopRefreshTokenTimer();
  userSubject.next(null);
  Cookies.remove('stonehenge_current_user');
  destroyBeacon();
}

function refreshToken(): Promise<UserDtoTypes> {
  return ApiWithCustomToken.post(
    `${baseUrl}/refresh`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userSubject.value.refreshToken}`,
      },
    },
  )
    .then((res) => res.data)
    .then((user) => {
      // publish user to subscribers and start timer to refresh token
      userSubject.next(user);
      // startRefreshTokenTimer();
      return user;
    });
}

function isAmazonAudit(): boolean {
  return userSubject.value?.brand_type === 'aa';
}

// helper functions

let refreshTokenTimeout: number | NodeJS.Timeout;

function startRefreshTokenTimer(user: UserDtoTypes): void {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(user.accessToken.split('.')[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  Cookies.set('stonehenge_current_user', JSON.stringify(user), {expires: expires});
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer(): void {
  clearTimeout(refreshTokenTimeout as number);
}

userSubject.subscribe((user) => {
  if (user) startRefreshTokenTimer(user);
});
