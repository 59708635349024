import moment from 'moment';
import {RouteComponentProps} from 'react-router-dom';

export interface QueryValuesInterface {
  [key: string]:
    | string
    | number
    | null
    | undefined
    | boolean
    | Date
    | moment.Moment
    | moment.MomentInput
    | string[]
    | number[];
}

function updateQueryParams(
  location: RouteComponentProps['location'],
  values: QueryValuesInterface,
  ignoreAttr?: string[],
): void {
  const search = new URLSearchParams(location.search);

  Object.entries(values).forEach(([key, value]) => {
    if (
      value === undefined ||
      value === null ||
      (value as string[])?.length === 0 ||
      value === '' ||
      ignoreAttr?.includes(key)
    ) {
      return search.delete(key);
    } else {
      return search.set(key, value as string);
    }
  });
  let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

  if (search?.toString() !== '') newUrl = newUrl + '?' + search.toString();
  window.history.pushState({path: newUrl}, '', newUrl);
}

function getDefaultQuery(
  location: RouteComponentProps['location'],
  values: QueryValuesInterface,
  customPreset?: QueryValuesInterface,
): {
  [key: string]: string | null;
} {
  const urlParams = new URLSearchParams(location.search);
  let query: {
    [key: string]: string | null;
  } = {};
  Object.entries(values).forEach(([key]) => {
    query[key] = urlParams.get(key);
  });
  if (
    !moment(query.from as moment.MomentInput).isValid() ||
    !moment(query.to as moment.MomentInput).isValid() ||
    !moment(query.compareFrom as moment.MomentInput).isValid() ||
    !moment(query.compareTo as moment.MomentInput).isValid()
  ) {
    query = {
      ...query,
      from: null,
      to: null,
      compareFrom: null,
      compareTo: null,
    };
  }

  if (!query.from || !query.to) {
    if (customPreset) {
      query.to = customPreset.to as string;
      query.from = customPreset.from as string;
      query.preset = customPreset.preset as string;
    } else {
      query.to = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      query.from = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
      query.preset = '7d';
    }
  } else if (!query.compareFrom || !query.compareTo) {
    query.from = moment(query.from as moment.MomentInput).format('YYYY-MM-DD');
    query.to = moment(query.to as moment.MomentInput).format('YYYY-MM-DD');
  } else {
    query.from = moment(query.from as moment.MomentInput).format('YYYY-MM-DD');
    query.to = moment(query.to as moment.MomentInput).format('YYYY-MM-DD');
    query.compareFrom = moment(query.compareFrom as moment.MomentInput).format('YYYY-MM-DD');
    query.compareTo = moment(query.compareTo as moment.MomentInput).format('YYYY-MM-DD');
  }

  return query;
}

export {updateQueryParams, getDefaultQuery};
