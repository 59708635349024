import React from 'react';
import {CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Line, LineChart} from 'recharts';
import moment from 'moment';
import {Checkbox, FormControlLabel, LinearProgress, Paper, Typography, Grid} from '@mui/material';
import {useParams} from 'react-router-dom';
// components
import {metricOptionsData} from './Metrics';
import {CustomAlert} from '../../../../Login/components/CustomAlert';

function PerformanceChart(props: {
  data:
    | {
        type: string;
        metric: string;
        data: {
          date: string | number;
          value: number;
        }[];
      }[]
    | null;
  metric: string[];
  onMetricChange: (metric: string[]) => void;
  loading?: boolean;
  hiddenMetrics?: string[];
}): JSX.Element {
  const {data, metric, onMetricChange, loading, hiddenMetrics} = props;
  const {adsType} = useParams<{
    adsType: string;
  }>();
  const metricsOptions: {
    [key: string]: {
      label: string;
      color: string;
      tooltip?: string;
    };
  } = metricOptionsData[adsType as keyof typeof metricOptionsData] || null;

  if (hiddenMetrics && hiddenMetrics?.length > 0) {
    hiddenMetrics.map((x) => delete metricsOptions[x as keyof typeof metricsOptions]);
  }
  function formatXAxis(tickItem: string) {
    return moment.utc(tickItem).format('YYYY-MM-DD');
  }
  const handleMetricChange = (metricName: string, metricValue: boolean) => {
    if (metricValue) onMetricChange([...metric, metricName]);
    else {
      onMetricChange(metric.filter((x) => x !== metricName));
    }
  };

  return (
    <Grid container spacing={1} style={{paddingTop: 30}}>
      <Grid item lg={12}>
        {metric?.length && metricsOptions && data?.some((x) => x.data?.length) ? (
          <ResponsiveContainer height={300} width={'100%'}>
            <LineChart>
              <CartesianGrid stroke="#ccc===" strokeDasharray="3 3" />
              <XAxis
                type={'number'}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                domain={['dataMin', 'dataMax']}
                dataKey={(x) => moment.utc(x.date).valueOf()}
                tickFormatter={formatXAxis}
                minTickGap={100}
              />
              <Tooltip
                contentStyle={{
                  color: '#4b4b4b',
                }}
                labelFormatter={(label) => moment.utc(label).format('YYYY-MM-DD HH:mm')}
              />
              {data.map((x, i) => {
                return metric.includes(x.metric) ? (
                  <Line
                    key={i}
                    data={x.data}
                    type="monotone"
                    dataKey="value"
                    name={metricsOptions[x.metric as keyof typeof metricsOptions]?.label}
                    stroke={metricsOptions[x.metric as keyof typeof metricsOptions]?.color}
                    strokeWidth={4}
                    dot={false}
                    yAxisId={x.metric}
                    strokeDasharray={x.type === 'previous' ? '5 5' : ''}
                    connectNulls
                  />
                ) : null;
              })}
              {data.map((x, i) => {
                return (
                  <YAxis
                    key={`axis_${i}`}
                    domain={['auto', 'auto']}
                    hide={true}
                    axisLine={false}
                    yAxisId={x.metric}
                    type={'number'}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Paper
            variant={'outlined'}
            style={{
              height: '300px',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '0px 0px 6px 0px #94949433',
            }}
          >
            <Typography color={'textSecondary'} variant={'h4'}>
              No data
            </Typography>
          </Paper>
        )}
        {loading ? <LinearProgress color={'primary'} /> : <></>}
        <div style={{textAlign: 'center'}}>
          {metricsOptions &&
            Object.entries(metricsOptions).map(([i, x]) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    style={{color: x?.color}}
                    checked={metric.includes(i)}
                    onChange={(e) => handleMetricChange(i, e.target.checked)}
                    disabled={loading}
                  />
                }
                label={x?.label}
              ></FormControlLabel>
            ))}
        </div>
        <CustomAlert id="performance-chart" />
      </Grid>
    </Grid>
  );
}

export {PerformanceChart};
