import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (
  theme: Theme,
): {
  [key: string]: SxStyleTypes;
} => ({
  grid: {
    display: 'flex',
    overflow: 'auto',
    '& .edit-cell-icon': {
      display: 'none',
      position: 'absolute',
      right: '0',
      '& svg': {
        color: '#a1a1a1',
        fontSize: '16px',
        marginLeft: '5px',
        '&:last-of-type': {
          cursor: 'pointer',
        },
      },
    },
    '& .custom-textfield .MuiOutlinedInput-notchedOutline': {
      outline: 'none!important',
      border: 'none!important',
    },
    '& .column-checkbox': {
      margin: '0 8px 0 0',
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },

    '& .MuiDataGrid-cell--editable': {
      position: 'relative',
      '& span': {
        paddingRight: '30px',
      },
    },
    '& .MuiDataGrid-cell--editable:hover .edit-cell-icon': {
      display: 'block',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CloseIcon"]': {
      color: '#f44336',
    },
    '& .MuiDataGrid-cell--editable [data-testid="CheckIcon"]': {
      color: '#4caf50',
    },

    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '65vh!important',
      overflowY: 'auto!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      display: 'flex',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none',
    },
    // fixed columns
    '& .fixed-column-container': {
      position: 'sticky',
      marginLeft: 'auto',
      alignItems: 'center',
      height: '0',
      right: '0',
      zIndex: 1,
      '& > div': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
        '& svg': {
          fontSize: '22px',
          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#a1a1a1',
        },
      },
    },
    '& .customButtons': {
      '& svg': {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#a1a1a1',
      },
    },
    '&:not(.collapsible)': {
      '& .MuiDataGrid-virtualScroller': {
        maxHeight: '65vh!important',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        background: '#999',
        borderRadius: '3px',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
        background: '#777',
      },
    },
  },
  customTable2: {
    background: theme.palette.mode === 'dark' ? '#414141' : '#f5f5f5',
  },
  detailsGrid: {
    '& > .MuiTypography-root': {
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        marginLeft: '5px',
      },
    },
    '& .MuiInputLabel-root': {
      textTransform: 'capitalize',
    },
    '& .subField': {
      fontSize: '1.4rem',
    },
    '& .containerField': {
      color: theme.palette.primary.main,
    },
    '& .container ': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fcfcfc',
      padding: '30px',
      marginTop: '0!important',
      borderRadius: '10px',
      boxShadow: theme.palette.mode === 'dark' ? 'none' : '0px 0px 6px 0px #69696933',
    },
    '& .sub-container': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fcfcfc',
      padding: '10px 15px',
      borderRadius: '10px',
    },

    '& .sub-container-child': {
      width: '100%',
      padding: '0',
      '& .sub-container-child': {
        paddingLeft: '10px',
      },
    },
    '& .bordered': {
      padding: '20px',
    },
    '& .img-container': {
      padding: '0 20px 0 0',
    },

    '& img': {
      width: '100%',
      objectFit: 'cover',
      maxHeight: 350,
      borderRadius: '10px',
    },
    '& .focus-element': {
      backgroundColor: theme.palette.mode === 'dark' ? '#525252' : '#f0f0f0',
      borderRadius: '4px',
      paddingBottom: '10px',
    },
    '& .date-field': {
      width: '100%!important',
      '& > div': {width: '100%!important'},
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-8px',
      },
      '& [placeholder="mm/dd/yyyy"]': {
        padding: '9px 20px',
      },
    },

    [theme.breakpoints.down(767)]: {
      '& .container': {
        padding: '15px',
      },
      '& .img-container': {
        padding: '0',
      },
    },
  },
  itemInfo: {
    padding: '1rem 0',
    margin: '1rem 0 2rem 0',
    width: '100%',
    '& .clickable': {
      cursor: 'pointer',
    },
    '& .attribute-text': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      '& .MuiSvgIcon-root': {
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
      },
      '&:hover .MuiSvgIcon-root': {
        opacity: 1,
      },
    },
    '& h6:first-of-type': {
      fontSize: '1.5rem',
      lineHeight: '1.7rem',
      padding: '0.5rem 0',
    },
    '& h6:last-of-type, & h6 > a': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        margin: '0 5px',
        fontSize: '20px',
      },
    },
    '& h6:last-of-type': {
      margin: '15px 0',
    },
    '& .MuiAccordion-rounded': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f9f9f9',
      borderRadius: '5px',
      position: 'static',
      '& .MuiAccordionSummary-content  > .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        '& .MuiSvgIcon-root': {
          margin: '0 5px',
          fontSize: '18px',
        },
      },
      '&.warning .MuiAccordionSummary-content  > .MuiTypography-root': {
        color: '#f3be1f',
      },
      '&.error .MuiAccordionSummary-content  > .MuiTypography-root': {
        color: '#ff0202',
      },
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div:first-of-type': {
      padding: '0',
    },
    '& img': {
      maxWidth: '350px',
      maxHeight: '350px',
      ObjectFit: 'cover',
      borderRadius: '5px',
      width: '100%',
    },
    '& .item-issues': {
      '&.warning li .MuiTypography-root': {
        color: '#f3be1f',
      },
      '&.error li .MuiTypography-root': {
        color: '#ff0202',
      },
      '& li': {
        alignItems: 'flex-start',
        padding: '0 16px',
        '& .MuiListItemIcon-root': {
          minWidth: '45px',
          '& svg': {
            fontSize: '20px',
            marginTop: '15px',
          },
        },
        '& .MuiListItemText-root': {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          overflowWrap: 'break-word',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 0 3rem 0',
      '& h6:first-of-type': {
        fontSize: '1.2rem',
        lineHeight: '1.3rem',
      },
    },
  },
  fieldsContainer: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
  },
  itemDetailsContainer: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
  },
  inputField: {
    margin: '0.50em 0',
    width: '95%',
  },
  buttonStyle: {
    '&.MuiButton-root': {
      margin: '0px 10px 0 0',
      fontSize: '0.8rem',
      [theme.breakpoints.down(1280)]: {
        display: 'none;',
      },
    },
  },
  iconButtonStyle: {
    display: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    margin: '0px 10px 0 0',
    padding: '6px 8px 3px 8px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down(1280)]: {
      display: 'block',
    },
  },
  iconFlag: {
    padding: '8px 8px 2px 8px',
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f3f3f3',
    borderRadius: '50%',
    width: '42px',
  },
  alert: {
    position: 'fixed',
    top: '10px',
    zIndex: 1202,
  },

  diffContainer: {
    '& >  span': {display: 'grid', gridTemplateColumns: '400px 100px 400px', gridGap: '10px'},
    [theme.breakpoints.down(670)]: {
      '& >  span': {display: 'grid', gridTemplateColumns: '300px 50px 300px', gridGap: '10px'},
    },
  },
  diffSubTitle: {},
  versionList: {
    '& .custom-accordion': {
      padding: '25px',
      '[aria-expanded="true"]': {
        display: 'block',
      },
      '[aria-expanded="false"]': {
        display: 'none',
      },
      [theme.breakpoints.down(767)]: {
        padding: '10px',
      },
    },
    '& .custom-accordion > ul': {
      display: 'grid',
      fontFamily: theme.typography.fontFamily,
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridGap: '1rem',
      '& .MuiListItemText-root': {
        fontFamily: theme.typography.fontFamily,
        padding: '0 1rem',
        marginTop: 0,
        alignSelf: 'start',

        '& > span': {
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            fontSize: '20px',
          },
        },
        '&  span': {
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px!important',
        },
      },
      '& .MuiTypography-root': {
        whiteSpace: 'break-spaces',
      },
      '& .versions-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start!important',
        color: theme.palette.mode === 'dark' ? '#c6c6c6' : '#858585',
        '& > span': {
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'clip',
          overflowWrap: 'break-word',
        },
      },
      '& .MuiListItemIcon-root': {
        minWidth: '25px',
        paddingTop: '4px',
        '& svg': {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
        },
      },
      '& .MuiTypography-body1:first-of-type': {
        fontWeight: 'bold',
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.54)',
        paddingBottom: '0.5rem',
        fontSize: '17px!important',
      },
      '& .MuiListItem-root': {
        alignItems: 'flex-start!important',
      },
      '& .MuiDivider-root': {
        margin: '10px 0',
        borderColor: theme.palette.mode === 'dark' ? '#818181' : 'rgba(0, 0, 0, 0.12)',
        '&:before, &:after': {
          borderColor: theme.palette.mode === 'dark' ? '#818181' : 'rgba(0, 0, 0, 0.12)',
        },
        '& > span': {
          backgroundColor: theme.palette.mode === 'dark' ? '#818181' : '#e9e9e9',
          color: theme.palette.mode === 'dark' ? '#e9e9e9' : '#757575',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          padding: '5px 6px 5px 5px!important',
          '& svg': {
            fontSize: '20px',
          },
        },
      },
      '& .diff-subtitle': {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '16px!important',
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
      },
    },
  },
  added: {color: '#ffffff', backgroundColor: '#469236', padding: '0 3px', borderRadius: '3px'},
  removed: {
    color: '#ffffff',
    backgroundColor: 'rgba(255, 0, 0, 0.575)',
    padding: '0 3px',
    borderRadius: '3px',
  },
  customGrid: {
    '&.MuiDataGrid-root ': {
      '& .MuiDataGrid-virtualScroller': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row': {
        maxHeight: '100%!important',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
      },
      '& .MuiDataGrid-virtualScrollerContent': {
        height: '100%!important',
        width: '100%!important',
      },
      maxHeight: '100%',
      '& .cell-action': {
        cursor: 'auto!important',
      },
      '& .hover-row': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f3f3f3',
        },
      },
      '& .MuiDataGrid-windowContainer, & .MuiDataGrid-virtualScrollerRenderZone': {
        height: 'auto!important',
        minHeight: '70px!important',
        position: 'relative',
        '& .MuiDataGrid-row': {
          alignItems: 'flex-start',
        },
      },
      '& .MuiDataGrid-row': {
        borderBottom: '1px solid #515151',
      },
      '& .MuiDataGrid-cell': {
        maxHeight: '100% !important',
        borderBottom: 'none',
      },
    },
  },
  productImages: {
    borderColor: theme.palette.mode === 'dark' ? '#a1a1a1' : theme.palette.primary.main,
    borderRadius: '10px',
    '& .MuiPaper-root': {
      contain: 'content',
      height: '100%',
      minHeight: '200px',
      '& label': {
        minWidth: 0,
        height: '100%',
        border: 0,
      },
    },
    '& .sc-bdvvtL': {
      '& span': {
        color: theme.palette.text.secondary,
      },
    },
  },
  activityIssues: {
    '& .custom-accordion > div': {
      padding: '20px',
      '& .issue-error': {
        color: theme.palette.mode === 'dark' ? '#f44336' : '#ff0202',
        '& svg': {
          fontSize: '18px',
          marginRight: '5px',
        },
      },
      '& .issue-warning': {
        color: theme.palette.mode === 'dark' ? '#ff9800' : '#ffbb00',
        '& svg': {
          fontSize: '18px',
          marginRight: '5px',
        },
      },
    },
    '& .MuiDivider-root': {
      margin: '10px 0',
      borderColor: theme.palette.mode === 'dark' ? '#818181' : 'rgba(0, 0, 0, 0.12)',
      '&:before, &:after': {
        borderColor: theme.palette.mode === 'dark' ? '#818181' : 'rgba(0, 0, 0, 0.12)',
      },
    },
    '& .custom-accordion > div > div': {
      padding: '20px',
      '& .activity-details': {
        '& .MuiTypography-body1:first-of-type': {
          fontWeight: 'bold',
          fontSize: '15px!important',
          color: theme.palette.mode === 'dark' ? '#54b142' : '#469236',
        },
      },
      '& div:not(.issue-error, .issue-warning) svg': {
        fontSize: '18px',
        marginRight: '5px',
        color: theme.palette.text.secondary,
      },
      '& .MuiTypography-root': {
        fontSize: '14px!important',
      },
      '&  .MuiTypography-body2': {
        fontSize: '13px!important',
        color: theme.palette.text.secondary,
      },
      [theme.breakpoints.down(767)]: {
        '& .MuiTypography-root': {
          fontSize: '13px',
        },
      },
    },
  },
  dateField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  customDivider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px 10px 0',
    '&.no-editable': {
      margin: '15px 0 0 0',
    },
    '&.attribute': {
      margin: '10px 0 0 0',
    },
    '&.add-btn': {
      padding: '20px 20px 0 20px',
    },
    '& hr': {
      flex: 1,
      margin: '0 8px',
    },
    [theme.breakpoints.down(767)]: {
      padding: '0px',
      '&.add-btn': {
        padding: '10px 10px 0 10px',
      },
    },
  },
  arrayContainer: {
    background: theme.palette.mode === 'dark' ? '#4c4c4c' : '#e1e1e1',
    padding: '15px 10px 0 10px!important',
    borderRadius: '5px',
    '&:not(:last-of-type)': {
      marginBottom: '15px',
    },
  },
  fieldHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '17px',
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    '& svg': {
      fontSize: '20px',
      marginLeft: '5px',
    },
    [theme.breakpoints.down(767)]: {
      fontSize: '16px',
      '& svg': {
        fontSize: '19px',
      },
    },
  },
  parentLabel: {
    fontSize: '1.3rem!important',
    padding: '5px 0 20px 0',
    '& svg': {
      fontSize: '20px!important',
    },
    [theme.breakpoints.down(767)]: {
      fontSize: '18px!important',
    },
  },
  attributeFieldHeader: {
    padding: '0 10px 10px 10px',
    width: '100%',
    fontSize: '18px',
    textTransform: 'capitalize',
    color: theme.palette.mode === 'dark' ? '#d8d8d8' : '#747474',
    [theme.breakpoints.down(767)]: {
      fontSize: '17px',
    },
  },
  subFields: {
    paddingLeft: '30px!important',
    '& > div': {
      margin: '10px 0',
      borderLeft: '1px solid #858585',
    },
    [theme.breakpoints.down(767)]: {
      paddingLeft: '10px!important',
    },
  },
  singleSubFields: {
    '& > div': {
      margin: '10px 0',
      [theme.breakpoints.down(767)]: {
        margin: '5px 0',
      },
    },
  },
  arrayItem: {
    '&:not(:first-of-type)': {
      marginTop: '15px',
    },
  },
  errorMessage: {
    fontSize: '14px',
    margin: '15px 0 0 20px',
    '&.field': {
      margin: '5px 0 0 0',
    },
  },
  errorAlert: {
    '& > div': {
      maxHeight: '90vh',
    },
    '& .MuiAlert-action': {
      position: 'absolute',
      right: '40px',
      zIndex: 2,
    },
    '& .MuiAlert-icon': {
      position: 'absolute',
      left: '20px',
      zIndex: 2,
    },
    '& .MuiAlert-message': {
      width: '100%',
      padding: 0,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? 'rgb(36, 18, 18)' : 'rgb(243, 217, 217)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'dark' ? 'rgb(244, 199, 199)' : 'rgb(95, 33, 32)',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.mode === 'dark' ? 'rgb(230, 182, 182)' : 'rgb(83, 28, 27)',
      },
      '& > p': {
        position: 'sticky',
        padding: '6px 0 15px 35px',
        background: theme.palette.mode === 'dark' ? 'rgb(22, 11, 11)' : 'rgb(253, 237, 237)',
        zIndex: 1,
        top: 0,
      },
      '& .MuiListItem-root': {
        cursor: 'pointer',
        paddingTop: '0',
        '&:hover': {
          background: theme.palette.mode === 'dark' ? 'rgb(36, 18, 18)' : 'rgb(243, 217, 217)',
        },
        '& .error-item span': {
          textTransform: 'capitalize',
        },
        '& .error-item:hover span': {
          textDecoration: 'underline',
        },
      },
    },
  },
  listingCard: {
    '& hr': {
      width: '100%',
      margin: '0',
    },
    '& > div .MuiPaper-root': {
      minHeight: 165,
      height: '100%',
      maxHeight: '165px!important',
      overflow: 'hidden',
      borderRadius: '0 0 5px 5px',
      margin: '0!important',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      '& > ul': {
        width: '100%',
      },
      '& .not-found': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '& > div:last-of-type': {
      '& .MuiPaper-root': {
        minHeight: 165,
        maxHeight: '165px!important',
        overflow: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
          width: '5px',
          height: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#999',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#777',
        },
        '& .MuiListItemButton-root': {
          maxHeight: '55px!important',
          '& .MuiListItemIcon-root': {
            minWidth: '40px!important',
            fontSize: '22px',
          },
        },
      },
    },
  },
  submitForm: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  subLabel: {
    fontSize: '1rem!important',
    padding: '5px 0 20px 0',
    '& svg': {
      fontSize: '20px!important',
    },
    [theme.breakpoints.down(767)]: {
      fontSize: '16px!important',
    },
  },
  formLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(66, 66, 66, 0.459)',
    zIndex: 2,
    '& .MuiCircularProgress-root': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles, styles};
