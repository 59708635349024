import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {useLocation} from 'react-router-dom';

import Button from 'src/components/Button/Button';

// import { Api } from 'src/utils/api';

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import {
  PaymentIntentResult,
  StripePaymentElementOptions
} from '@stripe/stripe-js';

interface Props {
  currentForm: string;
  setCurrentForm: (formName: string) => void;
}

export default function CheckoutForm({
  currentForm,
  setCurrentForm
}: Props): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // useCallback allows us to memoize the function so that it is only created once
  // and we can also use it as an async function outside of the useEffect hook.
  const processPaymentIntent = useCallback(async (clientSecret: string) => {
    // TODO call back end to update subscription?
    // TODO get brand_code from parent component and use that to
    // get brand and Stripe subscription details and that's how we can
    // associate the current user with Stripe subscription
    if (stripe) {
      const paymentIntentResult: PaymentIntentResult = await stripe.retrievePaymentIntent(clientSecret);
      switch (paymentIntentResult.paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }
  }, [stripe]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = urlParams.get('payment_intent_client_secret');
    if (!clientSecret) {
      return;
    }
    processPaymentIntent(clientSecret);
  }, [stripe, urlParams, processPaymentIntent]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // TODO should we remove this in a different component for payment complete?
    // localStorage.removeItem('stripeClientSecret');

    // TODO set stripe_subscription_client_secret to null in the database
    // when successful in the payment complete component

    let error;
    if (currentForm === 'createPaymentMethod') {
      // Provide payment for a new Stripe subscription of a reactivated plan.
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: process.env.REACT_APP_STRIPE_PAYMENT_COMPLETE_URL !== undefined ? process.env.REACT_APP_STRIPE_PAYMENT_COMPLETE_URL: '',
        },
      });
      error = response.error;
    } else if (currentForm === 'updatePaymentMethod') {
      // Provide payment for an existing Stripe subscription of an active plan.
      const response = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: process.env.REACT_APP_STRIPE_PAYMENT_COMPLETE_URL !== undefined ? process.env.REACT_APP_STRIPE_PAYMENT_COMPLETE_URL: '',
        },
      });
      error = response.error;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      if (error.message) {
        setMessage(error.message);
      }
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form
      className="subscription-form"
      id="payment-form"
    >
      <h5>Update your payment method</h5>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <a className="stripe-powered" href="https://stripe.com" target="_blank" rel="noreferrer">
        <span>Powered by
          <svg focusable="false" width="33" height="15" role="img" aria-labelledby="stripe-title"><title id="stripe-title">Stripe</title><g fillRule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
        </span>
      </a>
      <div className="buttons">
        <Button
          size="small"
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
        >
          Update
        </Button>
        <Button
          size="small"
          onClick={() => setCurrentForm('')}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
      <div className="terms">
        By confirming your subscription, you allow FlatFilePro to charge you for future payments in accordance with our <a href="https://flatfile.pro/terms-of-service/" target="_blank" rel="noreferrer">terms of service</a>.
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}