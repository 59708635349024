import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import ReflectingDifferenceContainer from 'src/components/ReflectingDifferenceContainer';

import BulkEditContainer from './components/BulkEditContainer';
import ManageVariationsContainer from './components/ManageVariationsContainer';
import IssuesSummary from './components/IssuesSummary';

import {
  Autocomplete,
  Grid,
  TextField,
  LinearProgress,
  MenuItem,
} from '@mui/material';

import {
  DataGrid,
  useGridApiContext,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderEditCellParams,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridSelectionModel,
} from '@mui/x-data-grid';

import {
  AccessTime,
  Edit,
  DifferenceOutlined,
  Visibility,
} from '@mui/icons-material';

import _ from 'lodash';

// services
import mixPanel from '../../services/mixpanel';
import { accountService } from '../../services/account.service';
import { alertService } from '../../services/alert.service';

// components
import TableContainer from '../../components/TableContainer/TableContainer';
import SearchBar from '../../components/SearchBar/SearchBar';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import TableSelectInput from '../../components/TableSelectInput/TableSelectInput';
import CollapsibleDataGrid from '../../components/CollapsibleDataGrid/CollapsibleDataGrid';
import ModalButton from '../../components/ModalButton/ModalButton';
import { CustomAlert } from '../Login/components/CustomAlert';
import Button from '../../components/Button/Button';
import { selectedColumns, defaultColumns } from './components/customColumns';
import DataGridToolbar from '../../components/DataGridToolbar/DataGridToolbar';

// styles
import { sxStyles } from './Styles';

// utils
// import { getSubBrands } from '../../utils/getSubBrands';
import { Role } from '../../utils/role';

import {
  ListingsItemStatuses,
  Marketplaces,
} from './utils/constant';

import { setUserPreferences, getUserPreferences, getColumnsItems } from '../../utils/tableSettings';
import { getArticle } from '../../utils/FFPBeacon';
import { CustomColumn, makeColumnFreeze, fixedPlaceHolder } from '../../utils/makeColumnFreeze';
import { rowsPerPageOptions } from '../../utils/constants';
import { Api, errorAlert } from '../../utils/api';
import { QueryValuesInterface, getDefaultQuery, updateQueryParams } from '../../utils/urlParams';
import { BrandTypesAlias } from '../../utils/brand-types';

import { AppContext } from '../../context/AppContext/AppContext';

// import { BrandInterface } from '../../interfaces/brands/brand';
import { FormikValues } from 'formik';
import { BrandRolesInterface } from '../../context/AppContext/interfaces/interfaces';

import {
  CountIssues,
  CountStatuses,
  Difference,
  ListingsInterface,
  ListingReflectingState,
  AuthorizedMarketplaceWithoutRefreshToken,
} from './interfaces/interfaces';

// const groups = ['parent_asin'];
const optionsForManagedByAgency = ['TRUE', 'FALSE'];
interface AmazonListingItemsProps {
  GAEvents?: (category: string, action: string, label?: string) => void;
}

type FunctionParamsTypes = {
  filter: Filter;
  page: number;
  pageSize: number;
  groupBy: string | null;
  marketplaceId?: string | null;
  sellerId?: string | null;
  isManagedByAgency?: string | null;
};

type Visibility = {
  [key: string]: boolean;
};

interface AuthorizedSeller {
  sellerId: string;
  sellerName: string;
}

type Filter = {
  brands: string[] | null;
  subBrands?: string[] | null;
  statuses: string | null;
  isManagedByAgency: string | null;
  issue: string | null;
  code: string | null;
  groupBy: string | null;
  asin: string | null;
  marketplaceId: string | null;
  // TODO allow selecting multiple sellers
  // sellerIds: string[];
  sellerId: string | null;
  sku?: string | null;
  itemTitle?: string | null;
};

interface ListingsSkuInterface {
  id?: string | number;
  sku: string;
  marketplace: string;
}

type SelectedCustomField = {
  field: string | null;
  data: ListingsSkuInterface[];
};

const defaultEmptyFilter: Filter = {
  sku: null,
  itemTitle: null,
  brands: null,
  subBrands: null,
  statuses: null,
  issue: null,
  code: null,
  groupBy: null,
  asin: null,
  marketplaceId: null,
  // TODO allow selecting multiple sellers
  // sellerIds: [],
  sellerId: null,
  isManagedByAgency: null,
};

export default function AmazonListings({ GAEvents }: AmazonListingItemsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [updatedListing, setUpdatedListing] = useState<
    {
      marketplace_id: string;
      asin: string;
      sku: string;
      version: number
    } | null
  >(null);
  const [listingDifferences, setListingDifferences] = useState<Difference[]>([]);

  const [allIssuesTotal, setAllIssuesTotal] = useState<number>(0);
  const [countIssues, setCountIssues] = useState<CountIssues | null>(null);
  const [countStatuses, setCountStatuses] = useState<CountStatuses | null>(null);

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [openBulkEdit, setOpenBulkEdit] = useState(false);
  const [openManageVariations, setOpenManageVariations] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState<string>('');

  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [listingRows, setListingRows] = useState<ListingsInterface['rows']>([]);

  const [sellers, setSellers] = useState<AuthorizedSeller[]>([]);
  const [availableMarketplacesIds, setAvailableMarketplacesIds] = useState<string[]>( // eslint-disable-line
    Marketplaces.map((marketplace) => marketplace.id),
  );

  const [filter, setFilter] = useState<Filter>(defaultEmptyFilter);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  // const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [groupByColumns, setGroupByColumns] = useState<GridColDef[]>([]);
  const [groupByVisibility, setGroupByVisibility] = useState<Visibility>({});
  const [visibilityModel, setVisibilityModel] = useState<Visibility>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [groupByValue, setGroupByValue] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | string | null>(0);
  const [switchTable, setSwitchTable] = useState(false);
  const [deleteItem, setDeleteItem] = useState<FormikValues | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableFields, setTableFields] = useState(
    {} as {
      [key: string]: {
        label: string;
        type: string;
        options: string;
        value?: string | number | boolean | null;
        custom_attribute: boolean;
      };
    },
  );

  // const [subBrandLoading, setSubBrandLoading] = useState(false);
  const [selectedCustomAttrData, setSelectedCustomAttrData] = useState<SelectedCustomField>({ field: null, data: [] });
  const [utilsLoading, setUtilsLoading] = useState(true);
  const customButtons: {
    icon?: ((v: { reflectingState?: ListingReflectingState }) => JSX.Element) | JSX.Element;
    tooltip?: ((v: { reflectingState?: ListingReflectingState }) => string) | string;
    url?: (v: { marketplace_id: string; seller_id: string, sku: string }) => string;
    onClick?: (v: FormikValues) => void;
  }[] = [
      {
        icon: () => <Edit />,
        tooltip: () => 'Edit',
        url: (v) => `/amazon-listings-item/${v.marketplace_id}/${v.seller_id}/${encodeURIComponent(v.sku)}/edit`,
      },
      {
        icon: <AccessTime />,
        tooltip: 'Activity',
        url: (v: { marketplace_id: string; seller_id: string; sku: string }) =>
          `/amazon-listings-item/${v.marketplace_id}/${v.seller_id}/${encodeURIComponent(v.sku)}/activity`,
      },
      {
        icon: (v) => (v.reflectingState === ListingReflectingState.REFLECTED ? <></> : <DifferenceOutlined className="pending-changes" />),
        tooltip: (v) => (v.reflectingState === ListingReflectingState.REFLECTED ? '' : 'Pending changes on Amazon'),
        onClick: (v: FormikValues) => {
          getDifference(
            v.marketplace_id,
            v.asin,
            v.sku,
            v.version,
            handleDiffModal,
          );
        },
      }
    ];

  const listingReflectingState: {
    [key in ListingReflectingState]: string
  } = {
    [ListingReflectingState.NOT_REFLECTED]: 'reflecting-state-not-reflected',
    [ListingReflectingState.REFLECTED]: 'reflecting-state-reflected',
    [ListingReflectingState.FAILED]: 'reflecting-state-failed',
  };

  const getDifference = async (
    marketplace_id: string,
    asin: string,
    sku: string,
    version: number,
    handleDiffModal: (
      differences: Difference[],
      marketplace_id: string,
      asin: string,
      sku: string,
      version: number,
    ) => void,
  ) => {
    try {
      const { data } = await Api.get(`amazon-listings/check-if-reflected`, {
        params: {
          marketplace_id,
          seller_sku: sku,
        },
      });
      // Show modal only if there are differences.
      if (data.filteredDifferences.length) {
        handleDiffModal(
          data.filteredDifferences,
          marketplace_id,
          asin,
          sku,
          version,
        );
      }
    } catch (e) {
      errorAlert('Error getting listing update differences.', e);
    }
  };

  const manageVariationsSuccess = async (message: string) => {
    setSelectionModel([]);
    alertService.success(message);
  };

  const bulkEditSuccess = async (message: string) => {
    await fetchData();
    setSelectionModel([]);
    alertService.success(message);
  };

  const handleCloseBulkEdit = () => {
    setOpenBulkEdit(false);
  };

  const handleCloseManageVariations = () => {
    setOpenManageVariations(false);
  };

  const handleClose = () => {
    setOpen(false);
    setUpdatedListing(null);
    setListingDifferences([]);
  };

  const handleDiffModal = (
    differences: Difference[],
    marketplace_id: string,
    asin: string,
    sku: string,
    version: number,
  ) => {
    setOpen(true);
    setUpdatedListing({
      marketplace_id,
      asin,
      sku,
      version,
    });
    setListingDifferences(differences);
  };

  const {
    brands,
    appLoading,
    getBrandRoles,
    brandUtils
  } = useContext(AppContext);

  const disableBrandsFilter = useMemo(() => loadingData || brands.length === 0, [brands.length, loadingData]);

  const thisRef = useRef(null);

  let selectedCustomAttr: {
    field: string | null;
    data: ListingsSkuInterface[];
  } = { field: null, data: [] };

  let skusByPage: ListingsSkuInterface[] = [];

  const setSelectedCheckboxes = (
    id: string,
    all: boolean,
    value: boolean,
    skus?: ListingsSkuInterface[],
    setProperty?: boolean,
  ) => {
    const input = document.getElementById(id) as HTMLInputElement | null;
    if (input) {
      input.checked = value;
      if (setProperty) {
        input.setAttribute('clear', 'true');
      } else {
        input.removeAttribute('clear');
      }
    }
    if (all) {
      const selectedSKUS = skus ? skus : selectedCustomAttr.data;

      selectedSKUS.forEach((i) => {
        const input = document.getElementById(
          `${id}-${i.sku}-${i.marketplace}-${i.id}`,
        ) as HTMLInputElement | null;

        if (input) {
          input.checked = value;
          if (setProperty) {
            input.setAttribute('clear', 'true');
          } else {
            input.removeAttribute('clear');
          }
        }
      });
    }
  };

  const resetCheckboxes = () => {
    setSelectedCheckboxes(selectedCustomAttrData.field as string, true, false, selectedCustomAttrData.data, true);
  };

  const getFieldOptions = (field: string) => {
    const optionsArray = field === 'role' ? brandUtils?.brandRoles : [];
    return optionsArray;
  };

  const checkboxHandleChange = (
    v: React.ChangeEvent<HTMLInputElement>,
    field: string,
    selectedAll: boolean | undefined,
    skusByPage: ListingsSkuInterface[],
    sellerSKU?: ListingsSkuInterface | null,
  ) => {
    const currentValue = v.target.value;

    if (selectedCustomAttr.field !== field) {
      setSelectedCheckboxes(selectedCustomAttr.field as string, true, false);
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
    }

    if (selectedAll) {
      if (v.target.checked) {
        selectedCustomAttr.data = [...selectedCustomAttr.data, ...skusByPage];
        selectedCustomAttr.data = selectedCustomAttr.data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.sku === value.sku && t.marketplace === value.marketplace),
        );
        selectedCustomAttr.field = currentValue;
        setSelectedCheckboxes(currentValue, true, true, skusByPage);
      } else {
        selectedCustomAttr.data = [];
        selectedCustomAttr.data.filter(
          (i) => !skusByPage.find((t) => t.sku === i.sku && t.marketplace === i.marketplace),
        );
        selectedCustomAttr.field = null;
        setSelectedCheckboxes(currentValue, true, false, skusByPage);
      }
    } else {
      if (v.target.checked) {
        if (selectedCustomAttr.field === field && sellerSKU) {
          selectedCustomAttr.data.push(sellerSKU);
        } else {
          selectedCustomAttr.field = field;
          selectedCustomAttr.data = [sellerSKU as ListingsSkuInterface];
        }
      } else {
        const index = selectedCustomAttr.data.findIndex(
          (x) =>
            x.id === sellerSKU?.id &&
            x.sku === sellerSKU?.sku &&
            x.marketplace === sellerSKU?.marketplace,
        );
        if (index > -1) {
          selectedCustomAttr.data.splice(index, 1);
        }
      }
    }

    setSelectedCustomAttrData({ ...selectedCustomAttr });
  };

  const BulkCustomAttributes = ({
    value,
    selectAll,
  }: {
    value: {
      label: string;
      sku?: string;
      marketplace?: string;
      id?: string;
      skus?: ListingsSkuInterface[];
    };
    selectAll?: boolean;
  }) => {
    let isSelected = false;
    const checkboxes = document.querySelectorAll("input[clear='true']:checked");

    if (!selectAll && value.skus && value.skus.length > 0) {
      skusByPage = value.skus;
    }

    if (checkboxes.length > 0) {
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
      checkboxes.forEach((i) => i.removeAttribute('clear'));
    }

    if (!selectAll) {
      if (
        selectedCustomAttr.field === value.label &&
        selectedCustomAttr.data.find((i) => i.sku === value.sku && i.marketplace === value.marketplace)
      ) {
        isSelected = true;
      }
    }

    if (
      selectAll &&
      selectedCustomAttr.field === value.label &&
      skusByPage.every(
        (i) =>
          selectedCustomAttr.data.find((t) => t.sku === i.sku && t.marketplace === i.marketplace)?.id ===
          i.id,
      )
    ) {
      isSelected = true;
    }

    return (
      <input
        className="column-checkbox"
        type="checkbox"
        id={selectAll ? value.label : `${value.label}-${value.sku}-${value.marketplace}-${value.id}`}
        value={selectAll ? value.label : `${value.label}-${value.sku}-${value.marketplace}-${value.id}`}
        checked={isSelected}
        onChange={(v) => {
          checkboxHandleChange(
            v,
            value.label,
            selectAll,
            skusByPage,
            value.sku
              ? {
                sku: value.sku as string,
                marketplace: value.marketplace as string,
                id: value.id as string,
              }
              : null,
          );
          if (v.target.checked) {
            isSelected = true;
          } else {
            isSelected = false;
          }
        }}
      />
    );
  };

  const CustomEditComponent = ({ props: { id, value, field } }: { props: GridRenderEditCellParams }) => {
    const apiRef = useGridApiContext();
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const v = (event?.target as HTMLInputElement)?.value;
      apiRef.current.setEditCellValue({ id, field, value: v });
    };
    const handleRef = (element: HTMLDivElement | null) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        (input as HTMLElement)?.focus();
      }
    };

    return (
      <TextField
        className="custom-textfield"
        ref={handleRef}
        onChange={handleValueChange}
        onBlurCapture={() => {
          selectedCustomAttr = { data: [], field: null };
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            selectedCustomAttr = { data: [], field: null };
          }
        }}
        value={value}
        variant="outlined"
      />
    );
  };

  const SelectInputCell = ({
    props: { id, value, field },
    optionsArray,
    currentItem,
    required,
  }: {
    props: GridRenderEditCellParams;
    optionsArray: BrandRolesInterface[];
    currentItem: BrandRolesInterface;
    required?: boolean;
  }) => {
    const apiRef = useGridApiContext();

    const handleChange = (event: string) => {
      apiRef.current.setEditCellValue({ id, field, value: event });
    };

    const handleRef = (element: HTMLInputElement | null) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        (input as HTMLElement)?.focus();
      }
    };

    return (
      <TableSelectInput
        ref={handleRef}
        required={required}
        onBlurCapture={() => {
          selectedCustomAttr = { data: [], field: null };
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
          if (e?.key === 'Enter') {
            selectedCustomAttr = { data: [], field: null };
          }
        }}
        value={currentItem?.id}
        renderValue={currentItem?.email}
        onChange={(e, v) => handleChange(v)}
        options={optionsArray?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.email}
          </MenuItem>
        ))}
      />
    );
  };

  const newColumns: GridColDef[] = _.map(tableFields, ({ type, label, options, custom_attribute }, key) => {
    let component = null;
    if (type === 'select') {
      component = {
        field: key,
        headerName: label,
        flex: 1,
        minWidth: 280,
        filterable: false,
        editable: true,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {custom_attribute && params.colDef.headerName && (
                <BulkCustomAttributes value={{ label: params.colDef.headerName }} selectAll />
              )}
              {label}
            </div>
          );
        },
        sortable: !custom_attribute,
        renderCell: ({ row }: GridRenderCellParams) => {
          const optionsArray = getFieldOptions(options);
          const values = {
            ...{ type, label, options, custom_attribute },
            seller_sku: row.sku,
            marketplace: row.marketplace_id,
            id: row.id,
            skus: row.skus,
          };
          const currentItem = optionsArray?.find((item) => {
            return item.id === row[key] || item.email === row[key];
          });

          return (
            <>
              {custom_attribute && <BulkCustomAttributes value={values} />}
              <TableSelectInput
                readOnly
                value={currentItem?.id as string}
                loading={utilsLoading}
                renderValue={currentItem?.email ? (currentItem.email as string) : (currentItem?.id as string)}
              />
            </>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          const optionsArray = getFieldOptions(options);
          const currentItem = optionsArray?.find((item) => {
            return item.id === params.row[key] || item.email === params.row[key];
          });

          return (
            <SelectInputCell
              props={params}
              optionsArray={optionsArray}
              currentItem={currentItem as BrandRolesInterface}
            />
          );
        },
      };
    } else if (type === 'text' || type === 'number') {
      component = {
        field: key,
        headerName: label,
        flex: 1,
        minWidth: 250,
        filterable: false,
        editable: true,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {custom_attribute && params.colDef.headerName && (
                <BulkCustomAttributes value={{ label: params.colDef.headerName }} selectAll />
              )}
              {label}
            </div>
          );
        },
        sortable: !custom_attribute,
        renderCell: ({ row }: GridRenderCellParams) => {
          const values = {
            ...{ type, label, options, custom_attribute },
            seller_sku: row.sku,
            marketplace: row.marketplace_id,
            id: row.id,
            skus: row.skus,
          };
          const newValue = row[key]?.toString().includes('https://') ? (
            <a target="_blank" rel="noopener noreferrer" style={{ color: '#3c8dc7' }} href={row[key]}>
              {row[key]}
            </a>
          ) : (
            row[key]
          );
          return (
            <span style={{ width: '100%', height: '100%' }}>
              {custom_attribute && <BulkCustomAttributes value={values} />}
              {row[key] ? newValue : 'N/A'}
            </span>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <CustomEditComponent props={params} />;
        },
      };
    } else {
      component = {
        field: key,
        headerName: label,
        flex: 1,
        minWidth: 250,
        filterable: false,
        sortable: false,
      };
    }
    return component;
  }).filter((item) => item !== null);

  useEffect(() => {
    async function getTableData() {
      const defaultColumnsData = defaultColumns(
        filter.groupBy,
        newColumns,
        sxStyles,
      );
      const data = await getUserPreferences({
        list: filter.groupBy ? selectedColumns : defaultColumnsData,
        tableName: filter.groupBy ? `listings-group-by-${filter.groupBy}` : 'listings',
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        if (filter.groupBy) {
          setGroupByColumns(data.columns);
          setGroupByVisibility(data.visibility);
        } else {
          setOrderColumns(data.columns);
          setVisibilityModel(data.visibility);
        }
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.groupBy, utilsLoading]);

  // async function pullSubBrands(brandCodes: string[]) {
  //   let newSubBrands: BrandInterface[] = [];

  //   const data = await getSubBrands(brandCodes, setSubBrandLoading);

  //   if (data) {
  //     newSubBrands = data;
  //   }

  //   const userSubBrandCodes = accountService.userValue.sub_brand_codes;

  //   const newFilter: {
  //     subBrands?: string[];
  //   } = {};

  //   if (![Role.Admin].includes(accountService.userValue.role)) {
  //     if (userSubBrandCodes && userSubBrandCodes.length > 0) {
  //       newSubBrands = newSubBrands.filter((subBrand) => userSubBrandCodes.includes(subBrand.brand_code));
  //       // This is a hack to make sure the user can only see their own sub brands
  //       newFilter.subBrands = newSubBrands
  //         .filter((subBrand) => accountService.userValue.sub_brand_codes.includes(subBrand.brand_code))
  //         .map((brand) => brand.brand_code);
  //     } else {
  //       newSubBrands = [];
  //     }
  //   }
  //   setSubBrands(newSubBrands);
  //   setFilter((prev) => ({ ...prev, ...newFilter }));
  // }

  async function fetchData() {
    const {
      selectedSellerId,
      selectedMarketplaceId,
    } = await setAuthorizedSellerAndMarketplace();

    const defaultQuery = getDefaultQuery(location, { ...filter, page, pageSize });
    try {
      if ((defaultQuery.brands && defaultQuery.brands.length > 0) || accountService.userValue.role === Role.BrandUser) {
        // if (accountService.userValue.role === Role.BrandUser) {
        //   pullSubBrands([accountService.userValue.brand_code]);
        // } else if (defaultQuery.brands) {
        //   pullSubBrands(defaultQuery.brands.split(','));
        // }
      }

      let updatedBrands;
      if (brands.length === 0) {
        const { data } = await Api.get('brands/ids');
        updatedBrands = data.map((brand: { brand_code: string, name: string }) => brand.brand_code).sort();
      } else {
        updatedBrands = brands.map((brand: { brand_code: string, name: string }) => brand.brand_code).sort();
      }

      const filterData: Filter = {
        brands:
          accountService.userValue.role === Role.BrandUser
            ? [accountService.userValue.brand_code]
            : defaultQuery.brands ? defaultQuery.brands?.split(',') || null : [updatedBrands[0]],
        subBrands: defaultQuery.subBrands?.split(',') || null,
        statuses: defaultQuery?.statuses || null,
        issue: defaultQuery?.issue || null,
        code: defaultQuery?.code || null,
        groupBy: defaultQuery?.groupBy,
        // marketplaceId: filter?.marketplaceId || null,
        marketplaceId: filter?.marketplaceId || selectedMarketplaceId || null,
        isManagedByAgency: defaultQuery?.isManagedByAgency,
        asin: defaultQuery?.asin || null,
        // sellerId: filter?.sellerId || null,
        sellerId: filter?.sellerId || selectedSellerId || null,
        // sellerId: filter?.sellerId,
      };

      if (urlParams.get('sku')) {
        filterData.sku = urlParams.get('sku');
      }
      if (urlParams.get('itemTitle')) {
        filterData.itemTitle = urlParams.get('itemTitle');
      }
      if (urlParams.get('asin')) {
        filterData.asin = urlParams.get('asin');
      }
  
      const tableData = {
        page: defaultQuery.page ? +defaultQuery.page : 0,
        pageSize: defaultQuery.pageSize ? +defaultQuery.pageSize : 40,
      };
      setFilter(filterData);
      setPage(tableData.page);
      setPageSize(tableData.pageSize);
      setGroupByValue(filterData.groupBy);

      updateQueryParams(location, { ...filterData, ...tableData });

      await loadData({
        filter: filterData,
        isManagedByAgency: filterData.isManagedByAgency,
        groupBy: filterData.groupBy,
        marketplaceId: filterData.marketplaceId,
        sellerId: filterData.sellerId,
        page: tableData.page,
        pageSize: tableData.pageSize,
      });
    } catch (e) {
      errorAlert('Unable to fetch listings', e);
    }
  }

  async function loadData(params: FunctionParamsTypes, updateCustomAttr?: boolean) {
    updateQueryParams(location, { ...params, ...params.filter } as QueryValuesInterface, ['filter']);
    if (params?.filter) {
      Object.keys(params?.filter).forEach((k) => {
        const key = k as keyof typeof params.filter;
        if (!params.filter[key]) delete params.filter[key];
      });
    }
    if (updateCustomAttr) setUtilsLoading(true);
    setLoadingData(true);
    if (params.groupBy) {
      if (loadingData) {
        setSwitchTable(true);
      }
    }

    // const getAttributes = (data: {
    //   [key: string]: {
    //     value: string;
    //     type: string;
    //   };
    // }) => {
    //   let customAttributes = {};
    //   Object.entries(data).forEach(([key, row]) => {
    //     customAttributes = {
    //       ...customAttributes,
    //       [key.replaceAll(' ', '_')]: row.value,
    //     };
    //   });
    //   return customAttributes;
    // };

    try {
      const {
        data,
      }: {
        data: ListingsInterface;
      } = await Api.get(`amazon-listings/items`, {
        params,
      });
      setCount(data.count);
      if (!params.groupBy) {
        const skus = data.rows.map((listing, index) => {
          return { sku: listing.sku, marketplace: listing.marketplace_id, id: index };
        });
        setListingRows(
          data.rows.map((listing, index) => {
            // const attr = getAttributes(listing?.custom_attributes);
            return {
              id: index,
              ...listing,
              // ...attr,
              skus: skus,
            };
          }),
        );
      } else {
        // TODO groupBy logic
        setListingRows(
          data.rows.map((listing, index) => {
            return { id: index, ...listing };
          }),
        );
      }

      // Load and display issues for listings on the page.
      // Using POST as it could be a long list of SKUs.
      const skus = data.rows.map(row => row.sku);
      if (params.filter.sellerId && params.filter.marketplaceId) {
        try {
          const issuesResponse = await Api.post(
            'amazon-listings/issues',
            {
              skus,
              sellerId: params.filter.sellerId,
              marketplaceId: params.filter.marketplaceId,
            }
          );
          setListingRows(
            data.rows.map((listing, index) => {
              return {
                id: index,
                ...listing,
                skus: skus,
                issues: issuesResponse.data[listing.sku] || [],
              };
            }),
          );
        } catch (e) {
          errorAlert('Unable to load listings issues', e);
        }
      }

      // TODO review
      // let customAttributes = {};
      // const attributeType = {
      //   TEXT: 'text',
      //   LINK: 'text',
      //   NUMBER: 'number',
      //   USER: 'select',
      // };
      // if (!params.groupBy) {
      //   if (data.rows.length > 0) {
      //     Object.entries(data.rows[0]?.custom_attributes).forEach(([key, row]) => {
      //       customAttributes = {
      //         ...customAttributes,
      //         [key.replaceAll(' ', '_')]: {
      //           type: attributeType[row.type as keyof typeof attributeType],
      //           value: null,
      //           label: key,
      //           custom_attribute: true,
      //           options: attributeType[row.type as keyof typeof attributeType] === 'select' ? 'role' : null,
      //         },
      //       };
      //     });
      //   }
      //   setTableFields({ ...customAttributes });
      // }

    } catch (e) {
      errorAlert('Unable to load listings data, make sure you have connected a Seller Central account. ', e);
    } finally {
      setLoadingData(false);
      setSwitchTable(false);
      setUtilsLoading(false);
    }

    await getSummaryData(params);

  }

  const getSummaryData = async (params: FunctionParamsTypes) => {
    try {
      const {data} = await Api.get(`amazon-listings/items/summary`, {
        params: {
          countBy: 'status',
          ...params,
        },
      });
      // debug
      // console.log('data from /amazon-listings/items/summary');
      // console.log(data);
      // end debug
      setCountIssues(data.countIssues);
      setCountStatuses(data.countStatuses);
      setAllIssuesTotal(data.allIssuesTotal);
    } catch (e) {
      errorAlert('Unable to load summary data by status', e);
    }

    // older
    // setCountLoading(true);
    // try {
    //   const {data} = await Api.get(`amazon-listings/items/summary`, {
    //     params: {
    //       countBy: 'status',
    //       ...params,
    //     },
    //   });
    //   setStatusCount(data);
    //   try {
    //     const {data} = await Api.get(`amazon-listings/items/summary`, {
    //       params: {
    //         countBy: 'code',
    //         ...params,
    //       },
    //     });
    //     setCodeCount(data);
    //   } catch (e) {
    //     errorAlert('Unable to load summary data by code', e);
    //   } finally {
    //     setCountLoading(false);
    //   }
    // } catch (e) {
    //   setCountLoading(false);
    //   errorAlert('Unable to load summary data by status', e);
    // }
  };

  const handleChangeBrandFilter = ({ brands, subBrands }: { brands: string[]; subBrands?: string[] }) => {
    if (brands?.length === 0) {
      subBrands = [];
    }

    if (brands?.length > 0) {
      // pullSubBrands(brands);
      setFilter((prev) => ({ ...prev, brands, subBrands }));
    } else {
      setFilter((prev) => ({
        ...prev,
        brands,
        subBrands: [],
      }));
      subBrands = [];
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    loadData({ filter, page, pageSize, groupBy: filter.groupBy });
  };

  async function handleChangePageSize(pageSize: number) {
    setPageSize(pageSize);
    setPage(0);
    loadData({ filter, page: 0, pageSize, groupBy: filter.groupBy }, true);
    resetCheckboxes();
  }

  async function onDeleteItem(item: FormikValues, closeModal: () => void) {
    resetCheckboxes();
    try {
      await Api.delete(`amazon-listings/item`, {
        params: {
          marketplace_id: item?.marketplace_id,
          brand_code: item?.brand_code,
          seller_id: item?.seller_id,
          seller_sku: item?.sku,
        },
      });
      setDeleteItem(null);
      alertService.success('Item deleted successfully');
      loadData({ filter, page: 0, pageSize, groupBy: filter.groupBy });
      closeModal();
    } catch (e) {
      setDeleteItem(null);
      errorAlert('Unable to delete item', e);
    }
  }

  // async function updateCustomAttr(selectedAttributes: SelectedCustomField, value: string | number, field: string) {
  //   const seller_skus = selectedAttributes.data.filter(
  //     (value, index, self) => index === self.findIndex((t) => t.id === value.id),
  //   );

  //   const attributes = seller_skus.map((i) => {
  //     return {
  //       seller_sku: i.sku,
  //       marketplace_id: i.marketplace,
  //       name: selectedAttributes.field,
  //       value: value,
  //     };
  //   });

  //   setLoadingData(true);

  //   try {
  //     await Api.put(`custom-attribute/bulk`, attributes);
  //     alertService.success('Brand updated successfully');
  //     selectedAttributes.data.forEach((i) => {
  //       const foundIndex = listingRows.findIndex((x) => x.id === i.id);
  //       const items = [...listingRows];
  //       const item = { ...items[foundIndex] };
  //       item[field as keyof typeof item] = value as never;
  //       items[foundIndex] = item;
  //       setListingRows(items);
  //     });
  //   } catch (e) {
  //     errorAlert('Unable to update brand', e);
  //   } finally {
  //     setLoadingData(false);
  //     setSelectedCheckboxes(selectedAttributes.field as string, true, false);
  //     setSelectedCustomAttrData({ field: null, data: [] });
  //   }
  // }

  const customToolbarProps = {
    loadingData: loadingData || tableLoading,
    items: filter.groupBy ? groupByColumns : orderColumns,
    ignoreItems: ['actions'],
    setItems: (v: GridColDef[]) => {
      if (filter.groupBy) {
        setGroupByColumns(v);
      } else {
        setOrderColumns(v);
      }
      setUserPreferences(
        {
          columnVisibilityModel: filter.groupBy ? groupByVisibility : visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        filter.groupBy ? `listings-group-by-${filter.groupBy}` : 'listings',
        setTableLoading,
      );
      resetCheckboxes();
    },
  };

  useEffect(() => {
    getBrandRoles?.();
    fetchData();
    mixPanel({
      eventName: 'View listings',
    });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getArticle({});
  }, [loadingData]);

  useEffect(() => {
    if (!filter.groupBy) makeColumnFreeze(thisRef, visibilityModel, listingRows);
  }, [visibilityModel, listingRows, filter.groupBy, switchTable]);

  useEffect(() => {
    if (urlParams.get('fetchingListings') === 'true') {
      alertService.success('Selling Partner API authorization granted successfully. Your listings will be available in a few minutes.');
    }
  }, [urlParams]);

  // debug, preselect listings
  // useEffect(() => {
  //   setSelectionModel([9, 10]);
  // }, []);
  // end debug

  async function setAuthorizedSellerAndMarketplace() {
    // Get authorized marketplaces without refresh tokens from the database and
    // use localStorage with state to persist.

    const sellers: AuthorizedSeller[] = [];

    try {
      const {
        data,
      } = await Api.get('brands/get-authorized-marketplaces');
      if (data.sp_api_authorized_marketplaces.length > 0) {
        data.sp_api_authorized_marketplaces.forEach((marketplace: AuthorizedMarketplaceWithoutRefreshToken) => {
          // Push to sellers array if it does not include an object
          // with the same sellerId and sellerName already.
          if (!sellers.some((seller) => seller.sellerId === marketplace.selling_partner_id && seller.sellerName === marketplace.seller_name)) {
            sellers.push({
              sellerId: marketplace.selling_partner_id,
              sellerName: marketplace.seller_name,
            });
          }
        });
      }
      setSellers(sellers);
    } catch (e) {
      console.error('Error getting authorized marketplaces', e);
    }

    let selectedSellerId = localStorage.getItem('selectedSellerId') || '';
    if (!selectedSellerId && sellers.length > 0) {
      selectedSellerId = sellers[0].sellerId;
    }
    localStorage.setItem('selectedSellerId', selectedSellerId as string);

    let selectedMarketplaceId = localStorage.getItem('selectedMarketplaceId') || '';
    if (!selectedMarketplaceId) {
      selectedMarketplaceId = availableMarketplacesIds[0];
    }
    localStorage.setItem('selectedMarketplaceId', selectedMarketplaceId);

    setFilter((prev) => ({
      ...prev,
      sellerId: selectedSellerId,
      marketplaceId: selectedMarketplaceId,
    }));

    return {
      selectedSellerId,
      selectedMarketplaceId,
    };
  }

  const applyFilters = () => {
    setFilter((prev) => ({
      ...prev,
      groupBy: groupByValue,
    }));
    if (groupByValue !== filter.groupBy) {
      setListingRows([]);
      setCount(0);
      setSelectedRow(null);
    }
    updateQueryParams(location, {
      ...filter,
      groupBy: groupByValue,
      page: 0,
      pageSize,
      isManagedByAgency: filter.isManagedByAgency,
    });

    // Persist sellerId and marketplaceId in localStorage.
    if (filter.sellerId) {
      localStorage.setItem('selectedSellerId', filter.sellerId);
    }
    if (filter.marketplaceId) {
      localStorage.setItem('selectedMarketplaceId', filter.marketplaceId);
    }
    mixPanel({
      eventName: 'Apply filters',
      eventProperties: {
        ...filter,
      },
    });
    loadData({ filter, page: 0, pageSize, groupBy: groupByValue, isManagedByAgency: filter.isManagedByAgency }, true);
    resetCheckboxes();
  };

  const clearFilters = async () => {
    mixPanel({
      eventName: 'Clear filters',
    });
    // Delete sellerId and marketplaceId from localStorage.
    localStorage.removeItem('selectedSellerId');
    localStorage.removeItem('selectedMarketplaceId');

    const {
      selectedSellerId,
      selectedMarketplaceId,
    } = await setAuthorizedSellerAndMarketplace();

    setFilter({
      ...defaultEmptyFilter,
      marketplaceId: selectedMarketplaceId,
      sellerId: selectedSellerId,
    });

    updateQueryParams(location, {
      ...defaultEmptyFilter,
      marketplaceId: selectedMarketplaceId,
      sellerId: selectedSellerId,
      groupBy: null,
      page: 0,
      pageSize,
      isManagedByAgency: null,
    });

    loadData({
      filter: {
        ...defaultEmptyFilter,
        marketplaceId: selectedMarketplaceId,
        sellerId: selectedSellerId,
      },
      page: 0,
      pageSize,
      groupBy: null,
      isManagedByAgency: null
    }, true);
    resetCheckboxes();
  };

  const refetchListings = async () => {
    setLoadingData(true);
    try {
      const {
        data,
      } = await Api.post(
        `/amazon-listings/fetch`,
        {},
        // TODO add job to request reports and process them by ID
        // and then lower this timeout
        {
          timeout: 30000,
        }
      );
      if (data.result === true) {
        mixPanel({
          eventName: 'Refetch listings',
        });
        alertService.success('Refetching successful. Your updated listings will be available in a few minutes.');
      }
    } catch (e) {
      // Report creation request throttled
      console.log('Refetching listings error');
      console.log(e);
      errorAlert('Refetching listings throttled, please retry in 60 seconds', e);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBulkEditRows = () => {
    const productTypes = listingRows.filter(listingRows => selectionModel.includes(listingRows.id as number)).map((listing) => listing.productType);
    if (!productTypes.every((value) => value === productTypes[0])) {
      // Don't allow bulk editing if all selected listings don't have the same product type.
      alertService.error('Cannot bulk edit listings with different product types.');
      return;
    }
    setOpenBulkEdit(true);
  };

  const handleManageVariationsRows = async () => {
    const productTypes = listingRows.filter(listingRows => selectionModel.includes(listingRows.id as number)).map((listing) => listing.productType);
    if (!productTypes.every((value) => value === productTypes[0])) {
      // Don't allow managing variations if all selected listings don't have the same product type.
      alertService.error('Cannot manage variations of listings with different product types.');
      return;
    }
    setSelectedProductType(productTypes[0]);
    setOpenManageVariations(true);
  };

  const theme = useTheme();

  // debug
  // console.log('Current filter');
  // console.log(filter);
  // end debug

  return (
    <TableContainer>
      <div style={{ display: 'none' }}>
        <CustomColumn thisRef={thisRef} rows={listingRows} buttons={customButtons} height="64px" width="130px" />
      </div>

      {updatedListing &&
        <Dialog
          className={`theme-${theme.palette.mode} differences-modal`}
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Differences between SKU <strong>{updatedListing.sku}</strong> and Amazon ASIN <strong>{updatedListing.asin}</strong></DialogTitle>
          <DialogActions className="close">
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </DialogActions>
          <DialogContent dividers={true}>
            <ReflectingDifferenceContainer
              differences={listingDifferences}
            />
          </DialogContent>
        </Dialog>
      }

      <Dialog
        className={`theme-${theme.palette.mode} bulk-edit-modal`}
        open={openBulkEdit}
        onClose={handleCloseBulkEdit}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Bulk Editing</DialogTitle>
        <DialogActions className="close">
          <Button onClick={handleCloseBulkEdit}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent dividers={true}>
          <BulkEditContainer
            handleCloseBulkEdit={handleCloseBulkEdit}
            bulkEditSuccess={bulkEditSuccess}
            listingRows={listingRows}
            selectionModel={selectionModel}
            marketplaceId={filter.marketplaceId}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        className={`theme-${theme.palette.mode} manage-variations-modal`}
        open={openManageVariations}
        onClose={handleCloseManageVariations}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Manage Variations</DialogTitle>
        <DialogActions className="close">
          <Button onClick={handleCloseManageVariations}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogContent dividers={true}>
          <p className="warning">
            Updating a variation family may cause its listings to become unsellable for a few hours while Amazon processes the change. We recommend you make this change during a low sales period.
          </p>
          <ManageVariationsContainer
            handleCloseManageVariations={handleCloseManageVariations}
            manageVariationsSuccess={manageVariationsSuccess}
            marketplaceId={filter.marketplaceId}
            sellerId={filter.sellerId}
            listingRows={listingRows}
            selectionModel={selectionModel}
            selectedProductType={selectedProductType}
          />
        </DialogContent>
      </Dialog>

      {filter.marketplaceId && filter.sellerId && allIssuesTotal > 0 && countIssues && (
        <IssuesSummary
          allIssuesTotal={allIssuesTotal}
          countIssues={countIssues}
          countStatuses={countStatuses}
        />
      )}

      <Grid
        container spacing={1.1}
        className="listing-action-buttons"
      >
        {accountService.userValue.role === Role.BrandUser && (
          <Button
            size="small"
            onClick={() => {
              refetchListings();
            }}
          >
            REFETCH LISTINGS
          </Button>
        )}
        <Button
          disabled={selectionModel.length < 2}
          size="small"
          onClick={() => {
            handleBulkEditRows();
          }}
        >
          BULK EDIT
        </Button>
        <Button
          disabled={selectionModel.length < 1}
          size="small"
          onClick={() => {
            handleManageVariationsRows();
          }}
        >
          MANAGE VARIATIONS
        </Button>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 30 }}>
        <Grid container spacing={1.1} style={{ paddingBottom: '20px' }}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              options={Array.from(
                new Set(
                  brands
                    .map((x) => x.brand_code)
                    .filter((x) => x != null)
                    .sort(),
                ),
              )}
              size="small"
              getOptionLabel={(option) => {
                const brandName = brands.find((x) => x.brand_code === option)?.name;
                if (brandName && accountService.userValue.brand_type !== 'ffp') {
                  return `${option} - ${brandName}`;
                } else if (brandName && accountService.userValue.brand_type === 'ffp') {
                  return brandName;
                } else {
                  return option;
                }
              }}
              // Set default brand to user's brand for regular users
              // or the first brand for admin users.
              value={
                accountService.userValue.role === Role.BrandUser
                  ? [accountService.userValue.brand_code]
                  : filter?.brands
                    ? filter.brands
                    : [brands.map((brand) => brand.brand_code).sort()[0]]
              }
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by Accounts." />}
              onChange={(event, value) => {
                handleChangeBrandFilter({ brands: value });
              }}
              disabled={disableBrandsFilter || accountService.userValue.brand_type === 'ffp'}
            />
          </Grid>

          {/* <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              size="small"
              options={subBrands.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = subBrands.find((x) => x.brand_code === option)?.name;
                if (brandName && accountService.userValue.brand_type !== 'ffp') {
                  return `${option} - ${brandName}`;
                } else if (brandName && accountService.userValue.brand_type === 'ffp') {
                  return brandName;
                } else {
                  return option;
                }
              }}
              isOptionEqualToValue={(option, value) => option === value}
              value={filter?.subBrands ? filter.subBrands : []}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by Sub Accounts" />}
              onChange={(event, value) => {
                setFilter((prev) => ({ ...prev, subBrands: value }));
              }}
              disabled={disableBrandsFilter || subBrandLoading}
            />
          </Grid> */}

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              // TODO allow multiple sellers
              // multiple
              size="small"
              options={sellers}
              getOptionLabel={(option) => option.sellerName}
              value={sellers.find(seller => seller.sellerId === filter.sellerId) || null}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by seller account" />}
              onChange={(event, value) => {
                // setFilter((prev) => ({ ...prev, sellerIds: value }));
                setFilter((prev) => ({ ...prev, sellerId: value?.sellerId ? value.sellerId : null}));
              }}
              disabled={sellers.length === 0}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={Marketplaces.map((marketplace) => marketplace.id)}
              getOptionLabel={(option) => Marketplaces.find((marketplace) => marketplace.id === option)?.name || ''}
              disableClearable
              // Set default marketplace to United States
              value={filter.marketplaceId ? filter.marketplaceId : availableMarketplacesIds[0]}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by marketplace" />}
              onChange={(event, value) => {
                setFilter((prev) => ({ ...prev, marketplaceId: value }));
              }}
              isOptionEqualToValue={(option, value) => option === value}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={ListingsItemStatuses}
              getOptionLabel={(option) => `${option}`}
              value={filter?.statuses ? filter.statuses : null}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by status" />}
              onChange={(event, value) => {
                setFilter((prev) => ({ ...prev, statuses: value }));
              }}
              isOptionEqualToValue={(option, value) => option === value}
              disabled={loadingData || appLoading}
            />
          </Grid>
          {/*
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple={false}
              size="small"
              options={groups}
              getOptionLabel={(option) => `${option.replace('_', ' ').toUpperCase()}`}
              value={groupByValue || null}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Group by" />}
              onChange={(event, value) => {
                setGroupByValue(value);
              }}
              disabled={loadingData || appLoading}
            />
          </Grid>
          */}
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              applyFilters={applyFilters}
              value={filter?.sku || ''}
              placeholder="Filter by SKU"
              onChange={(value) => setFilter((prevState) => ({ ...prevState, sku: value }))}
              showButton={false}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              applyFilters={applyFilters}
              value={filter.asin || ''}
              placeholder="Filter by ASIN"
              onChange={(value) => {
                setFilter((prevState) => ({ ...prevState, asin: value }));
              }}
              disabled={loadingData || appLoading}
              showButton={false}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              applyFilters={applyFilters}
              value={filter?.itemTitle || ''}
              placeholder="Filter by Title"
              onChange={(value) => setFilter((prevState) => ({ ...prevState, itemTitle: value }))}
              disabled={loadingData || appLoading}
              showButton={false}
            />
          </Grid>
          {/*
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              applyFilters={applyFilters}
              value={filter?.issue || ''}
              placeholder="Search By Issue Message"
              onChange={(value) => setFilter((prevState) => ({...prevState, issue: value}))}
              disabled={loadingData || appLoading}
              showButton={false}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              applyFilters={applyFilters}
              value={filter?.code || ''}
              placeholder="Search By Issue Code"
              onChange={(value) => setFilter((prevState) => ({...prevState, code: value}))}
              disabled={loadingData || appLoading}
              showButton={false}
            />
          </Grid>
          */}
          {accountService.userValue.brand_type !== BrandTypesAlias.ffp && (
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Autocomplete
                multiple={false}
                size="small"
                options={optionsForManagedByAgency}
                getOptionLabel={(option) => option}
                value={filter.isManagedByAgency || null}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Managed By Agency" />}
                onChange={(event, value) => {
                  setFilter((prevState) => ({ ...prevState, isManagedByAgency: value }));
                }}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={loadingData || appLoading}
              />
            </Grid>
          )}
          <Grid
            lg={3} md={6} sm={6} xs={12}
            item
            className="listing-action-buttons"
          >
            <Button
              size="small"
              disabled={loadingData || appLoading}
              onClick={() => {
                applyFilters();
              }}
            >
              Apply Filters
            </Button>
            <Button
              size="small"
              disabled={loadingData || appLoading}
              onClick={() => {
                clearFilters();
              }}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={12} position="relative">
        {(loadingData || tableLoading) && <LinearProgress sx={{ position: 'absolute', width: '100%' }} />}
        {filter?.groupBy ? (
          <CollapsibleDataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            gridSxStyles={sxStyles('grid')}
            className={`${tableLoading && !loadingData ? 'load-headers' : ''} custom-table collapsible`}
            pageSize={pageSize}
            rowCount={+count}
            loading={loadingData}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageSizeChange={(v) => handleChangePageSize(v)}
            disableSelectionOnClick={true}
            onPageChange={(params) => handleChangePage(params)}
            rows={listingRows}
            columns={groupByColumns}
            columnVisibilityModel={groupByVisibility}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = { ...data, [x]: v };
                }
              });
              const newOrder = getColumnsItems({
                list: selectedColumns,
                columnsVisibility: data,
                columnsOrder: groupByColumns,
                currentOrder: groupByColumns,
              });
              setGroupByColumns(newOrder);
              setGroupByVisibility(data);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `listings-group-by-${filter.groupBy}`,
                setTableLoading,
              );
            }}
            accordionContent={
              <CustomDataGrid
                rows={
                  listingRows?.[selectedRow as number]?.child_asins?.map((x, i) => {
                    return { id: i, ...x };
                  }) || []
                }
                columns={[
                  {
                    field: 'asin',
                    headerName: 'ASIN',
                    minWidth: 120,
                    align: 'left',
                    filterable: false,
                    sortable: false,
                    disableColumnMenu: true,
                  },
                  ...defaultColumns(
                    filter.groupBy,
                    newColumns,
                    sxStyles,
                  ).filter((x) => x.field !== 'brand_name'),
                  fixedPlaceHolder({
                    width: 120,
                    buttons: customButtons,
                  }),
                ]}
              />
            }
            selectedRow={selectedRow}
            setSelectedRow={(v) => setSelectedRow(v)}
            clickable={(params) => params.field !== 'actions'}
            rowClassName={(params) => (!_.isEqual(params.row, listingRows[listingRows.length - 1]) ? 'hover-row' : '')}
          />
        ) : (
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            componentsProps={{
              toolbar: customToolbarProps,
              pagination: customToolbarProps,
            }}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            // onCellEditCommit={(params) => {
            //   const field = listingRows.find((item) => item.id === params.id) as ListingsInterface['rows'][0];
            //   const currentValue = field?.[params.field as keyof typeof field];
            //   const columnField = tableFields[params.field];
            //   const customAttr = columnField.custom_attribute;
            //   const setNewValue = currentValue !== params.value;
            //   if (setNewValue) {
            //     if (customAttr) {
            //       let value: SelectedCustomField;
            //       if (selectedCustomAttrData.field) {
            //         value = !selectedCustomAttr.data.find(
            //           (i) =>
            //             i.id === field.id &&
            //             i.seller_sku === field.seller_sku &&
            //             i.marketplace === field.marketplace_id,
            //         )
            //           ? {
            //             ...selectedCustomAttrData,
            //             data: [
            //               ...selectedCustomAttrData.data,
            //               { seller_sku: field?.seller_sku, marketplace: field?.marketplace_id, id: field?.id },
            //             ],
            //           }
            //           : selectedCustomAttrData;
            //       } else {
            //         value = {
            //           field: columnField.label,
            //           data: [{ seller_sku: field?.seller_sku, marketplace: field?.marketplace_id }],
            //         };
            //       }
            //       // updateCustomAttr(value, params.value, params.field);
            //     }
            //   }
            // }}
            sx={sxStyles('grid')}
            className={`custom-table ${tableLoading && !loadingData ? 'load-headers' : ''}`}
            columnBuffer={1}
            columnThreshold={0}
            rowsPerPageOptions={rowsPerPageOptions}
            autoHeight={true}
            pageSize={pageSize}
            rowCount={+count}
            loading={loadingData}
            page={page}
            pagination
            disableVirtualization
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableSelectionOnClick={true}
            onPageChange={(params) => handleChangePage(params)}
            onPageSizeChange={handleChangePageSize}
            rows={listingRows}
            getRowClassName={(params: GridRowClassNameParams) => {
              return params.row.reflectingState === ListingReflectingState.REFLECTED ? '' : listingReflectingState[ListingReflectingState.NOT_REFLECTED];
            }}
            columns={[...orderColumns, fixedPlaceHolder({})]}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              const defaultDataColumns = defaultColumns(
                filter.groupBy,
                newColumns,
                sxStyles,
              );
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = { ...data, [x]: v };
                }
              });
              const newOrder = getColumnsItems({
                list: defaultDataColumns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `listings`,
                setTableLoading,
              );
              resetCheckboxes();
            }}
            rowHeight={64}
          />
        )}
      </Grid>

      <ModalButton
        openModal={deleteItem !== null}
        modalTitle="Delete Amazon Listings Item"
        hideButton
        closable
        onCloseText="No"
        onCloseAction={() => setDeleteItem(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              if (deleteItem) {
                onDeleteItem(deleteItem, closeModal);
                if (accountService.userValue.brand_type === 'ffp') {
                  GAEvents?.(
                    'Amazon Listings Item',
                    'Delete item',
                    `Listings item deleted: ${deleteItem?.seller_sku} on ${deleteItem?.marketplace_id}`,
                  );
                }
              }
            }}
          >
            Yes
          </Button>
        )}
      >
        {(): JSX.Element => {
          return (
            <span>
              Are you sure you want to delete item with sku: <b>{deleteItem?.seller_sku}</b>
            </span>
          );
        }}
      </ModalButton>

      <Grid item lg={12} md={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}
