import React, {useEffect, useState} from 'react';
import {Grid, Tab, Tabs, useTheme} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import {useHistory, useParams} from 'react-router-dom';
// components
import {BuildMenu} from '../../../components/menu';
import {MenuStore} from '../../../../../App';
import {CustomAlert} from '../../../../Login/components/CustomAlert';
import {BrandAdvertising} from '../BrandAdvertising';
import CustomTabs from '../../../../../components/CustomTabs/CustomTabs';

const tabNames = [
  {tabKey: 'sp', tabLabel: 'Sponsored Products'},
  {tabKey: 'attribution', tabLabel: 'Attribution'},
  {tabKey: 'dsp', tabLabel: 'DSP'},
  {tabKey: 'sb', tabLabel: 'Sponsored Brands'},
];

function a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function BrandAdvertisingPerformance(): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  const {id: brandCode, adsType} = useParams<Record<string, string>>();
  const [value, setValue] = useState(adsType);

  useEffect(() => {
    document.title = `Ads Performance`;
    MenuStore.update((s) => {
      s.menuItems = BuildMenu('ads_performance', brandCode as string, history);
    });
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [brandCode, history]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };
  const navigateTo = (tabName: string) => {
    history.push(`/brands/${brandCode}/ads-performance/${tabName}`);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabContext value={value}>
          <CustomTabs position={'static'} color="default">
            <Tabs
              value={value}
              onChange={(e, v) => handleChange(v)}
              indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              variant="scrollable"
              scrollButtons="auto"
              style={{justifyContent: 'center'}}
            >
              {tabNames.map(({tabKey, tabLabel}, idx) => (
                <Tab
                  label={tabLabel}
                  value={tabKey}
                  key={tabKey}
                  {...a11yProps(idx)}
                  onClick={() => navigateTo(tabKey)}
                />
              ))}
            </Tabs>
          </CustomTabs>
          <TabPanel value={adsType}>
            <BrandAdvertising adsType={adsType} />
          </TabPanel>
        </TabContext>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="ads-performance-tab-alert" />
      </Grid>
    </Grid>
  );
}

export {BrandAdvertisingPerformance};
