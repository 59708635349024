import React from 'react';

import {
  Grid,
} from '@mui/material';

import { sxStyles } from '../Listings/Styles';

import ListingForm from '../Listings/components/ListingForm';

const NewListingPage = (): JSX.Element => {
  return (
    <Grid container sx={sxStyles('itemDetailsContainer')}>
      <ListingForm />
    </Grid>
  );
};

export default NewListingPage;
