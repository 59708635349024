import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  loginPaper: {
    textAlign: 'center',
    padding: '3em 2em 3em 2em',
    margin: '2em 0em',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
  loginButton: {
    margin: '0.5em',
  },
  logoImg: {
    width: '10em',
    margin: '3em',
  },
  image: {
    '& > img': {
      width: '100%',
      maxWidth: '550px',
    },
  },
  grid: {
    padding: '0 65px 0 0',
    minHeight: 'calc(90vh - 70px)',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      padding: ' 0 25px',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
      '& > .MuiGrid-item': {
        width: '100%',
      },
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
