import {Theme} from '@mui/material';
// interfaces
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';
// utils
import {GetStyles} from '../../utils/getStyles';

const styles = (theme: Theme): {[key: string]: SxStyleTypes} => ({
  box: {
    padding: '10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: '0px 0px 6px 0px #94949433',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textTransform: 'capitalize',
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '65vh!important',
      overflowY: 'auto!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  formGroup: {
    '&.MuiGrid-root': {
      width: '100%',
      paddingTop: '30px',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > div:first-of-type': {
        padding: '20px 0',
        display: 'flex',
        width: '70%',
        '& > div': {
          width: '50%',
          maxWidth: '300px',
          '&:not(last-of-type)': {
            paddingRight: '10px',
          },
        },
      },
      '& > div:last-of-type': {
        width: '100%',
        maxWidth: '300px',
      },
      [theme.breakpoints.down(1024)]: {
        '& > div:first-of-type': {
          width: '70%',
          paddingRight: '10px',
        },
        '& > div:last-of-type': {
          maxWidth: '200px',
        },
      },
      [theme.breakpoints.down(767)]: {
        paddingTop: 0,
        '& > div:first-of-type': {
          justifyContent: 'space-between',
          width: '100%',
          paddingRight: 0,
          '& > div:last-of-type': {
            paddingRight: 0,
          },
        },
        '& > .MuiAutocomplete-root': {
          maxWidth: '100%',
          paddingRight: '0',
        },
      },
      [theme.breakpoints.down(520)]: {
        '& > div:first-of-type': {
          padding: '10px 0',
          width: '100%',
          flexDirection: 'column',
          '& > .MuiAutocomplete-root': {
            width: '100%',
            maxWidth: '100%',
            '&:not(:last-of-type)': {
              padding: '0 0 10px 0',
            },
          },
        },
        '& > div:last-of-type': {
          maxWidth: '100%',
        },
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '900px',
    '& .MuiButton-outlined:not(:focus, :active)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
    },
    '&.MuiButtonGroup-root .MuiButton-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(1240)]: {
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+4)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(4)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.new-group)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '&.MuiButtonGroup-root .MuiButton-root': {
          border: theme.palette.mode === 'dark' ? '1px solid #3887be' : '1px solid #174580',
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3n)': {
          borderLeftColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3)': {
          borderTopRightRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRightColor: 'transparent',
          '&:hover': {
            borderRightColor: 'transparent',
          },
        },
        '& .MuiButton-root:last-of-type': {
          gridArea: '4 / 1 / 4/ 4',
          borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          borderRadius: '0',
          borderBottomRightRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-outlined': {
          border: 'none',
          color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          '&:last-of-type': {
            backgroundColor: '#e9e9e9',
          },
        },
        '& .MuiButton-containedPrimary': {
          border: 'none',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
            outline: 'none',
          },
        },
      },
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',

        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+6)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(2)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(6), ': {
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(7)': {
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
  },
});

const sxStyles = (key: string): SxStyleTypes => GetStyles(styles)[key];

export {sxStyles};
