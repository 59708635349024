import React from 'react';
import TableContainer from '../TableContainer/TableContainer';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {Link} from 'react-router-dom';
import {Grid, Paper, InputAdornment, Button, Typography, CircularProgress} from '@mui/material';
import {AlternateEmail, LockOutlined, PersonOutlineOutlined} from '@mui/icons-material';
import Images from '../Images/Images';
// interfaces
import {LoginViewProps} from './interfaces/login-view';

export default function LoginView({
  validationSchema,
  onSubmit,
  initialValues,
  fields,
  submitText,
  forgotPassword,
  formDescription,
  innerRef,
  actionButton,
  isInvalid,
  message,
  action,
}: LoginViewProps): JSX.Element {

  const icons = {
    email: <AlternateEmail />,
    password: <LockOutlined />,
    user: <PersonOutlineOutlined />,
  };

  return (
    <TableContainer
      id="login-view"
      container
    >
      <Grid container>
        <Grid item sm={12}>
          <Paper elevation={0}>
            <Grid item className="logo-wrapper">
              <Images name={'FFP_Logo'} />
            </Grid>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={innerRef}
            >
              {({values, isSubmitting}) => {
                return (
                  <Form>
                    {isInvalid ? (
                      <Typography component="h2" variant="h4" gutterBottom>
                        {message}
                      </Typography>
                    ) : (
                      <>
                        {formDescription && (
                          <Typography
                            component="h3"
                            variant="h6"
                            gutterBottom
                          >
                            {formDescription}
                          </Typography>
                        )}
                        <div>
                          {fields?.map((item, key) => {
                            return (
                              <div key={key}>
                                <div>{item.extra}</div>
                                <Field
                                  autoComplete="none"
                                  className="field-container"
                                  component={TextField}
                                  variant="outlined"
                                  name={item.name}
                                  placeholder={item.label}
                                  type={item.type}
                                  size="large"
                                  InputLabelProps={{shrink: false}}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" style={{outline: 'none'}}>
                                        {icons[item.icon as keyof typeof icons]}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {forgotPassword && item.name === 'password' && (
                                  <div className="forgot-password">
                                    <Link to={forgotPassword}>
                                      Forgot your password?
                                    </Link>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          <div>
                            <Button
                              id="loginButton"
                              data-cy="loginButton"
                              variant="contained"
                              size="large"
                              type="submit"
                              disabled={isSubmitting}
                              onClick={() => actionButton && actionButton(values)}
                            >
                              {submitText}
                              {isSubmitting && <CircularProgress size={12} style={{margin: '0 10px'}} />}
                            </Button>
                          </div>
                          {action === 'reset-password' && (
                            <div className="back-to-login">
                              <Link to="/login">
                                Return to log in
                              </Link>
                            </div>
                          )}
                          {(action === 'login' || action === 'reset-password') && (
                            <div className="back-to-register">
                              Don&apos;t have an account? &nbsp;
                              <Link to="/create-account">
                                Create one
                              </Link>
                            </div>
                          )}
                          {action === 'register' && (
                            <div className="back-to-login">
                              Have an account? &nbsp;
                              <Link to="/login">
                                Log in
                              </Link>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </TableContainer>
  );
}
