export interface SummaryInterface {
  asin: string;
  fn_sku: string;
  status: string[];
  itemName: string;
  mainImage: {
    link: string;
    width: number;
    height: number;
  };
  createdDate: string;
  productType: string;
  conditionType: string;
  marketplaceId: string;
  lastUpdatedDate: string;
}

export interface AttributePropertyInterface {
  value: string;
  language_tag: string;
  marketplace_id: string;
}

export interface ImageLocatorInterface {
  marketplace_id: string;
  media_location: string;
}

export interface AttributesInterface {
  size: AttributePropertyInterface[];
  brand: AttributePropertyInterface[];
  color: {
    value: string;
    language_tag: string;
    marketplace_id: string;
    standardized_values: string[];
  }[];
  flavor: AttributePropertyInterface[];
  container: {
    type: [{value: string; language_tag: string}];
    marketplace_id: string;
  };
  item_form: AttributePropertyInterface[];
  item_name: AttributePropertyInterface[];
  directions: AttributePropertyInterface[];
  list_price: {value: string; currency: string; marketplace_id: string}[];
  unit_count: {
    type: {value: string; language_tag: string};
    value: number;
    marketplace_id: string;
  }[];
  dosage_form: AttributePropertyInterface[];
  ingredients: AttributePropertyInterface[];
  item_weight: {unit: string; value: number; marketplace_id: string}[];
  bullet_point: AttributePropertyInterface[];
  gift_options: {
    can_be_wrapped: string;
    marketplace_id: string;
    can_be_messaged: string;
  }[];
  manufacturer: AttributePropertyInterface[];
  fc_shelf_life: {unit: string; value: number; marketplace_id: string}[];
  target_gender: {value: string; marketplace_id: string}[];
  condition_type: {value: string; marketplace_id: string}[];
  safety_warning: AttributePropertyInterface[];
  generic_keyword: AttributePropertyInterface[];
  item_dimensions: {
    width: {unit: string; value: number};
    height: {unit: string; value: number};
    length: {unit: string; value: number};
    marketplace_id: string;
  }[];
  number_of_items: {value: number; marketplace_id: string};
  product_benefit: AttributePropertyInterface[];
  variation_theme: {name: string; marketplace_id: string}[];
  material_feature: AttributePropertyInterface[];
  item_type_keyword: {
    value: string;
    marketplace_id: string;
  }[];
  purchasable_offer: {
    end_at: {value: string | number | null};
    currency: string;
    start_at: {value: string};
    our_price: {schedule: {value_with_tax: number}[]}[];
    marketplace_id: string;
  };
  specification_met: AttributePropertyInterface[];
  batteries_included: {value: boolean | string; marketplace_id: string}[];
  batteries_required: {value: boolean | string; marketplace_id: string}[];
  item_package_weight: {
    unit: string;
    value: number;
    marketplace_id: string;
  }[];
  product_description: AttributePropertyInterface[];
  special_ingredients: AttributePropertyInterface[];
  allergen_information: {value: string; marketplace_id: string}[];
  age_range_description: AttributePropertyInterface[];
  item_package_dimensions: {
    width: {unit: string; value: number};
    height: {unit: string; value: number};
    length: {unit: string; value: number};
    marketplace_id: string;
  }[];
  merchant_shipping_group: {value: string; marketplace_id: string}[];
  merchant_suggested_asin: {value: string; marketplace_id: string}[];
  product_expiration_type: AttributePropertyInterface[];
  fulfillment_availability: {
    marketplace_id: string;
    fulfillment_channel_code: string;
  }[];
  product_site_launch_date: {
    value: string;
    marketplace_id: string;
  }[];
  cpsia_cautionary_statement: {value: string; marketplace_id: string}[];
  main_product_image_locator: ImageLocatorInterface[];
  is_expiration_dated_product: ImageLocatorInterface[];
  other_product_image_locator_1: ImageLocatorInterface[];
  other_product_image_locator_2: ImageLocatorInterface[];
  other_product_image_locator_3: ImageLocatorInterface[];
  other_product_image_locator_4: ImageLocatorInterface[];
  other_product_image_locator_6: ImageLocatorInterface[];
  supplier_declared_dg_hz_regulation: {value: string; marketplace_id: string}[];
  externally_assigned_product_identifier: {
    type: string;
    value: string;
    marketplace_id: string;
  }[];
}

export interface VersionInterface {
  id: string | number;
  version: string;
  marketplace_id: string;
  seller_id: string;
  brand_code: string;
  seller_sku: string;
  asin: string;
  date: Date;
  attributes: AttributesInterface;
  summaries: SummaryInterface[];
  requiredAttributes?: string[];
  other_product_image_locator_3: ImageLocatorInterface[];
  other_product_image_locator_4: ImageLocatorInterface[];
  other_product_image_locator_5: ImageLocatorInterface[];
  other_product_image_locator_6: ImageLocatorInterface[];
  other_product_image_locator_7: ImageLocatorInterface[];
  other_product_image_locator_8: ImageLocatorInterface[];
  swatch_product_image_locator: ImageLocatorInterface[];
  isSupportedProductType: boolean;
  offers: {
    price: {amount: string; currency: string; currencyCode: string};
    offerType: string;
    marketplaceId: string;
  }[];
  issues: string[] | null;
  fulfillmentAvailability: {fulfillmentChannelCode: string}[];
}

export interface ListingsInterface {
  count: number;
  rows: {
    id?: string | number;
    marketplace_id: string;
    seller_id: string;
    seller_sku?: string; // TODO remove this
    sku: string;
    brand_code: string;
    brand_name: string;
    custom_attributes: {
      [key: string]: {
        name: string;
        value: string;
        type: string;
      };
    };
    errorCnt: number;
    asin: string;
    isSupportedProductType: boolean;
    itemName: string;
    lastUpdatedDate: string;
    isUpdateReflected: boolean;
    mainImage: string;
    productType: string;
    version: string;
    warningCnt: number;
    child_asins: ListingsInterface['rows'];
  }[];
}

export enum ListingReflectingState {
  NOT_REFLECTED = 0,
  REFLECTED = 1,
  FAILED = 2,
}

// Used by reflecting differences modal

export interface Difference {
  kind: string;
  path: string[];
  index: number;
  lhs?: string;
  rhs?: string;
  item: {
    kind: string;
    lhs?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    rhs?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

// Used by new ListingForm

type SelectOption = [
  value: string,
  label: string
];

export interface ListingField {
  meta: {
    name: string;
    title?: string;
    description?: string;
    type: string;
    maxUniqueItems?: number;
    minUniqueItems?: number;
    maxLength?: number;
    format?: string[];
    selectOptions?: SelectOption[];
  };
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ListingFieldDefinitions {
  [groupName: string]: {
    title: string;
    description?: string;
    propertyNames: string[];
    expandedFields: {
      [fieldName: string]: ListingField;
    };
  }
}

enum ListingsItemSubmissionResponseStatus {
  ACCEPTED = 'ACCEPTED',
  INVALID = 'INVALID',
}

export enum Severity {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  NONE = 'NONE',
}

export interface CountIssues {
  [Severity.ERROR]?: number;
  [Severity.WARNING]?: number;
  [Severity.INFO]?: number;
  [Severity.NONE]?: number;
}

export enum ListingsItemSummaryStatus {
  BUYABLE = 'BUYABLE',
  DISCOVERABLE = 'DISCOVERABLE',
}

export interface CountStatuses {
  [ListingsItemSummaryStatus.BUYABLE]: number;
  [ListingsItemSummaryStatus.DISCOVERABLE]: number;
}

export interface ListingsItemIssue {
  code?: string;
  message?: string;
  severity?: Severity;
  categories?: string[];
  attributeNames?: string[];
}

export interface ListingsItemSubmissionResponse {
  sku: string;
  status: ListingsItemSubmissionResponseStatus;
  submissionId: string;
  issues?: ListingsItemIssue[];
  version?: number;
}

export enum ItemRelationshipsType {
  VARIATION = 'VARIATION',
  PACKAGE_HIERARCHY = 'PACKAGE_HIERARCHY',
}

export type ItemRelationshipsByMarketplace = {
  marketplaceId: string;
  relationships: ItemRelationships[];
}

export interface ItemVariationTheme {
  attributes?: string[];
  theme?: string;
}

export interface ItemRelationships {
  type: ItemRelationshipsType;
  childAsins?: string[];
  parentAsins?: string[];
  variationTheme?: ItemVariationTheme;
}

export interface Item {
  seller_sku: string;
  asin: string;
  relationships: ItemRelationshipsByMarketplace[];
}

export interface VariationFamilies {
  [parentAsin: string]: {
    children: string[];
    nonChildren: string[];
  }
}

export interface AsinToSkuMap {
  [asin: string]: string
}

export interface AsinSku {
  asin: string;
  sku: string;
  updated: boolean;
}

export interface VariationFamilyWithSku {
  parentAsin: string;
  parentSku: string;
  children: AsinSku[];
  nonChildren: AsinSku[];
}

export interface ProductType {
  product_type_name: string;
  marketplace_ids: string[];
}

export interface AuthorizedMarketplaceWithoutRefreshToken {
  marketplace_id: string;
  marketplace_name: string;
  selling_partner_id: string;
  seller_name: string;
}
