import React from 'react';
import {CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Line, LineChart} from 'recharts';
import moment from 'moment';
import {Checkbox, FormControlLabel, LinearProgress, Paper, Typography, useTheme, Grid} from '@mui/material';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';

const metricsOptions = {
  buy_box: {
    color: '#4dc2c2',
    label: 'Buy Box %',
  },
  session: {
    color: '#cf2727',
    label: 'Sessions',
  },
  conversion: {
    color: '#a337cb',
    label: 'Conversion Rate',
  },
  revenue: {
    color: '#929292',
    label: 'Revenue',
  },
  units: {
    color: '#003ea9',
    label: 'Units Sold',
  },
};

function PerformanceChart({
  data,
  metric,
  onMetricChange,
  loading,
}: {
  data: {
    interval: string;
    data: {
      metric: string;
      data: {
        date: string;
        value: number;
      }[];
      type: string;
    }[];
  } | null;
  metric: string[];
  onMetricChange: (metric: string[]) => void;
  loading: boolean;
}): JSX.Element {
  const theme = useTheme();
  function formatXAxis(tickItem: number) {
    switch (data?.interval) {
      case 'day':
        return moment.utc(tickItem).format('YYYY-MM-DD');
      default:
        return moment.utc(tickItem).format('YYYY-MM-DD HH:mm');
    }
  }

  const handleMetricChange = (metricName: string, metricValue: boolean) => {
    if (metricValue) onMetricChange([...metric, metricName]);
    else {
      onMetricChange(metric.filter((x) => x !== metricName));
    }
  };

  return (
    <Grid container spacing={1} style={{paddingTop: 30}}>
      <Grid item lg={12}>
        {metric?.length && data?.data.some((x) => x.data?.length) ? (
          <ResponsiveContainer height={450} width={'100%'}>
            <LineChart>
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis
                type={'number'}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                domain={['dataMin', 'dataMax']}
                dataKey={(x) => moment.utc(x.date).valueOf()}
                tickFormatter={formatXAxis}
                minTickGap={100}
              />
              <Tooltip
                labelStyle={{color: theme.palette.primary.main}}
                labelFormatter={(label) =>
                  data.interval === 'day'
                    ? moment.utc(label).format('YYYY-MM-DD')
                    : moment.utc(label).format('YYYY-MM-DD HH:mm')
                }
              />
              {data.data.map((x, i) => {
                return (
                  <Line
                    key={i}
                    data={x.data}
                    type="monotone"
                    dataKey="value"
                    name={metricsOptions[x.metric as keyof typeof metricsOptions].label}
                    stroke={metricsOptions[x.metric as keyof typeof metricsOptions].color}
                    strokeWidth={4}
                    dot={false}
                    yAxisId={x.metric}
                    strokeDasharray={x.type === 'previous' ? '5 5' : ''}
                    connectNulls
                  />
                );
              })}
              {data.data.map((x, i) => {
                return (
                  <YAxis
                    key={`axis_${i}`}
                    domain={['auto', 'auto']}
                    hide={true}
                    axisLine={false}
                    yAxisId={x.metric}
                    type={'number'}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Paper
            variant={'outlined'}
            style={{
              height: '450px',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '0px 0px 6px 0px #94949433',
            }}
          >
            <Typography color={'textSecondary'} variant={'h4'}>
              No data
            </Typography>
          </Paper>
        )}
        {loading ? <LinearProgress color={'primary'} /> : <></>}
        <div style={{textAlign: 'center'}}>
          {Object.keys(metricsOptions).map((x, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  style={{color: metricsOptions[x as keyof typeof metricsOptions].color}}
                  checked={metric.includes(x)}
                  onChange={(e) => handleMetricChange(x, e.target.checked)}
                  disabled={loading}
                />
              }
              label={metricsOptions[x as keyof typeof metricsOptions].label}
            ></FormControlLabel>
          ))}
        </div>
        <CustomAlert id="performance-chart" />
      </Grid>
    </Grid>
  );
}

export {PerformanceChart};
