import React, {useEffect, useState} from 'react';
import {Store} from 'pullstate';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import { socket } from './socket';

// import TestComponent from './components/TestComponent';

import PATH from './utils/path';

import {
  Container,
  Paper,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import CacheBuster from 'react-cache-buster';
// utils
import {Role} from './utils/role';
import {closeBeacon} from './utils/FFPBeacon';
import {FFPArticles} from './utils/FFPArticles';
import {BrandTypesAlias} from './utils/brand-types';
import {version} from '../package.json';
// services
import {accountService} from './services/account.service';
// components
import {PrivateRoute} from './pages/Login/components/PrivateRoute';
import {Footer} from './components/Footer/Footer';
import AuthorizeAdsForBrand from './pages/Brands/components/AuthorizeAdsForBrand';
import AppBar from './components/AppBar/AppBar';
import SideBar from './components/SideBar/SideBar';
import {AdvertisingPerformance} from './pages/Advertising/components/PerformanceTab';
// pages
import {Login} from './pages/Login/Login';
import {ViewBrandReport} from './pages/Brands/subpages/BrandsReports/subpages/ViewBrandReport/ViewBrandReport';
import {UpdateBrandReport} from './pages/Brands/subpages/BrandsReports/subpages/UpdateBrandReport/UpdateBrandReport';
import {BrandReports} from './pages/Brands/subpages/BrandsReports/BrandReports';
import {ViewBrand} from './pages/Brands/subpages/ViewBrand/ViewBrand';
import {CreateUser} from './pages/Users/subpages/CreateUser/CreateUser';
import {Register} from './pages/Register/Register';
import {Users} from './pages/Users/Users';
import {CreateBrand} from './pages/Brands/subpages/CreateBrand/CreateBrand';
import {Brands} from './pages/Brands/Brands';
import {UpdateUser} from './pages/Users/subpages/UpdateUser/UpdateUser';
import {Asins} from './pages/Asins/Asins';
import {UpdateAsin} from './pages/Asins/subpages/UpdateAsin/UpdateAsin';
import {Alerts} from './pages/Alerts/components/AlertsTab';
import {ViewAsin} from './pages/Asins/subpages/AsinDetails/ViewAsin';
import {Dashboard} from './pages/Dashboard/Dashboard';
import {PasswordReset} from './pages/Register/subpages/PasswordReset/PasswordReset';
import {AsinPerformance} from './pages/Asins/subpages/Performance/AsinPerformance';
import {BrandPerformance} from './pages/Brands/subpages/BrandPerformance/BrandPerformance';
import {ProductsErrors} from './pages/ProductsErrors/ProductsErrors';
import {AgencyReports} from './pages/AgencyReports/AgencyReports';
import OverallPerformance from './pages/Performance/Performance';
import {BrandInventory} from './pages/Brands/subpages/BrandInventory/BrandInventory';
import {AsinKeywordsTop} from './pages/Asins/subpages/Keywords/AsinKeywordsTop';
import {AsinInventory} from './pages/Asins/subpages/AsinDetails/AsinInventory';
import AuthorizeSpApiForBrands from './pages/Brands/components/AuthorizeSpApiForBrands';
import {AsinFeeEstimate} from './pages/Asins/subpages/AsinDetails/AsinFeeEstimate';
import {AsinProductOffers} from './pages/Asins/subpages/AsinDetails/AsinProductOffers';
import {Commissions} from './pages/Commissions/Commissions';
import {AmazonAuditRegister} from './pages/Register/subpages/AmazonAuditRegister/AmazonAuditRegister';
import {FFPRegistration} from './pages/FFPRegistration/FFPRegistration';
import {ECOM3KRegistration} from './pages/ECOM3KRegistration/ECOM3KRegistration';
import AmazonListings from './pages/Listings/AmazonListings';
import NewListingPage from './pages/NewListingPage';
import {CommissionDetail} from './pages/Commissions/subpages/CommisionDetails/CommissionDetail';
import {AmazonListingsItem} from './pages/Listings/subpages/AmazonListingsItem/AmazonListingsItem';
import AccountPageView from './pages/Account/AccountPageView';
import PaymentCompletePage from './pages/PaymentComplete';
import SubscriptionPlanChanged from './pages/SubscriptionPlanChanged';
import UpdateBrandView from './pages/Brands/subpages/UpdateBrandView/UpdateBrandView';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import {BrandAdvertisingPerformance} from './pages/Brands/subpages/Advertising/components/PerformanceTab';
import TermsOfService from './pages/InfoPages/subpages/TermsOfService/TermsOfService';
import Insights from './pages/Insights/Insights';
import {UserPermission} from './pages/Users/subpages/UserPermission/UserPermission';
import InfoPage from './pages/InfoPages/InfoPage';
import {CreateFfpSubBrand} from './pages/Brands/subpages/FFPSubBrands/subpages/CreateFfpSubBrand/CreateFfpSubBrand';
import {UpdateFfpSubBrand} from './pages/Brands/subpages/FFPSubBrands/subpages/UpdateFfpSubBrand/UpdateFfpSubBrand';
import BrandsOverview from './pages/Brands/subpages/BrandsOverview/BrandsOverview';
import {SubscriptionSuccessPage} from './pages/Subscription/SubscriptionSuccess/SubscriptionSuccessPage';
import {SubscriptionCancelledPage} from './pages/Subscription/SubscriptionCancelled/SubscriptionCancelledPage';
import {StripeBrands} from './pages/Brands/subpages/StripeBrands/StripeBrands';
// context
import ColorModeProvider from './context/ColorModeContext/ColorModeContext';
import AppContextProvider from './context/AppContext/AppContext';
import CustomAttributesConfig from './pages/ProductDetails/subpages/CustomAttributes/CustomAttributesConfig';
// interfaces
import {UserInterface} from './interfaces/user/user';

// eslint-disable-next-line
export const MenuStore: Store<any> = new Store({
  menuItems: null,
});

const SubMenuContainer = styled('div')(({theme}) => ({
  paddingTop: '65px',
  [theme.breakpoints.up(1024)]: {
    height: '100%',
    position: 'fixed',
    width: subMenuWidth,
  },
}));

const AppContainer = styled(Container)(() => ({
  '&.MuiContainer-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    padding: '64px 0 0 0',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowY: 'auto!important',
  },
  // '& .MuiDataGrid-columnHeaders': {
  //   backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f3f3f3',
  // },
  // '& .MuiInputBase-formControl  .MuiInputBase-input:-webkit-autofill': {
  //   boxShadow: theme.palette.mode === 'dark' && '0 0 0 100px #6a6f75 inset',
  // },
  '& .MuiDataGrid-virtualScrollerContent .fixed-column-container': {
    position: 'sticky',
    marginLeft: 'auto',
    alignItems: 'center',
    width: '100px',
    height: '0',
    right: '0',
    zIndex: 1,
    // '& > div': {
    //   backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    //   '& svg': {
    //     fontSize: '22px',
    //     color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#a1a1a1',
    //   },
    // },
  },
  '& .custom-datagrid': {
    // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f3f3f3',

    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    // '&::-webkit-scrollbar-track': {
    //   background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    // },
    '&::-webkit-scrollbar-thumb': {
      // background: '#999',
      borderRadius: '3px',
    },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: '#777',
    // },

    '& th': {
      // color: theme.palette.text.primary,
      // borderColor: theme.palette.mode === 'dark' ? '#515151' : '#e4e4e4',

      '& p': {
        fontSize: '0.875rem',
      },
      '& a > p': {
        fontSize: '0.875rem',
        // color: '#64aada',
      },
    },
  },
}));

const SubMenu = styled(Paper)(({theme}) => ({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      color: 'inherit',
      fontSize: '12px',
      width: '100%',
      '& svg': {
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : '#5f5f5f',
      },
    },
    '& .MuiButtonBase-root:focus, & .MuiButtonBase-root:active': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : '#fff',
    },
    '& .Mui-selected ': {
      fontWeight: 'bold',
      color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : '#fff',
    },

    [theme.breakpoints.down(1024)]: {
      '& .MuiTabs-flexContainer': {
        flexDirection: 'row',
      },
      '& .MuiButtonBase-root': {
        width: 'auto',
        fontSize: '13px',
        padding: '5px 16px',
      },
      '& .Mui-selected ': {
        borderBottom: '2px solid #174582',
      },
    },
    [theme.breakpoints.down(764)]: {
      '& .MuiButtonBase-root': {
        fontSize: '11px',
        padding: '0 8px',
      },
    },
  },
}));

const drawerWidth = '200px';
const subMenuWidth = '140px';

function App(): JSX.Element {
  const notMobile = useMediaQuery('(min-width:1024px)');
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState<UserInterface | null>(null);
  const [openDrawer, setOpenDrawer] = useState(notMobile);
  // eslint-disable-next-line
  const menuItems = MenuStore.useState((state: any) => state.menuItems);

  const [isConnected, setIsConnected] = useState(socket.connected); // eslint-disable-line
  const [wsMessage, setWsMessage] = useState(''); // eslint-disable-line

  useEffect(() => {

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('events', (data: string) => {
      // console.log('data from back end:', data);
      setWsMessage(data);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('events');
    };

  }, []);

  useEffect(() => {
    const subscription = accountService.user.subscribe((x: UserInterface | null) => {
      setUser(x);
    });
    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    setOpenDrawer(notMobile);
  }, [notMobile]);

  function onLogout() {
    accountService.logout();
    const {from} = location.state || {from: {pathname: '/login'}};
    history.push(from);
  }

  function handleDrawerOpen() {
    setOpenDrawer(!openDrawer);
  }

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const customPath = `/${path[path.length - 1]}`;
    const url = `${window.location.pathname}${window.location.search}`;
    const article = FFPArticles[url] || FFPArticles[customPath];
    if (!article) {
      closeBeacon();
    }
  });

  const hideBar = [
    'login',
    'create-account',
    'ecom3kregistration',
    'forgot-password',
    'amazonaudit',
    'register',
    'reset',
  ].includes(window.location.pathname.split('/')[1].toLowerCase());

  let paddingLeft;
  if (hideBar) {
    paddingLeft = '0';
  } else {
    paddingLeft = openDrawer && user && notMobile ? drawerWidth : notMobile ? '65px' : '0';
  }

  // debug
  // const hidePadding =
  //   ['ecom3k-gdrive-integration-for-stonehenge', 'terms-of-service', 'cookie-policy', 'privacy-policy'].includes(
  //     window.location.pathname.split('/')[1].toLowerCase(),
  //   ) && !accountService.userValue;

  const isProduction = process.env.NODE_ENV === 'production';

  const theme = useTheme();

  return (
    <CacheBuster
      currentVersion={version}
      isVerboseMode={false}
      isEnabled={isProduction}
      loadingComponent={<CircularProgress />}
    >
      <AppContextProvider>
        <ColorModeProvider>
          <div id="main-container" className={`theme-${theme.palette.mode}`}>
            <AppBar
              openDrawer={handleDrawerOpen}
              user={user as UserInterface}
              setDrawer={() => setOpenDrawer(!openDrawer)}
              hidden={hideBar}
              isMobile={!notMobile}
            />
            {user && <SideBar open={openDrawer} setOpen={() => setOpenDrawer(!openDrawer)} mobile={notMobile} onLogout={onLogout} />}
            <div
              style={{
                paddingLeft,
                transition: '0.15s all ease',
              }}
            >
              {menuItems && (
                <SubMenuContainer>
                  <SubMenu style={{height: '100%'}}>{menuItems}</SubMenu>
                </SubMenuContainer>
              )}
              <div
                style={{
                  paddingLeft: menuItems && notMobile ? subMenuWidth : '0px',
                  transition: '0.15s all ease',
                }}
              >
                <AppContainer id="app-container" className={hideBar ? 'hide-bar' : ''}>

                  {/* <TestComponent
                    wsMessage={wsMessage}
                  /> */}

                  <Switch>
                    <PrivateRoute
                      path="/users/create"
                      componentProps={{update: false}}
                      component={CreateUser}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/users/:id/permission"
                      componentProps={{update: true}}
                      component={UserPermission}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/users/update/:id"
                      componentProps={{update: true}}
                      component={UpdateUser}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/users"
                      component={Users}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brands/create"
                      component={CreateBrand}
                      roles={[Role.Admin, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path="/brands/create-sub-brand"
                      component={CreateFfpSubBrand}
                      roles={[Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.ffp, BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/brands/update/:id/:tabValue"
                      component={UpdateBrandView}
                      roles={[Role.Admin, Role.Manager, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path="/brands/update-sub-brand/:id"
                      component={UpdateFfpSubBrand}
                      roles={[Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.ffp, BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/brands/:id/reports"
                      component={BrandReports}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brands/:id/detail"
                      component={ViewBrand}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path="/brands/:id/ads-performance/:adsType"
                      component={BrandAdvertisingPerformance}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brands"
                      component={Brands}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path="/brands-overview"
                      component={BrandsOverview}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path="/brandreports/view/:id/:month"
                      component={ViewBrandReport}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brandreports/update/:id/:month"
                      component={UpdateBrandReport}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brandreports/:id"
                      component={BrandReports}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brand-health/:id"
                      component={BrandPerformance}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/brand-inventory/:id"
                      component={BrandInventory}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/asins/view/:id"
                      component={ViewAsin}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/asins/keywords/:id"
                      component={AsinKeywordsTop}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/asins/performance/:id"
                      component={AsinPerformance}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/asins/inventory/:id"
                      component={AsinInventory}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/asins/fees/:id"
                      component={AsinFeeEstimate}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/asins/product-offers/:id"
                      component={AsinProductOffers}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                    />
                    <PrivateRoute
                      path="/products-errors"
                      component={ProductsErrors}
                      roles={[Role.Admin, Role.Manager]}
                    />
                    <PrivateRoute
                      path="/agency-reports"
                      component={AgencyReports}
                      roles={[Role.Admin, Role.Manager, Role.StandardAgencyUser]}
                    />
                    {/* <PrivateRoute
                  path="/performance-sales"
                  component={OverallPerformance}
                  roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                  brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr]}
                /> */}
                    <PrivateRoute
                      path="/performance-insights"
                      component={Insights}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr, BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/performance"
                      component={OverallPerformance}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr, BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/advertising-performance/:adsType"
                      component={AdvertisingPerformance}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mc, BrandTypesAlias.mr, BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/asins/update/:id"
                      component={UpdateAsin}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/asins"
                      component={Asins}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute
                      exact
                      path="/"
                      component={Dashboard}
                      roles={[Role.Admin, Role.BrandUser, Role.User, Role.Manager, Role.StandardAgencyUser]}
                      // componentProps={{drawer: (x) => setOpenDrawer(x)}}
                      hideForFFPAdmin={true}
                    />
                    <PrivateRoute
                      exact
                      path="/alerts"
                      component={Alerts}
                      roles={[Role.Admin, Role.BrandUser, Role.User, Role.Manager, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mr, BrandTypesAlias.mc]}
                    />
                    <PrivateRoute
                      path="/authorize-ads"
                      component={AuthorizeAdsForBrand}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.ecom3k]}
                    />
                    <PrivateRoute
                      path="/authorize-sp-api"
                      component={AuthorizeSpApiForBrands}
                      roles={[Role.Admin, Role.Manager, Role.User, Role.BrandUser]}
                    />
                    <PrivateRoute path="/commissions" component={Commissions} roles={[Role.Admin, Role.Manager]} />
                    <PrivateRoute
                      path="/amazon-listings-item/:marketplaceId/:sellerId/:sku/:tabValue/:version"
                      component={AmazonListingsItem}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/amazon-listings-item/:marketplaceId/:sellerId/:sku/:tabValue"
                      component={AmazonListingsItem}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                    />
                    <PrivateRoute
                      path={PATH.NEW_LISTING}
                      component={NewListingPage}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      requireFfpActiveSubscription={true}
                    />
                    <PrivateRoute
                      path={PATH.LISTINGS}
                      component={AmazonListings}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      requireFfpActiveSubscription={true}
                    />
                    <PrivateRoute
                      path="/products/:sku/detail"
                      component={ProductDetails}
                      roles={[Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser]}
                      brandTypes={[BrandTypesAlias.mr, BrandTypesAlias.mc]}
                    />
                    <PrivateRoute
                      path="/commissions-detail"
                      component={CommissionDetail}
                      roles={[Role.Admin, Role.Manager]}
                    />
                    <PrivateRoute
                      path="/account/:tabValue"
                      component={AccountPageView}
                      roles={[Role.BrandUser]}
                      brandTypes={[BrandTypesAlias.ffp, BrandTypesAlias.ecom3k]}
                      requiresBrandAdminPermissions={true}
                    />
                    <PrivateRoute
                      path="/payment-complete"
                      component={PaymentCompletePage}
                      roles={[Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/subscription-plan-changed"
                      component={SubscriptionPlanChanged}
                      roles={[Role.BrandUser]}
                    />
                    <PrivateRoute
                      path="/brands-custom-attributes"
                      component={CustomAttributesConfig}
                      roles={[Role.Admin, Role.Manager, Role.User]}
                    />
                    <PrivateRoute
                      path="/custom-attributes"
                      component={CustomAttributesConfig}
                      roles={[Role.Admin, Role.Manager, Role.User]}
                    />
                    <PrivateRoute path="/stripe-brands" component={StripeBrands} roles={[Role.Admin]} />
                    <Route path="/login" component={Login} />
                    <Route path="/ecom3k-gdrive-integration-for-stonehenge" component={InfoPage} />
                    <Route path="/register/:token" component={Register} />
                    <Route path="/create-account" component={FFPRegistration} />
                    <Route path="/ecom3kRegistration" component={ECOM3KRegistration} />
                    <Route path="/amazonaudit" component={AmazonAuditRegister} />
                    <Route path="/reset/:token" render={(props) => <Register {...props} passwordReset={true} />} />
                    <Route path="/forgot-password" component={PasswordReset} />
                    <Route path="/subscription-success" component={SubscriptionSuccessPage} />
                    <Route path="/subscription-cancelled" component={SubscriptionCancelledPage} />
                    <Route path="/terms-of-service" render={(props) => <TermsOfService {...props} />} />
                    <Route path="/privacy-policy" render={(props) => <TermsOfService {...props} />} />
                    <Route path="/cookie-policy" render={(props) => <TermsOfService {...props} />} />
                    {/*<Redirect from="*" to="/" />*/}
                  </Switch>
                  <Footer />
                </AppContainer>
              </div>
            </div>
          </div>
        </ColorModeProvider>
      </AppContextProvider>
    </CacheBuster>
  );
}

export default App;
