import React, {useState, useEffect, useCallback} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Button, ButtonGroup, Checkbox, Grid, Paper, Typography, Tooltip, Chip} from '@mui/material';
import {DataGrid, GridColDef, GridSelectionModel} from '@mui/x-data-grid';
import moment from 'moment';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {AsinKeywordsChart} from './AsinKeywordsChart';
import {buildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
// styles
import {sxStyles} from '../../../Brands/Styles';

interface SummaryDataInterface {
  keyword: string;
  avg_rank: number;
  old_avg_rank: number;
}
[];

function AsinKeywordsMetrics(): JSX.Element {
  const {id} = useParams<Record<string, string | undefined>>();
  const [keywordRows, setKeywordRows] = useState<SummaryDataInterface[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryDataInterface[]>([]);
  const [chartData, setChartData] = useState<{
    data: {
      keyword: string;
      type: string;
      data: {
        [key: string]: string | number;
      }[];
    }[];
    interval: string;
  }>();
  const [count, setCount] = useState(0);
  const [selectedIdxes, setSelectedIdxes] = useState<GridSelectionModel>([]);
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);
  const [period, setPeriod] = useState<string | null>('7d');
  const [fromTo, setFromTo] = useState<{
    from: Date | number;
    to: Date | number;
    compareFrom: Date | number | null;
    compareTo: Date | number | null;
  }>({
    from: moment(new Date().setDate(new Date().getDate() - 6))
      .startOf('day')
      .valueOf(),
    to: moment(new Date()).valueOf(),
    compareFrom: null,
    compareTo: null,
  });

  const columns: GridColDef[] = [
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 0.6,
      type: 'filterString',
    },
    {
      field: 'avg_rank',
      headerName: 'Rank',
      flex: 0.4,
    },
  ];

  const history = useHistory();

  const getInterval = useCallback(() => {
    const fromValue = fromTo.compareFrom && fromTo.compareFrom < fromTo.from ? fromTo.compareFrom : fromTo.from;
    const toValue = fromTo.compareTo && fromTo.compareTo < fromTo.to ? fromTo.compareTo : fromTo.to;
    const momentFromValue = moment.unix(+fromValue / 1000);
    const momentToValue = moment.unix(+toValue / 1000);
    return momentToValue.diff(momentFromValue, 'days') > 1 ? 'day' : 'hour';
  }, [fromTo.compareFrom, fromTo.compareTo, fromTo.from, fromTo.to]);

  const loadChart = useCallback(async () => {
    const summary = keywordRows.filter((item) => selectedIdxes.includes(item.keyword));
    setSummaryData(summary);
    const interval = getInterval();
    setLoadingChart(true);
    try {
      const {data} = await Api.get(`asins/keyword_search_results_chart/${id}`, {
        params: {
          from: fromTo.from,
          to: fromTo.to,
          compareFrom: fromTo.compareFrom,
          compareTo: fromTo.compareTo,
          keywords: summary.map((item) => item.keyword),
          interval,
        },
      });
      setChartData({interval: interval, data: data || []});
    } catch (e) {
      errorAlert('Unable to get Asin Keywords', e);
    } finally {
      setLoadingChart(false);
    }
  }, [fromTo.compareFrom, fromTo.compareTo, fromTo.from, fromTo.to, getInterval, id, keywordRows, selectedIdxes]);

  function lastNDays(days: number) {
    const toValue = moment().valueOf();
    const fromValue = moment(toValue)
      .subtract(days - 1, 'days')
      .startOf('day')
      .valueOf();
    onFromToChange(fromValue, toValue, null, null, days + 'd', compare);
    setPeriod(days + 'd');
  }

  function quarterRange() {
    const toValue = moment().valueOf();
    const fromValue = moment().quarter(moment().quarter()).startOf('quarter').valueOf();
    onFromToChange(fromValue, toValue, null, null, 'q', compare);
    setPeriod('q');
  }

  function onFromToChange(
    from: Date | number,
    to: Date | number,
    cFrom: Date | number | null,
    cTo: Date | number | null,
    preset: string | boolean | null,
    compare: boolean,
  ) {
    const fromValue = moment(from);
    const toValue = moment(to);
    let compareFrom = cFrom;
    let compareTo = cTo;
    if (compare) {
      if (!compareFrom && !compareTo) {
        if (preset === 'q') {
          compareFrom = moment(fromValue).subtract(1, 'quarter').valueOf();
          compareTo = moment().quarter(moment(compareFrom).quarter()).endOf('quarter').valueOf();
        } else {
          const diff = moment(toValue).diff(fromValue, 'days');
          compareFrom = moment(fromValue)
            .subtract(diff + 1, 'days')
            .valueOf();
          compareTo = moment(toValue)
            .subtract(diff + 1, 'days')
            .valueOf();
        }
      }
    }

    setFromTo({from, to, compareFrom, compareTo});
    loadKeywords({from, to, compareFrom, compareTo});
  }

  const loadKeywords = useCallback(
    async function (objFromTo) {
      setLoadingKeywords(true);
      try {
        const {data} = await Api.get(`asins/keyword_search_results/${id}`, {
          params: {
            from: objFromTo.from,
            to: objFromTo.to,
            compareFrom: objFromTo.compareFrom,
            compareTo: objFromTo.compareTo,
          },
        });
        const rows = data.rows.map((item: {keyword: string}) => {
          return {id: item?.keyword, ...item};
        });
        setCount(data.count);
        setKeywordRows(rows);
        setSelectedIdxes(rows.map((item: {id: string}) => item?.id));
        const summary = rows;
        setSummaryData(summary);
      } catch (e) {
        errorAlert('Unable to get Asin Keywords', e);
      } finally {
        setLoadingKeywords(false);
      }
    },
    [id],
  );

  function toggleCompare(checked: boolean) {
    setCompare(checked);
    onFromToChange(fromTo.from, fromTo.to, null, null, period, checked);
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = buildMenu('keyword', id as string, history);
    });
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [id, history]);

  useEffect(() => {
    loadChart();
  }, [loadChart]);

  useEffect(() => {
    loadKeywords(fromTo);
  }, [fromTo, loadKeywords]);

  return (
    <Grid container style={{paddingTop: 30}}>
      <Grid item lg={8} xs={12}>
        <ButtonGroup
          className="asin-button-group"
          sx={sxStyles('buttonGroup')}
          size={'small'}
          variant={'outlined'}
          color={'primary'}
          disabled={loadingKeywords || loadingChart}
          fullWidth
          style={{marginBottom: '10px'}}
        >
          <Button variant={period === '7d' ? 'contained' : 'outlined'} color={'primary'} onClick={() => lastNDays(7)}>
            7 days
          </Button>
          <Button variant={period === '30d' ? 'contained' : 'outlined'} color={'primary'} onClick={() => lastNDays(30)}>
            30 days
          </Button>
          <Button variant={period === '60d' ? 'contained' : 'outlined'} color={'primary'} onClick={() => lastNDays(60)}>
            60 days
          </Button>
          <Button variant={period === '90d' ? 'contained' : 'outlined'} color={'primary'} onClick={() => lastNDays(90)}>
            90 days
          </Button>
          <Button variant={period === 'q' ? 'contained' : 'outlined'} color={'primary'} onClick={() => quarterRange()}>
            Quarter
          </Button>
          <Button variant={!period ? 'contained' : 'outlined'} color={'primary'} onClick={() => setPeriod(null)}>
            Custom
          </Button>
        </ButtonGroup>
        {fromTo.to && fromTo.from && (
          <DateRangePicker
            width="450px"
            disabled={loadingChart}
            from={fromTo.from}
            to={fromTo.to}
            onChange={(x) => {
              onFromToChange(
                x.selection.startDate as Date,
                x.selection.endDate as Date,
                fromTo.compareFrom,
                fromTo.compareTo,
                false,
                compare,
              );
            }}
          />
        )}
      </Grid>
      <Grid item lg={4} xs={12} style={{textAlign: 'right'}}>
        <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
          Individual ASIN Keyword Search Results
        </Typography>
        <Typography component="h2" variant="h5" color="secondary" gutterBottom>
          {id}
        </Typography>
        <Typography>
          <Checkbox checked={compare} onChange={(e) => toggleCompare(e.target.checked)} /> Compare to previous
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{border: '1px'}}>
          <Grid item xs={12} md={12} style={{padding: '0 0 20px 0'}}>
            <Grid container spacing={1} style={{paddingBottom: '30px'}}>
              {summaryData
                .filter((item) => item.avg_rank !== null)
                .map((item, index) => (
                  <Grid item lg={2} style={{width: '100%'}} key={`${item.keyword}-${index}`}>
                    <Paper variant={'elevation'}>
                      <Tooltip title={`Keyword : ${item.keyword}`} style={{cursor: 'help'}}>
                        <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                          {item.keyword}
                        </Typography>
                      </Tooltip>
                      <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                        {item.avg_rank}
                        {item.old_avg_rank ? (
                          <Chip
                            sx={item.old_avg_rank > item.avg_rank ? sxStyles('green') : sxStyles('red')}
                            size="medium"
                            label={`${item.old_avg_rank > item.avg_rank ? '' : ''}${Math.abs(
                              item.old_avg_rank - item.avg_rank,
                            )}`}
                            color={'primary'}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                      {compare && (
                        <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
                          {item?.old_avg_rank || 'N/A'}
                          {/* {data && 'old_buy_box' in data?
                        data?.old_buy_box!=null?data.old_buy_box+"%":"N/A":""} */}
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                ))}
            </Grid>
            {chartData && <AsinKeywordsChart data={chartData} loading={loadingChart} />}
          </Grid>
          <Grid item xs={12} md={12}>
            <DataGrid
              className="custom-table"
              style={{width: '100%'}}
              autoHeight={true}
              rowCount={count}
              rows={keywordRows}
              columns={columns}
              disableSelectionOnClick={true}
              checkboxSelection={true}
              selectionModel={selectedIdxes}
              onSelectionModelChange={(x) => {
                setSelectedIdxes(x);
              }}
            ></DataGrid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
      </Grid>
    </Grid>
  );
}

export {AsinKeywordsMetrics};
