import React from 'react';
import {
  // Public,
  // Person,
  // Help,
  // LineWeight,
  // OpenWith,
  BatteryUnknown,
  // BrandingWatermark,
  History,
  Inventory,
  // Error,
  // LocalOffer,
  // CheckCircle,
  // Key,
  InsertPhoto,
  CompareArrows,
} from '@mui/icons-material';
import {List, ListItem, ListItemText, ListItemIcon, Divider, Grid, Typography, Box} from '@mui/material';
import VisualDiff from 'react-visual-diff-r17-r18';
import odiff from 'odiff';
// import {getMarketplaceById} from '@bizon/amazon-ids';
import Spacer from '../../../components/Spacer/Spacer';
// interfaces
import {VersionInterface} from '../interfaces/interfaces';
import {SxStyleTypes} from '../../../interfaces/sxStyles/sxStyles.interface';

interface DiffListProps {
  version: VersionInterface;
  prevVersion: VersionInterface;
  styles: (key: string) => SxStyleTypes;
}

interface VersionsArrayTypes {
  key: string;
  value: {
    icon: React.ReactElement;
    value: React.ReactElement | null;
  };
}

type DiffObjType = {
  [key: string]: string | DiffObjType;
};

type DiffItem = DiffObjType[] | DiffObjType | string | number | null;

interface ChangesArrayTypes {
  current: {
    [key: string]: DiffItem;
  }[];
  prev: {
    [key: string]: DiffItem;
  }[];
  label: string;
  type: string;
}

export default function DiffList({version, prevVersion, styles}: DiffListProps): JSX.Element {
  // const weight = (v: VersionInterface) =>
  //   v.attributes?.item_weight && `${v.attributes?.item_weight[0]?.value} ${v.attributes?.item_weight[0]?.unit}`;

  // const packageWeight = (v: VersionInterface) =>
  //   v.attributes?.item_package_weight &&
  //   `${v.attributes?.item_package_weight[0]?.value} ${v.attributes?.item_package_weight[0]?.unit}`;

  // const dimensions = (v: VersionInterface) =>
  //   v.attributes?.item_dimensions
  //     ? `${v.attributes?.item_dimensions[0]?.height?.value} x ${v.attributes?.item_dimensions[0]?.width?.value} x ${v.attributes?.item_dimensions[0]?.length?.value} ${v.attributes?.item_dimensions[0]?.length?.unit}`
  //     : '';

  // const packageDimensions = (v: VersionInterface) =>
  //   v.attributes?.item_package_dimensions
  //     ? `${v.attributes?.item_package_dimensions[0]?.height?.value} x ${v.attributes?.item_package_dimensions[0]?.width?.value} x ${v.attributes?.item_package_dimensions[0]?.length?.value} ${v.attributes?.item_package_dimensions[0]?.length?.unit}`
  //     : '';

  // const apiSupported = (v: VersionInterface) =>
  //   v.isSupportedProductType === true ? 'API Editable' : 'API Unsupported';

  // const marketplace = (v: VersionInterface) => getMarketplaceById(v.marketplace_id)?.code.toUpperCase() as string;

  const diffs = (changes: ChangesArrayTypes, changeType: 'prev' | 'current') => {
    return changes[changeType].map((value, index) => {
      const keys = Object.keys(value);
      const finalValue = value[keys[0]];
      const finalPrevValue = changes[changeType === 'current' ? 'prev' : 'current']?.[index]?.[keys[0]];

      const attrName = `${[keys[0]]}: `.replaceAll(new RegExp('_', 'g'), ' ');

      const current = finalValue ? finalValue : '';
      const prev = finalPrevValue ? finalPrevValue : '';

      const images =
        typeof current === 'string' &&
        !current?.includes(' ') &&
        (current?.includes('http://') || current?.includes('https://'))
          ? true
          : false;

      const objOrArray: {
        path: string[];
        value: string;
      }[] = [];

      const doMap = (
        obj: {
          [key: string]: string | {[key: string]: string};
        },
        p?: string[],
      ) => {
        const path = p ? p : [];
        for (const key in obj) {
          if (obj[key] && (typeof obj[key] === 'object' || Array.isArray(obj[key]))) {
            doMap(obj[key] as {[key: string]: string}, path.concat(key));
          } else {
            objOrArray.push({
              path: path.concat(key),
              value: obj[key] as string,
            });
          }
        }
      };

      const isAnObject = current && (value.type === 'add' || value.type === 'rm') && typeof current === 'object';

      if (isAnObject) {
        doMap(
          current as {
            [key: string]: string;
          },
        );
      }

      return images && typeof current === 'string' ? (
        <span key={`${changeType}-${index}`}>
          <br />
          <span style={{textTransform: 'capitalize', fontWeight: 'bold'}}>{current && attrName}</span>
          <br />
          <img src={current} alt={current} style={{maxWidth: '200px', maxHeight: '200px'}} />
          <br />
        </span>
      ) : isAnObject ? (
        <span key={`${changeType}-${index}`} style={{display: 'flex', flexDirection: 'column'}}>
          <span className="diff-subtitle">
            {changes.label ? `${changes.label.replaceAll(new RegExp('_', 'g'), ' ')}: ` : ''}
          </span>
          <span className="diff-subtitle">
            {value.label ? `${value.label.toString().replaceAll(new RegExp('_', 'g'), ' ')}: ` : ''}
          </span>
          {Object.entries(objOrArray).map(([key, v]) => {
            const path = v.path.join(' > ').replaceAll(new RegExp('_', 'g'), ' ');
            const isAnImage =
              typeof v.value === 'string' &&
              !v.value?.includes(' ') &&
              (v.value?.includes('http://') || v.value?.includes('https://'));
            return v.value ? (
              <Typography
                component="span"
                sx={[
                  {
                    ...(changeType === 'current' && value.type === 'add' && styles('added')),
                  },
                  {
                    ...(changeType === 'current' && value.type === 'rm' && styles('removed')),
                  },
                ]}
                key={`${changeType}-${key}`}
              >
                <span style={{textTransform: 'capitalize', fontWeight: 'bold'}}>{path}: </span>
                {isAnImage ? (
                  <span>
                    <br />
                    <img src={v.value} alt={v.value} style={{maxWidth: '200px', maxHeight: '200px'}} />
                    <br />
                  </span>
                ) : (
                  <span>{v.value}</span>
                )}
              </Typography>
            ) : null;
          })}
        </span>
      ) : (
        <VisualDiff
          key={`${changeType}-${index}`}
          left={
            <span>
              <Typography component="span" style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                {prev && attrName}
              </Typography>
              <Typography component="span" sx={{...(value.type === 'rm' && styles('removed'))}}>
                {prev && prev.toString()}
              </Typography>
            </span>
          }
          right={
            <span>
              <Typography component="span" style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                {current && attrName}
              </Typography>
              <Typography component="span" sx={{...(value.type === 'rm' && styles('removed'))}}>
                {current && current.toString()}
              </Typography>
            </span>
          }
          renderChange={({type, children}: {type: string; children: React.ReactNode}) => {
            if (value.type === 'add' && changeType === 'current') {
              if (type === 'added') {
                return (
                  <Typography component="span" sx={styles('added')}>
                    {children}
                  </Typography>
                );
              }
              if (type === 'removed') {
                return <span></span>;
              }
            }
            if (value.type === 'add' && changeType === 'prev') {
              if (type === 'added') {
                return (
                  <Typography component="span" sx={styles('removed')}>
                    {children}
                  </Typography>
                );
              }
              if (type === 'removed') {
                return <span></span>;
              }
            }
            if (value.type === 'rm' && changeType === 'current') {
              return (
                <Typography component="span" sx={styles('removed')}>
                  {children}
                </Typography>
              );
            }
            if (value.type === 'rm' && changeType === 'prev') {
              return (
                <Typography component="span" sx={styles('removed')}>
                  {children}
                </Typography>
              );
            }

            if (value.type === 'set' && changeType === 'current') {
              if (type === 'added') {
                return (
                  <Typography component="span" sx={styles('added')}>
                    {children}
                  </Typography>
                );
              }
              if (type === 'removed') {
                return <span></span>;
              }
            }
            if (value.type === 'set' && changeType === 'prev') {
              if (type === 'added') {
                return (
                  <Typography component="span" sx={styles('removed')}>
                    {children}
                  </Typography>
                );
              }
              if (type === 'removed') {
                return <span></span>;
              }
            }
          }}
        />
      );
    });
  };

  const versionsArray: VersionsArrayTypes[] = [];

  const Diff = (current: DiffItem, prev: DiffItem, attr?: string) => {
    const result: {
      current: DiffItem | null;
      prev: DiffItem | null;
    } = {current: null, prev: null};
    const changesArray: ChangesArrayTypes = {current: [], prev: [], label: '', type: ''};

    const attrNotFound = [
      'main_product_image_locator',
      'marketplace_id',
      'bullet_point',
      'generic_keyword',
      'product_description',
      'item_package_dimensions',
      'item_package_weight',
      'item_dimensions',
      'item_weight',
      'merchant_shipping_group',
      'purchasable_offer',
      'batteries_required',
      'fulfillment_availability',
    ];

    const doFor = (prevV: DiffItem, crrnt: DiffItem, ix?: (string | number | (string | number)[])[]) => {
      const getDiff = odiff(prevV, crrnt);
      getDiff.forEach((d: odiff.odiffResult, i) => {
        const path = d.path;
        const type = d.type;

        const diffIndex = (d.type === 'add' ? d.index : undefined) as number;

        const vals = d.type === 'add' ? d.vals : undefined;

        const val = d.type === 'set' ? d.val : undefined;

        changesArray.type = type;

        if (Array.isArray(crrnt) && crrnt.every((x) => typeof x === 'string')) {
          current?.toString();
        }

        const propsArray = ix ? ix : path;

        const key = propsArray;

        if (attr && path.some((p: string | number) => attrNotFound.includes(p as string))) {
          return null;
        }

        let currentObj = crrnt;
        let prevObj = prevV;

        const getUnset = () => {
          if (path.length >= 1) {
            for (let i = 0; i < path.length; i++) {
              const property = path[i];
              prevObj = (prevObj as DiffObjType[])?.[property as number];
              currentObj = '';
              result.prev = prevObj;
              result.current = currentObj;

              changesArray.prev.push({[property]: result.prev, type: 'rm', label: path.join(' > ')});

              if (currentObj === '') {
                changesArray.current.push({[property]: result.prev, type: 'rm', label: path.join(' > ')});
              }
            }
          }
          if (typeof current === 'string' || typeof current === 'number') {
            result.current = currentObj;
            changesArray.current.push({value: result.current, type: type});
          }
          if (typeof prev === 'string' || typeof prev === 'number') {
            result.prev = prevObj;
            changesArray.prev.push({value: result.prev, type: type});
          }
        };

        const getAdd = () => {
          if (path && path.length >= 1) {
            for (let i = 0; i < path.length; i++) {
              const property = path[i];
              currentObj = vals?.[0];
              result.prev = '';
              result.current = currentObj;

              if (i === path.length - 1) {
                changesArray.current.push({[property]: result.current, type: type, label: property});
                changesArray.prev.push({[property]: result.prev, type: type, label: property});
              }
            }
          } else {
            result.prev = '';
            result.current = vals?.[0];

            changesArray.current.push({[diffIndex]: result.current, type: type, label: diffIndex});

            changesArray.prev.push({[diffIndex]: result.prev, type: type, label: diffIndex});
          }
        };

        const getRm = () => {
          if (path && path.length >= 1) {
            for (let i = 0; i < path.length; i++) {
              const property = path[i];
              result.prev = vals?.[0];
              result.current = vals?.[0];
              if (i === path.length - 1) {
                changesArray.current.push({[property]: result.prev, type: type, label: property});
                changesArray.prev.push({[property]: result.prev, type: type, label: property});
              }
            }
          } else {
            result.prev = vals?.[0];
            result.current = vals?.[0];
            changesArray.current.push({[diffIndex]: result.prev, type: type, label: diffIndex});

            changesArray.prev.push({[diffIndex]: result.prev, type: type, label: diffIndex});
          }
        };

        const getSet = () => {
          if (path.length >= 1) {
            for (let i = 0; i < path.length; i++) {
              const property = path[i] as keyof typeof prevObj | number;
              prevObj = (prevObj as DiffObjType)?.[property] || '';
              currentObj = val;
              result.prev = prevObj;
              result.current = currentObj;

              const currentKey = (
                typeof currentObj === 'object' || Array.isArray(currentObj) ? key : path.join(' > ')
              ) as string;

              if (
                prevObj !== '' &&
                i === path.length - 1 &&
                (typeof currentObj === 'string' || typeof currentObj === 'number')
              ) {
                changesArray.current.push({[currentKey]: result.current, type: 'add', label: property});
              }

              if (i === path.length - 1 && (typeof prevObj === 'string' || typeof prevObj === 'number')) {
                changesArray.prev.push({[currentKey]: result.prev, type: 'add', label: property});
              }

              if (prevObj === '') {
                changesArray.current.push({[currentKey]: result.current, type: 'add', label: property});
              }
            }

            if (ix) {
              propsArray.push(path);
            }
            if (i === path.length - 1 && (typeof currentObj === 'string' || typeof currentObj === 'number')) {
              propsArray.slice(1);
            }

            if (
              typeof currentObj === 'object' ||
              Array.isArray(currentObj) ||
              typeof prevObj === 'object' ||
              Array.isArray(prevObj)
            ) {
              doFor(prevObj, currentObj, propsArray);
            }
          }
          if (typeof current === 'string' || typeof current === 'number') {
            result.current = currentObj;
            changesArray.current.push({value: result.current, type: type});
          }
          if (typeof prev === 'string' || typeof prev === 'number') {
            result.prev = prevObj;
            changesArray.prev.push({value: result.prev, type: type});
          }
        };

        if (d.type === 'rm') {
          getRm();
        }
        if (d.type === 'set') {
          getSet();
        }

        if (d.type === 'add') {
          getAdd();
        }
        if (d.type === 'unset') {
          getUnset();
        }
      });
    };

    doFor(prev, current);

    return changesArray.type === 'rm' || (changesArray.current.length > 0 && changesArray.prev.length > 0) ? (
      <span>
        <span className="versions-container">{diffs(changesArray, 'prev')}</span>
        <div style={{display: 'flex'}}>
          <Divider orientation="vertical" textAlign="left">
            <History />
          </Divider>
        </div>
        <span className="versions-container">{diffs(changesArray, 'current')}</span>
      </span>
    ) : null;
  };

  // TODO: Improve differences to show.
  const versions = {

    // 'Brand Code': {icon: <BrandingWatermark />, value: Diff(version.brand_code, prevVersion.brand_code)},
    // Marketplace: {icon: <Public />, value: Diff(marketplace(version), marketplace(prevVersion))},
    // 'Seller ID': {icon: <Person />, value: Diff(version.seller_id, prevVersion.seller_id)},
    // 'Seller SKU': {icon: <Person />, value: Diff(version.seller_sku, prevVersion.seller_sku)},
    // ASIN: {
    //   icon: <OpenWith />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.asin, prevVersion.summaries[0]?.asin)
    //       : null,
    // },
    // 'FN SKU': {
    //   icon: <OpenWith />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.fn_sku, prevVersion.summaries[0]?.fn_sku)
    //       : null,
    // },
    // Offers: {
    //   icon: <LocalOffer />,
    //   value: version.offers && prevVersion.offers ? Diff(version.offers, prevVersion.offers) : null,
    // },
    // 'Main Image': {
    //   icon: <InsertPhoto />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.mainImage?.link, prevVersion.summaries[0]?.mainImage?.link)
    //       : null,
    // },
    // 'Product Type': {
    //   icon: <Inventory />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.productType, prevVersion.summaries[0]?.productType)
    //       : null,
    // },
    // 'Condition Type': {
    //   icon: <Inventory />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.conditionType, prevVersion.summaries[0]?.conditionType)
    //       : null,
    // },
    // 'Fulfillment Availability': {
    //   icon: <Inventory />,
    //   value: Diff(
    //     version.fulfillmentAvailability ? version.fulfillmentAvailability : '',
    //     prevVersion.fulfillmentAvailability ? prevVersion.fulfillmentAvailability : '',
    //   ),
    // },
    // 'Generic Keyword': {
    //   icon: <Key />,
    //   value: Diff(
    //     version.attributes?.generic_keyword && version.attributes?.generic_keyword.length > 0
    //       ? (version.attributes?.generic_keyword as unknown as DiffObjType[])
    //       : '',
    //     prevVersion.attributes?.generic_keyword && prevVersion.attributes?.generic_keyword.length > 0
    //       ? (prevVersion.attributes?.generic_keyword as unknown as DiffObjType[])
    //       : '',
    //   ),
    // },
    // 'Item Type Keyword': {
    //   icon: <Key />,
    //   value: Diff(
    //     version.attributes?.item_type_keyword && version.attributes?.item_type_keyword.length > 0
    //       ? version.attributes?.item_type_keyword
    //       : [],
    //     version.attributes?.item_type_keyword && version.attributes?.item_type_keyword.length > 0
    //       ? prevVersion.attributes?.item_type_keyword
    //       : [],
    //   ),
    // },

    // Status: {
    //   icon: <CheckCircle />,
    //   value:
    //     version.summaries[0]?.status &&
    //     prevVersion.summaries[0]?.status &&
    //     (!version.summaries[0]?.status.every((s) => prevVersion.summaries[0]?.status.includes(s)) ||
    //       !prevVersion.summaries[0]?.status.every((s) => version.summaries[0]?.status.includes(s)))
    //       ? Diff(
    //           version.summaries[0]?.status.toString().replaceAll(',', ', '),
    //           prevVersion.summaries[0]?.status.toString().replaceAll(',', ', '),
    //         )
    //       : null,
    // },

    // Supported: {icon: <Help />, value: Diff(apiSupported(version), apiSupported(prevVersion))},
    // 'Item Name': {
    //   icon: <Inventory />,
    //   value:
    //     version.summaries && prevVersion.summaries
    //       ? Diff(version.summaries[0]?.itemName, prevVersion.summaries[0]?.itemName)
    //       : null,
    // },
    // 'Item Weight': {icon: <LineWeight />, value: Diff(weight(version), weight(prevVersion))},
    // 'Item Dimensions': {icon: <OpenWith />, value: Diff(dimensions(version), dimensions(prevVersion))},
    // 'Item Package Weight': {
    //   icon: <LineWeight />,
    //   value: Diff(packageWeight(version), packageWeight(prevVersion)),
    // },
    // 'Item Package Dimensions': {
    //   icon: <OpenWith />,
    //   value: Diff(packageDimensions(version), packageDimensions(prevVersion)),
    // },
    // 'Merchant Shipping Group': {
    //   icon: <Inventory />,
    //   value: Diff(
    //     version.attributes?.merchant_shipping_group ? version.attributes?.merchant_shipping_group : '',
    //     prevVersion.attributes?.merchant_shipping_group ? prevVersion.attributes?.merchant_shipping_group : '',
    //   ),
    // },
    // 'Merchant Suggested ASIN': {
    //   icon: <Inventory />,
    //   value: Diff(
    //     version.attributes?.merchant_suggested_asin && version.attributes?.merchant_suggested_asin.length > 0
    //       ? version.attributes?.merchant_suggested_asin
    //       : '',
    //     prevVersion.attributes?.merchant_suggested_asin && prevVersion.attributes?.merchant_suggested_asin.length > 0
    //       ? prevVersion.attributes?.merchant_suggested_asin
    //       : '',
    //   ),
    // },

    'Main Product Image Locator': {
      icon: <InsertPhoto />,
      value: Diff(
        version.attributes?.main_product_image_locator && version.attributes?.main_product_image_locator.length > 0
          ? (version.attributes?.main_product_image_locator as unknown as DiffObjType[])
          : '',
        prevVersion.attributes?.main_product_image_locator &&
          prevVersion.attributes?.main_product_image_locator.length > 0
          ? (prevVersion.attributes?.main_product_image_locator as unknown as DiffObjType[])
          : '',
      ),
    },

    'Attributes': {
      icon: <Inventory />,
      value: Diff(
        version.attributes ? (version.attributes as unknown as DiffObjType) : '',
        prevVersion.attributes ? (prevVersion.attributes as unknown as DiffObjType) : '',
        'attributes',
      ),
    },

    'Purchasable offer': {
      icon: <Inventory />,
      value: Diff(
        version.attributes?.purchasable_offer ? (version.attributes?.purchasable_offer as unknown as DiffObjType) : '',
        prevVersion.attributes?.purchasable_offer
          ? (prevVersion.attributes?.purchasable_offer as unknown as DiffObjType)
          : '',
      ),
    },

    'Batteries Included': {
      icon: <BatteryUnknown />,
      value: Diff(
        version.attributes?.batteries_included && version.attributes?.batteries_included.length > 0
          ? (version.attributes?.batteries_included as unknown as DiffObjType)
          : '',
        prevVersion.attributes?.batteries_included && prevVersion.attributes?.batteries_included.length > 0
          ? (prevVersion.attributes?.batteries_included as unknown as DiffObjType)
          : '',
      ),
    },
    'Batteries Required': {
      icon: <BatteryUnknown />,
      value: Diff(
        version.attributes?.batteries_required && version.attributes?.batteries_required.length > 0
          ? (version.attributes?.batteries_required as unknown as DiffObjType[])
          : '',
        prevVersion.attributes?.batteries_required && prevVersion.attributes?.batteries_required.length > 0
          ? (prevVersion.attributes?.batteries_required as unknown as DiffObjType[])
          : '',
      ),
    },

    // Issues: {
    //   icon: <Error />,
    //   value: Diff(
    //     version.issues ? (version.issues as unknown as DiffObjType[]) : '',
    //     prevVersion.issues ? (prevVersion.issues as unknown as DiffObjType[]) : '',
    //   ),
    // },

    'Product Description': {
      icon: <Inventory />,
      value: Diff(
        version.attributes?.product_description && version.attributes?.product_description.length > 0
          ? (version.attributes?.product_description as unknown as DiffObjType[])
          : '',
        prevVersion.attributes?.product_description && prevVersion.attributes?.product_description.length > 0
          ? (prevVersion.attributes?.product_description as unknown as DiffObjType[])
          : '',
      ),
    },
    'Bullet Point': {
      icon: <Inventory />,
      value: Diff(
        version.attributes?.bullet_point && version.attributes?.bullet_point.length > 0
          ? (version.attributes?.bullet_point as unknown as DiffObjType[])
          : '',
        prevVersion.attributes?.bullet_point && prevVersion.attributes?.bullet_point.length > 0
          ? (prevVersion.attributes?.bullet_point as unknown as DiffObjType[])
          : '',
      ),
    },
  };

  Object.entries(versions).forEach(([key, item]) => {
    if (item.value) versionsArray.push({key: key, value: item});
  });

  return (
    <List>
      {versionsArray.length > 0 ? (
        Object.entries(versionsArray).map(([key, item]) => (
          <div key={key}>
            <ListItem>
              <ListItemIcon>{item.value.icon}</ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    component="span"
                    style={{fontSize: '18px', display: 'flex', alignItems: 'center'}}
                    gutterBottom
                  >
                    {item.key}
                    <Spacer width={10} /> <CompareArrows />
                  </Typography>
                }
                secondary={<Box sx={styles('diffContainer')}>{item.value.value}</Box>}
              />
            </ListItem>
            <Divider variant="middle" light={true} />
          </div>
        ))
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          There are no changes
        </Grid>
      )}
    </List>
  );
}
