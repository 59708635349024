import React, { useEffect, useState } from 'react';

import IssuesSummary from '../IssuesSummary';

import {
  Api,
  errorAlert,
} from 'src/utils/api';

import * as Yup from 'yup';

import ListingFieldComponent from '../ListingField';

import SearchBar from 'src/components/SearchBar/SearchBar';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import mixPanel from 'src/services/mixpanel';

import { alertService } from 'src/services/alert.service';
import { CustomAlert } from 'src/pages/Login/components/CustomAlert';

import { fieldGroupNames } from '../../utils/constant';

import ImageCard from '../ImageCard';

import {
  FastField,
  Formik,
  FormikValues,
  Form,
  ErrorMessage,
  FieldArray,
  // FormikErrors,
  // FormikHelpers,
} from 'formik';

import {
  ErrorOutlineOutlined,
  ExpandCircleDownOutlined,
  Link,
} from '@mui/icons-material';

import {
  CountIssues,
  ImageLocatorInterface,
  ListingFieldDefinitions,
} from '../../interfaces/interfaces';

import {
  buildEmptyField,
  fillInitialValues,
  getInitialValues,
  removeEmpty,
} from '../../utils/listings';

type FormikHelpers = {
  setSubmitting: (isSubmitting: boolean) => void;
};

type ProductImages = {
  [key: string]: ImageLocatorInterface;
};

type FieldValidationError = {
  fieldName: string;
  message: string;
};

const ListingForm = (
  props:
{
  marketplaceId?: string;
  sellerId?: string;
  sku?: string;
}): JSX.Element => {

  const [countIssues, setCountIssues] = useState<CountIssues | null>(null);
  const [allIssuesTotal, setAllIssuesTotal] = useState<number>(0);

  // Do not render form until done loading.
  const [loading, setLoading] = useState(false);
  const allFieldGroupsExpanded = fieldGroupNames.reduce((acc: { [key: string]: boolean } , fieldGroupName: string) => {
    acc[fieldGroupName] = true;
    return acc;
  }, {})

  // All field groups collapsed except for images.
  const allFieldGroupsCollapsed = fieldGroupNames.reduce((acc: { [key: string]: boolean } , fieldGroupName: string) => {
    acc[fieldGroupName] = fieldGroupName !== 'images' ? false : true;
    return acc;
  }, {})

  // Create an object with all field group names as keys and values set to false.
  // Use it to keep track of the expanded state of each field group with Accordion.
  const [expandedFieldGroups, setExpandedFieldGroups] = useState<{ [key: string]: boolean }>(
    allFieldGroupsCollapsed
  );

  const [filter, setFilter] = useState('');
  const [filteredAttributeKeys, setFilteredAttributeKeys] = useState<string[]>([]);

  const [fieldValidationErrors, setFieldValidationErrors] = useState<FieldValidationError[]>([]);

  const [isNewListing, setIsNewListing] = useState(false);

  const [initialValues, setInitialValues] = useState<FormikValues | null>(null);

  const [validationSchema, setValidationSchema] = useState({});

  const [version, setVersion] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [asin, setAsin] = useState<string>('');

  const [productType, setProductType] = useState<string>('');
  const [productTypesInMarketplace, setProductTypesInMarketplace] = useState<string[]>([]);

  const [marketplaceId, setMarketplaceId] = useState<string | undefined>(props.marketplaceId);
  const [sellerId, setSellerId] = useState<string | undefined>(props.sellerId);

  const [sku, setSku] = useState<string | undefined>(props.sku); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [productImages, setProductImages] = useState<ProductImages>({});
  const [mainImage, setMainImage] = useState<string>('');

  const [listingFieldDefinitions, setListingFieldDefinitions] = useState<ListingFieldDefinitions>({});

  const [allExpandedFields, setAllExpandedFields] = useState<any>({}); // eslint-disable-line @typescript-eslint/no-explicit-any

  const skipFields = [
    'marketplace_id',
    'meta',
    'language_tag',
  ];

  useEffect(() => {
    mixPanel({
      eventName: 'View listing form',
      eventProperties: {
        marketplaceId,
        sellerId,
        sku,
      },
    });
  }, [marketplaceId, sellerId, sku]);

  useEffect(() => {
    // Initialization for new listing.
    // The fields in the form will be initialized when
    // the product type is set in handleProductTypeChange.
    const getProductTypesInMarketplace = async (
      marketplaceId: string
    ) => {
      try {
        const uri = `amazon-listings/product-types/${marketplaceId}`;
        const { data } = await Api.get(uri);
        setProductTypesInMarketplace(data.productTypesInMarketplace);
      } catch (e) {
      }
    }
    if (!props.sku) {
      const urlParams = new URLSearchParams(location.search);
      const marketplaceId = urlParams.get('marketplaceId') || '';
      setMarketplaceId(marketplaceId);
      getProductTypesInMarketplace(marketplaceId);
      setIsNewListing(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // A common pattern for handling async operations within useEffect.
    // You're defining an async function inside the effect and then immediately calling it.
    // This is a valid approach. See an alternative with IIFE below.

    // Initialization for existing listing
    const getAmazonListingsItemDetail = async () => {
      const uri = `amazon-listings/item-detail?marketplace_id=${props.marketplaceId}&seller_id=${props.sellerId}&sku=${props.sku}`;
      try {
        setLoading(true);
        const { data } = await Api.get(uri);
        // debug
        // console.log('-- data from amazon-listings/item-detail --');
        // console.log(data);
        // end debug
        setSellerId(data.seller_id);
        if (data.seller_id !== props.sellerId) {
          errorAlert('Seller ID does not match', 'Seller ID does not match');
        }
        setVersion(data.id);
        setAsin(data.asin);
        setStatus(data.status);
        const productType = data.summaries[0].productType;
        setProductType(productType);
        initializeProductTypeFields(
          productType,
          data
        );
      } catch (e) {
        errorAlert('Unable to fetch Amazon Listings Item details', e);
      } finally {
        setLoading(false);
      }
    };
    if (props.sku) {
      getAmazonListingsItemDetail();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If you want to use await directly, you can wrap the call in an
    // immediately invoked async function expression (IIFE).
    if (props.sku) {
      (async () => {
        const uri = `amazon-listings/item-issues?marketplace_id=${props.marketplaceId}&seller_id=${props.sellerId}&sku=${props.sku}`;
        try {
          setLoading(true);
          const { data } = await Api.get(uri);
          if (data) {
            // debug
            // console.log('-- data from amazon-listings/item-issues --');
            // console.log(data);
            // end debug
            setCountIssues(data.countIssues);
            setAllIssuesTotal(data.allIssuesTotal);
          }
        } catch (e) {
            errorAlert('Unable to fetch Amazon Listings Item issues', e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const convertValue = (
    value: number | string | boolean,
    attributeType: string | null,
  ) => {
    if (attributeType === 'number' || attributeType === 'integer') {
      return Number(value);
    } else if (attributeType === 'boolean') {
      let result = false;
      if (typeof value === 'boolean') {
        result = value;
      } else if (typeof value === 'string') {
        const trueValues = [
          'enabled',
          'yes',
        ];
        if (trueValues.includes(value.toLowerCase())) {
          result = true;
        } else {
          result = false;
        }
      }
      return result;
    } else {
      return value;
    }
  };

  const initializeProductTypeFields = async (
    productType: string,
    listingData = null,
  ) => {
    // TODO use autocomplete field for all types.

    // Keep track of loading and listing state to initialize all fields
    // or React will throw an error saying that you have changed an input
    // from uncontrolled to controlled. See conditional form rendering below.
    setLoading(true);

    const uri = `amazon-listings/product-type-fields/${productType}/${props.marketplaceId}`;

    try {
      const {data} = await Api.get(uri);

      // TODO
      // See note about excludedProductTypes in the back-end route getProductTypesInMarketplace in
      // amazon-listings.controller.ts.
      // This loop, even if doing nothing, seems to avoid the error.
      // console.log(`-- -data for ${productType} - --`);
      // console.log(data);
      // fieldGroupNames.map((fieldGroupName: string, index: number) => {
      //   // console.log(`-- fieldGroupName: ${fieldGroupName}`);
      //   Object.keys(data.listingFieldDefinitions[fieldGroupName].expandedFields).map((fieldName: string, index: number) => {
      //   //   console.log(`fieldGroupName : fieldName: ${fieldGroupName} : ${fieldName}`);
      //   //   console.log(data.listingFieldDefinitions[fieldGroupName].expandedFields[fieldName]);
      //   });
      // });

      setListingFieldDefinitions(data.listingFieldDefinitions);

      const {
        initialValues,
        allExpandedFields,
      } = getInitialValues(data.listingFieldDefinitions);

      setAllExpandedFields(allExpandedFields);

      const validationSchemaObject: {
        [key: string]: Yup.StringSchema<string | undefined>;
      } = {};
      validationSchemaObject['sku'] = Yup.string().required(`SKU is required.`);

      if (sku) {
        // edit listing
        const filledInitialValues = fillInitialValues(
          initialValues,
          listingData,
        );
        // debug
        // console.log('-- initialValues --');
        // console.log(initialValues);
        // console.log('---  filledInitialValues ---');
        // console.log(filledInitialValues);
        // end debug
        setProductImages(getProductImages(filledInitialValues));
        filledInitialValues['sku'] = sku;
        setInitialValues(filledInitialValues);
        setMainImage(filledInitialValues.main_product_image_locator.media_location);
        setFilteredAttributeKeys(Object.keys(filledInitialValues));
      } else {
        // create listing
        initialValues['sku'] = '';
        setProductImages(getProductImages(initialValues));
        setInitialValues(initialValues);
        setFilteredAttributeKeys(Object.keys(initialValues));
      }

      const validationSchema = Yup.object(validationSchemaObject);
      setValidationSchema(validationSchema);

    } catch (e) {
      errorAlert('Unable to get product type fields', e);
    } finally {
      setLoading(false);
    }
  };

  const getProductImages = (values: FormikValues): ProductImages => {
    const productImages: ProductImages = {};
    Object.keys(values).filter((attributeKey) => {
        return attributeKey.indexOf('product_image_locator') > -1;
    }).forEach((attributeKey) => {
        productImages[attributeKey] = values[attributeKey];
    });
    return productImages;
  };

  const getImageKey = (count: number, index: number) => {
    return index === 0
      ? 'main_product_image_locator'
      : index === count - 1
      ? 'swatch_product_image_locator'
      : `other_product_image_locator_${index}`;
  };

  const updateProductMediaLists = (imageUrl: string, index: number, productImages: ProductImages) => {
    const imagesCount = Object.keys(productImages).length;
    const imageKey = getImageKey(imagesCount, index);
    const _productImages: ProductImages = {...productImages};
    _productImages[imageKey] = {
      ..._productImages[imageKey],
      media_location: imageUrl,
    };
    return _productImages;
  };

  const changeOrderImage = (index: number, offSet: number, productImages: ProductImages) => {
    if (index === 0 && offSet === -1) return;
    if (index === Object.keys(productImages).length - 1 && offSet === 1) return;
    const imagesCount = Object.keys(productImages).length;
    const sourceImageKey = getImageKey(imagesCount, index);
    const targetImagekey = getImageKey(imagesCount, index + offSet);
    const _productImages = {...productImages};
    const _tmp = _productImages[targetImagekey];
    _productImages[targetImagekey] = _productImages[sourceImageKey];
    _productImages[sourceImageKey] = _tmp;
    return _productImages;
  };

  const updateMainImage = (data: FormikValues | undefined) => {
    const mainImage = data?.main_product_image_locator.media_location || undefined;
    setMainImage(mainImage);
  };

  const onSearch = (attributes: FormikValues) => {
    const filteredAttributeKeys = Object.keys(attributes).filter((key) => {
      if (key === 'variation_theme') {
        key = 'parentage_variation_theme';
      }
      const newKey = key.replaceAll(new RegExp('_', 'g'), ' ').toLowerCase();
      return newKey.includes(filter.toLowerCase());
    });
    setExpandedFieldGroups(filter === '' ? allFieldGroupsCollapsed : allFieldGroupsExpanded);
    setFilteredAttributeKeys(filteredAttributeKeys);
  };

  const submitListing = async (
    values: FormikValues,
    { setSubmitting }: FormikHelpers,  // instead of FormikHelpers<FormikValues> from Formik
  ) => {

    // Note how productImages is not part of the form values but is passed
    // from the state variable updated with the ImageCard component.
    const { sku = '', ...attributes } = {
      ...removeEmpty(values),
      ...removeEmpty(productImages),
    };

    const attributesWithValues: FormikValues = attributes;

    const fieldsToExpand = [
      'marketplace_id',
      'language_tag',
    ];

    Object.keys(attributesWithValues).forEach((firstLevelFieldName) => {
      fieldsToExpand.forEach((fieldToExpand) => {
        if (allExpandedFields[firstLevelFieldName].hasOwnProperty(fieldToExpand)) {
          attributesWithValues[firstLevelFieldName][fieldToExpand] = allExpandedFields[firstLevelFieldName][fieldToExpand];
        }
      });
      if (Array.isArray(attributesWithValues[firstLevelFieldName])) {
        attributesWithValues[firstLevelFieldName].forEach((firstLevelItem: any, index: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          Object.keys(firstLevelItem).filter(key => !fieldsToExpand.includes(key)).forEach((key) => {
            fieldsToExpand.forEach((fieldToExpand) => {
              if (allExpandedFields[firstLevelFieldName].hasOwnProperty(fieldToExpand)) {
                attributesWithValues[firstLevelFieldName][index][fieldToExpand] = allExpandedFields[firstLevelFieldName][fieldToExpand];
              }
              if (allExpandedFields[firstLevelFieldName][key].hasOwnProperty(fieldToExpand)) {
                attributesWithValues[firstLevelFieldName][index][key][fieldToExpand] = allExpandedFields[firstLevelFieldName][key][fieldToExpand];
              }
            });
          });
        });
      } else {
        Object.keys(attributesWithValues[firstLevelFieldName]).filter(key => !fieldsToExpand.includes(key)).forEach((secondLevelFieldName) => {
          fieldsToExpand.forEach((fieldToExpand) => {
            if (allExpandedFields[firstLevelFieldName][secondLevelFieldName].hasOwnProperty(fieldToExpand)) {
              attributesWithValues[firstLevelFieldName][secondLevelFieldName][fieldToExpand] = allExpandedFields[firstLevelFieldName][secondLevelFieldName][fieldToExpand];
            }
          });
        });
      }
    });

    const attributesToSubmit: FormikValues = {};
    Object.keys(attributesWithValues).forEach((fieldKey) => {
      if (Array.isArray(attributesWithValues[fieldKey])) {
        // When the value in attributesWtihValues is already an array,
        // which is the case with bullet_point.
        attributesToSubmit[fieldKey] = attributesWithValues[fieldKey];
      } else {
        attributesToSubmit[fieldKey] = [];
        Object.keys(attributesWithValues[fieldKey]).forEach((firstLevelItemKey) => {
          if (allExpandedFields[fieldKey][firstLevelItemKey].hasOwnProperty('meta') && allExpandedFields[fieldKey][firstLevelItemKey].meta.type === 'array') {
            Object.keys(attributesWithValues[fieldKey][firstLevelItemKey]).forEach((secondLevelItemKey) => {
              if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta') && allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].meta.type === 'array') {
                const thirdLevelItemKeyData: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
                Object.keys(attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey]).forEach((thirdLevelItemKey) => {
                  let attributeType = null;
                  if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey].hasOwnProperty('meta')) {
                    attributeType = allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey].meta.type;
                  }
                  thirdLevelItemKeyData[thirdLevelItemKey] = convertValue(
                    attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey],
                    attributeType,
                  );
                });
                attributesToSubmit[fieldKey][firstLevelItemKey] = {
                  [secondLevelItemKey]: [
                    thirdLevelItemKeyData,
                  ],
                  ...attributesToSubmit[fieldKey][firstLevelItemKey],
                };
              }
              let attributeType = null;
              if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta')) {
                attributeType = allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].meta.type;
              }
              if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta') && allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].meta.type === 'array') {
                attributesToSubmit[fieldKey][firstLevelItemKey] = {
                  [secondLevelItemKey]: [
                    convertValue(
                      attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey],
                      attributeType,
                    ),
                  ],
                  ...attributesToSubmit[fieldKey][firstLevelItemKey],
                };
              } else {
                attributesToSubmit[fieldKey][firstLevelItemKey] = {
                  [secondLevelItemKey]: convertValue(
                    attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey],
                    attributeType,
                  ),
                  ...attributesToSubmit[fieldKey][firstLevelItemKey],
                };
              }
            });
            attributesToSubmit[fieldKey][firstLevelItemKey] = [
              attributesToSubmit[fieldKey][firstLevelItemKey],
            ];
          } else {
            let attributeType = null;
            if (allExpandedFields[fieldKey][firstLevelItemKey].hasOwnProperty('meta')) {
              attributeType = allExpandedFields[fieldKey][firstLevelItemKey].meta.type;
            }
            if (
              allExpandedFields[fieldKey][firstLevelItemKey].hasOwnProperty('meta') &&
              allExpandedFields[fieldKey][firstLevelItemKey].meta.hasSelectOptionsInsideItems) {
              attributesToSubmit[fieldKey] = {
                [firstLevelItemKey]: [
                  convertValue(
                    attributesWithValues[fieldKey][firstLevelItemKey],
                    attributeType,
                  ),
                ],
                ...attributesToSubmit[fieldKey],
              };
            } else {
              if (attributeType === 'object') {
                Object.keys(attributesWithValues[fieldKey][firstLevelItemKey]).forEach((secondLevelItemKey) => {
                  let attributeType = null;
                  if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta')) {
                    attributeType = allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].meta.type;
                  }
                  if (allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta') && allExpandedFields[fieldKey][firstLevelItemKey][secondLevelItemKey].meta.type === 'array') {
                    attributesToSubmit[fieldKey][firstLevelItemKey] = {
                      [secondLevelItemKey]: [
                        convertValue(
                          attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey],
                          attributeType,
                        ),
                      ],
                      ...attributesToSubmit[fieldKey][firstLevelItemKey],
                    };
                  } else {
                    attributesToSubmit[fieldKey][firstLevelItemKey] = {
                      [secondLevelItemKey]: convertValue(
                        attributesWithValues[fieldKey][firstLevelItemKey][secondLevelItemKey],
                        attributeType,
                      ),
                      ...attributesToSubmit[fieldKey][firstLevelItemKey],
                    };
                  }
                });
              }
              attributesToSubmit[fieldKey] = {
                [firstLevelItemKey]: convertValue(
                  attributesWithValues[fieldKey][firstLevelItemKey],
                  attributeType,
                ),
                ...attributesToSubmit[fieldKey],
              };
            }
          }
        });
        attributesToSubmit[fieldKey] = [
          attributesToSubmit[fieldKey],
        ];
      }
    });

    // Validate attributes against JSON schema for the product type.
    let attributesValidated = false;
    let fieldsWithValidationIssues: string[];
    try {
      const res = await Api.post(`amazon-listings/validate`, {
        productType,
        marketplaceId,
        attributes: attributesToSubmit,
      });
      // debug
      // console.log('res.data from amazon-listings/validate');
      // console.log(res.data);
      // end debug
      setFieldValidationErrors(res.data.validationResults.fieldValidationErrors);
      if (res.data.validationResults.fieldValidationErrors.length === 0) {
        attributesValidated = true;
        fieldsWithValidationIssues = [];
      } else {
        attributesValidated = false;
        fieldsWithValidationIssues = res.data.validationResults.fieldValidationErrors.map((fieldValidationError: FieldValidationError) => {
          return fieldValidationError.fieldName;
        });
      }
    } catch (e) {
      console.log('error posting to amazon-listings/validate');
      console.log(e);
    }

    // debug
    // setSubmitting(false);
    // console.log('attributesToSubmit');
    // console.log(attributesToSubmit);
    // return;
    // end debug

    if (!attributesValidated) {
      const issues = () => (
        <div className="field-validation-issues">
          <div>
            Invalid fields on the form.
            {fieldsWithValidationIssues.length > 0 && (
              <ul>
              {fieldsWithValidationIssues.map((fieldName: string, i: number) => (
                <li key={i}>
                  <span onClick={() => scrollToField(fieldName)}>{fieldName}</span>
                </li>
              ))}
              </ul>
            )}
          </div>
        </div>
      );
      setFilter('');
      setFilteredAttributeKeys(Object.keys(values));
      setExpandedFieldGroups(allFieldGroupsExpanded);
      alertService.error(
        issues(),
        {
          autoClose: false,
        }
      );
      setSubmitting(false);
      return;
    }

    // Disable quantity if is_inventory_available is false.
    if (attributesToSubmit['fulfillment_availability'][0]['is_inventory_available'] === true) {
      delete attributesToSubmit['fulfillment_availability'][0]['quantity'];
    }

    // Pass empty message and set error alert to auto close after 5 seconds
    // when form is validated and ready to submit.
    alertService.error(
      '',
      {
        autoClose: true,
      }
    );

    setExpandedFieldGroups(allFieldGroupsCollapsed);

    if (isNewListing) {
      // Create a new listing

      // Some product type definitions (SHOES, DRESS) don't include the marketplace_id
      // property in the variation_theme field.
      // See https://github.com/amzn/selling-partner-api-docs/issues/2845
      // This may not be necessary as it seems like creating a variation family
      // requires creating the parent and at least the first child.
      // Keeping code comment for documentation.
      // if (attributesToSubmit['variation_theme']) {
      //   const firstExpandedField: any = Object.values(allExpandedFields)[0]; // eslint-disable-line @typescript-eslint/no-explicit-any
      //   attributesToSubmit['variation_theme'][0]['marketplace_id'] = firstExpandedField['marketplace_id'];
      // }

      const urlParams = new URLSearchParams(location.search);
      // Use to skip the call to SP-API for testing and other purposes.
      const skipSpApi = urlParams.get('skipSpApi') || false;
      if (skipSpApi === 'true')  {
        console.log(`sku: ${sku}`);
        console.log('attributesToSubmit');
        console.log(attributesToSubmit);
        setSubmitting(false);
      } else {
        try {
          console.log(`sku: ${sku}`);
          console.log('attributesToSubmit before SP-API');
          console.log(attributesToSubmit);
          const res = await Api.post(`amazon-listings/create`, {
            sku,
            sellerId,
            productType,
            attributes: attributesToSubmit,
          });
          console.log(res); // TODO use response to decide next step
        } catch (e) {
          console.log('error posting to amazon-listings/create');
          console.log(e);
        } finally {
          setSubmitting(false);
        }
      }
    } else {
      try {
        const res = await Api.post(`amazon-listings/update`, {
          attributes: attributesToSubmit,
          marketplaceId,
          productType,
          sku,
          sellerId,
          version: parseInt(version, 10),
        });
        // debug
        // console.log('res.data from amazon-listings/update');
        // console.log(res.data);
        // end debug
        if (res.data?.status === 'ACCEPTED') {
          if (res.data.parentSkuChanged) {
            alertService.success(
              `The listing parent SKU has changed and it may take up to 24 hours to be reflected by Amazon.`,
              {
                autoClose: false,
                id: 'error-alert',
              });
            setTimeout(() => {
              window.location.href = '/amazon-listings-items';
            }, 3000);
          } else {
            setVersion(res.data.version);
            mixPanel({
              eventName: 'Update listing',
              eventProperties: {
                marketplaceId,
                sellerId,
                sku,
                version: res.data.version,
              },
            });
            alertService.success(
              'Your listing submission was accepted for processing. It may take a few minutes to process.',
            );
          }
        } else {
          const issues = () => (
            (res.data.differences && res.data.differences.length === 0) ? (
              <div>
                No differences detected. Please make sure you have changed at least one field and haven&apos;t left any required fields empty.
              </div>
            ) : (
              <div>
                The listing submission was not valid and was not accepted for processing. Issues detected: {res.data?.issues?.length}.
                {res.data?.issues?.map((msg: {attributeNames: string[], message: string}, i: number) => (
                  msg?.attributeNames?.length > 0 &&
                  <div key={i}>
                    <Tooltip title={msg?.message}>
                    <span style={{cursor: 'pointer', textDecoration: 'underline'}}>
                      {msg?.attributeNames[0]}&nbsp;{i !== res.data?.issues?.length - 1 && ', '}
                    </span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            )
          );
          alertService.error(issues(), {
            autoClose: false,
          });
        }
      } catch (e) {
        console.log('error posting to amazon-listings/update');
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      const productType = e.target.value;
      setProductType(productType);
      initializeProductTypeFields(productType);
    }
  };

  const scrollToField = (fieldName: string) => {
    const element = document.getElementById(`field-${fieldName}`);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  const toggleAccordion = (
    e: React.SyntheticEvent,
    fieldGroupName: string,
  ) => {
    setExpandedFieldGroups({
      ...expandedFieldGroups,
      [fieldGroupName]: !expandedFieldGroups[fieldGroupName],
    });
    e.preventDefault();
  };

  // DEBUG examples of calling ListingFieldComponent
  // {/* <ListingFieldComponent
  //   fieldDefinition={field}
  // /> */}

  // Loop over all field groups in ListingFieldDefinitions going down as many levels
  // as necessary, usually three, to create fields for all attributes except
  // those listed in skipFields.

  // Making an exception for product_description to only show one instance of the field.
  // See the checks for maxUniqueItems below.

  return (
    <Grid container>
      <CustomAlert id="error-alert" unique />
      <CustomAlert id="default-alert" />
      {!props.sku && productTypesInMarketplace.length > 0 && (
        <Grid
          container
          style={{
            marginBottom: '20px',
            padding: '10px',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Create a new listing
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label style={{ padding: '10px'}}>Product type</label>
            <select
              data-cy="product_type"
              disabled={loading}
              onChange={handleProductTypeChange}
              value={productType}

            >
              <option value="">-- Select a product type --</option>
              {productTypesInMarketplace.map((productType: string, index: number) => (
                <option
                  value={productType}
                  key={index}
                >
                  {productType}
                </option>
              ))}
            </select>
          </Grid>
        </Grid>
      )}

      <IssuesSummary
        allIssuesTotal={allIssuesTotal}
        countIssues={countIssues}
        status={status}
      />

      {initialValues && validationSchema && !loading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          /* Using Yup and validationSchema instead of validate function */
          /* validate={validate} */
          validationSchema={validationSchema}
          onSubmit={submitListing}
        >
          {({
            values,
            // errors,
            // touched,
            isSubmitting,
            // getFieldProps,
          }) => (
            <Form
              aria-disabled={isSubmitting}
              className="listing-form"
            >

              {props.sku && (
                <Grid
                  className="listing-details-container"
                  container
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h6">
                      {initialValues.item_name.value}
                    </Typography>
                    <Typography variant="h6">
                      SKU: {sku}
                    </Typography>
                    <Typography variant="h6">
                      Product Type: {productType}
                    </Typography>
                    <Typography className="asin-link" variant="h6">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.amazon.com/${sku}/dp/${asin}`}
                      >
                        ASIN: {asin} <Link />
                      </a>
                    </Typography>
                    <Typography variant="h6">Version: {version}</Typography>

                    {mainImage && (
                      <Grid
                        className="main-image-container"
                        item xs={12} sm={12} md={6} lg={6}
                      >
                        <img
                          src={mainImage}
                          alt={initialValues.item_name.value}
                        />
                      </Grid>
                    )}

                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid
                  item xs={12} sm={12} md={8} lg={6}
                  style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                  }}
                >
                  <SearchBar
                    placeholder="Filter by attribute name"
                    value={filter}
                    onChange={(newValue) => setFilter(newValue)}
                    onSearch={() => onSearch(initialValues)}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={6}
                className={!isNewListing ? 'hidden' : ''}
              >
                <Grid item xs={6}>
                  <label htmlFor="sku">SKU</label>
                </Grid>
                <Grid item xs={6}>
                  <FastField
                    id="sku"
                    disabled={!isNewListing}
                    data-cy="sku"
                    name="sku"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} className="alert">
                  <ErrorMessage name="sku" />
                </Grid>
              </Grid>

              {fieldGroupNames.filter(fieldGroupName => fieldGroupName !==  'images').map((fieldGroupName: string, index: number) => {

                // Check if the field group has any of the attributes set in filteredAttributeKeys
                // and set a CSS class to hide the field group if not.

                const className = listingFieldDefinitions[fieldGroupName].propertyNames
                  .filter(key => !key.includes('image_locator'))
                  .filter(key => filteredAttributeKeys.includes(key)).length === 0 ? 'hidden-fieldgroup' : 'visible-fieldgroup';

                return (
                  <Grid
                    key={index}
                    className={className}
                    item xs={12}
                  >
                    <Accordion
                      expanded={expandedFieldGroups[fieldGroupName]}
                      onChange={(e) => toggleAccordion(e, fieldGroupName)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined />}
                      >
                        {fieldGroupName.replace('_', ' ').toUpperCase()}
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields).map((fieldName: string, index: number) => (
                          <div
                            className={fieldValidationErrors.find(fieldValidationError => fieldValidationError.fieldName === fieldName) ? 'field-validation-error' : ''}
                            id={`field-${fieldName}`}
                            key={index}
                          >
                            <div className="field-validation-error-message">
                              <ErrorOutlineOutlined />
                              {fieldValidationErrors.find(fieldValidationError => fieldValidationError.fieldName === fieldName)?.message.replaceAll('_', ' ')}
                            </div>
                            {
                              fieldName !== 'product_description' &&
                              listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].hasOwnProperty('meta') &&
                              listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.hasOwnProperty('maxUniqueItems') &&
                              listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.type === 'array' &&
                              (listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.maxUniqueItems as number) > 1 ? (
                                filteredAttributeKeys.includes(fieldName) && (
                                  <FieldArray
                                    name={fieldName}
                                  >
                                    {({ remove, push }) => (
                                      values[fieldName].map((item: any, index: number) => ( // eslint-disable-line @typescript-eslint/no-explicit-any
                                        <div
                                          className='field-array-container'
                                          key={index}
                                        >
                                          {Object.keys(item).map((firstLevelItemKey: string, keyIndex: number) => (
                                              <div
                                                key={keyIndex}
                                              >
                                                {(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey].meta.type === 'object') ? (
                                                  Object.keys(item[firstLevelItemKey]).map((secondLevelItemKey: string, secondKeyIndex: number) => (
                                                    <FastField
                                                      key={secondKeyIndex}
                                                      name={`${fieldName}.${index}.${firstLevelItemKey}.${secondLevelItemKey}`}
                                                      fieldName={fieldName}
                                                      component={ListingFieldComponent}
                                                      fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]}
                                                      labelIndex={ index + 1 }
                                                    />
                                                  ))
                                                ) : (
                                                  <FastField
                                                    name={`${fieldName}.${index}.${firstLevelItemKey}`}
                                                    fieldName={fieldName}
                                                    component={ListingFieldComponent}
                                                    fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]}
                                                    labelIndex={ index + 1 }
                                                  />
                                                )}
                                                <div
                                                  className='field-array-buttons'
                                                >
                                                  <button
                                                    data-cy={`remove-${fieldName}-${index+1}}`}
                                                    onClick={() => {
                                                      if (values[fieldName].length > 1) {
                                                        remove(index);
                                                      }
                                                    }}
                                                    type="button"
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    data-cy={`add-${fieldName}-${index+1}`}
                                                    onClick={() => {
                                                      if (values[fieldName].length < (listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.maxUniqueItems as number)) {
                                                        push(buildEmptyField(fieldName, skipFields, listingFieldDefinitions[fieldGroupName].expandedFields));
                                                      }
                                                    }}
                                                    type="button"
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                          ))}
                                        </div>
                                      )
                                    ))}
                                  </FieldArray>
                                )
                            ) : (
                              Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName]).filter(key => !skipFields.includes(key)).map((firstLevelItemKey: string, index: number) => (
                                <div key={index}>
                                  {
                                    Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]).filter(key => !skipFields.includes(key)).length ?
                                    Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]).filter(key => !skipFields.includes(key)).map((secondLevelItemKey: string, index: number) => (
                                      <div key={index}>
                                        {
                                          Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]).filter(key => !skipFields.includes(key)).length ?
                                          Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]).filter(key => !skipFields.includes(key)).map((thirdLevelItemKey: string, index: number) => (
                                            filteredAttributeKeys.includes(fieldName) && !fieldName.includes('image_locator') && (
                                              <FastField
                                                name={`${fieldName}.${firstLevelItemKey}.${secondLevelItemKey}.${thirdLevelItemKey}`}
                                                component={ListingFieldComponent}
                                                fieldName={fieldName}
                                                fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey]}
                                                key={index}
                                              />
                                            )
                                          )) : (
                                            filteredAttributeKeys.includes(fieldName) && !fieldName.includes('image_locator') && (
                                              <FastField
                                                name={`${fieldName}.${firstLevelItemKey}.${secondLevelItemKey}`}
                                                component={ListingFieldComponent}
                                                fieldName={fieldName}
                                                fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]}
                                                key={index}
                                              />
                                            )
                                          )
                                        }
                                      </div>
                                    )) : (
                                      filteredAttributeKeys.includes(fieldName) && !fieldName.includes('image_locator') && (
                                        <FastField
                                          name={`${fieldName}.${firstLevelItemKey}`}
                                          component={ListingFieldComponent}
                                          fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]}
                                          fieldName={fieldName}
                                          key={index}
                                        />
                                      )
                                    )
                                  }
                                </div>
                              ))
                            )}
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}

              {filteredAttributeKeys.some(key => key.includes('image')) && (
                <Grid item xs={12}>
                  <Accordion
                    expanded={expandedFieldGroups['images']}
                    onChange={(e) => toggleAccordion(e, 'images')}
                  >
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined />}
                    >
                      IMAGES
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        className="image-cards-container"
                        item xs={12}
                      >
                        {!loading && Object.keys(productImages).length > 0 && (
                          Object.keys(productImages).map((key: string, index: number) => (
                            <ImageCard
                              key={key}
                              label={key.replaceAll('_', ' ')}
                              link={productImages[key].media_location || ''}
                              disabledDirection={index === 0 ? 'left' : index === 9 ? 'right' : null}
                              disabled={false}
                              onMove={(offSet: number) => {
                                const data = changeOrderImage(index, offSet, productImages);
                                if (data) {
                                  setProductImages(data);
                                  updateMainImage(data);
                                }
                              }}
                              handleChangeImagesArray={(imageUrl: string) => {
                                const data = updateProductMediaLists(
                                  imageUrl,
                                  index,
                                  productImages,
                                );
                                if (data) {
                                  setProductImages(data);
                                  updateMainImage(data);
                                }
                              }}
                            />
                          ))
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}

              <Grid
                className='submit-button-container'
                container
                rowSpacing={2}
              >
                <Grid item xs={12}>
                  <Button
                    data-cy="saveListingButton"
                    id="saveListingButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {isNewListing ? 'Create' : 'Update'} Listing
                  </Button>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      )}
      {loading && (
        <Grid item xs={12}>
          {/* See to learn about disableShrink https://github.com/mui/material-ui/issues/10327 */}
          <CircularProgress disableShrink size={40} />
        </Grid>
      )}
    </Grid>
  );
};

export default ListingForm;
