import React, {useCallback, useLayoutEffect, useRef} from 'react';
import {Box} from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRowClassNameParams,
  GridCellParams,
  GridRowId,
  GridSlotsComponent,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
// styles
import {sxStyles} from '../../pages/Listings/Styles';
// interfaces
import {ListingsInterface, VersionInterface} from '../../pages/Listings/interfaces/interfaces';
import {ActivitiesInterface} from '../../pages/Listings/subpages/AmazonListingsItemActivity/AmazonListingsItemActivity';
import {SxStyleTypes} from '../../interfaces/sxStyles/sxStyles.interface';

export interface CustomItemProps {
  id: string;
  content: JSX.Element | undefined;
}

type GridRowsTypes =
  | {
      [key: string]: GridRowId | string | number | boolean | undefined;
    }[]
  | undefined
  | ListingsInterface['rows']
  | ActivitiesInterface[]
  | VersionInterface[];

interface CollapsibleDataGridProps {
  gridSxStyles?: SxStyleTypes;
  className?: string;
  pageSize?: number;
  rowCount?: number;
  rows?: GridRowsTypes;
  columns?: GridColDef[];
  page?: number;
  rowsPerPageOptions?: number[];
  disableSelectionOnClick?: boolean;
  onPageChange?: (page: number) => void;
  loading: boolean;
  selectedRow: GridRowId | string | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<GridRowId | string | number | null>>;
  accordionContent: JSX.Element | (() => CustomItemProps[]);
  onPageSizeChange?: (rowsPerPage: number) => void;
  clickable?: ((params: GridCellParams) => boolean) | boolean;
  rowClassName?: ((params: GridRowClassNameParams) => string) | string | undefined;
  multiple?: boolean;
  gridName?: string;
  components?: Partial<GridSlotsComponent>;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
}

export default function CollapsibleDataGrid({
  gridSxStyles,
  className,
  pageSize,
  rowCount,
  rows,
  columns,
  page,
  rowsPerPageOptions,
  disableSelectionOnClick,
  onPageChange,
  loading,
  selectedRow,
  setSelectedRow,
  accordionContent,
  onPageSizeChange,
  clickable,
  rowClassName,
  multiple,
  components,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
}: CollapsibleDataGridProps): JSX.Element {
  const info: CustomItemProps[] | undefined =
    multiple && typeof accordionContent === 'function' ? accordionContent() : undefined;

  const thisRef = useRef<HTMLDivElement | null>(null);

  const setAccordion = (row: GridRowId) => {
    if (multiple) {
      if (document.getElementById(`ref-${row}-clone`)) {
        setSelectedRow(null);
        document.getElementById(`ref-${row}-clone`)?.remove();
      } else {
        setSelectedRow(row);
      }
    } else {
      setSelectedRow((p) => (p === row ? null : row));
      document.getElementById(`accordion-${selectedRow}-clone`)?.remove();
    }
  };

  const showAccordion = useCallback(
    ({item, selectedRow}: {item?: CustomItemProps; selectedRow?: GridRowId}) => {
      const id = multiple ? item?.id : selectedRow;

      const row = document.querySelector(`div[data-id="${id}"]`);
      if (multiple) {
        const comp = document.getElementById(`ref-${id}`)?.cloneNode(true) as HTMLElement;
        if (comp) {
          comp.id = `ref-${id}-clone`;
        }
        if (comp && row && !document.getElementById(`ref-${id}-clone`)) {
          row?.parentNode?.insertBefore(comp, row.nextSibling);
        }
      }
      if (!multiple && !document.getElementById(`accordion-${id}-clone`) && row) {
        const comp = thisRef.current;
        if (comp) {
          comp.id = `accordion-${id}-clone`;
          row?.parentNode?.insertBefore(comp, row.nextSibling);
        }
      }
    },
    [multiple],
  );

  const updateAccordion = useCallback(() => {
    if (loading) {
      const getAll = document.querySelectorAll('.custom-accordion');
      getAll.forEach((item) => {
        item.remove();
      });
    }
    if (!multiple) {
      if (selectedRow !== null && !loading && rows && rows.length > 0) {
        showAccordion({selectedRow});
      } else {
        document.getElementById(`accordion-${selectedRow}`)?.remove();
        document.getElementById('accordion-null')?.remove();
      }
    } else {
      if (!loading && rows?.length && rows.length > 1 && selectedRow === 'all') {
        info?.forEach((item) => {
          showAccordion({item});
        });
      }
      if (!loading && rows?.length && rows?.length > 1 && selectedRow !== null && selectedRow !== 'all') {
        showAccordion({item: info?.[+selectedRow]});
      }
    }
  }, [selectedRow, loading, info, multiple, rows, showAccordion]);

  useLayoutEffect(() => {
    updateAccordion();
  }, [selectedRow, loading, updateAccordion]);

  return (
    <>
      {!loading && (
        <div style={{display: 'none'}}>
          {multiple ? (
            info?.map((i, x) => (
              <Box key={x} ref={thisRef} id={`ref-${x}`} className="custom-accordion">
                {i.content}
              </Box>
            ))
          ) : (
            <div ref={thisRef} className="custom-accordion">
              {accordionContent}
            </div>
          )}
        </div>
      )}
      <DataGrid
        components={components}
        rowHeight={64}
        disableVirtualization
        className={`${className} collapsible`}
        sx={[sxStyles('customGrid'), {...(gridSxStyles && gridSxStyles)}]}
        autoHeight={true}
        pageSize={pageSize}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={(pageSize) => onPageSizeChange?.(pageSize)}
        disableSelectionOnClick={disableSelectionOnClick}
        onPageChange={(params) => onPageChange?.(params)}
        rows={rows || []}
        columns={columns || []}
        getRowClassName={(params) => {
          if (typeof rowClassName === 'function') {
            return rowClassName(params);
          } else {
            return rowClassName || '';
          }
        }}
        onCellClick={(params) => {
          if (typeof clickable === 'function') {
            if (clickable(params)) {
              setAccordion(params.id);
            }
          } else {
            if (clickable) {
              setAccordion(params.id);
            }
          }
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(model) => onColumnVisibilityModelChange && onColumnVisibilityModelChange(model)}
      />
    </>
  );
}
