import React, {useEffect} from 'react';
import * as Yup from 'yup';
import {FormikHelpers, FormikValues} from 'formik';
import {useHistory} from 'react-router-dom';
// components
import LoginView from '../../../../components/LoginView/LoginView';
import LogoComponent from '../../../Login/components/LogoComponent';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';

function PasswordReset(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
  }, [history]);

  const initialValues: FormikValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
  });

  function onSubmit(data: FormikValues, formikHelpers: FormikHelpers<FormikValues>) {
    formikHelpers.setSubmitting(true);
    alertService.clear();
    accountService
      .resetPassword(data)
      .then(() => {
        alertService.success(
          <>{`An email has been sent. Please follow the link in the email to complete your password reset request.`}</>,
          {autoClose: false},
        );
      })
      .catch((error) => {
        formikHelpers.setSubmitting(false);
        if (error.response.data && error.response.data.message) {
          alertService.error(error.response.data.message);
        } else {
          alertService.error(error.message);
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  const fields = [
    {
      label: 'Please enter your email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
  ];

  return (
    <>
      <LoginView
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Continue"
        fields={fields}
        formDescription="Reset your password"
        logo={<LogoComponent />}
        onSubmit={onSubmit}
        action='reset-password'
      />
      <CustomAlert id="default-alert" />
    </>
  );
}

export {PasswordReset};
