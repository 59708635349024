import React from 'react';

import { Difference } from 'src/pages/Listings/interfaces/interfaces';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

enum Kind {
  N = "N",
  D = "D",
  E = "E",
  A = "A",
}

type Props = {
  difference: Difference;
};

const ReflectingDifference = ({
  difference,
}: Props): JSX.Element => {

  const convertToLinkIfNeeded = (
    key: string,
    value: string
  ) => {
    let output: string | React.ReactNode = value;
    if (key.indexOf('product_image_locator') > 0) {
      output = <a
        href={value}
        rel='noreferrer noopener'
        target='_blank'
      >
        {value}
      </a>;
    }
    return output;
  };

  let lhsValue = '';
  let rhsValue = '';

  if (difference.kind === Kind.E && difference.lhs) {
    lhsValue = difference.lhs;
  } else if (difference.kind === Kind.A && difference.item.lhs) {
    lhsValue = difference.item.lhs.value;
  }

  if (difference.kind === Kind.E && difference.rhs) {
    rhsValue = difference.rhs;
  } else if (difference.kind === Kind.A && difference.item.rhs) {
    rhsValue = difference.item.rhs.value;
  }

  lhsValue = convertToLinkIfNeeded(
    difference.path[0],
    lhsValue
  ) as string; // Explicitly type the output as string

  rhsValue = convertToLinkIfNeeded(
    difference.path[0],
    rhsValue
  ) as string; // Explicitly type the output as string

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {difference.path[0].replace(/_/g, ' ')}
        {difference.kind === Kind.E && difference.path[0] === 'bullet_point' && (` ${difference.path[1] + 1}`)}
        {difference.kind === Kind.A && difference.path[0] === 'bullet_point' && (` ${difference.index + 1}`)}
      </TableCell>
      <TableCell>
        {lhsValue}
      </TableCell>
      <TableCell>
        {rhsValue}
     </TableCell>
  </TableRow>

  );
};

export default ReflectingDifference;
