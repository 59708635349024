import moment from 'moment';
// interfaces
import {OnFromToChangeProps} from '../interfaces/date/date';

export function onFromToChange({
  from,
  to,
  compareFrom,
  compareTo,
  preset = 'custom',
  compare,
  loadData,
  setFromTo,
}: OnFromToChangeProps): void {
  const fromValue = moment(from).format('YYYY-MM-DD');
  const toValue = moment(to).format('YYYY-MM-DD');
  let cFrom = compareFrom;
  let cTo = compareTo;

  if (compare) {
    switch (preset) {
      case 'q':
        cFrom = moment(fromValue).subtract(1, 'quarter');
        cTo = moment().quarter(moment(cFrom).quarter()).endOf('quarter');
        break;
      case 'cm':
        cFrom = moment(fromValue).subtract(1, 'month');
        cTo = moment(cFrom).endOf('month');
        break;
      case 'cw':
        cFrom = moment(fromValue).subtract(1, 'week');
        cTo = moment(cFrom).endOf('week');
        break;
      default:
        const diff = moment(toValue).diff(fromValue, 'days');
        cFrom = moment(fromValue).subtract(diff + 1, 'days');
        cTo = moment(toValue).subtract(diff + 1, 'days');
        break;
    }
  }
  const compareFromValue = cFrom ? moment(cFrom).format('YYYY-MM-DD') : null;
  const compareToValue = cTo ? moment(cTo).format('YYYY-MM-DD') : null;
  loadData?.(preset, fromValue, toValue, compareFromValue, compareToValue, false);
  setFromTo?.({from: fromValue, to: toValue, compareFrom: compareFromValue, compareTo: compareToValue, preset});
}
