import React, {useEffect, useState} from 'react';

import {
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';

import {useHistory} from 'react-router-dom';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
// utils
import {BrandTypesAlias} from '../../../utils/brand-types';
import { Api } from '../../../utils/api';
// services
import {alertService} from '../../../services/alert.service';

interface ResponseInterface {
  ok?: boolean;
  brandCode?: string;
  brandTypeAlias?: string;
}

export default function AuthorizeSpApiForBrands(): JSX.Element {
  const history = useHistory();
  const [response, setResponse] = useState<ResponseInterface>({});

  async function handleParams() {
    const url = new URL(window.location.href);
    const state = url.searchParams.get('state');
    const selling_partner_id = url.searchParams.get('selling_partner_id');
    const spapi_oauth_code = url.searchParams.get('spapi_oauth_code');
    const mws_auth_token = url.searchParams.get('mws_auth_token');
    if (!selling_partner_id || !state || !spapi_oauth_code) {
      setResponse({...response, ok: false});
      alertService.error('No information for seller partner id, state, auth_code ');
      return null;
    }
    try {
      const { data } = await Api.post(`/sp-api/authorize`, {
        state,
        selling_partner_id,
        spapi_oauth_code,
        mws_auth_token,
      });
      setResponse(data);
      if (data?.brandCode) {
        if (data.brandTypeAlias !== BrandTypesAlias.ecom3k) {
          await Api.post(
            `/amazon-listings/fetch`,
            {},
            {
              timeout: 3000,
            }
          );

          // Clear stored selected marketplace IDs and seller ID to allow listings
          // page to refresh them.
          localStorage.removeItem('selectedSellerId');
          localStorage.removeItem('selectedMarketplaceId');

        }
        if (data.brandTypeAlias === BrandTypesAlias.ffp) {
          history.push(`/amazon-listings-items?brands=${data.brandCode}&fetchingListings=true`);
        } else if (data.brandTypeAlias === BrandTypesAlias.ecom3k) {
          history.push(`/account/connect-to-amazon`);
        } else {
          history.push(`/brands/update/${data.brandCode}`);
        }
      } else {
        alertService.error('No brand.');
      }
    } catch (e) {
      // Disabled to avoid showing error alert if fetching taking long.
      // setResponse({...response, ok: false});
      // errorAlert('Something went wrong obtaining the Selling Partner API authorization. Please try again.', e);
    }
  }

  useEffect(() => {
    handleParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid
        className="centered-container"
        item
        lg={12} md={12} sm={12} xs={12}
      >
        <Typography>Amazon authorization in progress, please wait ...</Typography>
        <div>
          <CircularProgress size={40} />
        </div>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CustomAlert id="default-alert"></CustomAlert>
      </Grid>
    </Grid>
  );
}
