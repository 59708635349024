import {COLORS} from './colors';
// interfaces
import {
  PurchaserDataInterface,
  PurchaserBrandDataInterface,
  PurchaserBrandInterface,
  PurchaserInterface,
  DefaultInsightsDataInterface,
  SubsAndSaveBrandDataInterface,
  SummaryDataInterface,
  SummaryPropsInterface,
  HeatmapPropsInterface,
  HeatmapInterface,
  HeatmapBrandsPropsInterface,
  HeatmapBrandsInterface,
  FBADataPropsInterface,
  FBADataInterface,
  FBACountPropsInterface,
  FBACountInterface,
  SubsAndSaveDataInterface,
  SubsAndSavePropsInterface,
  TransformSubsAndSaveBrandDataInterface,
  PromotionCodesBrandInterface,
  PromotionCodesInterface,
  PromotionCodesItemInterface,
  ReturnRateData,
  transformReturnRateDataProps,
} from '../interfaces/insights';
import {HeatmapData} from '../components/heatmap';

const transformPurchaserData = (data: PurchaserDataInterface): PurchaserInterface[] => {
  return [
    {
      metric: 'One Time Percentage',
      value: +data.one_time_percentage,
      value_percent: +data.one_time_percentage,
      color: '#06b998',
      old_value: data.old_one_time_percentage ? +data.old_one_time_percentage : 0,
    },
    {
      metric: 'Repeating Percentage',
      value: +data.repeating_percentage,
      value_percent: +data.repeating_percentage,
      color: '#f1b42f',
      old_value: data.old_repeating_percentage ? +data.old_repeating_percentage : 0,
    },
  ];
};

const transformPurchaserBrandData = (
  data: PurchaserBrandDataInterface[],
  code: string,
): Array<PurchaserBrandInterface[]> =>
  data.map((item: PurchaserBrandDataInterface) => {
    return [
      {
        item: item[code as keyof PurchaserBrandDataInterface] as string,
        label: item.brand_name,
        metric: 'One Time Percentage',
        value: item.one_time_percentage,
        value_percent: item.one_time_percentage,
        color: '#06b998',
        old_value: item.old_one_time_percentage ? item.old_one_time_percentage : 0,
      },
      {
        item: item[code as keyof PurchaserBrandDataInterface] as string,
        label: item.brand_name,
        metric: 'Repeating Percentage',
        value: item.repeating_percentage,
        value_percent: item.repeating_percentage,
        color: '#f1b42f',
        invertedColor: true,
        old_value: item.old_repeating_percentage ? item.old_repeating_percentage : 0,
      },
    ];
  });

const transformSubsAndSaveBrandData = (
  data: {
    brand_name: string;
    percentage: number;
    sub_and_save: number;
    total: number;
    old_percentage?: number;
    old_sub_and_save?: number;
    old_total?: number;
  }[],
  code: string,
): SubsAndSaveBrandDataInterface[] =>
  data
    .sort((a, b) => b.percentage - a.percentage)
    .map((item, index) => {
      return {
        item: item[code as keyof typeof item] as string,
        label: item.brand_name,
        color: COLORS[index % COLORS.length],
        items: [
          {
            metric: 'Percentage',
            value: item.percentage,
            value_percent: item.percentage,
            invertedColor: true,
            old_value: item.old_percentage ? item.old_percentage : 0,
            fixed: 6,
            format: '%',
          },
          {
            metric: 'Subscribe and Save',
            value: item.sub_and_save,
            value_percent: item.sub_and_save,
            invertedColor: true,
            old_value: item.old_sub_and_save ? item.old_sub_and_save : 0,
            format: '',
          },
          {
            metric: 'Total',
            value: item.total,
            value_percent: item.total,
            invertedColor: true,
            old_value: item.old_total ? item.old_total : 0,
            format: '',
          },
        ],
      };
    });

const transformReturnRateData = ({
  data,
  complete,
  color,
  brands,
  total,
}: transformReturnRateDataProps): ReturnRateData[][] | ReturnRateData[] => {
  const returnRate: ReturnRateData[] = [];
  const returnRateResult: ReturnRateData[][] = [];

  data.forEach((item, index) => {
    if (complete && brands) {
      returnRateResult.push([
        {
          item: item.brand_code || item.seller_sku,
          label: item.brand_name,
          metric: 'Units Returned',
          format: '',
          color: color ? COLORS[index % COLORS.length] : null,
          value: +item.units_returned,
          value_percent: +item.units_returned,
          old_value: item.old_units_returned ? +item.old_units_returned : 0,
        },
        {
          item: item.brand_code || item.seller_sku,
          label: item.brand_name,
          metric: 'Units Sold',
          format: '',
          color: color ? COLORS[index % COLORS.length] : null,
          invertedColor: true,
          value: +item.units_sold,
          value_percent: +item.units_sold,
          old_value: item.old_units_sold ? +item.old_units_sold : 0,
        },
        {
          item: item.brand_code || item.seller_sku,
          label: item.brand_name,
          metric: 'Return Rate',
          format: '%',
          color: color ? COLORS[index % COLORS.length] : null,
          value: +(+item.return_rate * 100).toFixed(2),
          value_percent: +(+item.return_rate * 100).toFixed(2),
          old_value: item.old_return_rate ? +item.old_return_rate : 0,
        },
      ]);
    } else if (complete && !brands) {
      returnRate.push(
        {
          metric: 'Units Returned',
          format: '',
          color: color ? COLORS[index % COLORS.length] : null,
          value: +item.units_returned,
          value_percent: +item.units_returned,
          old_value: item.old_units_returned ? +item.old_units_returned : 0,
        },
        {
          metric: 'Units Sold',
          format: '',
          color: color ? COLORS[index % COLORS.length] : null,
          invertedColor: true,
          value: +item.units_sold,
          value_percent: +item.units_sold,
          old_value: item.old_units_sold ? +item.old_units_sold : 0,
        },
        {
          metric: item.brand_code ? item.brand_code : 'Return Rate',
          format: '%',
          color: color ? COLORS[index % COLORS.length] : null,
          value: +(+item.return_rate * 100).toFixed(2),
          value_percent: +(+item.return_rate * 100).toFixed(2),
          old_value: item.old_return_rate ? +item.old_return_rate : 0,
        },
      );
    } else {
      returnRate.push({
        metric: item.brand_code ? item.brand_code : item.seller_sku ? item.seller_sku : 'Return Rate',
        format: '%',
        color: color ? COLORS[index % COLORS.length] : null,
        value: +item.units_returned,
        value_percent: total ? +((+item.units_returned * 100) / total).toFixed(2) : +item.units_returned,
        old_value: item.old_return_rate ? +item.old_return_rate : 0,
      });
    }
  });
  return complete && brands ? returnRateResult : returnRate.filter((item) => item !== undefined);
};

const transformSummaryData = (data: SummaryPropsInterface[]): SummaryDataInterface[] => {
  return data.map((item, index) => {
    const countProperties = Object.entries(item).filter(
      ([key]) =>
        (key.includes('count') || key.includes('ship_service_level') || key.includes('marketplaceId')) &&
        !key.includes('total') &&
        !key.includes('old_'),
    );
    const summaryProperties = Object.entries(item).filter(([key]) => key.includes('total') && !key.includes('old_'));
    const oldValues = Object.entries(item).filter(([key]) => key.includes('old_'));
    const getOldValue = (currentValue: [string, number]): number | null => {
      const oldValue = oldValues.find((oldValue) => (oldValue[0] === `old_${currentValue[0]}` ? oldValue : null));
      const newOldValue = oldValue !== null && oldValue !== undefined ? oldValue[1] : null;
      return newOldValue;
    };

    return {
      id: index,
      brandCode: item.brand_code ? item.brand_code : null,
      brandName: item.brand_name || null,
      sellerSku: item.seller_sku ? item.seller_sku : null,
      count: countProperties.map((item, index) => {
        return {
          id: index,
          label: item[0].includes('discount') ? item[0] : item[0].replaceAll('count_', ''),
          value: item[1],
          oldValue: getOldValue(item),
        };
      }),
      summary: summaryProperties.map((item, index) => {
        return {
          id: index,
          label: item[0].replaceAll('amount', ''),
          value: item[1],
          oldValue: getOldValue(item),
        };
      }),
    };
  });
};

// const sumValues = (array: TransformGeoDataPropsInterface[], value: number | string) =>
//   array.reduce((acc, curr) => acc + curr[value as keyof typeof curr], 0);

// const transformGeoData = (array: any[], type: string): TransformGeoDataInterface[] => {
//   const newData = Object.entries(array).map(([key, value]: any): any => {
//     return {
//       label: key,
//       name: value[0].brand_name,
//       buyers: value,
//       type: type,
//       data: [
//         {
//           metric: 'Buyers',
//           value: value.length,
//         },
//         {
//           metric: 'revenue',
//           value: sumValues(value, 'revenue').toFixed(1),
//           old_value: sumValues(value, 'old_revenue').toFixed(1) || null,
//         },
//         {
//           metric: 'units',
//           value: sumValues(value, 'units').toFixed(1),
//           old_value: sumValues(value, 'old_units').toFixed(1) || null,
//         },
//       ],
//     };
//   });
//   return newData;
// };

const transformHeatmapData = (selectedData: HeatmapPropsInterface): HeatmapData['data'] => {
  return Object.entries(selectedData).map((item) => {
    return item[1];
  });
};

const transformHeatmapBrandData = (data: HeatmapBrandsPropsInterface, type: string): HeatmapBrandsInterface[] => {
  const getData = (selectedData: {[key: string]: HeatmapInterface[]}) => {
    return Object.entries(selectedData).map((item) => {
      return item[1];
    });
  };
  return Object.entries(data).map(([key, value]) => {
    return {
      label: key,
      type: type,
      name: value[Object.keys(value)[0]][0]['brand_name'] || '',
      labels: Object.keys(value).map((i) => i.substring(0, 3)),
      data: getData(value),
    };
  });
};

const transformFBAData = (data: FBADataPropsInterface[]): FBADataInterface[] =>
  data
    .sort((a, b) => b.count - a.count)
    .map((item, index) => {
      return {
        ...item,
        color: COLORS[index % COLORS.length],
        value: item.count,
        value_percent: item.percentage,
        old_value: item.old_count,
        old_percent: item.old_percentage,
      };
    });

const transformFBACountData = (data: FBACountPropsInterface[]): FBACountInterface[] =>
  data
    .sort((a, b) => b.count - a.count)
    .map((item, index) => {
      const percent = data?.reduce((acc, curr) => acc + curr.count, 0);
      const calc = percent > 0 ? percent : 100;
      return {
        metric: item.seller_sku,
        value: item.count,
        old_value: item.old_count,
        color: COLORS[index % COLORS.length],
        value_percent: ((+item.count * 100) / calc).toFixed(1),
      };
    });

const transformAverageOrderBrandData = (
  data: {
    brand_code: string;
    brand_name: string;
    average_order: number;
    old_average_order?: number;
  }[],
): DefaultInsightsDataInterface[] =>
  data
    .map((item) => {
      return {
        metric: item.brand_code,
        label: item.brand_name,
        value: item.average_order,
        old_value: item.old_average_order || 0,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformAverageOrderSkuData = (
  data: {
    seller_sku: string;
    average_order: number;
    old_average_order?: number;
  }[],
): DefaultInsightsDataInterface[] =>
  data
    .map((item) => {
      return {
        metric: item.seller_sku,
        value: item.average_order,
        old_value: item.old_average_order,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformSubsAndSaveData = (data: SubsAndSavePropsInterface): SubsAndSaveDataInterface[] => {
  return [
    {
      metric: 'Total',
      value: +data.total,
      value_percent: +data.total,
      old_value: data.old_total ? +data.old_total : 0,
      format: '',
      invertedColor: true,
    },
    {
      metric: 'Percentage',
      value: +data.percentage,
      value_percent: +data.percentage,
      old_value: data.old_percentage ? +data.old_percentage : 0,
      invertedColor: true,
      fixed: 6,
    },
    {
      metric: 'Subcribe and Save',
      value: +data.sub_and_save,
      value_percent: +data.sub_and_save,
      old_value: data.old_sub_and_save ? +data.old_sub_and_save : 0,
      format: '',
      invertedColor: true,
    },
  ];
};

const transformSubsAndSaveBrandChartData = (
  data: SubsAndSavePropsInterface[],
  value: 'brand_code' | 'brand_name' | 'seller_sku',
): TransformSubsAndSaveBrandDataInterface[] =>
  data
    .sort((a, b) => b.percentage - a.percentage)
    .map((item, index) => {
      return {
        ...item,
        metric: item[value] || '',
        color: COLORS[index % COLORS.length],
        value: item.percentage,
        value_percent: item.percentage,
        old_value: item.old_percentage,
        old_percent: item.old_percentage,
      };
    })
    .filter((item) => item.percentage > 0.009);

const transformPromotionCodesData = (
  data: {
    promo_code: string;
    count: number;
    old_count?: number;
  }[],
): PromotionCodesItemInterface[] =>
  data
    .map((item) => {
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: item.old_count,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformPromotionCodesBrandData = (data: {
  [key: string]: PromotionCodesBrandInterface[];
}): PromotionCodesInterface[] =>
  Object.entries(data).map(([key, item]) => {
    const values = item.map((item) => {
      let old_value = null;
      if (item.old_count !== null) {
        old_value = item.old_count;
      } else {
        old_value = 0;
      }
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: old_value,
        invertedColor: true,
        brand_name: item.brand_name,
      };
    });
    return {
      metric: values[0]?.brand_name || key,
      label: values[0]?.brand_name || key,
      brand_code: key,
      values: values.sort((a, b) => b.value - a.value),
      value: item.reduce((acc, item) => acc + item.count, 0),
      old_value: values.reduce((acc, item) => acc + (item.old_value || 0), 0) || null,
      invertedColor: true,
    };
  });

const transformPromotionCodesSkuData = (
  data: {
    [key: string]: PromotionCodesBrandInterface[];
  },
  compare: boolean,
): PromotionCodesInterface[] =>
  Object.entries(data).map(([key, item]) => {
    const values = item.map((item) => {
      let old_value = null;
      if (compare) {
        if (item.old_count !== null) {
          old_value = item.old_count;
        } else {
          old_value = 0;
        }
      }
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: old_value,
        invertedColor: true,
      };
    });
    return {
      metric: key,
      label: key,
      values: values.sort((a, b) => b.value - a.value),
      value: item.reduce((acc, item) => acc + item.count, 0),
      old_value: compare ? values.reduce((acc, item) => acc + (item.old_value || 0), 0) : null,
      invertedColor: true,
    };
  });

export {
  transformReturnRateData,
  transformPurchaserData,
  transformPurchaserBrandData,
  transformSummaryData,
  transformHeatmapData,
  transformHeatmapBrandData,
  transformSubsAndSaveBrandData,
  transformFBAData,
  transformFBACountData,
  transformAverageOrderBrandData,
  transformAverageOrderSkuData,
  transformSubsAndSaveData,
  transformSubsAndSaveBrandChartData,
  transformPromotionCodesData,
  transformPromotionCodesBrandData,
  transformPromotionCodesSkuData,
};
