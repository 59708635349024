import React, {useEffect, useState, useContext, useCallback} from 'react';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
  Grid,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  Link,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  CircularProgress,
  Checkbox,
  Autocomplete,
  LinearProgress,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
  GridSelectionModel,
} from '@mui/x-data-grid';
import {useLocation} from 'react-router-dom';
import {Close} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {rowsPerPageOptions} from '../../utils/constants';
import {marketplaces} from '../Performance/utils/marketplaces';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Button from '../../components/Button/Button';
import Icons from '../../components/Icons/Icons';
import {CustomAlert} from '../Login/components/CustomAlert';
import DataGridToolbar from '../../components/DataGridToolbar/DataGridToolbar';
// context
import {AppContext} from '../../context/AppContext/AppContext';
import {getSubBrands} from '../../utils/getSubBrands';
// interfaces
import {AccountManagersInterface} from '../../context/AppContext/interfaces/interfaces';
import {
  AlertTableProps,
  SelectedAlertItem,
  SelectedItem,
  AlertType,
  AlertDetails,
  Classification,
  SelectedAlerts,
  Params,
} from './interfaces/alerts.interfaces';
import {BrandInterface} from '../../interfaces/brands/brand';

const classificationAll = {
  department: 'All',
};

const visibleTypeLabels = ['Visible', 'Hidden'];

const urlArrayValues = ['alertVisibleTypes', 'brands', 'alertTypes', 'alertSubTypes', 'subBrands'];
const parentLabelForAdditionAMAlert = 'Additional AM AlertType';
const additionalAlertSubTypesForAM = [
  'Lost Best Seller Badge',
  'No Cart Button',
  'Product Listing Suspension',
  'marketplace Suspension',
  'Adult-Flag',
  'Out of Stock (Alert)',
  'Low on Stock (Alert)',
  'Search Result Suppression',
  'Star Rating Dropped',
  'Review Count Drop',
  'Lost Amazon Choice Badge',
  'Lost Prime Badge',
  'Prime Badge Recovered',
  'Search Result Suppression Lifted',
];

export default function AlertsTable({alertType, sxStyles}: AlertTableProps): JSX.Element {
  const location = useLocation();

  const [loadingData, setLoadingData] = useState(false);
  const [fromTo, setFromTo] = useState({
    from: moment().subtract(1, 'days').startOf('day').valueOf(),
    to: moment().valueOf(),
  });
  const [classifications, setClassifications] = useState<Classification[]>([]);
  const [classificationValue, setClassificationValue] = useState(classificationAll.department);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [updatingVisible, setUpdatingVisible] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState<GridSelectionModel>([]);
  const [selectedAlerts, setSelectedAlerts] = useState<SelectedAlerts>({visible: [], hidden: []});
  const [togglingRowId, setTogglingRowId] = useState<string | number | null>(null);
  const [filterModel, setFilterModel] = useState<GridFilterItem>();
  const [sortModel, setSortModel] = useState<
    {
      field: string;
      sort: string;
    }[]
  >([{field: 'dateTime', sort: 'desc'}]);
  const [accountManager, setAccountManager] = useState<AccountManagersInterface | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[] | null>(null);
  const [selectedMP, setSelectedMP] = useState<SelectedItem[]>([]);
  const [selectedAlertTypes, setSelectedAlertTypes] = useState<string[] | null>([]);
  const [alertTypes, setAlertTypes] = useState<string[]>([]);
  const [filteredAlertTypes, setFilteredAlertTypes] = useState<string[]>([]);
  const [selectedAlertSubTypes, setSelectedAlertSubTypes] = useState<string[]>([]);
  const [alertSubTypes, setAlertSubTypes] = useState<AlertType[]>([]);
  const [filteredAlertSubTypes, setFilteredAlertSubTypes] = useState<AlertType[]>([]);
  const [alertVisibleTypes, setAlertVisibleTypes] = useState(visibleTypeLabels.filter((item) => item !== 'Hidden'));

  const [params, setParams] = useState<Params>({
    am_email: null,
    from: fromTo.from,
    to: fromTo.to,
    page: 0,
    pageSize: pageSize,
    classification: null,
    alertVisibleTypes,
    sortBy: sortModel[0]?.sort || null,
    sortOrder: sortModel[0]?.field || null,
    filterField: null,
    filterValue: null,
    filterOperator: null,
    brands: [],
    alertTypes: [],
    alertSubTypes: [],
    subBrands: [],
    marketplaces: [],
  });

  const [alertDetailModalOpen, setAlertDetailModalOpen] = useState(false);
  const [alertDetail, setAlertDetail] = useState<AlertDetails | null>(null);
  const [subBrands, setSubBrands] = useState<BrandInterface[]>([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState<string[] | null>();
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [tableLoading, setTableLoading] = useState(false);

  const {accountManagers, brands} = useContext(AppContext);

  const getDefaultQuery = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const query = Object({});
    Object.keys(params).forEach((key) => {
      switch (key) {
        case 'from':
          query[key] = urlParams.get(key) ? urlParams.get(key) : moment().subtract(1, 'days').startOf('day').valueOf();
          break;
        case 'to':
          query[key] = urlParams.get(key) ? urlParams.get(key) : moment().valueOf();
          break;
        case 'am_email':
          if (urlParams.get(key)) {
            query[key] = urlParams.get(key);
          } else {
            query[key] = accountService.userValue.email;
          }
          break;
        case 'page':
        case 'pageSize':
          if (key === 'pageSize' && !urlParams.get(key)) {
            query[key] = pageSize;
          } else query[key] = Number(urlParams.get(key));
          break;
        case 'alertVisibleTypes':
          if (urlParams.get(key)) {
            query[key] = urlParams?.get(key)?.split(',');
            setAlertVisibleTypes(query[key]);
          } else {
            query[key] = alertVisibleTypes;
          }
          break;
        case 'brands':
        case 'subBrands':
        case 'alertTypes':
        case 'alertSubTypes':
          query[key] = urlParams.get(key) && urlParams.get(key) !== '' ? urlParams?.get(key)?.split(',') : [];
          break;
        case 'classification':
          query[key] = urlParams.get(key) ? urlParams.get(key) : classificationAll.department;
          break;
        case 'marketplaces':
          query[key] = urlParams.get(key) ? urlParams.get(key)?.split(',') : [];
          break;
        default:
          query[key] = urlParams.get(key);
          break;
      }
    });
    setPage(query['page']);

    if ('marketplaces' in query) {
      const activeMP = marketplaces.filter((item) => query.marketplaces?.includes(item.id));
      setSelectedMP(activeMP);
    }
    if ('brands' in query) {
      pullSubBrands(query.brands);
    }
    return query;
  }, [alertVisibleTypes, location.search, params, pageSize]);

  const getAccountManagers = useCallback(
    (query) => {
      if ([Role.Admin].includes(accountService.userValue.role)) {
        let currentUser: AccountManagersInterface | null = null;
        if (query.am_email) {
          currentUser = accountManagers.find((manager) => manager.email === query.am_email) || null;
          setAccountManager(currentUser);
        }
      }
    },
    [accountManagers],
  );

  const updateQueryParams = useCallback(() => {
    const search = new URLSearchParams(location.search);
    Object.keys(params).forEach((k) => {
      const key = k as keyof typeof params;
      if (key === 'sortBy' || key === 'sortOrder' || key === 'page' || key === 'pageSize') {
        search.delete(key);
      } else if (urlArrayValues.includes(key)) {
        if (!params[key] || (Array.isArray(params[key]) && (params[key] as never[])?.length === 0)) {
          search.delete(key);
        } else {
          search.set(key, String(params[key as keyof typeof params]));
        }
      } else {
        if (params[key] !== null) {
          search.set(key, String(params[key]));
        } else {
          if (key === 'am_email') search.set(key, String(params[key]));
          else search.delete(key);
        }
      }
    });

    if (filterModel) {
      search.set('filterField', filterModel?.columnField);
      search.set('filterValue', filterModel?.value);
    }
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newUrl}, '', newUrl);
  }, [params, filterModel, location.search]);

  useEffect(() => {
    const defaultQuery = getDefaultQuery();
    getClassifications(defaultQuery);
    getAlertTypes();
    setParams(defaultQuery);
    setFromTo({from: Number(defaultQuery.from), to: Number(defaultQuery.to)});
    loadData(defaultQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const defaultQuery = getDefaultQuery();
    if (accountManagers.length > 0) {
      getAccountManagers(defaultQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagers, getAccountManagers]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  async function getClassifications(query: {classification: string}) {
    try {
      const {data} = await Api.get(`classifications`);
      data.unshift(classificationAll);
      setClassifications(data);
      setClassificationValue(query.classification);
    } catch (e) {
      errorAlert('Unable to get classifications', e);
    }
  }

  async function getAlertTypes() {
    try {
      const {data} = await Api.get(`alerts/alert-types`);
      const resAlertSubTypes: AlertType[] = [];
      const resAlertTypes = data.map((item: {_id: string; subTypes: {value: string}[]}) => {
        for (const record of item.subTypes) {
          if (record?.value && !resAlertSubTypes.find((item) => item.name === record.value)) {
            resAlertSubTypes.push({
              name: record.value,
              parent: item._id,
            });
          }
        }
        return item._id;
      });
      setAlertTypes(resAlertTypes);
      setFilteredAlertTypes(resAlertTypes);
      setFilteredAlertSubTypes(resAlertSubTypes);
      for (const subType of additionalAlertSubTypesForAM) {
        resAlertSubTypes.push({
          name: subType,
          parent: parentLabelForAdditionAMAlert,
        });
      }
      setAlertSubTypes(resAlertSubTypes);
    } catch (e) {
      errorAlert('Unable to get alert types', e);
    }
  }

  function isHide(row: {hiddenFromTabs: string[]}) {
    if (classificationValue === 'All') {
      return (
        row.hiddenFromTabs &&
        row.hiddenFromTabs.length > 0 &&
        row.hiddenFromTabs.length === classifications.filter((x) => x.department !== 'All').length
      );
    } else {
      return (
        row.hiddenFromTabs &&
        (row.hiddenFromTabs.indexOf(classificationValue) > -1 ||
          row.hiddenFromTabs.indexOf(classificationAll.department) > -1)
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'alertType',
      headerName: 'Alert Type',
      width: 130,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.alertType}>
          <span className="table-cell-trucate">{params.row.alertType}</span>
        </Tooltip>
      ),
    },
    {
      field: 'alertSubType',
      headerName: 'Alert Sub Type',
      width: 150,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.alertSubType}>
          <span className="table-cell-trucate">{params.row.alertSubType}</span>
        </Tooltip>
      ),
    },
    {
      field: 'productImageURL',
      headerName: 'Photo',
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <img
          src={params.row.productImageURL}
          style={{maxWidth: '35px', maxHeight: '35px'}}
          alt={params.row?.productName || ''}
        />
      ),
    },
    {
      field: 'productName',
      headerName: 'Title',
      minWidth: 320,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row?.productName || ''} hidden={!params.row.productName}>
          <span className="table-cell-trucate">{params.row?.productName}</span>
        </Tooltip>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      minWidth: 600,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row?.message || ''} hidden={!params.row.message}>
          <span className="message-cell">{params.row?.message}</span>
        </Tooltip>
      ),
    },
    {
      field: 'asin',
      headerName: 'ASIN',
      width: 150,
      renderCell: (params: GridCellParams) => {
        if (params.row.asin) {
          const href = `https://www.amazon.com/${params.row.sku ? params.row.sku + '/' : ''}dp/${params.row.asin}`;
          return (
            <Typography>
              <Link href={href} target="_blank" rel="noreferrer noopener">
                {params.row.asin}
              </Link>
            </Typography>
          );
        } else return <></>;
      },
    },
    {field: 'brand', headerName: 'Brand', width: 150, filterable: false},
    {
      field: 'marketplace',
      headerName: 'MarketPlace',
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <Typography sx={params.row.marketplace && sxStyles('iconFlag')}>
            {params.row.marketplace ? <Icons name={params.row.marketplace.toUpperCase()} tooltip /> : 'N/A'}
          </Typography>
        );
      },
    },

    {
      field: 'dateTime',
      headerName: 'Date',
      width: 120,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.dateTime}>
          <span className="table-cell-truncate">{moment(params.row.dateTime).format('YYYY-MM-DD')}</span>
        </Tooltip>
      ),
    },
  ];

  if (accountService.userValue.role !== Role.BrandUser) {
    columns.unshift({
      headerName: 'Action',
      field: 'button',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <Box display="flex" alignItems="center" flexDirection="column" fontSize={12}>
          {params.row.cuTaskUrl && (
            <Typography variant="caption">
              <Link href={params.row.cuTaskUrl} target="_blank" rel="noopener noreferrer">
                <Button className={'textButton'} color="primary" size="small" variant="text">
                  View CU Task
                </Button>
              </Link>
            </Typography>
          )}
          <Typography variant="caption">
            {params.row.sentryKitURL && (
              <Link href={params.row.sentryKitURL} target="_blank" rel="noopener noreferrer">
                <Button className={'textButton'} color="primary" size="small" variant="text">
                  View Sentry Kit
                </Button>
              </Link>
            )}
          </Typography>
          <Typography variant="caption">
            <Button
              onClick={() => onViewAlert(params.row)}
              className={'textButton'}
              color="primary"
              size="small"
              variant="text"
            >
              View Alert
            </Button>
          </Typography>
          <Typography variant="caption">
            <Button
              onClick={() => hideAlert(params.row)}
              className={'textButton'}
              color={isHide(params.row) ? 'primary' : 'inherit'}
              size="small"
              disabled={loadingData || updatingVisible}
              variant="text"
            >
              {isHide(params.row) ? 'Show Alert' : 'Hide Alert'}
              {updatingVisible && params.row._id === togglingRowId && (
                <CircularProgress size={12} style={{color: 'blue'}} />
              )}
            </Button>
          </Typography>
        </Box>
      ),
    });
  }

  const csvHeaders = columns
    .filter((column) => column.field !== 'actions')
    .map((column) => {
      return {
        key: column.field,
        label: column.headerName || '',
      };
    });

  async function loadData(queryParams = params) {
    if (!queryParams.am_email) {
      delete queryParams.am_email;
    }
    setLoadingData(true);
    const active = true;

    try {
      const {data} = await Api.get(`alerts`, {
        params: queryParams,
      });
      if (!active) return;
      setCount(data.count);
      const gridRows = data.rows.map((alertRecord: Partial<AlertDetails>, index: number) => {
        if (alertRecord.dateTime) {
          alertRecord.dateTime = moment(new Date(alertRecord.dateTime)).format('YYYY-MM-DD hh:mm A');
        }
        return {id: index, ...alertRecord};
      });
      setRows(gridRows);
      if (alertVisibleTypes.length === 1) {
        const _selectedIndexes = gridRows
          .filter(
            (row: {_id: string}) =>
              selectedAlerts[alertVisibleTypes[0].toLowerCase() as keyof typeof selectedAlerts].findIndex(
                (item) => item.id === row._id,
              ) > -1,
          )
          .map((row: {id: number}) => row.id);
        setSelectedIndexes(_selectedIndexes);
      } else setSelectedIndexes([]);
    } catch (e) {
      errorAlert('Unable to get alerts', e, {id: 'data-grid-alert'});
    } finally {
      setLoadingData(false);
    }
  }

  function onFromToChange(from: string, to: string) {
    const fromValue = moment(from).startOf('day').valueOf();
    const toValue = moment(to).endOf('day').valueOf();
    setFromTo({from: fromValue, to: toValue});
    let queryParams = getUpdatedParamsState('from', fromValue);
    queryParams = getUpdatedParamsState('to', toValue);
    loadData(queryParams);
  }

  async function hideAlert(record: {_id: string | number; collection: string}) {
    setTogglingRowId(record._id);
    setUpdatingVisible(true);
    try {
      await Api.post('alerts/hide-alert', {
        alertId: record._id,
        alertCollection: record.collection,
        tabName: classificationValue,
      });
      alertService.success(`Alert hidden from tab ${classificationValue}`, {id: 'data-grid-alert'});
      setRows(rows.filter((row: {_id: string | number}) => record._id !== row._id));
      setCount((prev) => prev - 1);
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e, {id: 'data-grid-alert'});
    } finally {
      setTogglingRowId(null);
      setUpdatingVisible(false);
    }
  }

  function handleChangePage(page: number) {
    setSelectedIndexes([]);
    setPage(page);
    loadData(getUpdatedParamsState('page', page));
  }

  function handleChangePageSize(pageSize: number) {
    setPageSize(pageSize);
    loadData(getUpdatedParamsState('pageSize', pageSize));
  }

  async function handleHideAlerts() {
    if (alertVisibleTypes.length !== 1) {
      alertService.error('Hide/Show Action is available on one option of Visible or Hidden.', {id: 'data-grid-alert'});
    }
    setUpdatingVisible(true);
    try {
      const {data} = await Api.post('alerts/hide-alerts', {
        alerts: selectedAlerts[alertVisibleTypes[0].toLowerCase() as keyof typeof selectedAlerts],
        tabName: classificationValue,
      });

      const _selectedAlerts = selectedAlerts;
      _selectedAlerts[alertVisibleTypes[0].toLowerCase() as keyof typeof selectedAlerts] = _selectedAlerts[
        alertVisibleTypes[0].toLowerCase() as keyof typeof selectedAlerts
      ].filter((alertItem: SelectedAlertItem) => !data.includes(alertItem.id));

      setSelectedAlerts(_selectedAlerts);

      setRows(rows.filter((row: {_id: string | number}) => !data.includes(row._id)));

      setCount(count - data.length);

      loadData(params);
      alertService.success(
        `Set ${data.length} Alerts as [${visibleTypeLabels.find(
          (item) => item !== alertVisibleTypes[0],
        )}] on tab ${classificationValue}`,
        {id: 'data-grid-alert'},
      );
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e, {id: 'data-grid-alert'});
    } finally {
      setUpdatingVisible(false);
    }
  }

  function handleChangeAccountManager(accountManagerObj: AccountManagersInterface | null) {
    setAccountManager(accountManagerObj);
    loadData(getUpdatedParamsState('am_email', accountManagerObj?.email || null));
  }

  function handleChangeClassificationValue(event: React.ChangeEvent<{value: string}>) {
    const departmentStr = event.target.value;
    setClassificationValue(departmentStr);
    let queryParams = getUpdatedParamsState('classification', departmentStr);
    queryParams = getUpdatedParamsState('alertType', null);
    queryParams = getUpdatedParamsState('alertSubType', null);
    updateTypesByClassification(departmentStr);
    loadData(queryParams);
  }

  function updateTypesByClassification(departmentStr: string) {
    if (departmentStr === classificationAll.department) {
      setFilteredAlertTypes(alertTypes);
      setSelectedAlertTypes(null);
      setFilteredAlertSubTypes(alertSubTypes);
      setSelectedAlertSubTypes([]);
    } else {
      const cAlerts = classifications.find((item: {department: string}) => item.department === departmentStr)?.alerts;

      const CFilteredAlertTypes: string[] = [];
      const cFilteredAlertSubTypes = alertSubTypes.filter(
        (item) =>
          cAlerts?.indexOf(item.name) !== -1 ||
          (departmentStr === 'AM' && item.parent === parentLabelForAdditionAMAlert),
      );
      cFilteredAlertSubTypes.forEach((item) => {
        if (CFilteredAlertTypes.indexOf(item.parent) === -1) CFilteredAlertTypes.push(item.parent);
      });
      setFilteredAlertTypes(CFilteredAlertTypes);
      setSelectedAlertTypes(null);
      setFilteredAlertSubTypes(cFilteredAlertSubTypes);
      setSelectedAlertSubTypes([]);
    }
  }

  async function pullSubBrands(brandCodes: string[]) {
    const data = await getSubBrands(brandCodes);
    if (data) {
      setSubBrands(data);
    }
  }

  function handleChangeBrand(brands: string[]) {
    if (brands) {
      pullSubBrands(brands);
    }
    setSelectedBrands(brands);
    const queryParams = getUpdatedParamsState('brands', brands || []);
    loadData(queryParams);
  }

  function handleChangeSubBrand(subBrands: string[]) {
    setSelectedSubBrands(subBrands);
    const queryParams = getUpdatedParamsState('subBrands', subBrands || []);
    loadData(queryParams);
  }

  function handleChangeAlertTypes(selectedAlertTypes: string[]) {
    if (selectedAlertTypes && selectedAlertTypes.length > 0) {
      const filteredSubTypes = alertSubTypes.filter(
        (item) =>
          selectedAlertTypes.includes(item.parent) ||
          (classificationValue === 'AM' && item.parent === parentLabelForAdditionAMAlert),
      );
      setFilteredAlertSubTypes(filteredSubTypes);
      setSelectedAlertTypes(selectedAlertTypes);
      setSelectedAlertSubTypes([]);
    } else {
      updateTypesByClassification(classificationValue);
    }
    let queryParams = getUpdatedParamsState('alertTypes', selectedAlertTypes);
    queryParams = getUpdatedParamsState('alertSubTypes', null);
    loadData(queryParams);
  }

  function handleChangeAlertSubTypes(alertSubTypes: string[]) {
    setSelectedAlertSubTypes(alertSubTypes);
    loadData(getUpdatedParamsState('alertSubTypes', alertSubTypes || []));
  }

  function handleChangeMarketPlace(mpObj: {id: string; name: string}[]) {
    setSelectedMP(mpObj);
    const selectedMp = mpObj.map((i) => i.id);
    loadData(getUpdatedParamsState('marketplaces', selectedMp || null));
  }

  function handleVisibleTypeChange(label: string, value: boolean) {
    const _alertVisibleTypes = value ? [...alertVisibleTypes, label] : alertVisibleTypes.filter((x) => x !== label);
    const queryParams = getUpdatedParamsState('alertVisibleTypes', _alertVisibleTypes);
    setAlertVisibleTypes(_alertVisibleTypes);
    loadData(queryParams);
  }

  function handleChangeFilter(filter: GridFilterModel) {
    const filterObj = filter.items[0];
    setFilterModel(filterObj);
    let queryParams = getUpdatedParamsState('filterField', filterObj?.columnField || null);
    queryParams = getUpdatedParamsState('filterValue', filterObj?.value || null);
    queryParams = getUpdatedParamsState('filterOperator', filterObj?.operatorValue || null);
    loadData(queryParams);
  }

  function handleChangeSort(dgParams: GridSortModel) {
    const sortObj = dgParams;
    setSortModel([
      {
        field: sortObj[0]?.field,
        sort: sortObj[0]?.sort as string,
      },
    ]);
    let queryParams = getUpdatedParamsState('sortBy', sortObj[0]?.field || null);
    queryParams = getUpdatedParamsState('sortOrder', sortObj[0]?.sort || null);
    loadData(queryParams);
  }

  function handleSelectAlerts(rowIdxes: GridSelectionModel) {
    const gridSelectedAlerts = rows
      .filter((row: {id: number}) => rowIdxes.indexOf(row.id) > -1)
      .map((row: {_id: string; collection: string}) => ({id: row._id, collectionName: row.collection}));

    const _selectedAlerts: SelectedAlerts = selectedAlerts;
    const alertVisibleType = alertVisibleTypes[0].toLowerCase();
    const selectedAlertsFromOtherPage: {
      id: string;
      collectionName: string;
    }[] = _selectedAlerts[alertVisibleType as keyof typeof _selectedAlerts].filter(
      (alertItem: {id: string}) => rows.findIndex((row: {_id: string}) => row._id === alertItem.id) === -1,
    );
    _selectedAlerts[alertVisibleType as keyof typeof _selectedAlerts] =
      selectedAlertsFromOtherPage.concat(gridSelectedAlerts);
    setSelectedAlerts(_selectedAlerts);
    setSelectedIndexes(rowIdxes);
  }

  function getUpdatedParamsState(key: string, value: string | number | string[] | null) {
    const queryParams = params;
    if (key !== 'page') {
      queryParams['page'] = 0;
      setPage(0);
    }
    queryParams[key as keyof typeof queryParams] = value as never;
    setParams(queryParams);
    return queryParams;
  }

  function onViewAlert(row: AlertDetails) {
    setAlertDetail(row);
    setAlertDetailModalOpen(true);
  }

  function onAlertDetailModalClose() {
    setAlertDetail(null);
    setAlertDetailModalOpen(false);
  }

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: 'alerts',
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customToolbarProps = {
    loadingData: loadingData || tableLoading,
    items: orderColumns,
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        'alerts',
        setTableLoading,
      );
    },
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%'}}>
              {fromTo.to && fromTo.from && (
                <DateRangePicker
                  width="500px"
                  from={fromTo.from}
                  to={fromTo.to}
                  onChange={(x) => {
                    onFromToChange(x.selection?.startDate as string, x.selection?.endDate as string);
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="classification"
                value={classificationValue}
                onChange={handleChangeClassificationValue}
                sx={sxStyles('radioGroup')}
              >
                {classifications.map((item) => (
                  <FormControlLabel
                    key={item.department}
                    value={item.department}
                    control={<Radio size="small" />}
                    label={item.department}
                    disabled={loadingData}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={{lg: 1.5, md: 1.5, sm: 1, xs: 1}} columnSpacing={1}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={brands.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = brands.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={selectedBrands ? selectedBrands : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={subBrands.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = subBrands.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={selectedSubBrands ? selectedSubBrands : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeSubBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by sub brands" placeholder="Sub brands" />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              id="alerttype-list"
              size="small"
              multiple
              options={filteredAlertTypes}
              getOptionLabel={(option) => option}
              value={selectedAlertTypes ? selectedAlertTypes : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeAlertTypes(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Alert Type" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              id="alertsubtype-list"
              size="small"
              options={
                params.alertTypes.length > 0
                  ? alertSubTypes.filter((item) => params.alertTypes.includes(item.parent)).map((item) => item.name)
                  : filteredAlertSubTypes.map((item) => item.name)
              }
              getOptionLabel={(option) => option}
              value={selectedAlertSubTypes ? selectedAlertSubTypes : []}
              disabled={loadingData}
              multiple
              onChange={(event, newValue) => {
                handleChangeAlertSubTypes(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Alert Sub Type" variant="outlined" />}
            />
          </Grid>

          {[Role.Admin].includes(accountService.userValue.role) && accountManagers.length > 0 && (
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Autocomplete
                multiple={false}
                size="small"
                options={accountManagers}
                getOptionLabel={(option) => {
                  return typeof option === 'string' ? option : option.name || option.email;
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                value={accountManager}
                defaultValue={accountManager}
                disabled={loadingData}
                onChange={(event, newValue) => {
                  handleChangeAccountManager(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Account Manager" variant="outlined" />}
              />
            </Grid>
          )}

          <Grid item lg={3} md={3} sm={6} xs={12}>
            {marketplaces.length > 0 && (
              <Autocomplete
                id="marketplace-list"
                multiple
                size="small"
                options={marketplaces}
                getOptionLabel={(option) => option.name}
                value={selectedMP}
                disabled={loadingData}
                onChange={(event, newValue) => {
                  handleChangeMarketPlace(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="MarketPlaces" variant="outlined" />}
              />
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box component="div" sx={sxStyles('alertsToolbar')}>
              <FormGroup row={true}>
                {accountService.userValue.role !== Role.BrandUser &&
                  visibleTypeLabels.map((x) => (
                    <FormControlLabel
                      key={x}
                      control={
                        <Checkbox
                          checked={alertVisibleTypes.includes(x)}
                          onChange={(e) => handleVisibleTypeChange(x, e.target.checked)}
                          disabled={loadingData || (alertVisibleTypes.includes(x) && alertVisibleTypes.length === 1)}
                        />
                      }
                      label={x}
                    ></FormControlLabel>
                  ))}
              </FormGroup>
              <Grid>
                {accountService.userValue.role !== Role.BrandUser && alertVisibleTypes.length === 1 && (
                  <Button
                    onClick={handleHideAlerts}
                    size="small"
                    disabled={
                      loadingData ||
                      selectedAlerts[alertVisibleTypes[0].toLowerCase() as keyof SelectedAlerts].length < 1 ||
                      updatingVisible
                    }
                  >
                    {alertVisibleTypes[0] === 'Visible' ? 'Hide' : 'Show'} selected
                    {updatingVisible && <CircularProgress size={12} style={{color: 'blue'}} />}
                  </Button>
                )}
                <CSVLink filename={`${alertType} Alerts.csv`} data={rows} headers={csvHeaders.map((x) => x.label)}>
                  <Box ml={2}>
                    <Button size="small" disabled={loadingData}>
                      Export to CSV
                    </Button>
                  </Box>
                </CSVLink>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="data-grid-alert" />
      </Grid>
      <Grid item xs={12}>
        {accountService.userValue.role !== Role.BrandUser && alertVisibleTypes.length === 1 && (
          <p>
            {selectedAlerts[alertVisibleTypes[0].toLowerCase() as keyof SelectedAlerts]?.length} {alertVisibleTypes[0]}{' '}
            alerts selected
          </p>
        )}
      </Grid>
      <Grid item xs={12} lg={12} position="relative">
        {(loadingData || tableLoading) && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
        <DataGrid
          components={{
            Pagination: DataGridToolbar,
            Toolbar: DataGridToolbar,
          }}
          componentsProps={{
            toolbar: customToolbarProps,
            pagination: customToolbarProps,
          }}
          sx={sxStyles('grid')}
          className={`custom-table ${tableLoading ? 'load-headers' : ''}`}
          disableColumnMenu={loadingData}
          autoHeight={true}
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          paginationMode="server"
          onPageChange={(params) => handleChangePage(params)}
          filterMode="server"
          onFilterModelChange={(params) => handleChangeFilter(params)}
          sortingMode="server"
          onSortModelChange={(params) => handleChangeSort(params)}
          disableSelectionOnClick={true}
          checkboxSelection={alertVisibleTypes?.length === 1}
          selectionModel={selectedIndexes}
          onSelectionModelChange={(x) => {
            handleSelectAlerts(x);
          }}
          rowHeight={108}
          rows={loadingData ? [] : rows}
          columns={orderColumns}
          columnVisibilityModel={visibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            let data = {};
            Object.entries(newModel).forEach(([x, v]) => {
              if (v === false) {
                data = {...data, [x]: v};
              }
            });
            const newOrder = getColumnsItems({
              list: columns,
              columnsVisibility: data,
              columnsOrder: orderColumns,
              currentOrder: orderColumns,
            });
            setVisibilityModel(data);
            setOrderColumns(newOrder);
            setUserPreferences(
              {
                columnVisibilityModel: data,
                columnsOrder: newOrder.map((x) => x.field),
              },
              'alerts',
              setTableLoading,
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Dialog
          open={alertDetailModalOpen}
          onClose={onAlertDetailModalClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="alert-detail-dialog-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>Alert Detail</Box>
              <Box>
                <IconButton onClick={onAlertDetailModalClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} justifyContent="center">
              {alertDetail &&
                Object.keys(alertDetail).map((k) => {
                  const key = k as keyof typeof alertDetail;
                  if (
                    key.includes('_id') ||
                    key.includes('id') ||
                    key.includes('createdAt') ||
                    key.includes('updatedAt') ||
                    key.includes('__v')
                  )
                    return null;
                  else if (key.includes('fullPayload'))
                    return (
                      <Grid item xs={12}>
                        <Box fontWeight="fontWeightBold" mb={1}>
                          {key}
                        </Box>
                        <Box>{JSON.stringify(alertDetail[key])}</Box>
                      </Grid>
                    );
                  else
                    return (
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <label style={{fontWeight: 'bold'}}>{key}:&nbsp;&nbsp;</label>
                        {key === 'productImageURL' && <img src={alertDetail.productImageURL} alt="No ProductImage" width={256}/>}
                        {key !== 'productImageURL' && <span>{String(alertDetail[key])}</span>}
                      </Grid>
                    );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onAlertDetailModalClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
