import React, { useState, useEffect } from 'react';
import { Api, errorAlert } from 'src/utils/api';
import { alertService } from 'src/services/alert.service';

import mixPanel from 'src/services/mixpanel';

import Button from 'src/components/Button/Button';
import ModalButton from 'src/components/ModalButton/ModalButton';
import { BasicSubscriptionItem } from 'src/interfaces/stripe/basic-subscription-item';

enum UpdateMarketplacesStatus {
  MAX_MARKETPLACES_LOWER_THAN_AUTHORIZED = 'MAX_MARKETPLACES_LOWER_THAN_AUTHORIZED',
  MAX_MARKETPLACES_UPDATED = 'MAX_MARKETPLACES_UPDATED',
  MAX_MARKETPLACES_UPDATE_ERROR = 'MAX_MARKETPLACES_UPDATE_ERROR',
  MAX_MARKETPLACES_NO_PLAN_ITEM = 'MAX_MARKETPLACES_NO_PLAN_ITEM',
}

interface Props {
  currentPlanProductId: string;
  numberOfAuthorizedMarketplaces: number;
  maxMarketplaces: number;
  planMaxMarketplaces: number;
  subscriptionItems: BasicSubscriptionItem[];
  setSubscriptionItems: (subscriptionItems: BasicSubscriptionItem[]) => void;
}

export default function MaxMarketplacesSelect({
  currentPlanProductId,
  numberOfAuthorizedMarketplaces,
  maxMarketplaces,
  planMaxMarketplaces,
  subscriptionItems,
  setSubscriptionItems,
}: Props): JSX.Element {

  const [currentMaxMarketplaces, setCurrentMaxMarketplaces] = useState<number>(maxMarketplaces);
  const [selectedNumber, setSelectedNumber] = useState<number>(maxMarketplaces);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedNumber(maxMarketplaces);
  }, [maxMarketplaces]);

  useEffect(() => {
    setCurrentMaxMarketplaces(maxMarketplaces);
  }, [maxMarketplaces]);

  // Create an array starting at the number of authorized marketplaces
  // up to maxPossibleMarketplaces.
  const maxPossibleMarketplaces = 125;
  const maxMarketplacesArray = Array.from(
    { length: maxPossibleMarketplaces - planMaxMarketplaces },
    (_, i) => i + numberOfAuthorizedMarketplaces
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNumber(Number(e.target.value));
  };

  const confirmUpdateMaxMarketplaces = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDisplayConfirmation(true);
  };

  const updateMaxMarketplaces = async (
    closeModal: () => void,
  ) => {
    setCurrentMaxMarketplaces(selectedNumber);
    setIsLoading(true);
    try {
      const { data } = await Api.post(
        '/stripe/update-max-marketplaces',
        {
          currentPlanProductId,
          numberOfAuthorizedMarketplaces,
          maxMarketplaces: selectedNumber,
          planMaxMarketplaces,
          subscriptionItems,
        }
      );
      setIsLoading(false);
      if (data.success && data.status === UpdateMarketplacesStatus.MAX_MARKETPLACES_UPDATED) {
        setCurrentMaxMarketplaces(data.maxMarketplaces);
        setSubscriptionItems(data.newSubscriptionItems);
        mixPanel({
          eventName: 'Change max marketplaces',
          eventProperties: {
            currentPlanProductId,
            planMaxMarketplaces,
            numberOfAuthorizedMarketplaces,
            oldMaxMarketplaces: maxMarketplaces,
            newMaxMarketplaces: data.maxMarketplaces,
          }
        });
        alertService.success('All set, max marketplaces updated successfully');
      } else {
        switch (data.status) {
          case UpdateMarketplacesStatus.MAX_MARKETPLACES_LOWER_THAN_AUTHORIZED:
            errorAlert('The number of marketplaces cannot be less than the number of already authorized marketplaces', {});
            break;
          case UpdateMarketplacesStatus.MAX_MARKETPLACES_NO_PLAN_ITEM:
            errorAlert('No plan found for your account', {});
            break;
          default:
            errorAlert('Error updating max marketplaces', {});
            break;
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      errorAlert("Error updating the number of marketplaces.", {});
    }

    closeModal();

  }

  return (
    <div
      id="max-marketplaces-select"
    >
      <p>
        You are connected to <strong>{numberOfAuthorizedMarketplaces}</strong> marketplace{numberOfAuthorizedMarketplaces === 1 ? '' : 's'} of a maximum of <strong>{currentMaxMarketplaces}</strong>.
      </p>
      <label htmlFor="marketplaces-select">Change max marketplaces to</label>
      <select
        value={selectedNumber}
        onChange={handleChange}
      >
        {maxMarketplacesArray.map(number => (
          <option key={number} value={number}>
            {number}
          </option>
        ))}
      </select>
      <Button
        disabled={isLoading || selectedNumber <= planMaxMarketplaces}
        onClick={confirmUpdateMaxMarketplaces}
        size="small"
      >
        Update
      </Button>
      <ModalButton
        openModal={displayConfirmation}
        hideButton
        closable
        onCloseText="Cancel"
        onCloseAction={() => {
          setDisplayConfirmation(false);
        }}
        modalTitle={'Update Max Marketplaces'}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              updateMaxMarketplaces(closeModal);
            }}
          >
            Confirm
          </Button>
        )}
      >
        {() => {
          return (
            <div>
              {(selectedNumber < numberOfAuthorizedMarketplaces) ? (
                <div>The number of marketplaces you are selecting is less than the number of authorized marketplaces. <a href="/account/connect-to-amazon">Disconnect from some marketplaces</a> and try again.</div>
              ) : (
                <div>Are you sure you want to update the maximum number of allowed marketplaces to <strong>{selectedNumber}</strong> {' '}?</div>
              )}
            </div>
          );
        }}
      </ModalButton>
    </div>
  );
}
