import React, {useEffect, useState, useCallback} from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
  Link,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  Stack,
  Grid,
} from '@mui/material';
import {AxiosError} from 'axios';
import {Field, Form, Formik, FormikHelpers, FormikValues} from 'formik';
import {Select, TextField} from 'formik-mui';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import {CheckCircle} from '@mui/icons-material';
import {green} from '@mui/material/colors';
import {Alert} from '@mui/lab';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {alertService} from '../../../../services/alert.service';
// components
import Button from '../../../../components/Button/Button';
import {GoogleDrive} from '../../components/GoogleDrive';
import ModalButton from '../../../../components/ModalButton/ModalButton';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {SellerCentralDirectedIdForm} from '../../components/SellerCentralDirectedIdForm';
import Spacer from '../../../../components/Spacer/Spacer';
// styles
import {sxStyles} from '../../../Account/Styles';
// interfaces
import {BrandInterface} from '../../../../interfaces/brands/brand';
import {MarketplaceInterface} from '../../../../interfaces/marketplaces/marketplaces';

const validationSchema = Yup.object().shape({
  brand_code: Yup.string().required('Brand code is required'),
  name: Yup.string().required('Name is required'),
  status_id: Yup.number().typeError('Status is required').required('Status is required'),
  seller_central_account_location: Yup.object()
    .nullable()
    .when('type_id', {
      is: 2,
      then: Yup.object()
        .typeError('The Seller Central account location is required for Max Results brands')
        .required('Seller central account location is required for Max Results brands'),
    }),
});

const marketplaceValidationSchema = Yup.object().shape({
  sp_auth_marketplace: Yup.string().required('The marketplace is required'),
});

const sellerCentralCredentialsValidationSchema = Yup.object().shape({
  email: Yup.string().required('The email is required'),
  password: Yup.string().required('The password is required'),
  merchant_id: Yup.string().required('The Seller ID is required'),
  two_step_verification_token: Yup.string().required('The 2-step verification token is required'),
});

function Channels(): JSX.Element {
  const theme = useTheme();
  const mobile = useMediaQuery('(max-width:767px)');
  const [values, setValues] = useState<BrandInterface>();
  const {id} = useParams<Record<string, string | undefined>>();
  const [displayConfirmDisconnectAdvertising, setDisplayConfirmDisconnectAdvertising] = useState(false);
  const [displaySelectMarketplaceForSPAPI, setDisplaySelectMarketplaceForSPAPI] = useState(false);
  const [amazonMarketplaces, setAmazonMarketplaces] = useState<MarketplaceInterface[]>([]);
  const [displayConfirmDisconnectSpApi, setDisplayConfirmDisconnectSpApi] = useState(false);
  const [marketplaceToDelete, setMarketplaceToDelete] = useState('');
  const [displaySellerCentralCredentialsDialog, setDisplaySellerCentralCredentialsDialog] = useState(false);
  const [displaySellerCentralDirectedIdDialog, setDisplaySellerCentralDirectedIdDialog] = useState(false);
  const [displaySellerCredentialsForm, setDisplaySellerCredentialsForm] = useState(true);
  const [directedIdInitialValues, setDirectedIdInitialValues] = useState<{[key: string]: string} | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSaveToDatabase, setIsSaveToDatabse] = useState(false);
  const [isUploadToDrive, setIsUploadToDrive] = useState(false);
  const [googleDriveFolders, setGoogleDriveFolders] = useState([]);
  const [lastSuccess, setLastSuccess] = useState([]);
  const [googleDriveLoaded, setGoogleDriveLoaded] = useState(false);

  const pullAllData = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await Api.get('sp-api/marketplaces');
      setAmazonMarketplaces(data);
      try {
        const {data} = await Api.get(`brands/${id}`);
        const brandInfo = data;
        setValues(brandInfo);
        setIsSaveToDatabse(brandInfo.is_save_to_database);
        setIsUploadToDrive(brandInfo.is_upload_to_drive);
        setGoogleDriveFolders(brandInfo.google_drive_folders || []);
        setLastSuccess(brandInfo.last_success || []);
        setGoogleDriveLoaded(true);
        if (brandInfo.seller_central_credentials) {
          setDisplaySellerCredentialsForm(false);
        }
        const newInitialValues: {[key: string]: string} = {};
        for (let i = 0; i < brandInfo.seller_central_directed_ids?.length; i++) {
          const value = brandInfo.seller_central_directed_ids[i];

          newInitialValues[`directed_id_${i}`] = value.directed_id;
          newInitialValues[`marketplace_id_${i}`] = value.marketplace_id;
        }
        setDirectedIdInitialValues(newInitialValues);
      } catch (e) {
        errorAlert('Unable to get Brand Info', e);
      } finally {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertService.error('Unable to get Amazon Marketplaces');
    }
  }, [id]);

  useEffect(() => {
    pullAllData();
  }, [pullAllData]);

  async function onSubmit(data: FormikValues, {setSubmitting}: FormikHelpers<BrandInterface>) {
    alertService.clear();
    const fields: FormikValues = {...data, status_id: +data.status_id};

    if (data.type_id === 2 && data.seller_central_account_location) {
      fields.seller_central_account_location = (
        data.seller_central_account_location as {brand_code: string}
      ).brand_code;
    }

    fields.google_drive_folders = googleDriveFolders;
    fields.last_success = lastSuccess;

    try {
      await Api.put(`brands/${id}`, fields);
      alertService.success('Brand updated successfully');
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to update brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  async function grantAdvertisingAccess() {
    try {
      const response = await Api.get(`advertising/authorization-link/${values?.brand_code}`);
      window.location.href = response.data;
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to get authorization link', e);
    }
  }

  async function connectSellingPartnerAccess(marketplaceId: string) {
    try {
      const response = await Api.get(`sp-api/authorization-link/${values?.brand_code}/${marketplaceId}`);
      window.location.href = response.data;
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to get authorization link', e);
    }
  }

  async function grantSellingPartnerAccess(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>) {
    setSubmitting(true);
    await connectSellingPartnerAccess(data?.sp_auth_marketplace);
  }

  async function submitSellerCentralCredentials(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>) {
    setSubmitting(true);
    try {
      await Api.put(`brands/${values?.brand_code}/seller-central-credentials`, {...data});
      alertService.success('Seller Central credentials correctly stored');
      pullAllData();
      setSubmitting(false);
      setDisplaySellerCentralCredentialsDialog(false);
      setDisplaySellerCentralDirectedIdDialog(true);
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to store Seller Central credentials', e);
    } finally {
      setSubmitting(false);
    }
  }

  async function confirmDisconnectAdvertisingAccess() {
    setDisplayConfirmDisconnectAdvertising(true);
  }

  function handleClose() {
    setDisplayConfirmDisconnectAdvertising(false);
  }

  async function disconnectAdvertising() {
    setDisplayConfirmDisconnectAdvertising(false);
    try {
      const response = await Api.post(`advertising/deauthorize/${values?.brand_code}`, {});

      if (response.data.ok === true) {
        alertService.success('The advertising API access was revoked.');
      }
      pullAllData();
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to revoke advertising API access', e);
    }
  }

  async function confirmDisconnectSellingPartnerAccess() {
    setDisplayConfirmDisconnectSpApi(false);
    try {
      const response = await Api.post(`sp-api/deauthorize/${values?.brand_code}/${marketplaceToDelete}`, {});
      if (response.data.ok === true) {
        alertService.success('The Selling Partner API access was revoked.');
      }
      pullAllData();
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to revoke Selling Partner API access', e);
    }
  }

  return (
    <Grid>
      <ModalButton
        openModal={displaySellerCentralDirectedIdDialog}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplaySellerCentralDirectedIdDialog(false)}
        modalTitle="Seller Central Directed IDs"
      >
        {() => {
          return values && directedIdInitialValues ? (
            <SellerCentralDirectedIdForm
              brand={values}
              marketplaces={amazonMarketplaces}
              onBack={() => {
                setDisplaySellerCentralCredentialsDialog(true);
                setDisplaySellerCentralDirectedIdDialog(false);
                pullAllData();
              }}
              initialValues={directedIdInitialValues}
            />
          ) : (
            <></>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={displaySellerCentralCredentialsDialog}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplaySellerCentralCredentialsDialog(false)}
        modalTitle="Seller Central Credentials"
      >
        {() => {
          return (
            <>
              {values && values.seller_central_credentials && displaySellerCredentialsForm === false && (
                <Alert color="warning">
                  <Typography>We already have the Seller Central credentials for this brand.</Typography>
                  <Typography>What do you want to do?</Typography>
                  <Spacer height={20} />
                  <div style={{display: 'flex'}}>
                    <Button
                      onClick={() => {
                        setDisplaySellerCredentialsForm(true);
                      }}
                      variant="outlined"
                      type="button"
                    >
                      Update Seller Central Credentials
                    </Button>
                    <Spacer width={10} />
                    <Button
                      onClick={() => {
                        setDisplaySellerCentralCredentialsDialog(false);
                        setDisplaySellerCentralDirectedIdDialog(true);
                      }}
                      variant="outlined"
                      type="button"
                    >
                      Update Directed IDs
                    </Button>
                  </div>
                </Alert>
              )}
              {displaySellerCredentialsForm ? (
                <Formik
                  initialValues={{}}
                  validationSchema={sellerCentralCredentialsValidationSchema}
                  onSubmit={submitSellerCentralCredentials}
                  validateOnChange={true}
                >
                  {({errors, touched, isSubmitting}) => (
                    <Form>
                      <FormControl
                        size="small"
                        error={touched['email' as keyof typeof touched] && !!errors['email' as keyof typeof errors]}
                        style={{width: '100%', paddingBottom: '10px'}}
                        variant={'outlined'}
                      >
                        <Field component={TextField} name="email" label="Email" />
                      </FormControl>

                      <FormControl
                        size="small"
                        error={
                          touched['password' as keyof typeof touched] && !!errors['password' as keyof typeof errors]
                        }
                        style={{width: '100%', paddingBottom: '10px'}}
                        variant={'outlined'}
                      >
                        <Field component={TextField} name="password" label="Password" />
                      </FormControl>

                      <FormControl
                        size="small"
                        error={
                          touched['merchant_id' as keyof typeof touched] &&
                          !!errors['merchant_id' as keyof typeof errors]
                        }
                        style={{width: '100%', paddingBottom: '10px'}}
                        variant={'outlined'}
                      >
                        <Field component={TextField} name="merchant_id" label="Seller ID" />
                      </FormControl>
                      <FormControl
                        size="small"
                        error={
                          touched['two_step_verification_token' as keyof typeof touched] &&
                          !!errors['two_step_verification_token' as keyof typeof errors]
                        }
                        style={{width: '100%', paddingBottom: '10px'}}
                        variant={'outlined'}
                      >
                        <Field
                          name="two_step_verification_token"
                          label="2-step verification token"
                          component={TextField}
                        />
                      </FormControl>
                      <Spacer height={15} />
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                          onClick={() => setDisplaySellerCredentialsForm(false)}
                          size="large"
                          disabled={isSubmitting}
                        >
                          Back
                        </Button>
                        <Spacer width={10} />
                        <Button size="large" type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                      <Spacer height={20} />
                    </Form>
                  )}
                </Formik>
              ) : null}
            </>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={displayConfirmDisconnectAdvertising}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={handleClose}
        modalTitle="Are you sure you want to disconnect the Advertising API access?"
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              disconnectAdvertising();
              closeModal();
            }}
          >
            Agree
          </Button>
        )}
      >
        {() => {
          return (
            <Typography gutterBottom>
              This cannot be undone. After the API access is disconnected, Stonehenge will no longer pull the
              advertising information from this brand until the access is granted again.
            </Typography>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={displaySelectMarketplaceForSPAPI}
        hideButton
        closable
        onCloseText="Close"
        onCloseAction={() => setDisplaySelectMarketplaceForSPAPI(false)}
        modalTitle="Please select the marketplace that you want to connect"
      >
        {() => {
          return (
            <Formik
              initialValues={
                {
                  sp_auth_marketplace: 'ATVPDKIKX0DER',
                } as FormikValues
              }
              validationSchema={marketplaceValidationSchema}
              onSubmit={grantSellingPartnerAccess}
              validateOnChange={true}
              enableReinitialize={true}
            >
              {({errors, touched, isSubmitting, values: formValues}) => (
                <Form>
                  <Grid container>
                    {values?.sp_api_authorized_marketplaces?.find(
                      (marketplace) => marketplace.marketplace_id === formValues.sp_auth_marketplace,
                    ) && (
                      <Alert color="warning">
                        <Typography>
                          This brand has already granted us Selling Partner API authorization for this marketplace.
                          Authorizing this marketplace again would overwrite the information from the previous
                          authorization.
                        </Typography>
                      </Alert>
                    )}
                    <Spacer height={30} />
                    <FormControl
                      size="small"
                      error={touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']}
                      style={{width: '100%', paddingTop: '30px'}}
                      variant={'outlined'}
                    >
                      <Field
                        component={Select}
                        name="sp_auth_marketplace"
                        label="Marketplace"
                        labelId="sp-marketplace-label"
                      >
                        {amazonMarketplaces.map((marketplace) => (
                          <MenuItem key={`marketplace_${marketplace.marketplaceId}`} value={marketplace.marketplaceId}>
                            {marketplace.name} ({marketplace.countryCode})
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText>
                        {touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']
                          ? errors['sp_auth_marketplace']
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Spacer height={30} />
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Button size="large" type="submit" disabled={isSubmitting}>
                      Authorize
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={displayConfirmDisconnectSpApi}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplayConfirmDisconnectSpApi(false)}
        modalTitle="Are you sure you want to disconnect the Selling Partner API access to this marketplace?"
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              confirmDisconnectSellingPartnerAccess();
              closeModal();
            }}
          >
            Agree
          </Button>
        )}
      >
        {() => {
          return (
            <Typography gutterBottom>
              This cannot be undone. After the Selling Partner API access is disconnected, Stonehenge will no longer
              pull the brand&apos;s information from this marketplace until the access is granted again.
            </Typography>
          );
        }}
      </ModalButton>
      {loading || !values ? (
        <Grid item lg={12} style={mobile ? {padding: '30px 0'} : {paddingBottom: 30}}>
          <Typography>Loading Information ...</Typography>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent="center" style={{paddingTop: 30}}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" color="primary" gutterBottom>
              Channels
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {values ? (
              <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({isSubmitting, values: formValues, setFieldValue, submitForm}) => {
                  return (
                    <Form>
                      <Grid container>
                        {formValues.type_id === 1 && (
                          <Grid container spacing={2}>
                            <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
                              <Card variant="outlined" sx={sxStyles('connectorCard')}>
                                <CardHeader title="Amazon API Connections" />
                                <CardContent>
                                  <Typography
                                    component="h6"
                                    color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                                    gutterBottom
                                  >
                                    Selling Partner API:
                                  </Typography>
                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {values.selling_partner_api_access ? <div style={{display: 'flex'}}>
                                      <CheckCircle style={{color: green[500]}} />
                                      <Spacer width={7} />
                                      <Typography style={{color: green[500]}}>Connected</Typography>
                                    </div>:<Typography color="error">Disconnected</Typography>}
                                    <Divider />
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <Typography
                                        component="h6"
                                        color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                                        gutterBottom
                                      >
                                        Marketplaces:
                                      </Typography>
                                      {amazonMarketplaces.map((marketplace) => (
                                        <Stack
                                          flexDirection={'row'}
                                          justifyContent="space-between"
                                          key={`mp_${marketplace.marketplaceId}`}
                                        >
                                          <Typography>{marketplace.name}</Typography>
                                          {values?.sp_api_authorized_marketplaces?.find(
                                            (x) => x.marketplace_id === marketplace.marketplaceId,
                                          ) ? (
                                            <Link
                                              style={{cursor: 'pointer'}}
                                              onClick={() => {
                                                setMarketplaceToDelete(marketplace.marketplaceId);
                                                setDisplayConfirmDisconnectSpApi(true);
                                              }}
                                            >
                                              Disconnect
                                            </Link>
                                          ) : (
                                            <Link
                                              style={{cursor: 'pointer'}}
                                              onClick={() => {
                                                connectSellingPartnerAccess(marketplace.marketplaceId);
                                              }}
                                            >
                                              Connect
                                            </Link>
                                          )}
                                        </Stack>
                                      ))}
                                    </div>
                                  </div>
                                  <Spacer height={10} />
                                  <Divider />
                                  <Typography
                                    component="h6"
                                    color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                                    gutterBottom
                                  >
                                    Advertising API access:
                                  </Typography>
                                  {values.advertising_access ? (
                                    <div style={{display: 'flex'}}>
                                      <CheckCircle style={{color: green[500]}} />
                                      <Spacer width={7} />
                                      <Typography style={{color: green[500]}}>Connected</Typography>
                                    </div>
                                  ) : (
                                    <Typography color="error">Disconnected</Typography>
                                  )}
                                  <Spacer height={10} />
                                  <div>
                                    {values.advertising_access ? (
                                      <Button color="primary" onClick={confirmDisconnectAdvertisingAccess} size="small">
                                        Revoke advertising access
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={grantAdvertisingAccess}
                                        size="small"
                                      >
                                        Grant advertising access
                                      </Button>
                                    )}
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
                              <Card variant="outlined" sx={sxStyles('connectorCard')}>
                                <CardHeader title="Seller Central Credentials" />
                                <CardContent>
                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography
                                      component="h6"
                                      color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                                      gutterBottom
                                    >
                                      Seller Central Credentials:
                                    </Typography>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() => setDisplaySellerCentralCredentialsDialog(true)}
                                      size="small"
                                    >
                                      Update Seller Central Credentials
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item lg={12} md={7} sm={12} xs={12}>
                          <Grid item lg={12}>
                            <Typography component="h2" variant="h6" color="secondary" style={{paddingTop: '20px'}}>
                              Brand Reports
                            </Typography>
                            <FormGroup row={true}>
                              <FormControlLabel
                                key="isSaveToDatabase"
                                control={
                                  <Checkbox
                                    checked={isSaveToDatabase}
                                    onChange={(e) => {
                                      setIsSaveToDatabse(e.target.checked);
                                      values.is_save_to_database = e.target.checked;
                                      submitForm();
                                    }}
                                  />
                                }
                                label="Save To Database"
                              ></FormControlLabel>
                              <FormControlLabel
                                key="isUploadToDrive"
                                control={
                                  <Checkbox
                                    checked={isUploadToDrive}
                                    onChange={(e) => {
                                      setIsUploadToDrive(e.target.checked);
                                      values.is_upload_to_drive = e.target.checked;
                                      submitForm();
                                    }}
                                  />
                                }
                                label="Upload To Drive"
                              ></FormControlLabel>
                            </FormGroup>
                          </Grid>
                        </Grid>
                        <Spacer height={30} />
                        {values?.is_upload_to_drive && <Grid item lg={12}>
                          <Grid item lg={12}>
                            <Typography component="h2" variant="h6" color="secondary">
                              Google Drive
                            </Typography>
                            <Spacer height={20} />
                            {googleDriveLoaded && values.google_drive_folders && values.last_success && (
                              <GoogleDrive
                                brandReports={values.google_drive_folders}
                                lastSuccess={values.last_success}
                                brandCode={values.brand_code}
                                onSubmit={submitForm}
                                setValues={setFieldValue}
                                keyValue="google_drive_folders"
                                isSubmitting={isSubmitting}
                              />
                            )}
                          </Grid>
                        </Grid>}
                        <Grid item xs={12}>
                          <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export {Channels};
