import React, {useState, useEffect, useRef, memo} from 'react';
import {Moment} from 'moment';
import {DateRange, Range, DateRangeProps} from 'react-date-range';
import {renderToString} from 'react-dom/server';
import moment from 'moment';
import {Box, useTheme} from '@mui/material';
// styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {sxStyles} from './Styles';
interface DateRangePickerProps {
  onChange: (dateRange: {
    selection: {
      startDate: Date | string | number | Moment;
      endDate: Date | string | number | Moment;
    };
  }) => void;
  disabled?: boolean;
  from: Date | string | number | Moment;
  to: Date | string | number | Moment;
  width?: string;
  customPicker?: boolean;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default memo(function DateRangePicker({
  onChange,
  disabled,
  from,
  to,
  width,
  customPicker,
  open,
  setOpen,
}: DateRangePickerProps): JSX.Element {
  const theme = useTheme();

  const datePickerRef = useRef(null);

  const [showDatePicker, setShowDatePicker] = useState(customPicker);
  const [selectedValue, setSelectedValue] = useState<{
    selection: {
      startDate: Date | string | number | Moment;
      endDate: Date | string | number | Moment;
    };
  } | null>();
  const [state, setState] = useState<Range[]>([
    {
      startDate: moment(from).toDate(),
      key: 'selection',
      endDate: moment(to).toDate(),
    },
  ]);

  useEffect(() => {
    const sendData = () => {
      if (selectedValue) {
        onChange(selectedValue);
        setOpen?.(false);
        if (!customPicker) setShowDatePicker(false);
        setSelectedValue(null);
      }
    };

    const cancel = () => {
      setShowDatePicker(false);
      setSelectedValue(null);
      setOpen?.(false);
      setState([
        {
          startDate: moment(from).toDate(),
          key: 'selection',
          endDate: moment(to).toDate(),
        },
      ]);
    };

    if (!customPicker) {
      const id = 'custom-date-range';
      const idTwo = 'cancel-button';
      const containerId = 'date-range-btn-container';
      document.getElementById(id)?.remove();
      document.getElementById(idTwo)?.remove();
      document.getElementById(containerId)?.remove();

      const selectDate = <button color="primary">Select Date</button>;
      const cancelButton = <button color="primary">Cancel</button>;
      const selectDateElement = document.createElement('div');
      const cancelButtonElement = document.createElement('div');
      const container = document.createElement('div');
      const staticElement = renderToString(selectDate); // eslint-disable-line
      const staticElementTwo = renderToString(cancelButton); // eslint-disable-line
      selectDateElement.innerHTML = staticElement;
      cancelButtonElement.innerHTML = staticElementTwo;
      selectDateElement.id = id;
      cancelButtonElement.id = idTwo;
      selectDateElement.onclick = sendData;
      cancelButtonElement.onclick = cancel;
      selectDateElement.className = 'date-range-button';
      cancelButtonElement.className = 'date-range-button';
      container.id = containerId;
      container.className = 'date-range-button-container';
      if (!document.getElementById(id)) {
        container.appendChild(selectDateElement);
      }
      if (!document.getElementById(idTwo)) {
        container.appendChild(cancelButtonElement);
      }
      const row = document.getElementsByClassName(`rdrMonthsVertical`)[0];
      if (!document.getElementById(containerId) && row) {
        row?.appendChild(container);
      }
    }
  }, [selectedValue, customPicker, from, to, onChange, setOpen]);

  useEffect(() => {
    setState([
      {
        startDate: moment(from).toDate(),
        key: 'selection',
        endDate: moment(to).toDate(),
      },
    ]);
  }, [from, to]);

  useEffect(() => {
    setShowDatePicker(open);
  }, [open]);

  useEffect(() => {
    if (!customPicker) {
      const button = document.getElementsByClassName('rdrDateInput');
      for (let i = 0; i < button.length; i++) {
        button[i]?.addEventListener('click', () => {
          setShowDatePicker((p) => !p);
          setOpen?.((p) => !p);
        });
      }
    } else {
      setShowDatePicker(true);
      setOpen?.(true);
    }
  }, [customPicker, setOpen]);

  interface RangeProps extends DateRangeProps {
    readOnly: boolean;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }

  const customDateRange = (props: RangeProps) => {
    return <DateRange {...props} />;
  };

  return (
    <Box
      style={{maxWidth: width, width: '100%'}}
      className={`${disabled && 'disabled'}`}
      sx={sxStyles(theme).container}
      ref={datePickerRef}
    >
      {customDateRange({
        readOnly: true,
        onKeyDown: (e) => e.preventDefault(),
        onClick: () => setShowDatePicker(true),
        ranges: state,
        maxDate: moment().toDate(),
        rangeColors: theme.palette.mode === 'dark' ? ['#618299'] : ['#174582'],
        color: theme.palette.mode === 'dark' ? '#618299' : '#174582',
        className: `customDateRangePicker ${showDatePicker ? 'show' : ''} custom-date-range`,
        editableDateInputs: true,
        onChange: (item): void => {
          setState([item.selection]);
          setSelectedValue(item as {selection: {startDate: Date; endDate: Date}});
        },
      })}
    </Box>
  );
});
