import React from 'react';
import {styled} from '@mui/system';
import {Grid, Typography} from '@mui/material';
// components
import {CustomAlert} from '../Login/components/CustomAlert';
import {AsinLookupWidget} from '../Asins/subpages/AsinDetails/AsinLookupWidget';
import {WatchlistWidget} from './components/WatchlistWidget';
import {NewBrandsWidget} from './components/NewBrandsWidget';
import {TopBrandsWidget} from './components/TopBrandsWidget';
import {TopAsinsWidget} from './components/TopAsinsWidget';
import {AmazonAuditAuthorize} from './components/AmazonAuditAuthorize';
import Images from '../../components/Images/Images';
// styles
import {useStyles} from './Styles';
// services
import {accountService} from '../../services/account.service';
// utils
import {Role} from '../../utils/role';

const Container = styled(Grid)(({theme}) => ({
  padding: '30px 25px',
  maxWidth: '1300px',
  '& .MuiGrid-item > div:not(:first-of-type)': {
    marginTop: '10px',
  },
  '& .MuiDataGrid-cell--withRenderer p': {
    fontSize: '14px',
  },
  [theme.breakpoints.down(1220)]: {
    '&.MuiGrid-root': {
      justifyContent: 'center',
    },
  },
  [theme.breakpoints.down(1200)]: {
    '& .MuiGrid-item': {
      width: '50%',
    },
  },
  [theme.breakpoints.down(1024)]: {
    padding: '30px 15px',
    '& .MuiGrid-item': {
      width: '100%',
    },
  },
}));

function Dashboard(): JSX.Element {
  const classes = useStyles;
  document.title = 'Dashboard';

  function renderSwitch(brandType: string) {
    const isSubBrandManager =
      accountService.userValue.role === Role.BrandUser &&
      accountService.userValue.sub_brand_codes &&
      accountService.userValue.sub_brand_codes.length > 0;

    switch (brandType) {
      case 'mc':
      case 'mr':
        return (
          <>
            <WatchlistWidget classes={classes} />
            {isSubBrandManager ? null : <TopAsinsWidget classes={classes} />}
          </>
        );
      case 'AMAZON_AUDIT':
        return <AmazonAuditAuthorize />;
      case 'ffp': // This page will need to display something new, as now the SP API will be authorized from the "Account" page
      // return <AuthorizeSpApiForFFPBrand />;
      default:
        return '';
    }
  }

  return ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type as string) ? (
    <Grid container style={{margin: '50px 0'}} display="flex" justifyContent="center">
      <div style={classes.grid}>
        <Images width="100%" maxWidth="350px" name="Subscriber" />
        <Typography variant="h6">
          Your subscription is inactive.
          <br /> Please contact your administrator to renew your subscription
        </Typography>
      </div>
    </Grid>
  ) : (
    <>
      {accountService.userValue.role !== Role.BrandUser ? (
        <Container container spacing={1}>
          <Grid item lg={6} sm={10} xs={12}>
            <WatchlistWidget classes={classes} />
            <NewBrandsWidget classes={classes} />
          </Grid>
          <Grid item lg={6} sm={10} xs={12}>
            <AsinLookupWidget classes={classes} />
            <TopBrandsWidget classes={classes} />
            <TopAsinsWidget classes={classes} />
          </Grid>
        </Container>
      ) : (
        <Grid container spacing={1} justifyContent="center" style={{paddingTop: 30}}>
          <Grid item lg={12} xs={12}>
            {renderSwitch(accountService.userValue?.brand_type as string)}
          </Grid>
        </Grid>
      )}
      <CustomAlert id="default-alert" />
    </>
  );
}

export {Dashboard};
