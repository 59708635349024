import React, {useEffect, useState} from 'react';
import {Tab, Tabs, Grid, useTheme} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import {useHistory, useParams, Link} from 'react-router-dom';
// services
import {accountService} from '../../../../services/account.service';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import CustomTabs from '../../../../components/CustomTabs/CustomTabs';
import ProductDetails from '../../../ProductDetails/ProductDetails';
import AmazonListingsItemActivity from '../AmazonListingsItemActivity/AmazonListingsItemActivity';
import CustomAttributes from '../../../ProductDetails/subpages/CustomAttributes/CustomAttributes';
import Button from '../../../../components/Button/Button';

import ListingForm from '../../components/ListingForm';

const tabNames = [
  {tabKey: 'edit', tabLabel: 'Edit'},
  {tabKey: 'activity', tabLabel: 'Activity'},
];

function a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function AmazonListingsItem(): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  const user = accountService.userValue;
  const {marketplaceId, sellerId, sku, tabValue} = useParams<Record<string, string>>();
  const [value, setValue] = useState(tabValue);

  document.title = `Listing SKU: ${sku}`;

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const navigateTo = (tabName: string) => {
    history.push(`/amazon-listings-item/${marketplaceId}/${sellerId}/${sku}/${tabName}`);
  };

  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <TabContext value={value}>
        <CustomTabs position={'static'} color="default">
          <Tabs
            value={value}
            onChange={(e, v) => handleChange(v)}
            indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
            textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
            variant="scrollable"
            scrollButtons="auto"
            style={{justifyContent: 'center'}}
          >
            {tabNames.map(({tabKey, tabLabel}, idx) => (
              <Tab
                label={tabLabel}
                value={tabKey}
                key={tabKey}
                {...a11yProps(idx)}
                onClick={() => navigateTo(tabKey)}
              />
            ))}
            {user.brand_type !== 'ffp' && (
              <Tab
                label="Custom Attributes"
                value="custom_attributes"
                onClick={() => navigateTo('custom_attributes')}
              />
            )}
          </Tabs>
        </CustomTabs>
        <TabPanel value={tabValue} style={{padding: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
          <TableContainer>
            {tabValue === 'edit' && (
              <ListingForm
                marketplaceId={marketplaceId}
                sellerId={sellerId}
                sku={sku}
              />
            )}
            {tabValue === 'activity' && <AmazonListingsItemActivity />}
            {accountService.userValue.brand_type !== 'ffp' && tabValue === 'custom_attributes' && (
              <Grid container>
                {accountService.userValue.role === 'admin' && (
                  <Grid container justifyContent={'flex-end'}>
                    <Link to={`/custom-attributes?marketplace=${marketplaceId}&sku=${sku}`}>
                      <Button>Add Custom Attributes</Button>
                    </Link>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <ProductDetails />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} style={{paddingTop: '40px'}}>
                  <CustomAttributes />
                </Grid>
              </Grid>
            )}
          </TableContainer>
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export {AmazonListingsItem};
