import React, {useEffect, useState, useCallback} from 'react';
import {Box, Typography, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import moment from 'moment';
import {CSVLink} from 'react-csv';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import {BuildMenu} from '../../components/menu';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
// utils
import {Api, errorAlert} from '../../../../utils/api';

const columns: GridColDef[] = [
  {
    field: 'seller_sku',
    width: 200,
    headerName: 'SKU',
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    renderCell: (params: GridCellParams) => {
      if (params.row.date) {
        return <Typography variant="subtitle1">{moment(params.row.date).format('MM/DD/YYYY HH:mm')}</Typography>;
      } else {
        return <Typography variant="subtitle1">N/A</Typography>;
      }
    },
  },
  {
    field: 'doi',
    headerName: 'DOI',
    type: 'number',
    width: 120,
    align: 'right',
    renderCell: (params: GridCellParams) => {
      return <Typography>{params.row.doi ? Number(params.row.doi).toFixed(2) : 'N/A'}</Typography>;
    },
  },
  {
    field: 'average_daily_sales',
    headerName: 'Average Daily Sales',
    type: 'number',
    align: 'right',
    width: 120,

    renderCell: (params: GridCellParams) => {
      return (
        <Typography>
          {params.row.average_daily_sales ? Number(params.row.average_daily_sales).toFixed(2) : 'N/A'}
        </Typography>
      );
    },
  },
  {
    field: 'sales_last_30_days',
    headerName: 'Sales Last 30 days',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'units_sold_last_30_days',
    headerName: 'Units Sold Last 30 Days',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'total_units',
    headerName: 'Total Units',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'inbound',
    headerName: 'Inbound',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'available',
    headerName: 'Available',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'fc_transfer',
    headerName: 'FC Transfer',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'fc_processing',
    headerName: 'FC Processing',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'customer_order',
    headerName: 'Customer Order',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'unfulfillable',
    headerName: 'Unfulfillable',
    type: 'number',
    align: 'right',
    width: 120,
  },
  {
    field: 'recommended_replenishment_qty',
    headerName: 'Recommended Replenishment Quantity',
    type: 'number',
    align: 'right',
    width: 120,
    flex: 1,
  },
  {
    field: 'recommended_ship_date',
    headerName: 'Recommended Ship Date',
    align: 'center',
    width: 150,
    renderCell: (params: GridCellParams) => {
      if (params.row.recommended_ship_date) {
        return (
          <Typography variant="subtitle1">
            {moment(params.row.recommended_ship_date).format('MM/DD/YYYY HH:mm')}
          </Typography>
        );
      } else {
        return <Typography variant="subtitle1">N/A</Typography>;
      }
    },
  },
];

const csvHeaders = columns.map((column) => {
  return {
    key: column.field,
    label: column.headerName || column.field,
  };
});

function BrandInventory(): JSX.Element {
  const history = useHistory();

  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const loadData = useCallback(async () => {
    setLoadingData(true);
    try {
      const {data} = await Api.get(`brand-inventory/${id}`);
      setRows(
        data.map((item: {seller_sku: string}) => {
          return {
            ...item,
            id: item.seller_sku,
          };
        }),
      );
    } catch (e) {
      errorAlert('Unable to get brand inventory', e);
    } finally {
      setLoadingData(false);
    }
  }, [id]);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = BuildMenu('inventory', id as string, history);
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [id, history, loadData]);

  return (
    <TableContainer className="sub-page">
      <Grid item xs={12} style={{justifyContent: 'right', display: 'flex'}}>
        <CSVLink
          filename={`${id} Inventory Report ${moment().format('YYYY-MM-DD')}.csv`}
          data={rows}
          headers={csvHeaders.map((header) => header.label)}
        >
          <Box ml={2} padding={'0 0 10px 0'}>
            <Button variant="contained" color="primary" size="small" disabled={loadingData}>
              Export to CSV
            </Button>
          </Box>
        </CSVLink>
      </Grid>
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <Grid container spacing={1}>
          <Grid item lg={12} style={{width: '100%'}}>
            <DataGrid
              disableColumnSelector={false}
              autoHeight={true}
              hideFooterPagination={true}
              rows={rows}
              columns={columns}
            />
            <CustomAlert id="default-alert" />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export {BrandInventory};
