import React from 'react';
import {Grid} from '@mui/material';
// components
import Images from '../../../components/Images/Images';

const sxStyles = {
  logoImg: {
    '& > img': {
      width: '100%',
      maxWidth: '12em',
      margin: '1em 0',
    },
  },
};

export default function LogoComponent(): JSX.Element {
  return (
    <Grid container marginBottom={2}>
      <Grid item xs={12} lg={12} sx={sxStyles.logoImg}>
        <Images name={'Logo'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} sx={sxStyles.logoImg}>
        <Images name={'FFP_Logo'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} sx={sxStyles.logoImg}>
        <Images name={'ECOM3K'} />
      </Grid>
    </Grid>
  );
}
