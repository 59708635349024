import React, {useEffect, useState, useCallback} from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {Grid, Typography, Divider, ListItem, ListItemText, ListItemIcon} from '@mui/material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

import {
  Error,
  Warning,
  // CheckCircle,
  DateRange,
  Person,
  Email,
  History,
  Code,
  Public
} from '@mui/icons-material/';

import {getMarketplaceById} from '@bizon/amazon-ids';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {rowsPerPageOptions} from '../../../../utils/constants';
// components
import CollapsibleDataGrid from '../../../../components/CollapsibleDataGrid/CollapsibleDataGrid';
import Icons from '../../../../components/Icons/Icons';
import { CustomAlert } from '../../../Login/components/CustomAlert';
import DiffList from '../../components/DiffList';
// styles
import {sxStyles} from '../../Styles';

import { VersionInterface } from '../../interfaces/interfaces';

export interface ActivitiesInterface {
  id: number;
  created_at: string;
  issues: {
    severity: string;
    code: string;
    message: string;
    attributeNames: string[];
  }[];
  marketplace_id: string;
  new_version: string;
  seller_id: string;
  sku: string;
  status: string;
  // submissionId: string;
  version: string;
  user_id: string;
  user: {
    email: string;
  };
}

interface ActivitiesData {
  rows: ActivitiesInterface[];
  count: number;
  versionRows: {
    [version: string]: VersionInterface;
  };
}

export default function AmazonListingsItemActivity(): JSX.Element {
  const filter = null;
  const sort = null;
  const {marketplaceId, sku, sellerId} = useParams<Record<string, string | undefined>>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(40);
  const [listingRows, setListingRows] = useState<ActivitiesInterface[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<string | number | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Update Time',
      flex: 2,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams): JSX.Element => (
        <Typography>{moment(params.row.created_at).format('YYYY-MM-DD HH:mm:ss')}</Typography>
      ),
    },
    {
      field: 'differences',
      headerName: 'Differences',
      flex: 10,
      filterable: false,
      sortable: false,
      renderCell: (params: GridCellParams): JSX.Element => {
        return versionDetails(params.row.currentVersion, params.row.previousVersion) || <span>No differences available.</span>;

      },
      disableColumnMenu: true,
    },
    {
      field: 'marketplace_id',
      headerName: 'MarketPlace',
      flex: 1,
      filterable: false,
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams): JSX.Element => {
        const getCode = params.row.marketplace_id ? getMarketplaceById(params.row.marketplace_id) : null;
        const code = getCode ? getCode.code : null;
        return (
          <Typography sx={code ? sxStyles('iconFlag') : undefined}>
            {code ? <Icons name={code.toUpperCase()} tooltip /> : 'N/A'}
          </Typography>
        );
      },
    },
    {
      field: 'user_id',
      headerName: 'User',
      flex: 2,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams): JSX.Element => {
        return <Typography>{params.row.user?.email || 'N/A'}</Typography>;
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   // width: 180,
    //   flex: 1,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true
    // },
    // {
    //   field: 'issues',
    //   headerName: 'Issues',
    //   flex: 1,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params: GridCellParams): JSX.Element => {
    //     return <Typography>{params.row.issues?.length || 0}</Typography>;
    //   },
    // },
  ];

  const getDefaultQuery = useCallback(() => {
    const params = {filter: null, page, pageSize, sort};
    return params;
  }, [page, pageSize, sort]);

  const loadData = useCallback(
    async (params) => {
      setLoadingData(true);
      setSelectedRow(null);
      try {
        const {
          data,
        }: {
          data: ActivitiesData;
        } = await Api.get(`amazon-listings/user-activity?seller_id=${sellerId}&marketplace_id=${marketplaceId}&sku=${sku}`, {
          params,
        });
        setCount(data.count);
        const listingRows = data.rows.map((x, i) => {
          return {
            ...x,
            currentVersion : data.versionRows[x.new_version] ? data.versionRows[x.new_version] : null,
            previousVersion : data.versionRows[x.version] ? data.versionRows[x.version] : null,
            id: i
          };
        });
        setListingRows(listingRows);
      } catch (e) {
        errorAlert('Unable to load data', e);
      } finally {
        setLoadingData(false);
      }
    },
    [marketplaceId, sellerId, sku],
  );

  const versionDetails = (version: VersionInterface, prevVersion: VersionInterface) => {
    if (!version || !prevVersion) return;
    return <DiffList version={version} prevVersion={prevVersion} styles={sxStyles} />;
  };

  function handleChangePage(page: number) {
    setPage(page);
    loadData({filter, page: page, pageSize, sort});
  }

  function handleChangePageSize(pageSize: number) {
    setPageSize(pageSize);
    setPage(0);
    loadData({filter, page: 0, pageSize, sort});
  }

  const activityData = {
    // status: {label: 'Status', icon: <CheckCircle />},
    created_at: {label: 'Created At', icon: <DateRange />},
    version: {label: 'Version', icon: <History />},
    new_version: {label: 'New Version', icon: <History />},
    marketplace_id: {label: 'MarketPlace', icon: <Public />},
    seller_id: {label: 'Seller ID', icon: <Person />},
    user_id: {label: 'User ID', icon: <Person />},
    user: {label: 'User Email', icon: <Email />},
    sku: {label: 'SKU', icon: <Code />},
    // submissionId: {label: 'Submission ID', icon: <Code />},
  };

  const issueDetails = (index: number | string) => (
    <div>
      {listingRows[+index]?.status && listingRows[+index]?.status === 'INVALID' ? (
        listingRows[+index]?.issues.map((issue, i) => {
          return (
            <div
              className={issue.severity === 'ERROR' ? 'issue-error' : 'issue-warning'}
              key={issue.severity + '_' + i}
            >
              <Typography style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                {issue.severity === 'ERROR' ? <Error /> : <Warning />} {issue.severity} {issue.code}
              </Typography>
              <Typography>{issue.message}</Typography>
              <Divider style={{margin: '20px 0'}} />
            </div>
          );
        })
      ) : (
        <div>
          {listingRows[+index] &&
            Object.entries(listingRows[+index]).map(([k]) => {
              const key = k as keyof typeof activityData;
              let data;
              if (typeof listingRows[+index][key] === 'string') {
                data = listingRows[+index][key] as string;
              } else if (typeof listingRows[+index][key] === 'number') {
                data = listingRows[+index][key];
              } else if (listingRows[+index][key] instanceof Array) {
                data = listingRows[+index][key];
              } else {
                data = listingRows[+index][key];
              }
              if (key === 'user') {
                data = listingRows[+index]?.[key].email;
              }
              if (key === 'marketplace_id') {
                const code = getMarketplaceById(listingRows[+index]?.[key])?.code;
                data = code ? (
                  <span style={{display: 'flex', alignItems: 'center'}}>
                    <Icons name={code.toUpperCase()} tooltip /> {code.toUpperCase()}
                  </span>
                ) : (
                  code
                );
              }
              if (key === 'created_at') {
                data = moment(listingRows[+index]?.[key]).format('YYYY-MM-DD HH:mm:ss');
              }
              return (
                activityData[key] &&
                listingRows[+index].new_version && (
                  <ListItem key={key} className="activity-details">
                    <ListItemIcon>{activityData[key]?.icon}</ListItemIcon>

                    <ListItemText primary={activityData[key]?.label} secondary={data} />
                  </ListItem>
                )
              );
            })}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const defaultQuery = getDefaultQuery();
    loadData(defaultQuery);
  }, [getDefaultQuery, loadData]);
  return (
    <Grid container spacing={4} justifyContent="center" sx={sxStyles('activityIssues')}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CollapsibleDataGrid
          className="custom-table"
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          disableSelectionOnClick={true}
          onPageChange={(params) => handleChangePage(params)}
          onPageSizeChange={(v) => handleChangePageSize(v)}
          rows={listingRows}
          columns={columns}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          accordionContent={issueDetails(selectedRow || 0)}
          rowClassName="hover-row"
          clickable={true}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </Grid>
  );
}
