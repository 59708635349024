import mixpanel from 'mixpanel-browser';

// services
import {accountService} from './account.service';

// interfaces
import {BrandInterface} from '../interfaces/brands/brand';
import {User} from '../pages/Users/Users';

export default function mixPanel({
  eventName,
  eventProperties = {},
  registrationId,
}: {
  eventName?: string;
  eventProperties?:
    | BrandInterface
    | {
        [key: string]:
          | number
          | string
          | boolean
          | User[]
          | null
          | undefined
          | Date
          | string[]
          | BrandInterface
          | BrandInterface[];
      };
  registrationId?: string;
}): void {
  const user = accountService.userValue;
  const properties = {
    user_id: user?.userId,
    email: user?.email,
    brand_code: user?.brand_code,
    // name: user?.name,
    role: user?.role,
    active_subscription: user?.active_brand_subscription,
    brand_name: user?.brand_name,
  };

  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN as string,
    {
      ignore_dnt: true,
      cross_subdomain_cookie: true
    }
  );

  mixpanel.identify(registrationId ? registrationId : user?.email);

  if (eventProperties) {
    mixpanel.people.set({...properties, ...eventProperties});
  }

  if (eventName) {
    mixpanel.track(eventName, {...properties, ...eventProperties});
  }

}
