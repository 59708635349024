import React, {Key} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
// interfaces
import {ListingsInterface} from '../../pages/Listings/interfaces/interfaces';

interface CustomDataGridProps {
  columns: GridColDef[];
  rows: ListingsInterface['rows'];
}

export default function CustomDataGrid({columns, rows}: CustomDataGridProps): JSX.Element {
  return (
    <TableContainer component={Paper} className="custom-datagrid">
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id as Key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              {columns.map((column, i) => (
                <TableCell
                  key={`${row.id}-${i}`}
                  component="th"
                  scope="row"
                  align={column.align || 'left'}
                  style={{flex: column.flex || 0, minWidth: column.minWidth || 100}}
                >
                  {column?.renderCell
                    ? column?.renderCell({
                        row: row,
                        field: column.field,
                        value: row[column.field as keyof typeof row],
                      } as GridRenderCellParams)
                    : row[column.field as keyof typeof row]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
