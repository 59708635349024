import React, {useEffect, useRef, useMemo} from 'react';
import * as Yup from 'yup';
import {useHistory, useLocation} from 'react-router-dom';
// components
import Logo from '../../assets/images/ECOM3K_logo.png';
import ECOM3KRegistrationView from '../../components/LoginView/ecom3kRegistrationView';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {withTracker} from '../../utils/withTracker';
import {Api} from '../../utils/api';
// services
import mixPanel from '../../services/mixpanel';
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
import {AxiosError} from 'axios';
import {FormikHelpers, FormikValues} from 'formik';
// styles
import {sxStyles} from './Styles';
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: {action: string}) => Promise<string>;
    };
  }
}

function ECOM3KRegistration(): JSX.Element {
  const history = useHistory();

  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  document.title = 'ECOM3K Registration';

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/account/connect-to-amazon');
    }
  }, [history]);

  useEffect(() => {
    if (urlParams.get('couponCode')) {
      window.localStorage.setItem('couponCode', urlParams.get('couponCode') as string);
    }
    if (urlParams.get('distinct_id')) {
      mixPanel({
        eventName: 'ECOM3K Registration Page View',
        registrationId: urlParams.get('distinct_id') as string,
      });
    }
  }, [urlParams]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  const initialValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    captcha: '',
  };
  const formRef = useRef(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        'Email not valid',
      ),
    brandName: Yup.string().required('Brand name is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
    passwordConfirmation: Yup.string()
      .required('Please retype your password')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  async function onSubmit(data: FormikValues, {setSubmitting}: FormikHelpers<FormikValues>): Promise<void> {
    setSubmitting(true);
    alertService.clear();
    alertService.info('Creating account ...');
    try {
      return await new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string, {action: 'submit'})
            .then(async (token: string) => {
              if (token) {
                try {
                  const response = await Api.post('users/sign-up/ecom3k?ecom3kRegistrationComplete', {
                    email: data.email.toLowerCase(),
                    password: data.password,
                    brandName: data.brandName,
                    recatpchaToken: token,
                  });
                  if (response?.statusText === 'Created' && response.data.id) {
                    alertService.success('Account created succesfully. Signing in now...', {autoClose: false});
                    mixPanel({
                      eventName: 'ECOM3K Registration Complete',
                      registrationId: data.email.toLowerCase(),
                    });
                    withTracker('Submit', 'ECOM3K Registration Complete', data.brandName);
                    setTimeout(
                      () =>
                        accountService
                          .login(data.email, data.password)
                          .then(() => {
                            if (urlParams.get('couponCode')) {
                              history.push('/account/billing?couponCode=' + urlParams.get('couponCode')?.toString());
                            } else {
                              history.push('/account/connect-to-amazon');
                            }
                            setSubmitting(false);
                          })
                          .catch((error) => {
                            setSubmitting(false);
                            if (error.response && error.response.status === 401) {
                              reject('Email or password is incorrect');
                            } else {
                              reject(error.message);
                            }
                          }),
                      3000,
                    );
                  }
                } catch (e) {
                  const error = e as AxiosError;
                  reject(error?.response?.data?.message || error);
                } finally {
                  setSubmitting(false);
                }
              }
            });
        });
      });
    } catch (e) {
      const error = e as AxiosError;
      setSubmitting(false);
      alertService.clear();
      alertService.error(error?.response?.data?.message);
    }
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Seller Central Account Name',
      name: 'brandName',
      icon: 'user',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm Password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <ECOM3KRegistrationView
        itemRef={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Create an Account"
        fields={loginFields}
        formDescription="To begin using ECOM3K, please complete your registration details below."
        logo={<img className="logoImg" src={Logo} alt="ecom3k Logo" />}
      />
      <CustomAlert id="default-alert" sxStyles={sxStyles('inputField')} />
    </>
  );
}

export {ECOM3KRegistration};
