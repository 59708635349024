import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Grid} from '@mui/material';
import {Link} from 'react-router-dom';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortItem,
  GridFilterItem,
  GridSortModel,
} from '@mui/x-data-grid';
import {Delete, Edit, Security} from '@mui/icons-material';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import Button from '../../components/Button/Button';
import ModalButton from '../../components/ModalButton/ModalButton';
import {CustomAlert} from '../Login/components/CustomAlert';
import DataGridToolbar from '../../components/DataGridToolbar/DataGridToolbar';
// utils
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {getArticle} from '../../utils/FFPBeacon';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
import {Role} from '../../utils/role';
import {Api, errorAlert} from '../../utils/api';
// services
import mixPanel from '../../services/mixpanel';
import {alertService} from '../../services/alert.service';
import {accountService} from '../../services/account.service';

const sxStyles = {
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
  },
  placeholder: {
    width: '50%',
    margin: 'auto',
    display: 'block',
  },
  button: {
    marginBottom: '1em',
  },
};

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

export type User = {
  brand: string;
  brand_admin: boolean;
  department: string;
  email: string;
  id: string;
  inactive: boolean;
  name: string;
  role: string;
  sub_brand_codes: string[];
  think_tank: string;
};

interface UsersProps {
  GAEvents?: (category: string, action: string, label?: string) => void;
}

function Users({GAEvents}: UsersProps): JSX.Element {
  document.title = 'Users';
  const [rows, setRows] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<GridSortItem | null>(null);
  const [filter, setFilter] = useState<GridFilterItem | null>(null);
  const [deleteUser, setDeleteUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [tableLoading, setTableLoading] = useState(false);

  const columns: GridColDef[] = [
    {field: 'email', headerName: 'Email', minWidth: 280, type: 'filterString'},
    {field: 'name', headerName: 'Name', minWidth: 250, type: 'filterString'},
    {field: 'role', headerName: 'Role', minWidth: 140, type: 'filterString'},
    {field: 'department', headerName: 'Department', minWidth: 200, type: 'filterString'},
    {field: 'brand', headerName: 'Brand', minWidth: 200, type: 'filterString'},
    {field: 'inactive', headerName: 'Inactive', minWidth: 140},
  ];

  const brandManagerColumns: GridColDef[] = [
    {field: 'email', headerName: 'Email', flex: 1, minWidth: 280, type: 'filterString'},
    {field: 'name', headerName: 'Name', width: 250, type: 'filterString'},
    {field: 'brand', headerName: 'Brand', width: 200, type: 'filterString'},
    {field: 'inactive', headerName: 'Inactive', width: 100},
  ];

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: [Role.Admin, Role.Manager].includes(accountService.userValue.role)
          ? columns
          : [Role.BrandUser].includes(accountService.userValue.role)
          ? brandManagerColumns
          : [],
        tableName: `users-${accountService.userValue.role}`,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onPageChange(params: number) {
    loadData(params, pageSize, sort, filter);
    setPage(params);
  }

  const loadData = useCallback(
    async (page: number, pageSize: number, sortModel: typeof sort, filterModel: typeof filter) => {
      setLoading(true);
      try {
        const {data} = await Api.get('users', {
          params: {
            page: page,
            pageSize: pageSize,
            sortField: sortModel ? sortModel.field : null,
            sortOrder: sortModel ? sortModel.sort : null,
            filterField: filterModel ? filterModel.columnField : null,
            filterValue: filterModel ? filterModel.value : null,
          },
        });
        setCount(data.count);
        setRows(data.rows);
      } catch (e) {
        errorAlert('Unable to get users', e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  function handleSortModelChange(params: GridSortModel) {
    loadData(page, pageSize, params[0], filter);
    setSort(params[0]);
  }

  function handleFilterModelChange(params: GridFilterModel) {
    loadData(0, pageSize, sort, params.items[0]);
    setFilter(params.items[0]);
    setPage(0);
  }

  async function deleteUserById(id: string, closeModal: () => void) {
    try {
      await Api.delete(`users/${id}`);
      setDeleteUser(null);
      alertService.success('User deleted successfully');
      loadData(page, pageSize, sort, filter);
      closeModal();
    } catch (e) {
      errorAlert('Unable to delete user', e);
    }
  }

  useEffect(() => {
    if ([Role.Admin, Role.Manager].includes(accountService.userValue.role)) loadData(page, pageSize, null, null);

    if ([Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.create_users === true)
      loadData(page, pageSize, null, null);
  }, [page, pageSize, loadData]);

  useEffect(() => {
    if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)) getArticle({});
  }, [loading]);

  useEffect(() => {
    if (rows.length > 0 && ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type)) {
      mixPanel({
        eventName: 'Account Page - Users And Permissions',
        eventProperties: {users_added: rows},
      });
    }
  }, [rows]);

  const customToolbarProps = {
    loadingData: loading || tableLoading,
    ignoreItems: ['actions'],
    items: [...orderColumns],
    setItems: (v: GridColDef[]) => {
      setOrderColumns(v);
      setUserPreferences(
        {
          columnVisibilityModel: visibilityModel,
          columnsOrder: v.map((x) => x.field),
        },
        `users-${accountService.userValue.role}`,
        setTableLoading,
      );
    },
  };

  const thisRef = useRef(null);

  const customButtons = [Role.Admin, Role.Manager].includes(accountService.userValue.role)
    ? [
        {
          icon: <Security />,
          url: (v: User) => `/users/${v.id}/permission`,
          tooltip: 'Permissions',
        },
        {
          icon: <Edit />,
          url: (v: User) => `/users/update/${v.id}`,
          tooltip: 'Edit',
        },
        {
          icon: <Delete />,
          onClick: (v: User) => setDeleteUser(v),
          tooltip: 'Delete',
        },
      ]
    : [Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.brand_type === 'ffp'
    ? [
        {
          icon: (v: User) => (v.brand_admin ? <Security /> : null),
          url: (v: User) => `/users/${v.id}/permission`,
          tooltip: 'Permissions',
        },
        {
          icon: <Edit />,
          url: (v: User) => `/users/update/${v.id}`,
          onClick: () => {
            if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type))
              GAEvents?.('Edit user', 'Click on Edit Button');
          },
          tooltip: 'Edit',
        },
        {
          icon: (v: User) => (!v.brand_admin ? <Delete /> : null),
          onClick: (v: User) => {
            setDeleteUser(v);
            if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type))
              GAEvents?.('Delete user', 'Click on Delete Button');
          },
          tooltip: 'Delete',
        },
      ]
    : [Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.brand_type === 'ecom3k'
    ? [
        {
          icon: <Edit />,
          url: (v: User) => `/users/update/${v.id}`,
          onClick: () => {
            if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type))
              GAEvents?.('Edit user', 'Click on Edit Button');
          },
          tooltip: 'Edit',
        },
        {
          icon: (v: User) => (!v.brand_admin ? <Delete /> : null),
          onClick: (v: User) => {
            setDeleteUser(v);
            if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type))
              GAEvents?.('Delete user', 'Click on Delete Button');
          },
          tooltip: (v: User) => (!v.brand_admin ? 'Delete' : null),
        },
      ]
    : [];

  useEffect(() => {
    makeColumnFreeze(thisRef, visibilityModel, rows);
  }, [visibilityModel, rows]);

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={rows} buttons={customButtons} width="130px" />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        {[Role.Admin, Role.Manager, Role.BrandUser].includes(accountService.userValue.role) && (
          <Link to="/users/create">
            <Button
              sxStyles={sxStyles.button}
              onClick={() =>
                ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
                GAEvents?.('Create user', 'Click on Create Button')
              }
            >
              Create user
            </Button>
          </Link>
        )}
        <Grid item lg={12} position="relative">
          {[Role.Admin, Role.Manager].includes(accountService.userValue.role) ? (
            <DataGrid
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              componentsProps={{
                toolbar: customToolbarProps,
                pagination: customToolbarProps,
              }}
              className={`custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
              sx={sxStyles.grid}
              style={{width: '100%'}}
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              loading={loading}
              rowCount={count}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[40]}
              rows={rows}
              columns={[...orderColumns, fixedPlaceHolder({width: 130})]}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `users-${accountService.userValue.role}`,
                  setTableLoading,
                );
              }}
            />
          ) : (
            ''
          )}
          {[Role.BrandUser].includes(accountService.userValue.role) ? (
            <DataGrid
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              className={`custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
              sx={sxStyles.grid}
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              rowCount={count}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
              }}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[40]}
              rows={rows}
              columns={[...orderColumns, fixedPlaceHolder({width: 130})]}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `users-${accountService.userValue.role}`,
                  setTableLoading,
                );
              }}
              loading={loading}
            />
          ) : (
            ''
          )}
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
      <ModalButton
        openModal={deleteUser !== null}
        modalTitle="Delete user"
        hideButton
        closable
        onCloseText="No"
        onCloseAction={() => setDeleteUser(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              deleteUserById((deleteUser && (deleteUser as {id: string})?.id) || '', closeModal);
              if (['ffp', 'ecom3k'].includes(accountService.userValue.brand_type))
                GAEvents?.('Users', 'Delete user', `User deleted: ${deleteUser && (deleteUser as {id: string})?.id}`);
            }}
          >
            Yes
          </Button>
        )}
      >
        {(): JSX.Element => {
          return (
            <span>
              Are you sure you want to delete user with email:
              <b>{deleteUser && (deleteUser as {email: string})?.email}</b>
            </span>
          );
        }}
      </ModalButton>
    </TableContainer>
  );
}

export {Users};
