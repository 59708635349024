import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

if (process.env.SENTRY_ENV && process.env.SENTRY_DSN && process.env.SENTRY_RELEASE) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.SENTRY_ENV,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
