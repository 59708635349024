import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Link, useHistory, useParams} from 'react-router-dom';
import {FormikValues, FormikHelpers} from 'formik';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import Button from '../../components/Button/Button';
import LoginView from '../../components/LoginView/LoginView';
import LogoComponent from '../Login/components/LogoComponent';
import {CustomAlert} from '../Login/components/CustomAlert';

function Register({passwordReset}: {passwordReset?: boolean}): JSX.Element {
  const history = useHistory();
  const {token} = useParams<Record<string, string | undefined>>();
  const [valid, setValid] = useState<boolean | null>(null);

  useEffect(() => {
    if (accountService.userValue || !token) {
      history.push('/');
    }
    accountService
      .isRegisterTokenValid(token as string)
      .then(() => setValid(true))
      .catch(() => {
        setValid(false);
      });
  }, [history, token]);

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      ),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  function onSubmit({password, passwordConfirmation}: FormikValues, formikHelpers: FormikHelpers<FormikValues>) {
    formikHelpers.setSubmitting(true);
    alertService.clear();
    accountService
      .register({password, passwordConfirmation}, token as string)
      .then(() => {
        if (passwordReset) {
          alertService.success(
            <>
              Password changed successfully, please log in using your email address and the password you just created{' '}
              <br />
              <br />
              <Link to="/login">
                <Button variant="contained" color="secondary">
                  Login
                </Button>
              </Link>
            </>,
            {autoClose: false},
          );
        } else
          alertService.success(
            <>
              Account successfully created, please log in using your email address and the password you just created{' '}
              <br />
              <br />
              <Link to="/login">
                <Button variant="contained" color="secondary">
                  Login
                </Button>
              </Link>
            </>,
            {autoClose: false},
          );
      })
      .catch((error) => {
        formikHelpers.setSubmitting(false);
        if (error.response.data && error.response.data.message) {
          alertService.error(error.response.data.message);
        } else {
          alertService.error(error.message);
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  const fields = [
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Register"
        fields={fields}
        logo={<LogoComponent />}
        formDescription="Please enter and confirm your new password"
        isInvalid={valid !== null && !valid}
        message="Invalid token"
      />
      <CustomAlert id="default-alert" />
    </>
  );
}

export {Register};
