import moment, {Moment} from 'moment';

export const rangeData: {
  [key: string]: {
    label: string;
    value: string;
    from?: Moment | string | number | Date;
    to?: Moment | string | number | Date;
    days?: number;
  };
} = {
  yesterday: {
    label: 'Yesterday',
    value: 'y',
    from: moment(new Date()).subtract(1, 'days'),
    to: moment(new Date()).subtract(1, 'days'),
  },
  currentWeek: {
    label: 'This Week',
    value: 'cw',
    from: moment().startOf('week'),
    to: moment().endOf('week'),
  },
  currentMonth: {
    label: 'This Month',
    value: 'cm',
    from: moment().startOf('month'),
    to: moment().endOf('month'),
  },
  '7d': {
    label: '7 days',
    value: '7d',
    days: 7,
    from: moment().subtract(7, 'days'),
    to: moment(new Date()).subtract(1, 'days'),
  },
  '30d': {
    label: '30 days',
    value: '30d',
    days: 30,
    from: moment().subtract(30, 'days'),
    to: moment(new Date()).subtract(1, 'days'),
  },
  '90d': {
    label: '90 days',
    value: '90d',
    days: 90,
    from: moment().subtract(90, 'days'),
    to: moment(new Date()).subtract(1, 'days'),
  },
  quater: {
    label: 'Quarter',
    value: 'q',
    from: moment().quarter(moment().quarter()).startOf('quarter'),
    to: moment(new Date()).subtract(1, 'days'),
  },
  custom: {
    label: 'Custom',
    value: 'custom',
  },
};
