import React, { memo, useEffect, useState } from 'react';

import _ from 'lodash';

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTheme } from '@mui/material/styles';

import {
  Button,
} from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { GridSelectionModel } from '@mui/x-data-grid';

import { ListingsInterface } from '../../interfaces/interfaces';

import ListingFieldComponent from '../ListingField';

import {
  Api,
  errorAlert,
} from 'src/utils/api';

import mixPanel from 'src/services/mixpanel';

import {
  FastField,
  Formik,
  FormikValues,
  Form,
  FieldArray,
} from 'formik';

import { alertService } from 'src/services/alert.service';

import { fieldGroupNames } from '../../utils/constant';

import {
  buildEmptyField,
  getInitialValues,
  removeEmpty,
} from '../../utils/listings';

import {
  ListingFieldDefinitions,
  ListingsItemIssue,
  ListingsItemSubmissionResponse,
} from '../../interfaces/interfaces';

type FormikHelpers = {
  setSubmitting: (isSubmitting: boolean) => void;
};

type Props = {
  selectionModel: GridSelectionModel;
  marketplaceId: string | null;
  listingRows: ListingsInterface['rows'];
  handleCloseBulkEdit: () => void;
  bulkEditSuccess: (message: string) => void;
};

const BulkEditContainer = ({
  selectionModel,
  listingRows,
  marketplaceId,
  handleCloseBulkEdit,
  bulkEditSuccess,
}: Props): JSX.Element => {
  const [selectedListings, setSelectedListings] = useState<ListingsInterface['rows']>([]);
  // Do not render form until done loading.
  const [loading, setLoading] = useState(false);
  const [allInitialValues, setAllInitialValues] = useState<FormikValues | null>(null);
  const [firstLevelFields, setFirstLevelFields] = useState<string[]>([]);
  const [listingFieldDefinitions, setListingFieldDefinitions] = useState<ListingFieldDefinitions>({});
  const [initialValues, setInitialValues] = useState<FormikValues | null>(null);
  const [allExpandedFields, setAllExpandedFields] = useState<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [dataListings, setDataListings] = useState<{ [key: string]: FormikValues } | null>(null);
  const [skuList, setSkuList] = useState<string[]>([]);
  const [buttonClicked, setButtonClicked] = useState<string | null>(null);

  const skipFields = [
    'marketplace_id',
    'meta',
    'language_tag',
  ];

  const attributesToSkip = [
    'child_parent_sku_relationship',
    'parentage_level',
    'variation_theme',
  ];

  useEffect(() => {
    setLoading(true);
    let productType = '';
    let sellerId = '';
    let attributeKeyToUpdate = '';

    const initializeProductTypeFields = async (productType: string) => {
      let uri;
      const firstLevelFields: string[] = [];
      let initialValues: FormikValues | null = null;
      let dataListings: { [key: string]: FormikValues } | null = null;
      let allExpandedFields: any; // eslint-disable-line @typescript-eslint/no-explicit-any
      uri = `amazon-listings/product-type-fields/${productType}/${marketplaceId}`;
      try {
        const { data } = await Api.get(uri);
        setListingFieldDefinitions(data.listingFieldDefinitions);
        const results = getInitialValues(data.listingFieldDefinitions);
        initialValues = results.initialValues;
        setInitialValues(initialValues);
        allExpandedFields = results.allExpandedFields;
        setAllExpandedFields(allExpandedFields);
        Object.keys(allExpandedFields).forEach((key) => {
          firstLevelFields.push(key);
        });
        firstLevelFields.sort();
        attributeKeyToUpdate = firstLevelFields[0];
        setFirstLevelFields(firstLevelFields);
      } catch (e) {
        console.log('Error with amazon-listings/product-type-fields in initializeProductTypeFields', e)
      }

      const skuList: string[] = [];
      selectionModel.forEach((i) => {
        const listing = listingRows[i as number];
        if (!sellerId) {
          sellerId = listing.seller_id;
        }
        skuList.push(listing.sku);
      });
      setSkuList(skuList);

      if (skuList.length) {
        mixPanel({
          eventName: 'View bulk edit form',
          eventProperties: {
            skuList,
            productType,
          },
        });
      }

      const skus = skuList.toString(); // equivalent to skuList.join(',')
      uri = 'amazon-listings/bulk-load';
      try {
        const { data } = await Api.get(
          uri,
          {
            params: {
              marketplaceId,
              sellerId,
              skus,
            },
          });
        dataListings = data.listings;
        setDataListings(dataListings);
      } catch (e) {
        errorAlert('Unable to get data from bulk load', e);
        console.log('Error with amazon-listings/bulk-load in initializeProductTypeFields', e)
      }

      const bulkInitialValues = buildBulkInitialValues(
        initialValues,
        dataListings,
        attributeKeyToUpdate,
        firstLevelFields,
        skuList,
      );

      // console.log('initialValues', initialValues);
      // console.log('dataListings', dataListings);
      // console.log('allExpandedFields');
      // console.log(allExpandedFields);
      // console.log('bulkInitialValues after initializing');
      // console.log(bulkInitialValues);

      setAllInitialValues(bulkInitialValues);
    };

    selectionModel.forEach((i) => {
      const listing = listingRows[i as number];
      if (!productType) {
        productType = listing.productType;
      }
      setSelectedListings((prevSelectedListings) => [...prevSelectedListings, listing]);
    });

    initializeProductTypeFields(productType);

    setLoading(false);
  }, [selectionModel, listingRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeAttribute = (
    e: React.SyntheticEvent,
    newValue: string | null,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => void) => {
    const attributeKeyToUpdate = newValue;
    setFieldValue("attributeKeyToUpdate", attributeKeyToUpdate);
    const bulkInitialValues = buildBulkInitialValues(
      initialValues,
      dataListings,
      attributeKeyToUpdate,
      firstLevelFields,
      skuList,
    );
    // console.log('bulkInitialValues after changing attribute');
    // console.log(bulkInitialValues);

    setAllInitialValues(bulkInitialValues);
  };

  const setNullToEmpty = (input: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const keys = Object.keys(input);
    for (const key of keys) {
      if (input[key] === null) {
        input[key] = '';
      } else if (Array.isArray(input[key])) {
        input[key].forEach((item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          setNullToEmpty(item);
        });
      } else if (typeof input[key] === 'object') {
        setNullToEmpty(input[key]);
      }
    }
    return input;
  };

  const buildBulkInitialValues = (
    initialValues: FormikValues | null,
    dataListings: { [key: string]: FormikValues } | null,
    attributeKeyToUpdate: string | null,
    firstLevelFields: string[],
    skuList: string[],
  ) => {
    const bulkInitialValues: FormikValues = {};
    let value;
    bulkInitialValues['attributeKeyToUpdate'] = attributeKeyToUpdate;

    // Need to use a deep clone to avoid mutating the original values.
    const dataListingsClone = _.cloneDeep(dataListings);
    setNullToEmpty(dataListingsClone);

    firstLevelFields.forEach((attributeKey) => {
      bulkInitialValues[attributeKey] = [];
      skuList.forEach((sku: string, skuIndex: number) => {
        if (initialValues && dataListingsClone) {
          value = Object.assign({}, initialValues[attributeKey]);
          if (dataListingsClone[sku].attributes[attributeKey]) {
            if (Array.isArray(initialValues[attributeKey])) {
                // If initialValues[attributeKey] has been turned into
                // an array in getInitialValues because of maxUniqueItems in the product
                // type field definitions then just use that array.
                bulkInitialValues[attributeKey][skuIndex] = [];
                dataListingsClone[sku].attributes[attributeKey].forEach((item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                  bulkInitialValues[attributeKey][skuIndex].push(
                    extractFieldValue(item)
                  );
                });
            } else {
              value = Object.assign(
                value,
                extractFieldValue(dataListingsClone[sku].attributes[attributeKey][0])
              );
              bulkInitialValues[attributeKey].push(
                value
              );
            }
          } else {
            if (Array.isArray(initialValues[attributeKey])) {
              // If initialValues[attributeKey] has been turned into
              // an array in getInitialValues because of maxUniqueItems in the product
              // type field definitions then just use that array.
              bulkInitialValues[attributeKey][skuIndex] = initialValues[attributeKey];
            } else {
              bulkInitialValues[attributeKey].push(
                value
              );
            }
          }
        }
      });
    });

    return bulkInitialValues;
  };

  const extractFieldValue = (
    originalValue: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    const value: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    // Some attributes are an array with an object inside so we need to extract the object
    // to avoid conflicts with the Formik field names.
    // Note how we go down an extra level for attributes like purchasable_offer.
    for (const key in originalValue) {
      if (Array.isArray(originalValue[key]) && originalValue[key].length) {
        value[key] = originalValue[key][0];
      } else {
        value[key] = originalValue[key];
      }
      if (typeof value[key] === 'object') {
        for (const secondLevelKey in value[key]) {
          if (Array.isArray(value[key][secondLevelKey]) && value[key][secondLevelKey].length) {
            value[key][secondLevelKey] = value[key][secondLevelKey][0];
          } else {
            value[key][secondLevelKey] = value[key][secondLevelKey];
          }
        }
      }
    }
    return value;
  }

  const copyToAllListings = (
    values: FormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    const attributeKeyToUpdate = values.attributeKeyToUpdate;
    const valuesToCopy = values[attributeKeyToUpdate][0];
    const clonedInitialValues = _.cloneDeep(allInitialValues);
    if (clonedInitialValues) {
      clonedInitialValues[attributeKeyToUpdate].forEach((_: any, i: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        clonedInitialValues[attributeKeyToUpdate][i] = valuesToCopy;
      });
      setFieldValue(attributeKeyToUpdate, clonedInitialValues[attributeKeyToUpdate]);
    }
  };

  const submitListing = async (
    values: FormikValues,
    { setSubmitting }: FormikHelpers,  // instead of FormikHelpers<FormikValues> from Formik
  ) => {
    if (buttonClicked === 'copy-to-all') {
      setButtonClicked(null);
      return;
    }
    // Need to use a deep clone to avoid mutating the original values.
    const valuesClone = _.cloneDeep(values);
    const attributeKeyToUpdate = valuesClone.attributeKeyToUpdate;
    const listingsData: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any

    const fieldsToExpand = [
      'marketplace_id',
      'language_tag',
    ];

    selectedListings.forEach((listing, i) => {
      const updatedValues = removeEmpty(valuesClone[attributeKeyToUpdate][i]);

      if (Array.isArray(updatedValues)) {
        updatedValues.forEach((_, index: number) => {
          fieldsToExpand.forEach((fieldToExpand) => {
            if (allExpandedFields[attributeKeyToUpdate].hasOwnProperty(fieldToExpand)) {
              updatedValues[index][fieldToExpand] = allExpandedFields[attributeKeyToUpdate][fieldToExpand];
            }
          });
        });
      } else {
        fieldsToExpand.forEach((fieldToExpand) => {
          if (allExpandedFields[attributeKeyToUpdate].hasOwnProperty(fieldToExpand)) {
            updatedValues[fieldToExpand] = allExpandedFields[attributeKeyToUpdate][fieldToExpand];
          }
        });
      }

      if (!Array.isArray(updatedValues)) {

        Object.keys(updatedValues).forEach((secondLevelFieldName) => {
          fieldsToExpand.forEach((fieldToExpand) => {
            if (allExpandedFields[attributeKeyToUpdate][secondLevelFieldName].hasOwnProperty(fieldToExpand)) {
              updatedValues[secondLevelFieldName][fieldToExpand] = allExpandedFields[attributeKeyToUpdate][secondLevelFieldName][fieldToExpand];
            }
          });
        });

        Object.keys(updatedValues).filter(firstLevelItemKey => !skipFields.includes(firstLevelItemKey)).forEach((firstLevelItemKey) => {
          Object.keys(updatedValues[firstLevelItemKey]).filter(secondLevelItemKey => !skipFields.includes(secondLevelItemKey)).forEach((secondLevelItemKey) => {
            if (typeof updatedValues[firstLevelItemKey][secondLevelItemKey] === 'object') {
              if (allExpandedFields[attributeKeyToUpdate][firstLevelItemKey][secondLevelItemKey].hasOwnProperty('meta') && allExpandedFields[attributeKeyToUpdate][firstLevelItemKey][secondLevelItemKey].meta.type === 'array'
              ) {
                updatedValues[firstLevelItemKey][secondLevelItemKey] = [
                  updatedValues[firstLevelItemKey][secondLevelItemKey]
                ];
              }
            }
          });
          if (allExpandedFields[attributeKeyToUpdate][firstLevelItemKey].hasOwnProperty('meta') && (
            allExpandedFields[attributeKeyToUpdate][firstLevelItemKey].meta.hasSelectOptionsInsideItems ||
            allExpandedFields[attributeKeyToUpdate][firstLevelItemKey].meta.type === 'array'
          )) {
            updatedValues[firstLevelItemKey] = [
              updatedValues[firstLevelItemKey]
            ];
          }
        });
  
      }

      listingsData.push({
        seller_sku: listing.sku,
        seller_id: listing.seller_id,
        brand_code: listing.brand_code,
        marketplace_id: listing.marketplace_id,
        productType: listing.productType,
        updatedValues,
        attributeKeyToUpdate: valuesClone.attributeKeyToUpdate,
      });
    });

    // debug
    // console.log('listingsData');
    // console.log(listingsData);
    // end debug

    try {
      const res = await Api.post(
        'amazon-listings/bulk-edit',
        {
          listingsData,
        }
      );
      if (res.data?.status === 'ACCEPTED') {
        mixPanel({
          eventName: 'Bulk update listings',
          eventProperties: {
            skuList,
          },
        });
        handleCloseBulkEdit();
        bulkEditSuccess('Listings updated in bulk successfully.');
      } else {
        // debug
        // console.log('res.data');
        // console.log(res.data);

        const issues = () => (
          <div>
            Some of your changes were not accepted for processing.
            {res.data.submissionResponses.map((submissionResponse: ListingsItemSubmissionResponse, i: number) => (
              submissionResponse.issues && submissionResponse.issues?.length > 0 &&
              <div key={i}>
                <p>SKU: {submissionResponse.sku}</p>
                <ul>
                  {submissionResponse.issues.map((msg: ListingsItemIssue, i: number) => (
                    <li key={i}>{msg.message}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );

        alertService.error(
          issues(),
          {
            autoClose: false,
          }
        );
      }
    } catch (e) {
      errorAlert('Error while saving the changes', e);
    } finally {
      setSubmitting(false);
    }
  };

  const theme = useTheme();

  return (
    <TableContainer
      className={`theme-${theme.palette.mode} bulk-edit-container`}
      component={Paper}
    >
      {allInitialValues && !loading && (
        <Formik
          initialValues={allInitialValues}
          enableReinitialize={true}
          onSubmit={(
            values: FormikValues,
            actions: FormikHelpers,
          ) => submitListing(values, actions)}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
        >
          {({
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            values,
          }) => (
            <Form
              aria-disabled={isSubmitting}
              className="bulk-edit-form"
            >
              {firstLevelFields.length > 0 && (
                <div className="attribute-selection">
                  <Autocomplete
                    data-cy="attributeKeyToUpdate"
                    disablePortal
                    freeSolo
                    id="attributeKeyToUpdate"
                    onChange={(e: React.SyntheticEvent, newValue: string | null) => handleChangeAttribute(e, newValue, setFieldValue )}
                    options={firstLevelFields.filter(fieldName => !attributesToSkip.includes(fieldName)).map((fieldName) => fieldName)}
                    renderInput={(params) => <TextField {...params} label="Attribute" />}
                    sx={{
                      width: '350px',
                      height: '60px',
                      '& label': {
                        color: '#1292ee',
                      }
                    }}
                    value={values.attributeKeyToUpdate}
                  />
                </div>
              )}

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {selectedListings.map((listing, i) => {
                      return (
                        <TableCell
                          key={i}
                        >
                          <h3>{listing.sku}</h3>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {selectedListings.map((listing, listingIndex) => {
                      return (
                        <TableCell
                          key={listingIndex}
                        >
                          {fieldGroupNames.map((fieldGroupName: string, index: number) => (
                            <div key={index}>
                              {Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields).filter(key => key === values.attributeKeyToUpdate).map((fieldName: string, index: number) => (
                                <div
                                  className="field-wrapper"
                                  key={index}
                                >
                                  { listingIndex === 0 && (
                                    <button
                                      id="copy-to-all"
                                      onClick={() => {
                                        setButtonClicked('copy-to-all');
                                        copyToAllListings(
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                    >
                                      <ContentCopyIcon />
                                      <span>copy to all listings</span>
                                    </button>
                                  )}
                                  {
                                    fieldName !== 'product_description' &&
                                    listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].hasOwnProperty('meta') &&
                                    listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.hasOwnProperty('maxUniqueItems') &&
                                    listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.type === 'array' &&
                                    (listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.maxUniqueItems as number) > 1 ? (
                                    <FieldArray
                                      name={fieldName}
                                    >
                                      {() => (
                                        values[fieldName][listingIndex].map((item: any, index: number) => ( // eslint-disable-line @typescript-eslint/no-explicit-any
                                          <div
                                            className="field-array-container"
                                            key={index}
                                          > 
                                            {Object.keys(item).filter(firstLevelItemKey => !skipFields.includes(firstLevelItemKey)).map((firstLevelItemKey: string, keyIndex: number) => (
                                              <div
                                                key={keyIndex}
                                              >
                                                <FastField
                                                  name={`${fieldName}.${listingIndex}.${index}.${firstLevelItemKey}`}
                                                  fieldName={fieldName}
                                                  component={ListingFieldComponent}
                                                  fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]}
                                                  labelIndex={ index + 1 }
                                                />
                                                <div
                                                  className='field-array-buttons'
                                                >
                                                  <button
                                                    onClick={() => {
                                                      if (values[fieldName][listingIndex].length > 1) {
                                                        const updatedData = values[fieldName][listingIndex].filter((_: any, itemIndex: number) => itemIndex !== index); // eslint-disable-line @typescript-eslint/no-explicit-any
                                                        const valuesCloned = _.cloneDeep(values[fieldName]);
                                                        valuesCloned[listingIndex] = updatedData;
                                                        setFieldValue(fieldName, valuesCloned);
                                                        // setFieldValue(fieldName, values[fieldName]);
                                                        setFieldTouched(fieldName);
                                                      }
                                                    }}
                                                    type="button"
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginLeft: '10px',
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    onClick={() => {
                                                      if (values[fieldName][listingIndex].length < (listingFieldDefinitions[fieldGroupName].expandedFields[fieldName].meta.maxUniqueItems as number)) {
                                                        values[fieldName][listingIndex].push(buildEmptyField(fieldName, skipFields, listingFieldDefinitions[fieldGroupName].expandedFields));
                                                        setFieldValue(fieldName, values[fieldName]);
                                                        setFieldTouched(fieldName);
                                                      }
                                                    }}
                                                    style={{
                                                      cursor: 'pointer',
                                                      marginLeft: '10px',
                                                      position: 'relative',
                                                    }}
                                                    type="button"
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        )
                                      ))}
                                    </FieldArray>
                                  ) : (
                                    Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName]).filter(key => !skipFields.includes(key)).map((firstLevelItemKey: string, index: number) => (
                                      <div key={index}>
                                        {
                                          Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]).filter(key => !skipFields.includes(key)).length ?
                                            Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]).filter(key => !skipFields.includes(key)).map((secondLevelItemKey: string, index: number) => (
                                              <div key={index}>
                                                {
                                                  Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]).filter(key => !skipFields.includes(key)).length ?
                                                    Object.keys(listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]).filter(key => !skipFields.includes(key)).map((thirdLevelItemKey: string, index: number) => (
                                                      <FastField
                                                        fieldName={fieldName}
                                                        name={`${fieldName}.${listingIndex}.${firstLevelItemKey}.${secondLevelItemKey}.${thirdLevelItemKey}`}
                                                        component={ListingFieldComponent}
                                                        fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey]}
                                                        key={index}
                                                      />
                                                    )) : (
                                                      <FastField
                                                        fieldName={fieldName}
                                                        name={`${fieldName}.${listingIndex}.${firstLevelItemKey}.${secondLevelItemKey}`}
                                                        component={ListingFieldComponent}
                                                        fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey][secondLevelItemKey]}
                                                        key={index}
                                                      />
                                                    )
                                                }
                                              </div>
                                            )) : (
                                              <FastField
                                                fieldName={fieldName}
                                                name={`${fieldName}.${listingIndex}.${firstLevelItemKey}`}
                                                component={ListingFieldComponent}
                                                fieldDefinition={listingFieldDefinitions[fieldGroupName].expandedFields[fieldName][firstLevelItemKey]}
                                                key={index}
                                              />
                                            )
                                        }
                                      </div>
                                    ))
                                  )}
                                </div>
                                )
                              )}
                            </div>
                          )
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>

                  <TableRow>
                    <TableCell
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        id="updateListingsButton"
                        data-cy="updateListingsButton"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Update Listings
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Form>
          )}
        </Formik>
      )}
    </TableContainer>
  );
};

export default memo(BulkEditContainer);
