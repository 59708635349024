import React from 'react';

import Button from 'src/components/Button/Button';

interface Props {
  setCurrentForm: (formName: string) => void;
}

export default function CancelSubscriptionForm(
  { setCurrentForm }: Props
): JSX.Element {

  // const [message, setMessage] = useState<string>("");
  // const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      className="subscription-form"
      id="cancel-subscription-form"
    >
      <h5>Cancel your subscription</h5>
      <p>When you cancel your subscription you will lose access to continue managing your listings. Are you sure you want to cancel your subscription?</p>
      <div className="buttons">
        <Button
          size="small"
        >
          Yes, cancel my subscription
        </Button>
        <Button
          size="small"
          onClick={() => setCurrentForm('')}
          variant="outlined"
        >
          No, take me back to my account
        </Button>
      </div>
      <div className="terms">
        By confirming your subscription, you allow FlatFilePro to charge you for future payments in accordance with our <a href="https://flatfile.pro/terms-of-service/" target="_blank" rel="noreferrer">terms of service</a>.
      </div>
    </form>
  );
}