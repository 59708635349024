import React, {useEffect, useState, memo} from 'react';
import {FormControl, Select, MenuItem, Tooltip} from '@mui/material';
import {Edit, ContentPaste} from '@mui/icons-material';
// components
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
//styles
import {globalStyles} from '../AppStyles/AppStyles';

interface TableSelectInputProps {
  value: string;
  onChange?: (event: string | null, name: string) => void;
  options?: JSX.Element[];
  renderValue: string;
  readOnly?: boolean;
  required?: boolean;
  copyToClipboard?: () => void;
  loading?: boolean;
  ref?:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | ((element: HTMLInputElement | null) => void)
    | null
    | undefined;
  onBlurCapture?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => void;
}

export default memo(function TableSelectInput({
  value,
  onChange,
  options,
  renderValue,
  readOnly,
  required,
  copyToClipboard,
  loading,
  ref,
  onBlurCapture,
  onKeyDown,
}: TableSelectInputProps) {
  const [label, setLabel] = useState(renderValue);
  const [currentValue, setCurrentValue] = useState(value);
  const classes = globalStyles;

  useEffect(() => {
    setLabel(renderValue);
    setCurrentValue(value);
  }, [renderValue, value]);

  return (
    <FormControl variant="standard" fullWidth sx={classes.tableSelect}>
      {readOnly ? (
        loading ? (
          <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
            <SkeletonLoader numberOfLines={3} xs={12} height={10} />
          </div>
        ) : (
          <Tooltip title="Double Click to Edit" disableInteractive>
            <span style={{width: '100%', height: '100%', position: 'relative'}}>
              <em>{label ? label : 'N/A'}</em>
              <div className="edit-cell-icon">
                <Edit />
                {label && copyToClipboard && (
                  <Tooltip title="Copy to Clipboard">
                    <ContentPaste onClick={() => copyToClipboard()} />
                  </Tooltip>
                )}
              </div>
            </span>
          </Tooltip>
        )
      ) : (
        <Select
          ref={ref && ref}
          readOnly={readOnly}
          onChange={(event) => {
            const newLabel =
              (options && options.find((option) => option.props.value === event.target.value)?.props.children) || 'N/A';

            onChange?.(event.target.value === 'n/a' ? null : event.target.value, newLabel);
            setLabel(newLabel);
            setCurrentValue(event.target.value);
          }}
          value={currentValue ? currentValue : 'n/a'}
          renderValue={() => {
            return <em>{label ? label : 'N/A'}</em>;
          }}
          onBlurCapture={onBlurCapture}
          onKeyDown={onKeyDown}
        >
          {!required && <MenuItem value="n/a">N/A</MenuItem>}
          {options}
        </Select>
      )}
    </FormControl>
  );
});
