import React from 'react';
import {AxiosError} from 'axios';
import {Typography, Grid, Button} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
// services
import {alertService} from '../../../../../../services/alert.service';
import {accountService} from '../../../../../../services/account.service';
// components
import TableContainer from '../../../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../../../Login/components/CustomAlert';
// styles
import {sxStyles} from '../../../../Styles';
// utils
import {withTracker} from '../../../../../../utils/withTracker';
import {Role} from '../../../../../../utils/role';
import {BrandTypesAlias} from '../../../../../../utils/brand-types';
import {Api, errorAlert} from '../../../../../../utils/api';

const initialValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

function CreateFfpSubBrand(): JSX.Element {
  const history = useHistory();

  async function onSubmit(
    data: {
      name: string;
    },
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) {
    alertService.clear();

    const fields = {...data};

    try {
      const {data: newSubBrand} = await Api.post(`brands/${accountService.userValue.brand_code}/sub-brands`, fields);
      if (
        newSubBrand?.brand_code &&
        accountService.userValue.role === Role.BrandUser &&
        accountService.userValue.brand_type &&
        [BrandTypesAlias.ffp as string, BrandTypesAlias.ecom3k as string].includes(accountService.userValue.brand_type)
      ) {
        const newSubBrandCodes = [...accountService.userValue.sub_brand_codes, newSubBrand?.brand_code];
        accountService.setUserValue({sub_brand_codes: newSubBrandCodes});
      }
      alertService.success('Brand created successfully');
      history.push(`/account/connect-to-amazon`);
    } catch (e) {
      const error = e as AxiosError;
      errorAlert(error?.response?.data?.message || 'Unable to create brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Create sub brand
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
        >
          {({isSubmitting}) => {
            return (
              <Form>
                <div>
                  <div>
                    <Field
                      sx={sxStyles('inputField')}
                      component={TextField}
                      variant="outlined"
                      name="name"
                      label="Brand name"
                      type="text"
                      size="small"
                    />
                  </div>

                  <div style={{height: 10}} />

                  <div>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        withTracker('Create', 'Create sub brand');
                      }}
                    >
                      Save
                    </Button>
                    <CustomAlert sxStyles={sxStyles('inputField')} id="default-alert" />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </TableContainer>
  );
}

export {CreateFfpSubBrand};
