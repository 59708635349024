import { SxStyleTypes } from "../../../../interfaces/sxStyles/sxStyles.interface";

export const styles = (): {[key: string]: SxStyleTypes} => ({
  inputField: {
    margin: '0.5em 0',
    width: '100%',
  },
  select: {
    margin: '0.5em 0',
    width: '100%',
    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
      top: '-8px',
    },
  },
  selected: {
    '& > .MuiInputLabel-formControl': {
      opacity: 0,
    },
  },
});