import {brandFields} from '../../utils/BrandProperties';

export interface BrandFieldsInterface {
  name: string;
  type: string;
  label: string;
  options?: string;
  priority?: boolean;
  variant?: string;
  mode?: boolean;
}

export const getFields = (type: string): BrandFieldsInterface[] => {
  const newFields: BrandFieldsInterface[] = [];

  Object.entries(brandFields)
    .filter((field) => field[1].type === type)
    .map(([key, field]) => {
      return newFields.push({
        name: key,
        type: field.type,
        label: field.label,
        options: field.options,
        priority: field.priority,
        variant: field.variant,
        mode: field.createMode,
      });
    });
  return newFields;
};
