import {Typography, Paper, Grid} from '@mui/material';
import React, {useEffect} from 'react';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Api, errorAlert} from '../../../utils/api';
// components
import TableContainer from '../../../components/TableContainer/TableContainer';
import PageTitle from '../../../components/PageTitle/PageTitle';
// styles
import {sxStyles} from '../Styles';

function SubscriptionSuccessPage(): JSX.Element {
  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    async function submitSessionId() {
      try {
        await Api.post('/users/ffp-checkout-succeeded', {sessionId: sessionId});
        accountService.refreshToken();
      } catch (e) {
        errorAlert('Failed to submit session id', e);
      }
    }
    if (sessionId) {
      submitSessionId();
    }
  }, []);

  return (
    <TableContainer sx={sxStyles('grid')} container justifyContent="space-between" alignItems="center">
      <Grid item lg={6} md={6} xs={12} sx={sxStyles('image')}>
        <img src={process.env.PUBLIC_URL + '/Market.svg'} alt="login-img" />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Paper sx={sxStyles('loginPaper')} elevation={5}>
          <PageTitle variant="h4" component="h1" display="block" fontSize="28px" fontWeight={600}>
            Thank you for subscribing!
          </PageTitle>
          <Typography variant="h6" component="h6">
            We will send you an email with your subscription details.
          </Typography>
        </Paper>
      </Grid>
    </TableContainer>
  );
}

export {SubscriptionSuccessPage};
