export const createQueryString = (
  data: {
    [key: string]: string | number | string[] | undefined | null;
  },
  ignore?: string[],
): string => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === null || value === undefined || (value as string[])?.length === 0 || ignore?.includes(key)) {
      delete data[key];
    }
  });

  const newQuery = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');

  return newQuery;
};
