import React, {memo} from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Divider,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import {MoreVert, ArrowRightAlt} from '@mui/icons-material';
// styles
import {sxStyles} from '../../Performance/Styles';
// utils
import {getAmountDiff} from './pieChart';
import getLabel from '../../../utils/getLabel';
// components
import DropDownButton from '../../../components/DropdownButton/DropDownButton';
import {SummaryDataInterface} from '../interfaces/insights';

interface SalesCardProps {
  data: SummaryDataInterface[];
  dateInterval: string;
  title: string | JSX.Element;
  setSelectedItem?: ((v: SummaryDataInterface) => void) | null;
  actionButton?: {
    label: string;
    action: () => void;
  }[];
  id: string;
  loading: boolean;
  compare: boolean;
}

export default memo(function SalesCard({
  data,
  dateInterval,
  title,
  setSelectedItem,
  actionButton,
  id,
  loading,
  compare,
}: SalesCardProps) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} style={{height: '100%'}}>
      <div id={id} style={{height: '100%', display: 'flex', alignItems: 'stretch'}}>
        <Card sx={sxStyles('salesCard')}>
          <CardHeader
            title={title}
            action={
              <DropDownButton iconButton={<MoreVert />} closable>
                {actionButton?.map((option, index) => (
                  <MenuItem
                    key={`${option.label}-${index}`}
                    onClick={() => {
                      option.action();
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </DropDownButton>
            }
            subheader={dateInterval}
          />
          <Divider />
          {loading ? (
            <Grid container className="container" justifyContent="center" alignItems="center">
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Loading...
              </Typography>
              <LinearProgress style={{position: 'absolute', width: '100%', bottom: 0}} />
            </Grid>
          ) : (
            <div className={`container ${compare ? 'compare' : ''}`} id="card-container">
              {data.map((item, index) => {
                return (
                  <React.Fragment key={`${item.id}-${index}`}>
                    <CardContent
                      style={{padding: '0'}}
                      onClick={() => setSelectedItem && setSelectedItem(item)}
                      className={setSelectedItem ? 'selected-item' : ''}
                    >
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{padding: '5px'}}>
                          <List className="card-list-1">
                            {(item.brandName || item.brandCode || item.sellerSku) && (
                              <ListItem>
                                <ListItemText
                                  className={`brand-code ${item.sellerSku ? 'sku' : ''}`}
                                  primary={
                                    item.brandName ||
                                    item.brandCode || (
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`/products/${encodeURIComponent(item.sellerSku as string)}/detail`}
                                      >
                                        {item.sellerSku}
                                      </a>
                                    )
                                  }
                                  secondary={
                                    item.brandName
                                      ? 'Brand Name'
                                      : item.brandCode
                                      ? 'Brand Code'
                                      : item.sellerSku
                                      ? 'Seller SKU'
                                      : null
                                  }
                                />
                              </ListItem>
                            )}
                            {item.summary.map((listItem, index) => {
                              return (
                                <ListItem key={`${listItem.value}-${index}`}>
                                  <ListItemText
                                    primary={listItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    secondary={
                                      <div>
                                        {compare && listItem.oldValue !== null && listItem.oldValue !== undefined && (
                                          <span>
                                            {listItem.oldValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                            <ArrowRightAlt />{' '}
                                            {listItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ={' '}
                                            {getAmountDiff({
                                              current: +listItem.value,
                                              old: +listItem.oldValue,
                                              styles: sxStyles,
                                              invertedColor: true,
                                            })}
                                          </span>
                                        )}
                                        <span>{getLabel(listItem.label)}</span>
                                      </div>
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{padding: '5px'}}>
                          <List dense className="card-list-2">
                            {item.count.map((listItem, index) => {
                              return (
                                <React.Fragment key={`${listItem.label}-${index}`}>
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        <>
                                          <Typography variant="body1" color="textPrimary">
                                            {getLabel(listItem.label)}
                                          </Typography>
                                          <Typography variant="body1" color="textPrimary">
                                            {listItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          </Typography>
                                        </>
                                      }
                                      secondary={
                                        compare &&
                                        listItem.oldValue !== null &&
                                        listItem.oldValue !== undefined && (
                                          <div>
                                            <span>
                                              {listItem.oldValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                              <ArrowRightAlt />{' '}
                                              {listItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ={' '}
                                              {getAmountDiff({
                                                current: +listItem.value,
                                                old: +listItem.oldValue,
                                                styles: sxStyles,
                                                invertedColor: true,
                                              })}
                                            </span>
                                          </div>
                                        )
                                      }
                                    />
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              );
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {(item.brandCode || item.sellerSku) && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </Card>
      </div>
    </Grid>
  );
});
