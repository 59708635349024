import { FormikValues } from 'formik';

import _ from 'lodash';

import {
  ListingField,
  ListingFieldDefinitions,
} from '../interfaces/interfaces';

export const isEmptyObjectOrString = (value: any): boolean => { // eslint-disable-line
  if (value === null) {
    return true;
  }
  return (
    typeof value === 'object' &&
    Object.keys(value).length === 0
  ) || value === "";
};

export const removeEmpty = (attributes: FormikValues): FormikValues => {
  // Need to use a deep clone to avoid mutating the original Formik values.
  const attributesWithValues = _.cloneDeep(attributes);

  if (Array.isArray(attributesWithValues)) {
    attributesWithValues.forEach((attributeWithValue: any, i: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      Object.keys(attributesWithValues[i]).forEach((fieldName) => {
        Object.keys(attributesWithValues[i][fieldName]).forEach((firstLevelItemKey) => {
          if (attributesWithValues[i][fieldName][firstLevelItemKey] !== null) {
            if (
              typeof attributesWithValues[i][fieldName][firstLevelItemKey] === 'object' &&
              Object.keys(attributesWithValues[i][fieldName][firstLevelItemKey]).length > 0
            ) {
              Object.keys(attributesWithValues[i][fieldName][firstLevelItemKey]).forEach((secondLevelItemKey) => {
                if (
                  typeof attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey] === 'object' &&
                  Object.keys(attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey]).length > 0
                ) {
                  Object.keys(attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey]).forEach((thirdLevelItemKey) => {
                    if (isEmptyObjectOrString(attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey])) {
                      delete attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey];
                    }
                  });
                }
                if (isEmptyObjectOrString(attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey])) {
                  delete attributesWithValues[i][fieldName][firstLevelItemKey][secondLevelItemKey];
                }
              });
            }
          }
          if (isEmptyObjectOrString(attributesWithValues[i][fieldName][firstLevelItemKey])) {
            delete attributesWithValues[i][fieldName][firstLevelItemKey];
          }
        });
        if (
          typeof attributesWithValues[i][fieldName] === 'object' &&
          Object.keys(attributesWithValues[i][fieldName]).length === 0
        ) {
          delete attributesWithValues[i][fieldName];
        }
      });
    });
  } else {
    Object.keys(attributesWithValues).forEach((fieldName) => {
      Object.keys(attributesWithValues[fieldName]).forEach((firstLevelItemKey) => {
        if (attributesWithValues[fieldName][firstLevelItemKey] !== null) {
          if (
            typeof attributesWithValues[fieldName][firstLevelItemKey] === 'object' &&
            Object.keys(attributesWithValues[fieldName][firstLevelItemKey]).length > 0
          ) {
            Object.keys(attributesWithValues[fieldName][firstLevelItemKey]).forEach((secondLevelItemKey) => {
              if (attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey] !== null) {
                if (
                  typeof attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey] === 'object' &&
                  Object.keys(attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey]).length > 0
                ) {
                  Object.keys(attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey]).forEach((thirdLevelItemKey) => {
                    if (isEmptyObjectOrString(attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey])) {
                      delete attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey][thirdLevelItemKey];
                    }
                  });
                }
                if (isEmptyObjectOrString(attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey])) {
                  delete attributesWithValues[fieldName][firstLevelItemKey][secondLevelItemKey];
                }
              }
            });
          }
        }
        if (isEmptyObjectOrString(attributesWithValues[fieldName][firstLevelItemKey])) {
          delete attributesWithValues[fieldName][firstLevelItemKey];
        }
      });
      if (
        typeof attributesWithValues[fieldName] === 'object' &&
        Object.keys(attributesWithValues[fieldName]).length === 0
      ) {
        delete attributesWithValues[fieldName];
      }
    });
  }
  return attributesWithValues;
};

export const buildEmptyField = (
  fieldName: string,
  skipFields: string[],
  allExpandedFields: {[key: string]: ListingField}
): {[key: string]: string} => {
  const emptyField: {[key: string]: any } = {}; // eslint-disable-line
  Object.keys(allExpandedFields[fieldName]).filter(key => !skipFields.includes(key)).forEach((key) => {
    if (allExpandedFields[fieldName][key].meta.type === 'object') {
      emptyField[key] = buildEmptyField(key, skipFields, allExpandedFields[fieldName]);
    } else {
      emptyField[key] = '';
    }
  });
  return emptyField;
};

export const getInitialValues = (listingFieldDefinitions: ListingFieldDefinitions): { initialValues: FormikValues, allExpandedFields: {[key: string]: ListingField} } => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const initialValues: FormikValues = {};
  const skipFields = [
    'marketplace_id',
    'meta',
    'language_tag',
  ];
  let allExpandedFields: {[key: string]: ListingField} = {};

  // Loop over all field groups and go down as many levels as necessary,
  // usually three, to initialize all attributes.
  Object.keys(listingFieldDefinitions).forEach((fieldGroupName: string) => {
    const fieldGroupFields = listingFieldDefinitions[fieldGroupName].expandedFields;
    allExpandedFields = {
      ...fieldGroupFields,
      ...allExpandedFields,
    }
    Object.keys(fieldGroupFields).forEach((firstLevelFieldName: string) => {
      initialValues[firstLevelFieldName] = {};
      Object.keys(fieldGroupFields[firstLevelFieldName]).forEach((secondLevelFieldName: string) => {
        if (!skipFields.includes(secondLevelFieldName)) {
          if (Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName]).length > 1) {
            // If there are other keys besides meta initialize as an empty object.
            initialValues[firstLevelFieldName][secondLevelFieldName] = {};
            Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName]).forEach((thirdLevelFieldName: string) => {
              if (!skipFields.includes(thirdLevelFieldName)) {
                if (Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]).length > 1) {
                  // If there are other keys besides meta initialize as an empty object.
                  initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = {};
                  Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]).forEach((fourthLevelFieldName: string) => {
                    if (!skipFields.includes(fourthLevelFieldName)) {
                      initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName][fourthLevelFieldName] = '';
                    }
                  });
                } else {
                  // If there are no keys besides meta initialize as an empty string.
                  if (Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]).length > 1) {
                    // If there are other keys besides meta initialize as an empty object.
                    initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = {};
                    Object.keys(fieldGroupFields[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]).forEach((fourthLevelFieldName: string) => {
                      if (!skipFields.includes(fourthLevelFieldName)) {
                        initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName][fourthLevelFieldName] = '';
                      }
                    });
                  } else {
                    // If there are no keys besides meta initialize as an empty string.
                    initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = '';
                  }



                }
              }
            });
          } else {
            // If there are no keys besides meta initialize as an empty string.
            initialValues[firstLevelFieldName][secondLevelFieldName] = '';
          }
        }
      });
    });
  });

  // Turn initial values into arrays if necessary.
  // Making an exception for product_description to only show one instance of the field.
  // See the checks for maxUniqueItems below.
  Object.keys(initialValues).forEach((firstLevelFieldName) => {
    if (
      firstLevelFieldName !== 'product_description' &&
      allExpandedFields[firstLevelFieldName].hasOwnProperty('meta') &&
      allExpandedFields[firstLevelFieldName].meta.hasOwnProperty('maxUniqueItems') &&
      allExpandedFields[firstLevelFieldName].meta.type === 'array' &&
      (allExpandedFields[firstLevelFieldName].meta.maxUniqueItems as number) > 1
    ) {
      initialValues[firstLevelFieldName] = [
        initialValues[firstLevelFieldName]
      ];
    }
  });

  return {
    initialValues,
    allExpandedFields,
  };
};

export const fillInitialValues = (
  initialValues: FormikValues,
  amazonListingsItem: any, // eslint-disable-line
) : FormikValues => {
  const filledValues: FormikValues = {};

  Object.keys(initialValues).forEach((firstLevelFieldName) => {
    if (typeof initialValues[firstLevelFieldName] === 'object') {
      Object.keys(initialValues[firstLevelFieldName]).forEach((secondLevelFieldName) => {
        // If the attribute has an array in the first level, as in the case of bullet_point
        if (Array.isArray(initialValues[firstLevelFieldName])) {
          if (!filledValues[firstLevelFieldName]) {
            filledValues[firstLevelFieldName] = [];
          }
          initialValues[firstLevelFieldName].forEach((item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            Object.keys(item).forEach((secondLevelFieldName) => {
              if (amazonListingsItem.attributes[firstLevelFieldName]) {
                // There's data in amazonListingsItem for this field.
                amazonListingsItem.attributes[firstLevelFieldName].forEach((item: any, index: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                  if (!filledValues[firstLevelFieldName][index]) {
                    filledValues[firstLevelFieldName][index] = {};
                  }
                  filledValues[firstLevelFieldName][index][secondLevelFieldName] = item[secondLevelFieldName];
                });
              } else {
                // There's no data in amazonListingsItem for this field so
                // let's initialize according to initialValues.
                if (!filledValues[firstLevelFieldName][0]) {
                  filledValues[firstLevelFieldName][0] = {};
                }
                filledValues[firstLevelFieldName][0][secondLevelFieldName] = initialValues[firstLevelFieldName][0][secondLevelFieldName];
              }
            });
          });
        } else {
          if (
            amazonListingsItem.attributes[firstLevelFieldName] &&
            Array.isArray(amazonListingsItem.attributes[firstLevelFieldName]) &&
            amazonListingsItem.attributes[firstLevelFieldName][0] &&
            amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName]
          ) {
            const attributeValue = typeof amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName] === 'object' ? '' : amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName];
            if (attributeValue) {
              if (!filledValues[firstLevelFieldName]) {
                filledValues[firstLevelFieldName] = {};
              }
              filledValues[firstLevelFieldName][secondLevelFieldName] = attributeValue;
            }
          }
          if (typeof initialValues[firstLevelFieldName][secondLevelFieldName] === 'object') {
            Object.keys(initialValues[firstLevelFieldName][secondLevelFieldName]).forEach((thirdLevelFieldName) => {
              if (
                amazonListingsItem.attributes[firstLevelFieldName] &&
                Array.isArray(amazonListingsItem.attributes[firstLevelFieldName]) &&
                amazonListingsItem.attributes[firstLevelFieldName][0] &&
                amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName]
              ) {
                if (Array.isArray(amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName])) {
                  const attributeValue = typeof amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][0][thirdLevelFieldName] === 'object' ? '' : amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][0][thirdLevelFieldName];
                  if (attributeValue) {
                    if (!filledValues[firstLevelFieldName]) {
                      filledValues[firstLevelFieldName] = {};
                    }
                    if (!filledValues[firstLevelFieldName][secondLevelFieldName]) {
                      filledValues[firstLevelFieldName][secondLevelFieldName] = {};
                    }
                    filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = attributeValue;
                  }
                } else if (amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][thirdLevelFieldName]) {
                  if (amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][thirdLevelFieldName]) {
                    if (!filledValues[firstLevelFieldName]) {
                      filledValues[firstLevelFieldName] = {};
                    }
                    if (!filledValues[firstLevelFieldName][secondLevelFieldName]) {
                      filledValues[firstLevelFieldName][secondLevelFieldName] = {};
                    }
                    filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][thirdLevelFieldName];
                  }
                }
              } else {
                if (!filledValues[firstLevelFieldName]) {
                  filledValues[firstLevelFieldName] = {};
                }
                if (!filledValues[firstLevelFieldName][secondLevelFieldName]) {
                  filledValues[firstLevelFieldName][secondLevelFieldName] = {};
                }
                filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = '';
              }
              if (typeof initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] === 'object') {
                Object.keys(initialValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]).forEach((fourthLevelFieldName) => {
                  if (
                    amazonListingsItem.attributes[firstLevelFieldName] &&
                    Array.isArray(amazonListingsItem.attributes[firstLevelFieldName]) &&
                    amazonListingsItem.attributes[firstLevelFieldName][0] &&
                    amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName]
                  ) {
                    if (Array.isArray(amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][0][thirdLevelFieldName])) {
                      if (amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][0][thirdLevelFieldName][0][fourthLevelFieldName]) {
                        if (!filledValues[firstLevelFieldName]) {
                          filledValues[firstLevelFieldName] = {};
                        }
                        if (!filledValues[firstLevelFieldName][secondLevelFieldName]) {
                          filledValues[firstLevelFieldName][secondLevelFieldName] = {};
                        }
                        if (!filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]) {
                          filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = {};
                        }
                        filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName][fourthLevelFieldName] = amazonListingsItem.attributes[firstLevelFieldName][0][secondLevelFieldName][0][thirdLevelFieldName][0][fourthLevelFieldName];
                      }
                    }
                  } else {
                    if (!filledValues[firstLevelFieldName]) {
                      filledValues[firstLevelFieldName] = {};
                    }
                    if (!filledValues[firstLevelFieldName][secondLevelFieldName]) {
                      filledValues[firstLevelFieldName][secondLevelFieldName] = {};
                    }
                    if (!filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName]) {
                      filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName] = {};
                    }
                    filledValues[firstLevelFieldName][secondLevelFieldName][thirdLevelFieldName][fourthLevelFieldName] = '';
                  }
                });
              }
            });
          }
        }
      });
    }
  });
  const result = {
    ...initialValues,
    ...filledValues,
  };
  return result;  
};
