import React, {useEffect, useState, useCallback} from 'react';
import {Grid, Typography, Button, LinearProgress} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {Visibility, VisibilityOutlined} from '@mui/icons-material';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {BuildMenu} from '../../components/menu';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {MenuStore} from '../../../../App';
import {BrandInterface} from '../../../../interfaces/brands/brand';

function ViewBrand(): JSX.Element {
  const {id} = useParams<Record<string, string>>();

  const [brandInfo, setBrandInfo] = useState({} as BrandInterface);
  const [loadingData, setLoadingData] = useState(false);

  const history = useHistory();

  const getBrandInfo = useCallback(async () => {
    setLoadingData(true);
    try {
      const {data} = await Api.get(`/brands/${id}`);
      setBrandInfo(data);
    } catch (e) {
      errorAlert('Unable to get Brand Info', e);
    } finally {
      setLoadingData(false);
    }
  }, [id]);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = BuildMenu('detail', id, history);
    });
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, [id, history]);

  useEffect(() => {
    getBrandInfo();
  }, [getBrandInfo]);

  async function watchBrand() {
    try {
      await Api.post(`brands/watchlist/watch/${id}`, null);
      setBrandInfo((prev) => ({
        ...prev,
        watched: true,
      }));
    } catch (e) {
      errorAlert('Unable to add Asin to watchlist', e);
    }
  }

  function unWatchBrand() {
    try {
      Api.post(`brands/watchlist/unwatch/${id}`, null);
      setBrandInfo((prev) => ({
        ...prev,
        watched: false,
      }));
    } catch (e) {
      errorAlert('Unable to remove Asin from watchlist', e);
    }
  }

  return (
    <TableContainer className="sub-page-details">
      {loadingData ? (
        <Grid item lg={12} style={{paddingBottom: 30}}>
          <Typography>Loading Brand Information ...</Typography>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid item lg={12} style={{paddingBottom: 30}}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              {brandInfo ? (
                brandInfo?.watched ? (
                  <>
                    <Button
                      color="primary"
                      onClick={unWatchBrand}
                      startIcon={<Visibility />}
                      style={{textTransform: 'none'}}
                    >
                      <Typography component="h6" variant="subtitle1" color="inherit">
                        Unwatch
                      </Typography>
                    </Button>
                    <br />
                  </>
                ) : (
                  <>
                    <Button onClick={watchBrand} startIcon={<VisibilityOutlined />} style={{textTransform: 'none'}}>
                      <Typography component="h6" variant="subtitle1" color="inherit">
                        Watch
                      </Typography>
                    </Button>
                    <br />
                  </>
                )
              ) : (
                ''
              )}
              {brandInfo?.parent_brand_code && (
                <>
                  <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                    Parent Brand Code
                  </Typography>
                  <Typography component="h2" variant="h5" color="primary" gutterBottom>
                    {brandInfo?.parent_brand_code || 'N/A'}
                  </Typography>
                </>
              )}

              {brandInfo?.seller_central_account_location && (
                <>
                  <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                    Seller Central Account Location
                  </Typography>
                  <Typography component="h2" variant="h5" color="primary" gutterBottom>
                    {brandInfo?.seller_central_account_location || 'N/A'}
                  </Typography>
                </>
              )}

              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Brand Code
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.brand_code || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Brand Name
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.name || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Logo
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo && brandInfo.logo ? (
                  <img style={{backgroundColor: '#245fac'}} src={brandInfo?.logo || ''} alt={''} width={200} />
                ) : (
                  'N/A'
                )}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Product Image
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo && brandInfo.product_image ? (
                  <img style={{backgroundColor: '#245fac'}} src={brandInfo?.product_image || ''} alt={''} width={200} />
                ) : (
                  'N/A'
                )}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Status
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.brand_status?.name || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Seller ID
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.seller_id || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                MWS Auth Token
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.mws_auth_token || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Brand Type
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.brand_type?.name || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Account Manager
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.account_manager?.name || 'N/A'}
              </Typography>
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                Think Tank
              </Typography>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {brandInfo?.think_tank?.name || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <CustomAlert id="default-alert" />
        </Grid>
      )}
    </TableContainer>
  );
}

export {ViewBrand};
