import React, {useEffect, useState, useMemo} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import _ from 'lodash';
import {useLocation} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef, GridSortDirection} from '@mui/x-data-grid';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import {CustomAlert} from '../Login/components/CustomAlert';
import {CommissionChart} from './components/CommissionChart';
// utils
import {getDefaultQuery, updateQueryParams} from '../../utils/urlParams';
import {onFromToChange} from '../../utils/OnFromToChange';
import {Helper} from '../../utils/helper';
import {Api, errorAlert} from '../../utils/api';
// styles
import {sxStyles} from './Styles';
// interfaces
import {DateRangeInterface} from '../../interfaces/date/date';
import {ChartDataTypes} from '../Performance/components/PerformanceChart';

type GroupTypes = {
  id: string;
  value: number;
  old_value: number;
  predicted: number;
  old_predicted: number;
  category: string;
};

interface CommissionsInterface {
  account_manager: string;
  brand_code: string;
  category: string[];
  name: string;
  predicted: number;
  think_tank: string;
  value: number;
  id: string;
}

interface LoadDataProps {
  from: string;
  to: string;
  compareFrom: string | null;
  compareTo: string | null;
  metric?: string[];
  filter: {brands: string[]; categories: string[]};
  groupBy?: string | null;
  selected?: string[];
  data?: CommissionsInterface[];
}

interface LoadMetricProps {
  from: string;
  to: string;
  compareFrom: string | null;
  compareTo: string | null;
  selected: string[];
}

type FilterData = {
  metric: string[];
  from: string;
  to: string;
  compareFrom?: string | null;
  compareTo?: string | null;
  filterBrands: string[];
  filterCategories: string | string[];
  groupBy: string;
  sortBy: string;
  sortDirection: string;
};

interface CalculateGroupBy {
  rows?: CommissionsInterface[];
  groupBy?: string;
  compare?: boolean;
  filter: {brands: string[]; categories: string[]};
}

function Commissions(): JSX.Element {
  document.title = 'Commissions';
  const columns: GridColDef[] = [
    {
      field: 'brand_code',
      headerName: 'Brand',
      width: 250,
      renderCell: (x) => <Link to={`/commissions-detail?brand=${x.value}`}>{x.value}</Link>,
    },
    {field: 'category', headerName: 'Category', width: 200},
    {field: 'account_manager', headerName: 'Account manager', width: 180},
    {
      field: 'value',
      align: 'right',
      headerName: 'Commission',
      width: 220,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      // sortComparator: (v1, v2, c1, c2) => sortCompare(c1, c2),
    },
    {
      field: 'predicted',
      align: 'right',
      headerName: 'Forecast',
      width: 220,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
    },
  ];

  const categoryColumns: GridColDef[] = [
    {field: 'category', headerName: 'Category', flex: 0.2},
    {
      field: 'value',
      align: 'right',
      headerName: 'Commission',
      width: 200,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      // sortComparator: (v1, v2, c1, c2) => sortCompare(c1, c2),
    },
    {
      field: 'predicted',
      align: 'right',
      headerName: 'Predicted',
      width: 180,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
    },
  ];

  // function sortCompare(cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) {
  //   const value1 = cellParams1.value !== null ? Math.round(+cellParams1.value * 100) / 100 : 0;
  //   const value2 = cellParams2.value !== null ? Math.round(+cellParams2.value * 100) / 100 : 0;
  //   if (!compare) {
  //     return value1 - value2;
  //   }
  //   const old_field = ('old_' + cellParams1.field) as keyof typeof cellParams1.rowNode;
  //   const old_field_2 = ('old_' + cellParams2.field) as keyof typeof cellParams1.rowNode;
  //   const oldValue1 =
  //     'old_' + cellParams1.field in cellParams1.rowNode && cellParams1.rowNode[old_field]
  //       ? ((+cellParams1.rowNode[old_field] as number) * 100) / 100
  //       : 0;
  //   const percentage1 =
  //     cellParams1.value !== null && cellParams1.rowNode[old_field] !== null && cellParams1.rowNode[old_field] !== 0
  //       ? ((value1 - oldValue1) * 100) / oldValue1
  //       : null;
  //   const oldValue2 =
  //     'old_' + cellParams2.field in cellParams2.rowNode && cellParams2.rowNode[old_field_2]
  //       ? (+cellParams2.rowNode[old_field_2] * 100) / 100
  //       : 0;
  //   const percentage2 =
  //     cellParams2.value !== null && cellParams2.rowNode[old_field_2] !== null && cellParams2.rowNode[old_field_2] !== 0
  //       ? ((value2 - oldValue2) * 100) / oldValue2
  //       : 0;
  //   if (percentage1 === null) {
  //     return -1;
  //   }
  //   if (percentage2 === null) {
  //     return 1;
  //   }
  //   return percentage1 - percentage2;
  // }

  function renderAmountCell(x: GridCellParams) {
    const value = x.value != null ? Math.round(parseFloat(x.value.toString()) * 100) / 100 : null;
    let oldValue: string | number | null = null;
    let percentage: string | number | null = null;
    if ('old_' + x.field in x.row) {
      oldValue =
        x.row['old_' + x.field] !== null
          ? Math.round(parseFloat(x.row['old_' + x.field].toString()) * 100) / 100
          : 'N/A';
      if (value !== null && x.row['old_' + x.field] != null && x.row['old_' + x.field] !== 0)
        percentage = +(((value - +oldValue) * 100) / +oldValue).toFixed(2);
    }
    return (
      <div>
        <Typography color={'primary'}>{x.value != null ? Helper.formatAmount(value as number) : 'N/A'}</Typography>
        {'old_' + x.field in x.row ? <Typography color={'textSecondary'}>{oldValue}</Typography> : ''}
        {percentage ? (
          <Typography sx={percentage >= 0 ? sxStyles('green') : sxStyles('red')} color={'primary'}>
            {percentage < 0 ? '' : '+'}
            {percentage}%
          </Typography>
        ) : (
          ''
        )}
      </div>
    );
  }

  const location = useLocation();
  const toValue = moment(new Date());
  const [fromTo, setFromTo] = useState<DateRangeInterface>({
    from: moment(toValue).subtract(5, 'months').startOf('month').format('YYYY-MM-DD') as string,
    to: moment(toValue).endOf('month').format('YYYY-MM-DD') as string,
    compareFrom: null,
    compareTo: null,
  });
  const [metric, setMetric] = useState<string[]>([]);
  const [period, setPeriod] = useState('6m');
  const [groupBy, setGroupBy] = useState('brand_code');
  const [groupRows, setGroupRows] = useState<GroupTypes[]>([]);
  const [sortBy, setSortBy] = useState<string | null>('brand_code');
  const [sortDirection, setSortDirection] = useState<string | undefined | null>('asc');

  const [skuRows, setSkuRows] = useState<CommissionsInterface[]>([]);
  const [filteredRows, setFilteredRows] = useState<CommissionsInterface[]>([]);
  const [filter, setFilter] = useState<CalculateGroupBy['filter']>({
    brands: [],
    categories: [],
  });
  const [metricData, setMetricData] = useState<ChartDataTypes | null>(null);
  const [filterData, setFilterData] = useState({} as FilterData);

  const [loadingSku, setLoadingSku] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  const params = useMemo(
    () => ({
      metric: metric?.length !== skuRows.length ? metric.toString() : null,
      from: fromTo.from,
      to: fromTo.to,
      compareFrom: fromTo.compareFrom,
      compareTo: fromTo.compareTo,
      filterBrands: filter?.brands?.toString(),
      filterCategories: filter?.categories?.toString(),
      groupBy: groupBy !== 'brand_code' ? groupBy : null,
      sortBy: sortBy,
      sortDirection: sortDirection,
    }),
    [metric, fromTo, filter, groupBy, sortBy, sortDirection, skuRows.length],
  );

  useEffect(() => {
    const defaultQuery = getDefaultQuery(location, params, {
      from: fromTo.from,
      to: fromTo.to,
      preset: period,
    });

    setMetric(
      defaultQuery.metric && defaultQuery.metric.length > 0 ? defaultQuery.metric?.split(',') : ['commissions'],
    );
    loadSkus({
      from: defaultQuery.from || (fromTo.from as string),
      to: defaultQuery.to || (fromTo.from as string),
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
      metric: defaultQuery.metric?.split(',') || ['commissions'],
      filter: {
        brands: defaultQuery.filterBrands?.split(',') || [],
        categories: defaultQuery.filterCategories?.split(',') || [],
      },
      groupBy: defaultQuery.groupBy,
    });
    setFromTo({
      from: defaultQuery.from || fromTo.from,
      to: defaultQuery.to || fromTo.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset || period);
    setCompare(defaultQuery.compareFrom != null);
    if (defaultQuery.groupBy) setGroupBy(defaultQuery.groupBy);
    if (defaultQuery.sortBy && defaultQuery.sortDirection) {
      setSortBy(defaultQuery.sortBy);
      setSortDirection(defaultQuery.sortDirection);
    }
    setFilterData(defaultQuery as unknown as FilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateQueryParams(location, params);
  }, [location, params]);

  async function loadSkus({from, to, compareFrom, compareTo, metric, filter, groupBy}: LoadDataProps) {
    setLoadingSku(true);
    try {
      const {data: brandData} = await Api.get(
        `brand-commission/all?from=${from}&to=${to}${compareFrom ? `&compareFrom=${compareFrom}` : ''}${
          compareTo ? `&compareTo=${compareTo}` : ''
        }`,
      );
      const data = brandData.map((x: {brand_code: string; category: string}) => {
        return {id: x.brand_code, ...x, category: x.category ? x.category?.split(',') : []};
      });
      setSkuRows(data);
      setFilteredRows(data);
      onFilterChange({filter, data, from, to, compareFrom, compareTo, metric, groupBy});
    } catch (e) {
      errorAlert('Unable to get Commissions', e, {id: 'commissions'});
    } finally {
      setLoadingSku(false);
    }
  }

  async function loadMetric({selected, from, to, compareFrom, compareTo}: LoadMetricProps) {
    setLoadingChart(true);
    try {
      const {data} = await Api.post(`brand-commission/metric`, {
        brands: selected,
        from: from,
        to: to,
        compareFrom: compareFrom,
        compareTo: compareTo,
      });
      setLoadingChart(false);
      setMetricData({data: data});
    } catch (e) {
      errorAlert('Unable to get metric data', e, {id: 'commission-chart'});
    } finally {
      setLoadingChart(false);
    }
  }

  function toggleCompare(checked: boolean) {
    setCompare(checked);
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      compareFrom: null,
      compareTo: null,
      preset: period,
      compare: checked,
      setFromTo: setFromTo,
    });
  }

  function onFilterChange({filter, data, from, to, compareFrom, compareTo, groupBy}: LoadDataProps) {
    let result = data;
    if (filter?.brands && filter.brands.length) {
      result = result?.filter((x) => filter.brands.includes(x.brand_code));
    }
    if (filter?.categories && filter.categories.length) {
      result = result?.filter((x) => x.category.some((y) => filter.categories.includes(y)));
    }
    if (filter) {
      setFilter(filter);
    }
    if (result) {
      setFilteredRows(result);
    }
    if (groupBy !== 'brand_code') {
      calculateGroupBy({rows: result, groupBy: groupBy as string, compare: !!(compareFrom && compareTo), filter});
    }
    const selected = result?.map((x) => x.brand_code);
    if (selected?.length && selected?.length > 0) {
      loadMetric({selected, from, to, compareFrom, compareTo});
    } else {
      setMetricData(null);
    }

    setFilterData((prev) => {
      return {
        ...prev,
        filterBrands: filter?.brands || [],
        filterCategories: filter?.categories || [],
      };
    });
  }

  function calculateGroupBy({rows, groupBy, compare, filter}: CalculateGroupBy) {
    setGroupRows(
      _.map(_.uniq(_.flatMap(rows, 'category')), (idx) => {
        const filteredRows = _.filter(rows, (y) => y.category.includes(idx));
        const result = compare
          ? {
              id: idx,
              value: _.sumBy(filteredRows, 'value'),
              old_value: _.sumBy(filteredRows, 'old_value'),
              predicted: _.sumBy(filteredRows, 'predicted'),
              old_predicted: _.sumBy(filteredRows, 'old_predicted'),
            }
          : ({
              id: idx,
              value: _.sumBy(filteredRows, 'value'),
              predicted: _.sumBy(filteredRows, 'predicted'),
            } as GroupTypes);
        switch (groupBy) {
          default:
            return {
              category: idx,
              ...result,
            };
        }
      })
        .filter((x) => x.id !== 'null' && x.id !== '')
        .filter((x) => (filter?.categories?.length ? filter.categories.includes(x.id) : true)),
    );
  }

  function onGroupBy(v: string) {
    setGroupBy(v);
    setSortBy(null);
    setSortDirection('asc');
    calculateGroupBy({rows: filteredRows, groupBy: v, compare, filter});
  }

  function onFilterData() {
    loadSkus({
      from: fromTo.from as string,
      to: fromTo.to as string,
      compareFrom: fromTo.compareFrom as string,
      compareTo: fromTo.compareTo as string,
      metric,
      filter,
      groupBy,
    });
  }

  return (
    <TableContainer>
      <DateRangeButtons
        fromTo={fromTo}
        compare={compare}
        period={period}
        setPeriod={(v) => setPeriod(v)}
        setFromTo={(v) => setFromTo(v)}
        compareOnChange={(e) => toggleCompare(e.target.checked)}
        compareComponent
        loadData={(preset, from, to, compareFrom, compareTo) => {
          const queryParams = {
            ...filterData,
            from: from,
            to: to,
            compareFrom: compareFrom,
            compareTo: compareTo,
            metric: metric.toString(),
          };
          setFilterData(queryParams as unknown as FilterData);
        }}
        showButton
        buttonOnClick={() => onFilterData()}
        pageTitle="Commissions"
      />
      <CommissionChart data={metricData} loading={loadingChart} />
      <Grid container sx={sxStyles('formGroup')}>
        <div>
          <Autocomplete
            size="small"
            multiple
            options={Array.from(
              new Set(
                skuRows
                  .map((x) => x.brand_code)
                  .filter((x) => x !== null)
                  .sort(),
              ),
            )}
            getOptionLabel={(option) => {
              const brandName = skuRows.find((x) => x.brand_code === option)?.name;
              return brandName ? `${option} -  ${brandName}` : option;
            }}
            value={filter?.brands ? filter.brands : []}
            onChange={(event, value) => {
              onFilterChange({
                filter: {...filter, brands: value},
                data: skuRows,
                from: fromTo.from as string,
                to: fromTo.to as string,
                compareFrom: fromTo.compareFrom as string,
                compareTo: fromTo.compareTo as string,
                metric,
                groupBy,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
            )}
          />
          <Autocomplete
            size="small"
            multiple
            options={skuRows
              .filter((x) => x.category.length > 0)
              .map((x) => x.category.map((x) => x.trim()))
              .flat()
              .sort()
              .filter((x, i, a) => a.indexOf(x) === i)}
            getOptionLabel={(option) => option}
            value={filter?.categories ? filter.categories : []}
            onChange={(event, value) => {
              onFilterChange({
                filter: {...filter, categories: value},
                data: skuRows,
                from: fromTo.from as string,
                to: fromTo.to as string,
                compareFrom: fromTo.compareFrom as string,
                compareTo: fromTo.compareTo as string,
                metric,
                groupBy,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Filter by categories" placeholder="Category" />
            )}
          />
        </div>
        <FormControl variant="outlined" style={{width: '100%'}} size="small">
          <InputLabel id="demo-simple-select-outlined-label">Group by</InputLabel>
          <Select
            value={groupBy ? groupBy : 'brand_code'}
            onChange={(e) => onGroupBy(e.target.value)}
            label={'Group by'}
            labelId="demo-simple-select-outlined-label"
            variant="outlined"
          >
            <MenuItem value={'brand_code'}>Brand</MenuItem>
            <MenuItem value={'category'}>Category</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
        <CustomAlert id="commissions" />
        <DataGrid
          sx={sxStyles('grid')}
          autoHeight={true}
          rows={groupBy === 'brand_code' ? filteredRows : groupRows}
          disableSelectionOnClick={true}
          columns={groupBy === 'brand_code' ? columns : categoryColumns}
          loading={loadingSku}
          disableColumnFilter
          rowHeight={72}
          pagination
          pageSize={40}
          onSortModelChange={(x) => {
            setSortBy(x[0]?.field);
            setSortDirection(x[0]?.sort);
          }}
          sortModel={
            sortBy && sortDirection
              ? [
                  {
                    field: sortBy,
                    sort: sortDirection as GridSortDirection,
                  },
                ]
              : []
          }
        />
      </Grid>
    </TableContainer>
  );
}

export {Commissions};
